import React from 'react';
import {
  CheckboxField,
  Field,
  RadioField,
} from 'signer-app/types/editor-types';
import { notEmpty } from 'signer-app/utils/not-empty';

const isGroupedField = (field: Field): field is RadioField | CheckboxField =>
  'group' in field;

// The next button in the signer app is still managed by the legacy models,
// which don't have a concept of "hidden" fields. When the next button lands the
// user  in a hidden field, this hook will automatically move them to the next
// non-hidden field.
export default function useSkipHiddenFields(
  fields: Field[],
  selectedFieldIds: string[],
  setCurrentField: (apiId: string) => void,
) {
  const visibleFields = selectedFieldIds
    .map((id) => fields.find((f) => f.id === id))
    .filter((f) => notEmpty(f) && !f.hidden);

  React.useLayoutEffect(() => {
    // In the signer app selectedFieldIds should only ever contain a single
    // item.
    if (visibleFields.length === 0 && selectedFieldIds.length === 1) {
      let found = false;
      // keeps track of indexes of the first field in each checkbox/radio group
      const groupIndexes = new Map<string, number>();

      // If ALL of the fields after selectedFieldIds were hidden, the `pass`
      // loop will come back around and select the first non-hidden field in the
      // document.
      for (let pass = 1; pass <= 2; pass++) {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (found && !field.hidden) {
            // if this field is checkbox/radio we need to check if it is the first one in the group
            if (isGroupedField(field) && field.group) {
              const group = field.group;
              if (!groupIndexes.has(group)) {
                const groupIndex = fields.findIndex(
                  (field) => isGroupedField(field) && field.group === group,
                );
                groupIndexes.set(field.group, groupIndex);
              }
              const groupIndex = groupIndexes.get(group);
              if (groupIndex === i) {
                setCurrentField(field.id);
                return;
              }
            } else {
              setCurrentField(field.id);
              return;
            }
          } else if (selectedFieldIds.includes(field.id)) {
            found = true;
          }
        }
      }
    }
  }, [fields, selectedFieldIds, setCurrentField, visibleFields.length]);
}
