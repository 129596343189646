export default class UserError extends Error {
  public translated: boolean;

  constructor(msg: string, translated: boolean = false) {
    super(msg);
    this.translated = translated;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UserError.prototype);
  }
}
