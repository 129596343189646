/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  no-void
*/

import { intl } from 'hellospa/common/hs-intl-provider';
import hsMessages from 'signer/components/common/translations/hs-messages';
import Field from './field';

module.exports = Field.extend({
  /**
   */
  getValidationError() {
    if (!this.required) {
      return;
    }
    if (
      this.value == void 0 ||
      String(this.value).replace(/[\n\r]/g, '').length < 1
    ) {
      return new Error(intl.formatMessage(hsMessages.requiredMessage));
    }
  },

  /**
   */
  toData() {
    const data = Field.prototype.toData.call(this);
    data.fontSize = this.fontSize;
    data.value =
      this.isMultiLine && this.value
        ? this.textUtils.getLines(this.fontSize).join('\n')
        : this.value;
    return data;
  },

  /**
   */
  fromData(data) {
    const props = Field.prototype.fromData.call(this, data);
    props.fontSize = data.fontSize;
    props.fontFamily = data.fontFamily;
    props.validationType = data.validationType || null;
    props.validationCustomRegex = data.validationCustomRegex || null;
    props.validationCustomRegexFormatLabel =
      data.validationCustomRegexFormatLabel || null;
    props.name = data.name || '';
    props.width += 3; // NOTE: This is to account for left padding on the textarea
    return props;
  },
});
