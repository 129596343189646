import React from 'react';
import DisplayDocument, { fieldsContext } from 'signer-app/signature-request';
import wrapPage from 'signer-app/signer-signature-document/wrap-page';
import GroupedFields from 'signer-app/signer-signature-document/grouped-fields';
import { CheckboxField, RadioField } from 'signer-app/types/editor-types';

type GroupableFields = CheckboxField | RadioField;

type FieldGroups = Record<string, Array<GroupableFields>>;

function SignerDocument() {
  const fields = React.useContext(fieldsContext);

  const groupedFields = React.useMemo(() => {
    return (fields as CheckboxField[]).reduce((groups, f) => {
      if (f.group != null) {
        groups[f.group] = groups[f.group] || [];
        groups[f.group].push(f);
      }
      return groups;
    }, {} as FieldGroups);
  }, [fields]);

  return (
    <DisplayDocument wrapPage={wrapPage}>
      <GroupedFields groupedFields={groupedFields} />
    </DisplayDocument>
  );
}

export default React.memo(SignerDocument);
