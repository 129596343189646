import hsFetch from 'js/sign-components/common/hs-fetch';
import type {
  Experiments,
  SubscriptionVariations,
} from 'hellospa/components/experiments';

export async function fetchVariations(
  experiments: Experiments[],
): Promise<SubscriptionVariations> {
  const query: string = experiments
    .map((experiment) => `experiments[]=${experiment}`)
    .join('&');
  const url = `/endpoint/org/getExperimentVariations?${query}`;
  const response = await hsFetch(url);
  if (!response.ok) {
    throw Error(`${response.status}: Failed to fetch ${url}`);
  }
  const body = await response.json();
  return body.data;
}
