import { assertDataSchemaResponse } from 'signer-app/utils/response-helpers';
import type { CountryCode } from 'signer-app/authentication/countries';
import hsFetch, { getCSRFToken } from 'signer-app/utils/hs-fetch';

import {
  AcceptTermsData,
  DropboxManagedCheckResponse,
  SamlCheckResponse,
  SignInResponse,
  SignUpData,
  SignUpResponse,
} from 'js/sign-components/generated/types/WebApp';

import {
  validateAcceptTermsData,
  validateDropboxManagedCheckResponse,
  validateSamlCheckResponse,
  validateSignInResponse,
  validateSignUpData,
  validateSignUpResponse,
} from 'js/sign-components/generated/validators/validateWebApp';

export async function getSignupData(
  teamInviteGuid: string | null,
  onLoginRedirectUrl?: string | null,
  isOfficeAddIn?: boolean,
): Promise<SignUpData> {
  const url = '/api/sign-up-data';

  const searchParams = new URLSearchParams();
  if (teamInviteGuid) {
    searchParams.set('teamInviteGuid', teamInviteGuid);
  }

  if (onLoginRedirectUrl) {
    searchParams.set('on_login_redirect_url', onLoginRedirectUrl);
  }

  if (isOfficeAddIn) {
    searchParams.set('is_office_add_in', String(isOfficeAddIn));
  }

  const response = await hsFetch(`${url}?${searchParams}`);

  return assertDataSchemaResponse(response, validateSignUpData);
}

export async function getTermsData(): Promise<AcceptTermsData> {
  const url = '/api/account/accept-terms-data';

  const response = await hsFetch(url);

  return assertDataSchemaResponse(response, validateAcceptTermsData);
}

type SignUpArgs = {
  emailAddress: string;
  firstName?: string | null;
  lastName?: string | null;
  redirectUrl?: string | null;
  pendingTsmGroupGuid?: string;
  teamInviteGuid?: string | null;
  countryCode?: CountryCode;
  isOptedInMarketingEmails: boolean;
  arkoseToken?: string;
};

export async function signUp({
  emailAddress,
  firstName,
  lastName,
  redirectUrl,
  pendingTsmGroupGuid,
  teamInviteGuid,
  countryCode,
  isOptedInMarketingEmails,
  arkoseToken,
}: SignUpArgs): Promise<SignUpResponse> {
  const formData = new FormData();

  formData.append('force_delay_analytics_js', 'true');
  formData.append('async', '1');

  if (arkoseToken) {
    formData.append('arkose_token', arkoseToken);
  }

  formData.append('signup[email_address]', emailAddress);
  formData.append(
    'signup[is_opted_in_marketing_emails]',
    isOptedInMarketingEmails ? 'true' : 'false',
  );
  formData.append('signup[_csrf_token]', getCSRFToken());

  if (firstName) {
    formData.append('signup[first_name]', firstName);
  }

  if (lastName) {
    formData.append('signup[last_name]', lastName);
  }

  if (redirectUrl) {
    formData.append('signup[redirect_url]', redirectUrl);
  }

  if (pendingTsmGroupGuid) {
    formData.append('signup[pending_tsm_group_guid]', pendingTsmGroupGuid);
  }

  if (teamInviteGuid) {
    formData.append('signup[team_invite_guid]', teamInviteGuid);
  }

  if (countryCode) {
    formData.append('signup[country_code]', countryCode);
  }

  const url = '/account/signUp';
  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  return assertDataSchemaResponse(response, validateSignUpResponse);
}

type SamlCheckArgs = {
  emailAddress: string;
  redirectUrl?: string;
};

export async function samlCheck({
  emailAddress,
  redirectUrl,
}: SamlCheckArgs): Promise<SamlCheckResponse> {
  const qs = new URLSearchParams();

  qs.append('account', emailAddress);
  qs.append('csrfToken', getCSRFToken());

  if (redirectUrl) {
    qs.append('redirect_url', redirectUrl);
  }

  const url = `/account/samlLoginVerification?${qs}`;
  const response = await hsFetch(url, {
    method: 'POST',
    body: JSON.stringify({
      account: emailAddress,
      redirect_url: redirectUrl,
      csrfToken: getCSRFToken(),
    }),
  });

  return assertDataSchemaResponse(response, validateSamlCheckResponse);
}

type DropboxManagedArgs = {
  emailAddress: string;
};

export async function dropboxManagedCheck({
  emailAddress,
}: DropboxManagedArgs): Promise<DropboxManagedCheckResponse> {
  const qs = new URLSearchParams();

  qs.append('email_address', emailAddress);

  const url = `/account/getDropboxManagedAccountStatus?${qs}`;
  const response = await hsFetch(url, {
    method: 'POST',
    body: JSON.stringify({
      email_address: emailAddress,
    }),
  });

  return assertDataSchemaResponse(
    response,
    validateDropboxManagedCheckResponse,
  );
}

type SignInArgs = {
  emailAddress: string;
  password: string;
  arkoseToken?: string;
  redirectUrl?: string | null;
  rememberMe?: boolean;
  pendingTsmGroupGuid?: string;
  closeAfterLogin?: boolean;
};

export async function signIn({
  emailAddress,
  password,
  arkoseToken = '',
  redirectUrl = '',
  pendingTsmGroupGuid = '',
  closeAfterLogin = false,
  rememberMe = false,
}: SignInArgs): Promise<SignInResponse> {
  const url = '/account/logIn';

  const formData = new FormData();

  formData.append('login[email_address]', emailAddress);
  formData.append('login[password]', password);
  formData.append('login[should_remember_me]', `${rememberMe}`);
  formData.append('login[_csrf_token]', getCSRFToken());
  formData.append('login[pending_tsm_group_guid]', pendingTsmGroupGuid);
  formData.append('arkose_token', arkoseToken);
  formData.append('async', '1');
  formData.append('closeAfterLogin', closeAfterLogin ? '1' : '0');

  if (redirectUrl) {
    formData.append('login[redirect_url]', redirectUrl);
  }

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  return assertDataSchemaResponse(response, validateSignInResponse);
}
