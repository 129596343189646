import React from 'react';
import debounce from 'lodash/debounce';
import { shallowEqual } from 'react-redux';
import { useFeatureFlag } from 'js/sign-components/common/feature-flag';
import { DEFAULT_FONT_SIZE } from 'signer-app/signature-request';
import ErrorBoundary from 'signer-app/parts/error-boundary';
import FullPageLoader from 'signer-app/parts/full-page-loader';
import SignerContext from 'signer-app/signer-signature-document/context';
import SignerDocument from 'signer-app/signer-signature-document/document';
import { useSignerAppClient } from 'signer-app/context/signer-app-client';
import { AccessibleDocument } from 'signer-app/signer-signature-document/accessible-document.tsx';
import { useAtomValue } from 'jotai';
import { accessibleViewAtom } from 'signer/components/main/pages/signature-request/toggle-accessible-view';

const findField = (fields, predicate) => {
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (field.children) {
      const result = findField(field.children, predicate);
      if (result) {
        return result;
      }
    }
    if (predicate(field)) {
      return field;
    }
  }
};

/**
 * This symbol allows me to tag a model as hidden without having to go change
 * the data the model holds.
 *
 * When moving to the confirm page `<Signer` (in this file) is unmounted and
 * re-mounted. That process destroys all of the `fieldData` objects, and they
 * have to be recreated from the models. When a field is hidden, the data is
 * removed from the model and then is restored if the field is un-hidden.
 * DEV-10199 seems to be caused by un-hiding already visible fields when moving
 * to the confirm page.
 */
export const isHidden = Symbol('hidden');
const originalRequired = Symbol('originalRequired');
const requestCache = {};
function useFetchSignatureRequest(model, fetchUnifedJson, appContext) {
  const [state, setState] = React.useState();
  const { guid } = model;
  React.useEffect(() => {
    // The linter says not to use an async function passed directly to useEffect
    // "Effect callbacks are synchronous to prevent race conditions. Put the async function inside
    // ...
    // Learn more about data fetching with Hooks: https://fb.me/react-hooks-data-fetching"
    async function fetchData() {
      if (!requestCache[guid]) {
        // This component is rendered in `<SignatureRequestPreview`,
        // `<SignatureRequest`, and `<SignatureRequestConfirm`. This request
        // cache allows us to fetch the data once and use it across all 3 pages.
        if (fetchUnifedJson) {
          requestCache[guid] = appContext.signer.loadUnifiedData(
            model.settings.cachedParamsToken,
          );
        } else {
          requestCache[guid] = appContext.signer.loadData(
            model.settings.cachedParamsToken,
          );
        }
      }
      const signatureRequest = await requestCache[guid];

      // When switching between `<SignatureRequest` and
      // `<SignatureRequestConfirm`, the cached fields don't have the values the
      // user just entered. While we could re-fetch the data from the server, I
      // think this is a good case for synchronizing the values from the  legacy
      // models.
      const fields = signatureRequest.fields.map((fieldData) => {
        const fieldModel = findField(
          model.fields,
          (field) => field.data.apiId === fieldData.id,
        );

        if (fieldModel != null) {
          // `fieldData` seems to arrive with conditional logic already having
          // run. That means if a field would be required, but is currently
          // hidden, it comes down with a `required: false`.
          fieldModel[originalRequired] =
            fieldModel[originalRequired] ?? fieldModel.required;
          // eslint-disable-next-line no-param-reassign
          fieldData = {
            ...fieldData,
            originalRequired:
              fieldData.originalRequried ?? fieldModel[originalRequired],
          };

          // if the field is actually hidden, just return the original field data
          // and skip trying to retrieve the values from the model
          if (fieldModel[isHidden]) {
            return fieldData;
          }

          switch (fieldData.type) {
            case 'text':
            case 'hyperlink': {
              const originalFontSize =
                fieldModel.data.originalFontSize || DEFAULT_FONT_SIZE;

              return {
                ...fieldData,
                value: fieldModel.value,
                fontSize: fieldModel.fontSize || originalFontSize,
                originalFontSize,
              };
            }
            case 'radiobutton':
            case 'checkbox':
              return { ...fieldData, checked: fieldModel.value };
            case 'date':
              return fieldData;
            case 'signature':
            case 'initials': {
              const guid = fieldModel.value && fieldModel.value.guid;
              if (guid) {
                return { ...fieldData, signature: { guid } };
              }
              return fieldData;
            }
            case 'dropdown': {
              return {
                ...fieldData,
                value: fieldModel.value,
              };
            }
            default:
              // eslint-disable-next-line no-console
              console.warn('Unhandled', fieldData.type, fieldModel);
          }
        }

        return fieldData;
      });

      setState({ ...signatureRequest, fields });
    }
    fetchData();
  }, [guid, model, fetchUnifedJson, appContext]);

  return state;
}

function useRouterAdapter(legacyApp) {
  const routerState = legacyApp.router.location.state;
  const routerGUID =
    (routerState.currentField && routerState.currentField.guid) || null;

  // If using the keyboard navigation flag, we want to keep track of _both_ of
  // the router IDs instead of just the first one. We want to be able to
  // activate any field within a group, not just the first.
  const routerApiIds = React.useMemo(() => {
    if (routerGUID) {
      const field = legacyApp.signatureRequest.fields.find(
        (field) => field.guid === routerGUID,
      );

      if (field && field.children && field.children.length > 0) {
        return field.children.map((field) => field.data.apiId);
      }

      if (field) {
        return [field.data.apiId];
      }
    }
    return [];
  }, [legacyApp.signatureRequest.fields, routerGUID]);

  const routerFieldId = React.useMemo(() => {
    if (routerGUID) {
      let field = legacyApp.signatureRequest.fields.find(
        (field) => field.guid === routerGUID,
      );

      // The router uses the ID of the group, but the ID if the first child
      // would be better here.
      if (field && field.children && field.children.length > 0) {
        field = field.children[0];
      }

      if (field) {
        return field.data.apiId;
      }
    }
    return null;
  }, [legacyApp.signatureRequest.fields, routerGUID]);

  const [state, setState] = React.useState(() => ({
    currentFieldId: routerFieldId,
    routerFieldId,
  }));

  // This hook only switches out the `currentFieldId` if it's not
  // included in the list of router IDs. Users will be able to focus any field
  // in a group when initially activating the group.
  React.useEffect(() => {
    setState((currentState) => {
      if (!routerApiIds.includes(currentState.currentFieldId)) {
        return {
          ...currentState,
          currentFieldId: routerApiIds[0] ?? null,
        };
      } else {
        return currentState;
      }
    });
  }, [routerApiIds]);

  const setCurrentField = React.useCallback(
    (apiId) => {
      if (apiId == null) {
        setState((state) => ({ ...state, currentFieldId: null }));
        return;
      }

      // The router uses the ID of the group, so this will either find the current
      // field, or the group that contains the current field.
      const isTargetField = (field) =>
        field.children
          ? Boolean(field.children.find(isTargetField))
          : field.data.apiId === apiId;
      const field = legacyApp.signatureRequest.fields.find(isTargetField);

      setState((state) => ({ ...state, currentFieldId: apiId }));
      // There is no need to change the route when moving between different
      // checkboxes in the same group.
      if (field && field !== legacyApp.router.location.state.currentField) {
        legacyApp.router.redirect('signNext', {
          params: {
            field,
          },
        });
      }
    },
    [legacyApp.router, legacyApp.signatureRequest.fields],
  );

  return [state.currentFieldId, setCurrentField];
}

const useModelUpdater = (legacyApp, currentFieldId, error, forceUpdateHack) => {
  const save = React.useMemo(() => {
    return debounce(() => {
      legacyApp.signatureRequest.fields
        .filter((f) => f.type === 'text')
        .forEach((field) => {
          // The base model for `field` sets its own .data when you call
          // `toData`. But the `text-field.js` doesn't, it just returns a
          // modified version of the data object. I don't know that it's safe
          // to change the `toData` implementation, so I'm using the same
          // hack found here:
          // https://github.com/HelloFax/hellosign-web/blob/a42c3a652ad4f822dc82c9057d379a90c374f5ba/src/signer/components/common/signature-document/field/text-input.jsx#L160
          field.data = field.toData();
        });

      legacyApp.signatureRequest.save();
      forceUpdateHack?.();
    }, 1500);
  }, [forceUpdateHack, legacyApp.signatureRequest]);

  const getInstantValidationErrorHack = React.useCallback(
    (fieldId) => {
      const field = findField(
        legacyApp.signatureRequest.fields,
        (field) => field.data.apiId === fieldId,
      );

      field.data = field.toData();
      forceUpdateHack?.();
      return field.hasDataValue() && field.getDataValidationError();
    },
    [forceUpdateHack, legacyApp.signatureRequest.fields],
  );

  const getValidationErrors = () => {
    const validationErrors = {};

    for (let i = 0; i < legacyApp.signatureRequest.fields.length; i++) {
      const field = legacyApp.signatureRequest.fields[i];
      if (field.hasDataValue() && Boolean(field.getDataValidationError())) {
        validationErrors[field.data.apiId] = true;
      }
    }

    // We should just have all of the validation errors or they should at least
    // be attached to the fields, but that's not how the signer app was written.
    // Instead src/signer/components/main/pages/signature-request/index.jsx
    // generates a single Error that is assumed to be attached to the current
    // field.
    if (currentFieldId && error) {
      const field =
        legacyApp.signatureRequest &&
        legacyApp.signatureRequest.fields.find((f) => f.id === currentFieldId);

      // If the field is a group, then all fields in the group get that error.
      if (field && field.group) {
        return legacyApp.signatureRequest.fields
          .filter((f) => f.group === field.group)
          .reduce(
            (errors, f) => {
              errors[f.id] = error;
              return errors;
            },
            { ...validationErrors },
          );
      }

      return {
        ...validationErrors,
        [currentFieldId]: error,
      };
    }
    return validationErrors;
  };

  const validationRef = React.useRef();
  const tmpErrors = getValidationErrors();
  if (!shallowEqual(tmpErrors, validationRef.current)) {
    validationRef.current = tmpErrors;
  }
  const validationErrors = validationRef.current;

  const onFieldUpdate = React.useCallback(
    (fieldData, updates) => {
      const field = findField(
        legacyApp.signatureRequest.fields,
        (field) => field.data.apiId === fieldData.id,
      );

      if (!field) {
        // eslint-disable-next-line no-console
        console.warn('Failed to find', fieldData.id);
        return;
      }

      Object.keys(updates).forEach((key) => {
        switch (key) {
          case 'value':
            // Text fields push `lines` to the models instead of `value`.
            if (fieldData.type !== 'text') {
              field.value = updates.value;
            }
            break;
          case 'lines':
            // `lines` contains the current value with all of the newlines
            // injected
            // The model expects null/undefined instead of empty strings.
            field.value = updates.lines || undefined;
            break;
          case 'fontSize':
            field.fontSize = updates.fontSize;
            break;
          case 'checked':
            // Checkboxes need to use `setValue` so that the group gets marked as
            // edited if it's in a group.
            field.setValue(updates.checked ? true : undefined);

            break;
          case 'signature':
            field.value = updates.signature;
            break;
          case 'required':
            field.required = updates.required;
            break;
          case 'originalRequired':
            // This doesn't need to get saved to the model. It's local-only data.
            break;
          case 'hidden':
            // Groups don't behave like normal fields. This code can't live in the
            // "required" case because in a required group, none of the fields are
            // required. This means when both hiding and showing a grouped field
            // `.required = false`.
            if (fieldData.requirement) {
              // For regular fields the logic to mark fields as not required when
              // hiding, and to restore the original required lives in
              // src/hellospa/conditional-logic/run-rules.ts. I can't put this
              // there because the Editor/Signer core doesn't have group objects.
              // Info about groups is just attached to all fields in the group.
              if (updates.hidden) {
                // WARNING: This assumes that we will *always* show/hide whole
                // groups. In the Editor when you select a single item in a group,
                // it adds the  whole group to the rule.
                field.group.required = false;
              } else {
                // All groups must provide a requirement, but the lower bound might
                // be 0 (`require_0-1`)
                const isOptional = fieldData.requirement.includes('require_0');
                field.group.required = !isOptional;
              }
            }

            // The goal of hiddenFieldsNotificationCount is to alert the user about
            // changes to filled items.

            // Remove the value from the model so it doesn't get submitted
            if (updates.hidden && !field[isHidden]) {
              field[isHidden] = true;

              if (field.setValue && field.value) {
                field.setValue(undefined);
              } else if (field.value) {
                field.value = undefined;
              }
            } else if (!updates.hidden && field[isHidden]) {
              field[isHidden] = false;

              // Restore any value that was in the model before
              switch (fieldData.type) {
                case 'checkbox':
                case 'radiobutton': {
                  field.setValue(fieldData.checked ? true : undefined);
                  break;
                }
                case 'text': {
                  field.value = fieldData.lines || undefined;
                  break;
                }
                case 'date':
                case 'dropdown': {
                  field.value = fieldData.value;
                  break;
                }
                case 'signature':
                case 'initials': {
                  field.value = fieldData.signature;
                  break;
                }
                default: {
                  break;
                }
              }
            }

            break;
          default:
            // eslint-disable-next-line no-console
            console.warn('Unable to convert update', key, updates[key]);
        }
      });

      // Welcome to the Rube Goldberg machine that is the legacy app. Validation
      // is driven by these models.  Models that expose their values, and allow
      // those values to be updated externally. Since values are modified
      // externally, the model doesn't know when an update happens and can't
      // update the UI. As a terrible workaround, we have watch-app-notifier that
      // listens for any change event and just forces the app to re-render after a
      // 100ms delay for no apparent reason.
      //
      // But if that code is listenting for changes, and chaning the data doesn't
      // fire an event, how does it work?  We've been relying on a change that
      // happens to fire an event as a side effect of saving the models. save is
      // debounced 1500ms, so there's a 1.5 second gap between chaning the data,
      // and our legacy model system waking up and noticing.
      //
      // This dummy notification is here to force an instant update of the UI.
      // This allows "You have completed all required fields..." to show or hide
      // immediately.
      legacyApp.notifier.notify({});
      save();
    },
    [legacyApp.notifier, legacyApp.signatureRequest.fields, save],
  );

  return [onFieldUpdate, validationErrors, getInstantValidationErrorHack];
};

const NOOP = () => {};

function Signer({
  // Please don't pass legacyApp or any of its contained models down to other
  // components. Use this file to do whatever coordination is necessary.
  legacyApp,
  isPreview = false,
  startEnabled = true,
  isConfirmation = false,
  error,
  dateFormat,
  documentTitle,
  // The parent component is responsible for showing validation errors, but it
  // doesn't subscribe to model updates. So this is a hack to force the parent
  // to re-render when a model is updated.
  forceUpdateHack = NOOP,
  onPrefill,
}) {
  const appContext = useSignerAppClient();
  const allowAccessibleSignerView = useFeatureFlag(
    'sign_core_2024_05_10_accessible_view',
  );
  const showAccessibleSignerView = useAtomValue(accessibleViewAtom);

  const fetchUnifiedJson = false;
  const signatureRequest = useFetchSignatureRequest(
    legacyApp.signatureRequest,
    fetchUnifiedJson,
    appContext,
  );

  const [currentFieldId, setCurrentField] = useRouterAdapter(legacyApp);
  const [onFieldUpdate, validationErrors, getInstantValidationErrorHack] =
    useModelUpdater(legacyApp, currentFieldId, error, forceUpdateHack);

  const onPageClick = React.useCallback(() => {
    if (isPreview && startEnabled) {
      const router = legacyApp.router;
      const state = router.location.state;

      // The user has clicked on the signature request document so reset focus
      router.redirect('signNext', {
        query: {
          validate: state.validate,
        },
        params: {
          field:
            state.currentField ||
            legacyApp.signatureRequest.getNextEditableField(),
        },
      });
    }
  }, [isPreview, legacyApp.router, legacyApp.signatureRequest, startEnabled]);

  const selectedFieldIds = React.useMemo(
    () => (currentFieldId != null ? [currentFieldId] : []),
    [currentFieldId],
  );

  if (signatureRequest == null) {
    return <FullPageLoader showDotsLoader={true}></FullPageLoader>;
  }

  return (
    <SignerContext
      validationErrors={validationErrors}
      startEnabled={startEnabled}
      onFieldUpdate={onFieldUpdate}
      selectedFieldIds={selectedFieldIds}
      setCurrentField={setCurrentField}
      signatureRequest={signatureRequest}
      isPreview={isPreview}
      onPageClick={onPageClick}
      defaultDateFormat={dateFormat}
      documentTitle={documentTitle}
      getInstantValidationErrorHack={getInstantValidationErrorHack}
      onPrefill={onPrefill}
    >
      {!isConfirmation &&
      !isPreview &&
      allowAccessibleSignerView &&
      showAccessibleSignerView ? (
        <AccessibleDocument />
      ) : (
        <SignerDocument />
      )}
    </SignerContext>
  );
}

export default function SignerWithErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <Signer {...props} />
    </ErrorBoundary>
  );
}
