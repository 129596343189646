/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  no-nested-ternary
*/
import React from 'react';
import cx from 'classnames';
import createReactClass from 'create-react-class';

require('./index.scss');

const Logo = createReactClass({
  render() {
    const shouldUseCustomLogo = !!(
      this.props.app && this.props.app.customLogoUri
    );

    const logo = this.props.dark
      ? require('./dropbox-sign-wordmark-128.svg')
      : require('./dropbox-sign-wordmark-coconut-128.svg');

    const src = shouldUseCustomLogo
      ? `//${window.location.host}/${this.props.app.customLogoUri}`
      : logo;

    let classNames = cx({
      'm-signer-mobile-logo': true,
      'is-custom': shouldUseCustomLogo,
    });

    const style = {
      display:
        shouldUseCustomLogo && this.props.showIfCustom === false
          ? 'none'
          : 'inline-block',
      padding: '4px',
    };
    if (shouldUseCustomLogo && this.props.showIfCustom === false) {
      // Custom logos don't need the extra padding.
      delete style.padding;
    }

    if (this.props.className) {
      classNames += ` ${this.props.className}`;
    }

    return (
      <img
        className={classNames}
        style={style}
        src={src}
        data-testid="logo-image"
      ></img>
    );
  },
});

module.exports = Logo;
