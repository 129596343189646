import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@dropbox/dig-components/modal';
import { Button } from '@dropbox/dig-components/buttons';

type Props = {
  link: string;
  isOpen: boolean;
  onRequestClose: () => void;
};
export default class HyperlinkInterstitialModal extends React.PureComponent<Props> {
  linkRef = React.createRef<HTMLAnchorElement>();

  handleConfirm = () => {
    this.linkRef.current?.click();
    this.props.onRequestClose();
  };

  render() {
    const { link, isOpen, onRequestClose } = this.props;

    return (
      <Modal
        open={isOpen}
        isCentered
        width="small"
        onRequestClose={onRequestClose}
        aria-labelledby="link-title"
      >
        <Modal.Header>
          <Modal.Title id="link-title">
            <FormattedMessage
              id="87c211d939cdf82a158e67f62effc0344e8ad5edabd5a7fa87f2d501ce567a0b"
              defaultMessage="Open link"
              description="Title of the hyperlink interstitial modal"
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <FormattedMessage
              id="ed5b7b2712f07705144c41bb190829ea610ce9ab5afff673dfa92236a4d7bb15"
              description="message in HyperlinkInterstitial Modal informing that user is opening third pary web page in new window"
              defaultMessage="You're about to open a third party webpage in a new window."
            />
          </p>
          <b>{link}</b>

          {/* This <a/> tag is the one that actually does the work */}
          <a
            href={link}
            ref={this.linkRef}
            style={{ opacity: 0 }}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {link}
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline"
            data-qa-ref="interstitial-modal--cancel-btn"
            onClick={this.props.onRequestClose}
          >
            <FormattedMessage
              id="730779771cd2e23db9614c49f570cce8e4a7b7f20a03eaa1a48677a9d5b0c19e"
              description="Cancel button in HyperlinkInterstitial Modal"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            variant="primary"
            data-qa-ref="interstitial-modal--continue-btn"
            onClick={this.handleConfirm}
          >
            <FormattedMessage
              id="96a0fb1b174a12c816aeeda65a320d7bd091dce50c7bc3c561df2bfe016b4008"
              description="Continue button in HyperlinkInterstitial Modal"
              defaultMessage="Continue"
            />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
