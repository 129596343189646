import HfReactHelper from 'js/sign-components/common/hf-react-helper';

import UserError from 'signer-app/utils/error-handling/user-error';

let CSRFToken: string;

declare global {
  interface Window {
    hellofaxJS: {
      attachSessionInfoToUrl(url: string): string;
      setLastAjaxTimestamp(timestamp: number): void;
    };
  }
}

export function setCsrfToken(csrfToken: string) {
  CSRFToken = csrfToken;
}
export function getCSRFToken(): string {
  return CSRFToken;
}

export function updateSessionTimeout() {
  // @ts-ignore sessionTimeoutMonitor
  const sessionTimeoutMonitor = window.SessionTimeoutMonitor;
  const hellofaxJS = window.hellofaxJS;
  if (sessionTimeoutMonitor && hellofaxJS) {
    hellofaxJS.setLastAjaxTimestamp(sessionTimeoutMonitor.getNow());
  }
}

export function needsCSRFToken(url: string) {
  return (
    new URL(url, window.location.href).host ===
    new URL('/', window.location.href).host
  );
}

export async function handleException(response: Response): Promise<never> {
  const data = await response.json();
  if (data && data.data && data.data.translated) {
    throw new UserError(data.data.translated, true);
  } else if (data && data.error) {
    throw new UserError(data.error);
  } else if (response.status >= 400) {
    throw new UserError(`Error: ${response.statusText} ${response.url}`);
  }
  throw new Error('Unhandled error condition');
}

/* eslint-disable no-restricted-globals */
export default function hsFetch(
  url: string,
  options: RequestInit = {},
): ReturnType<typeof fetch> {
  // If this is running in an iframe on another site, the browser may choose to
  // not include the "3rd party" cookies. This attaches the session info so the
  // session isn't lost.
  if (window.top !== window) {
    // eslint-disable-next-line no-param-reassign
    url = HfReactHelper.attachSessionInfoToUrl(url);
  }

  const requestHeaders: HeadersInit = {
    Accept: 'application/json',
    ...options.headers,
  };

  if (needsCSRFToken(url)) {
    // Adding global X-CSRF-Token
    (requestHeaders as any)['X-CSRF-Token'] = CSRFToken;
  }

  const requestOptions = {
    ...options,
  };

  requestOptions.headers = requestHeaders;

  return fetch(url, {
    credentials: 'same-origin',
    method: 'GET',
    ...requestOptions,
  });
}
