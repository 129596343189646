import ErrorBoundary from 'signer-app/parts/error-boundary';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon, UIIconProps } from '@dropbox/dig-icons';
import { useOnMount } from 'js/sign-components/common/hooks';
import { useForceUpdate } from 'signer-app/utils/use-force-update';
import { useEventListener } from 'signer-app/utils/use-event-listener';
import delay from 'hellospa/common/utils/delay';
import React from 'react';
import ReactDOM from 'react-dom';

export type DebugPanelProps<T> = {
  setBadgeState: (badgeState: BadgeState) => void;
  badgeState: BadgeState;
  isOpen: boolean;
  data: T;
};

/**
 * PanelComponent will be rendered whether it's active or not. If the
 * PanelComponent is expensive to render, you might check the `isOpen` and
 * `return null`.
 *
 * If your new panel needs to use a badge, but shouldn't render when closed then
 * I'd try to separate those two. Point directly to a component that uses
 * whatever hooks it needs to render the badge. Then that component can do
 * something like `return isOpen ? <YourUIComponent /> : null`
 *
 *
 */
export type PanelComponent<T = undefined> = React.ComponentType<
  DebugPanelProps<T>
>;
type BadgeState = null | 'red' | 'yellow' | 'green';

type Props<T> = {
  title: string;
  icon: UIIconProps['src'];
  Component: PanelComponent<T>;
  updateWhileClosed?: boolean;
  data?: T;
};
/**
 * I'm using Partial<ToolbarAPI> to force the code to verify all the
 * functions exist. This'll be helpful if these get out of sync because the
 * component will return early instead of crashing while trying to call a
 * function that doesn't exist.
 */
type DebugToolsRoot = HTMLElement & Partial<ToolbarAPI>;

type PanelResult = [HTMLLIElement, DebugToolsRoot['buttonHandler']];

/**
 * This function operates outside React's lifecycle so that it doesn't introduce
 * extra renders. If the toolbar is found, this will return an `<li` to attach
 * to and the buttonHandler.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535230981072&cot=14
 */
async function attachToDebugPanelIfItExists(): Promise<null | PanelResult> {
  let debugToolsRoot;
  let count = 0;

  do {
    if (count++ > 1) {
      // eslint-disable-next-line no-await-in-loop
      await delay(1000, 200);
    }
    if (count > 10) {
      return null;
    }
    debugToolsRoot = document.getElementById(
      'hs-debug-tools',
    ) as DebugToolsRoot | null;
  } while (!debugToolsRoot || !debugToolsRoot.buttonHandler);

  let ul;
  count = 0;
  do {
    if (count++ > 1) {
      // eslint-disable-next-line no-await-in-loop
      await delay(1000, 200);
    }
    if (count > 10) {
      return null;
    }
    ul = debugToolsRoot.querySelector('.hs-debug-tools__toolbar > ul');
  } while (!ul);

  const li = document.createElement('li');
  ul.appendChild(li);
  return [li, debugToolsRoot.buttonHandler];
}

function ErrorComponent() {
  return (
    <Text variant="paragraph" color="error">
      The DebugPanel crashed. See the first error in the console for more info.
    </Text>
  );
}

/**
 * <DebugPanel hooks into our existing `_debug-tools.php` over in `HelloFax` to
 * add custom panels. Your component is wrapped in an `<ErrorComponent` so that
 * you don't crash the whole app while developing your panel.
 *
 * To make sure all of the panel code is removed from production builds, use
 * this pattern:
 *
 * ```
 * export const MyDebugPanel: PanelComponent = ({ isOpen }) => {
 * }
 *
 * export const myDebugPanel = NODE_ENV !== 'production' && (
 *   <DebugPanel
 *    Component={MyDebugPanel}
 *    icon={SearchLine}
 *    title="data-testid" />
 * )
 * ```
 *
 * Then in some high level component in the middle of some JSX, just put
 * `{myDebugPanel}`.  In production builds it's simply the value `false` and is
 * ignored. Everywhere else, this will portal into the existing panel if it's
 * found.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535231103413&cot=14
 */
export const DebugPanel = (({
  title,
  icon,
  Component,
  updateWhileClosed = true,
  data,
}) => {
  const forceUpdate = useForceUpdate();
  const liRef = React.useRef<null | PanelResult>();
  const [badge, setBadge] = React.useState<BadgeState>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const panelRef = React.useRef<HTMLDivElement>(null);

  useOnMount(() => {
    attachToDebugPanelIfItExists().then((result) => {
      liRef.current = result;
      if (result != null) {
        forceUpdate();
      }
    });
  });

  const hasAttachedEvent = Boolean(panelRef.current);
  useEventListener(panelRef.current, 'hs-toggle-debug-panel', (event) => {
    if (event instanceof CustomEvent) {
      const panelVisible = Boolean(event.detail?.panelVisible);
      setIsOpen(panelVisible);
    }
  });

  const [li, buttonHandler] = liRef.current ?? [];
  React.useEffect(() => {
    if (li && !panelRef.current) {
      forceUpdate();
    }
  }, [forceUpdate, li]);

  if (!li) {
    return null;
  }
  if (!buttonHandler) {
    // eslint-disable-next-line no-console
    console.warn('hs-debug-tools was found, but is missing buttonHandler');
    return null;
  }
  return ReactDOM.createPortal(
    <React.Fragment>
      <button
        title={title}
        type="button"
        onClick={(e) => {
          if (!hasAttachedEvent) {
            setIsOpen(true);
          }
          buttonHandler(e);
        }}
        className="foundation-exempt"
      >
        <UIIcon width={32} height={32} src={icon} />
        {badge && (
          <div className={`hs-debug-tools__toolbar-button-count ${badge}`} />
        )}
      </button>
      <div className="hs-debug-tools__panel" ref={panelRef}>
        <div className="hs-debug-tools__section">
          <ErrorBoundary component={ErrorComponent}>
            {(updateWhileClosed || isOpen) && (
              <Component
                badgeState={badge}
                isOpen={isOpen}
                data={data}
                setBadgeState={setBadge}
              />
            )}
          </ErrorBoundary>
        </div>
      </div>
    </React.Fragment>,
    li,
  );
}) as DebugPanelWithSubComponents<any>;

/**
 * https://github.com/HelloFax/HelloFax/blob/e6ca69b3e04b876e2ff59fb9938d193f006906e8/templates/es6/_debug_tools.php#L1108-L1110
 */
type ToolbarAPI = {
  buttonHandler: (event: React.MouseEvent) => void;
};
type DebugPanelWithSubComponents<T = never> = React.FC<Props<T>> &
  SubComponents;

const SectionHeader = ({ children }: React.PropsWithChildren<{}>) => (
  <div className="hs-debug-tools__section-header">
    <h2>{children}</h2>
  </div>
);
type SubComponents = {
  SectionHeader: typeof SectionHeader;
};
DebugPanel.SectionHeader = SectionHeader;
