import React from 'react';
import type Application from 'hellosign/detached-application';
import type { HSUser } from 'hellospa/common/hs-user';
import type { FeatureFlags } from 'js/sign-components/common/feature-flags';
import type { SiteCodeType } from 'js/sign-components/common/brand';

export type LayoutContextShape = {
  withSearch?: boolean;
  /**
   * Application instance
   */
  app: Application | null | undefined;

  csrfToken: string;

  /**
   * Indicates either HelloSign or HelloFax.
   */
  siteCode: SiteCodeType;

  signature: {
    /**
     * Indicates a type of either "signature" or "initial."  Used in the
     * signature modal
     */
    type: 'S' | 'I';
  };

  /**
   * This is used by the signature modal
   */
  isDropbox: boolean;
  /**
   * This is used by the signature modal
   */
  isEmbedded: boolean;

  /**
   * Properties of the currently logged-in user.
   */
  user: HSUser;

  isSpa: true;
  featureFlags: FeatureFlags;
  termsURL: string;
  privacyURL: string;

  pageProps: any;
  stripePublishableKey?: string;
  sessionInfo?: string;
  preloadedTsmGroupKey?: string;
};

// Everything the layout needs is injected to the `<Root`, but that component
// needs to handle routing, then each page decides whether or not to use the
// <DefaultLayout. Instead of having to pass the props down, `<Root` will just
// inject the data into the context so that each route can just render
// `<DefaultLayout>{content}`
export const DefaultLayoutContext = React.createContext<
  LayoutContextShape | undefined
>(undefined);

export const useLayoutContext = () => React.useContext(DefaultLayoutContext);

export const DefaultLayoutConsumer = DefaultLayoutContext.Consumer;

type Props = React.PropsWithChildren<{
  value: LayoutContextShape;
}>;
export function DefaultLayoutProvider({ value, children }: Props) {
  return (
    <DefaultLayoutContext.Provider value={value}>
      {children}
    </DefaultLayoutContext.Provider>
  );
}
