import { WebAppClientNamespaces } from '../../hello-react/web-app-client/build-web-app-client';
import _request from 'signer-app/context/signer-app-client/_request';
import * as Yup from 'yup';

export type SignerHttpRequest = {
  url: string;
  query: unknown;
};

export async function signerHttpRequest(
  req: SignerHttpRequest,
  appActions: Pick<WebAppClientNamespaces, 'signer'>,
) {
  if (req.url === '/signer/ready') {
    /**
     * validation does NOT strip extra fields, it only validates what is there
     */
    const validatedReq = await Yup.object({
      url: Yup.mixed().oneOf([req.url]),
      method: Yup.mixed().oneOf(['GET']),
      query: Yup.object({
        tsm_guid: Yup.string(),
        token: Yup.string(),
      }),
    }).validate(req);

    return appActions.signer.ready(validatedReq);
  }

  if (req.url === '/signer/load') {
    /**
     * validation does NOT strip extra fields, it only validates what is there
     */
    const validatedReq = await Yup.object({
      url: Yup.mixed().oneOf([req.url]),
      method: Yup.mixed().oneOf(['GET']),
      query: Yup.object({
        guid: Yup.string(),
        status_token: Yup.string(),
      }),
    }).validate(req);

    return appActions.signer.load(validatedReq);
  }

  if (req.url === '/signer/save') {
    /**
     * validation does NOT strip extra fields, it only validates what is there
     */
    const validatedReq = await Yup.object({
      url: Yup.mixed().oneOf([req.url]),
      method: Yup.mixed().oneOf(['POST']),
      data: Yup.string(),
      form: Yup.boolean(),
    }).validate(req);

    return appActions.signer.save(validatedReq);
  }

  if (req.url === '/signer/finalize') {
    /**
     * validation does NOT strip extra fields, it only validates what is there
     */
    const validatedReq = await Yup.object({
      url: Yup.mixed().oneOf([req.url]),
      method: Yup.mixed().oneOf(['POST']),
      data: Yup.string(),
    }).validate(req);

    return appActions.signer.finalize(validatedReq);
  }

  return _request(req as any);
}
