/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  default-case,
  eqeqeq,
  global-require,
  import/no-extraneous-dependencies,
  import/order,
  no-alert,
  no-empty,
  no-void,
  prefer-rest-params,
  prefer-spread
*/
import User from 'hellosign/models/user';
import React from 'react';
import Router from 'common/router';
import extend from 'lodash/extend';
import debounce from 'lodash/debounce';
import BaseApplication from 'common/application';
import { injectIntl } from 'react-intl';
import HelloSign from 'common/utils/hellosign';
import { messages, postMessage } from 'js/sign-components/common/messages';
import checkPlatform from 'common/utils/check-platform';

import { INITIALIZE } from 'common/messages';
import { TypeNotifier, CallbackNotifier } from 'common/notifiers';
import transformWhitelabelStyles from './utils/transform-whitelabel-styles';
import APIService from './api';
import { SignatureRequest } from './models';
import Commands from './commands';
import Routes from './routes';
import Main from './components/main';
import { closeWindow } from './actions/embedded';

import Endpoints from './api/endpoints';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';
import { FeatureFlagProvider } from 'js/sign-components/common/feature-flag';

/**
 */

function Application() {
  BaseApplication.apply(this, arguments);

  // set default endpoints if not defined. This is common with tests
  if (!this.endpoints) {
    this.endpoints = Endpoints.create(this);
  }

  // service makes API calls. This gets used by models

  this.notifier = this.notifierCollection;

  this.api = APIService.create({
    endpoints: this.endpoints,

    // request function might be provided in tests. (CC)
    _request: this.httpRequest,
    notifier: this.notifier,
  });

  this.notifier.push(this.api);

  this.startWatchingPlatformSpecs();

  // http router - maintains app state
  this.router = Router.create({
    testMode: this.testMode || NODE_ENV === 'test',
    notifier: this.notifier,
  });

  // grab a ref of the location (never changes)
  this.location = this.router.location;

  Commands.create(this);
  Routes.create(this);

  this.notifierCollection.unshift(
    TypeNotifier.create(
      INITIALIZE,
      CallbackNotifier.create(this._initialize.bind(this)),
    ),
  );
}

BaseApplication.extend(Application, {
  /**
   */

  rootComponentClass: injectIntl(Main),

  /**
   */

  legalVersion: 1,

  /**
   * include the package to show various info such as version number (CC)
   */

  pkg: require('../../package.json'),

  /**
   */

  intl: {
    locales: ['en-US'],
  },

  /**
   */

  startWatchingPlatformSpecs() {
    window.addEventListener('resize', debounce(this.onResize.bind(this), 500));
    this.onResize();
  },

  /**
   */

  onResize() {
    this.checkPlatform();
  },

  /**
   */

  checkPlatform() {
    const platform = this.forceMobile ? 'mobile' : checkPlatform();

    this.setProperties({
      isDesktop: platform === 'desktop',
      isMobile: platform === 'mobile',
      platform,
    });
  },

  /**
   */

  redirect() {
    return this.router.redirect.apply(this.router, arguments);
  },

  /**
   */

  getZoomLevel() {
    return this.zoomLevel ? this.zoomLevel : 1;
  },

  /**
   */

  _initialize() {
    this._injectWhitelabelStyles();

    // signature request might already exist for testing purposes.
    // TODO - this should be a property on the user model. Not a property
    // on the application. (CC)
    if (!this.signatureRequest) {
      this.signatureRequest = SignatureRequest.create({
        notifier: this.notifier,
        embeddedData: this.embeddedData || {},
        guid: this.signatureRequestId,
        statusToken: this.statusToken,
        transmissionGuid: this.transmissionGuid,
        settings: {},
      });
    }

    if (this.signatureRequestData) {
      const data = {
        ready: true,
        signatureRequestData: this.signatureRequestData,
      };
      this.signatureRequest = extend(
        this.signatureRequest,
        this.signatureRequest.fromData(data),
      );
    }

    this.user = User.create({
      notifier: this.notifier,
      settings: this.settings || {},
    });

    this.router.setProperties({
      bindWindowLocation: !this.isEmbedded(),
    });

    this.router.initialize();

    // helper util (kinda dirty) for testing.
    if (this.initialLocation) {
      this.router.redirect(this.initialLocation);
    }

    if (this.isEmbedded() && HelloSign.XWM) {
      this._getEmbeddedConfig();
      this._attachEmbeddedErrorInfo();
    }

    // keep users session alive in embedded flow (send request every minute)
    if (this.isEmbedded()) {
      // Too complicated for unit testing, approved by Frontend
      /* istanbul ignore next */
      setInterval(() => {
        this.appActions.fetchActions.keepSessionAlive();
      }, 60000);
    }
  },

  /**
   */

  _getEmbeddedConfig() {
    if (this.signatureRequest.embeddedData.parentUrl.startsWith('http://')) {
      /* eslint-disable-next-line */
      console.warn(
        // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
        // eslint-disable-next-line no-restricted-syntax
        'You are attempting to load HelloSign from an unsecured domain, which may result in unexpected behavior. Please call "hellosign.open()" from a page served securely over HTTPS.',
      );
    }

    HelloSign.XWM.receive((evt) => {
      let message;

      // only handle flux standard actions (FSA) messages here
      try {
        message = JSON.parse(evt.data);
      } catch (e) {}

      if (message == void 0) return;

      if (message.type === 'embeddedConfig') {
        this.setProperties({
          allowCancel: message.payload.allowCancel !== false,
        });
      }
    }, '*');

    HelloSign.XWM.send(
      'initialize',
      this.signatureRequest.embeddedData.parentUrl,
    );

    postMessage(
      {
        type: messages.APP_INITIALIZE,
      },
      this.signatureRequest.embeddedData.parentUrl,
    ); // Embedded V2

    window.addEventListener('message', this._onMessage.bind(this));
  },

  /**
   */

  _attachEmbeddedErrorInfo() {
    if (this.embeddedData && typeof Raven !== 'undefined') {
      Raven.setUserContext({
        clientId: this.embeddedData.clientId,
        signatureId: this.embeddedData.signatureId,
      });
    }
  },

  _onMessage({ data, origin }) {
    const parentURL = this.signatureRequest.embeddedData.parentUrl;
    const parentURLOrigin = parentURL.replace(/([^:]+:\/\/[^/]+).*/, '$1');

    if (origin === parentURLOrigin) {
      switch (data.type) {
        case messages.APP_CONFIGURE: {
          this.setProperties({
            allowCancel: data.payload.allowCancel,
          });
          break;
        }
        case messages.USER_CANCEL_REQUEST: {
          if (/complete$/i.test(this.router.location.state.contentPage)) {
            // The close button was pressed when the user
            // has finished the signature request. Send the
            // "finish" insread of "cancel" message.
            postMessage(
              {
                type: messages.USER_FINISH_REQUEST,
              },
              this.embeddedData.parentUrl,
            );
          } else {
            closeWindow(this);
          }
          break;
        }
      }
    }
  },

  /**
   */

  overrideLogo(url) {
    const img = document.getElementsByClassName('m-signer-mobile-logo')[0];
    if (img && url) {
      img.src = url;
    }
  },

  updateWhitelabels(whiteLabels) {
    this.whiteLabelStyles = whiteLabels;
    this._injectWhitelabelStyles();
  },

  _injectWhitelabelStyles() {
    if (!this.whiteLabelStyles) {
      return;
    }

    this.whitelabel = true;

    if (typeof this.whiteLabelStyles === 'string') {
      this.whiteLabelStyles = JSON.parse(this.whiteLabelStyles);
    }

    this.legalVersion = Number(
      this.whiteLabelStyles.legal_version.substr(5) || 1,
    );

    // this.whiteLabelStyles = require('./fake-styles');
    const styles = transformWhitelabelStyles(this.whiteLabelStyles);
    const style = document.createElement('style');
    style.type = 'text/css';

    if (style.styleSheet) {
      style.styleSheet.cssText = styles;
    } else {
      style.appendChild(document.createTextNode(styles));
    }

    document.getElementsByTagName('head')[0].appendChild(style);
  },

  /**
   */

  isEmbedded() {
    return window.top !== window && this.signatureRequest.isEmbedded();
  },

  /**
   * Permissions checks
   */

  canCancel() {
    return this.allowCancel == true;
  },

  canDecline() {
    return this.allowDecline == true;
  },

  canInsertEverywhere() {
    return this.allowInsertEverywhere == true;
  },

  canDownload() {
    return (
      !this.signatureRequest.isEmbeddedSigning() &&
      !this.signatureRequest.isDirty()
    );
  },

  canDelegate() {
    return this.allowDelegate == true;
  },

  /**
   */

  _createRootComponent(props) {
    const RootComponent = this.rootComponentClass;
    return (
      <FeatureFlagProvider featureFlags={this.featureFlags || {}}>
        <HSIntlProvider>
          <RootComponent {...props} />
        </HSIntlProvider>
      </FeatureFlagProvider>
    );
  },

  /**
   */

  _createRootComponentProps() {
    return {
      login: this.login,
      error: this.error,
      location: this.router.location,
      tosUrl: this.tosUrl,
      privacyPolicyUrl: this.privacyPolicyUrl,
      ...this.intl,
      ...BaseApplication.prototype._createRootComponentProps.call(this),
    };
  },
});

/**
 */

module.exports = Application;
