/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  func-names
*/
import logger from 'common/logger';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

// catch any global unhandled promise rejections
window.onunhandledrejection = function (event) {
  const isTesting = NODE_ENV === 'test';
  const rethrowUnhandled = false;
  if (isTesting) {
    if (!rethrowUnhandled) {
      event.preventDefault();
    }
  } else {
    logger.warn(
      `An unexpected error occurred. This has been reported to our engineering team. For further support, please contact ${HfReactHelper.HfConstants.supportEmail.api}.`,
      event.reason,
    );
  }
};
