import React from 'react';

export function useRefReady(ref: React.RefObject<any>) {
  const [ready, setReady] = React.useState(
    ref.current != null ||
      // jsdom doesn't actually calculate positions, so there is no need to wait
      // for a 2nd render.
      navigator.userAgent.includes('jsdom'),
  );
  React.useEffect(() => {
    if (!ready && ref.current != null) {
      setReady(true);
    }
  }, [ready, ref]);

  return ready;
}
