import { useCallback, useRef, useState } from 'react';

export type Reducer<S, A> = (state: S, action: A) => S;

export type Thunk<S, A extends object> = (
  dispatch: Dispatch<S, A>,
  getState: () => S,
) => void;
export type Dispatch<S, A extends object> = (action: A | Thunk<S, A>) => void;

/**
 * Augments React's useReducer() hook so that the action
 * dispatcher supports thunks.
 *
 * @param {Function} reducer
 * @param {*} initialArg
 * @param {Function} [init]
 * @returns {[*, Dispatch]}
 */
function useThunkReducer<S, A extends object, I>(
  reducer: Reducer<S, A>,
  initialArg: I,
  init = (a: I): S => a as any,
) {
  const [hookState, setHookState] = useState(init(initialArg));

  const state = useRef(hookState);
  const getState = useCallback(() => state.current, []);
  const setState = useCallback((newState) => {
    state.current = newState;
    setHookState(newState);
  }, []);

  const reduce = useCallback(
    (action) => reducer(getState(), action),
    [getState, reducer],
  );
  const dispatch: Dispatch<S, A> = useCallback(
    (action) =>
      typeof action === 'function'
        ? action(dispatch, getState)
        : setState(reduce(action)),
    [getState, reduce, setState],
  );

  return [hookState, dispatch] as const;
}

export default useThunkReducer;
