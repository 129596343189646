import React from 'react';
import styles from 'signer-app/signature-modal/common/tab-icon-container.module.css';

interface TabIconContainerProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  isMobile: boolean;
}

export const TabIconContainer = ({
  icon,
  children,
  isMobile,
}: TabIconContainerProps) => (
  <div className={styles.tabIconContainer}>
    {icon} {!isMobile && children}
  </div>
);
