import {
  getEndpointResource,
  postJSONEndpointResource,
} from 'hello-react/web-app-client/namespace/admin/endpoints';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';

type BEStructure = {
  filename: string;
  title: string;
  roles: { [key: string]: string };
  message: string;
};

type CreateFromDocumentResponseData = {
  templateGuid: string;
  redirectUrl: string;
};
type CreateFromDocumentResponseError = {
  error?: string;
};

export type SignerToRole = {
  position: string;
  signer: string;
  role: string;
};

export type TemplateData = {
  filename: string;
  title: string;
  signers: Array<SignerToRole>;
  message: string;
};

export async function getDocToTemplateData(docId: string) {
  try {
    const result = await getEndpointResource(
      `/template/prepareDocumentForTemplate/?guid=${docId}`,
    );
    if (result.data) {
      const data: BEStructure = result.data as BEStructure;
      const signers = Object.keys(data.roles).map((signerPos) => ({
        position: signerPos,
        signer: data.roles[signerPos],
        role: '',
      }));
      return {
        filename: data.filename,
        title: data.title,
        signers,
        message: data.message,
      };
    }
    return null;
  } catch (e) {
    return null;
  }
}

export async function convertDocToTemplate(
  docId: string,
  data: TemplateData,
  edit: boolean = false,
  isVersionTwo: boolean = false,
): Promise<
  undefined | CreateFromDocumentResponseData | CreateFromDocumentResponseError
> {
  const roles: { [key: string]: string } = {};
  data.signers.forEach((signer) => {
    roles[signer.position] = signer.role;
  });

  try {
    // The return type of postJSONEndpointResource is simply wrong. data is not
    // simply `Object`, it is an object that has properties on it. But no matter
    // what properties you attempt to access TypeScript will always tell you
    // they don't exist, because it thinks it's an `Object` which doesn't have
    // any properties.
    const result: any = await postJSONEndpointResource(
      'template/createFromDocument',
      {
        body: JSON.stringify({
          guid: docId,
          options: {
            title: data.title,
            message: data.message,
            roles,
            edit,
            isVersionTwo,
          },
        }),
      },
    );

    if (result.success && result.data && result.data.redirect_url) {
      trackHeapCustomEvent('docToTemplateModalSuccessfulSubmit', {
        templateGuid: result.data.template_guid,
      });
      return {
        redirectUrl: result.data.redirect_url as string,
        templateGuid: result.data.template_guid as string,
      };
    }
    return {
      error: result.error as string | undefined,
    };
  } catch (e) {
    throw e;
  }
}

export async function templateCreateModalDoNotShowToggle(
  shouldShow: boolean,
): Promise<boolean> {
  const result = await postJSONEndpointResource('org/updateDoNotShow', {
    body: JSON.stringify({
      templateCreateModalDoNotShow: { value: shouldShow },
    }),
  });
  return result.success;
}
