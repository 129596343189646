/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  @typescript-eslint/no-use-before-define,
  no-void
*/
import extend from 'lodash/merge';
import fecha from 'fecha';

/**
 */

module.exports = function (data, settings) {
  return {
    data: data.snapshots.map((snapshot) =>
      serializeSnapshot(snapshot, settings),
    ),
    guid: data.id,
    isComingFromSmsDelivery: data.isComingFromSmsDelivery,
  };
};

/**
 */

function serializeSnapshot(snapshot, settings) {
  let signerFields = [];
  const groups = {};
  const fields = snapshot.fields;

  fields.forEach((field) => {
    if (field.type === 'group') {
      const groupName = field.children[0].group;
      field.children.forEach((child) => {
        signerFields.push(child);
      });

      groups[groupName] =
        `required_${field.validate.min || 0}-${field.validate.max || 'ormore'}`;
    } else if (field.signer !== void 0 && field.signer !== null) {
      signerFields.push(field);
    }
  });

  // Ensures ordering is kept
  signerFields = signerFields.sort((a, b) => {
    return a.order > b.order ? 1 : -1;
  });

  return {
    guid: snapshot.guid,
    data: signerFields.map((field) => serializeField(field, settings)),
    signers: [],
    signer_names: null,
    order_map: null,
    ccs: [],
    groups,
    merge_fields: [],
  };
}

/**
 */

function serializeField(field, settings) {
  const serializers = {
    def: serializeDefaultField,
    signature: serializeSignatureField,
    initials: serializeSignatureField,
    checkbox: serializeCheckboxField,
    date: serializeDateField,
    text: serializeTextField,
    dropdown: serializeTextField,
    radiobutton: serializeCheckboxField,
    hyperlink: serializeHyperlinkField,
  };

  return extend(
    serializers.def(field),
    serializers[field.type] ? serializers[field.type](field, settings) : {},
    serializeFieldType(field),
  );
}

/**
 */

function serializeSignatureField(field) {
  // NOTE: The back-end expects '' when it's not been filled in
  // see HFOverlayUtils::overlaySigOrInitials()
  return {
    data: {
      imgSrc: field.value ? `/attachment/view?sig_guid=${field.value}` : '',
    },
  };
}

/**
 */

function serializeCheckboxField(field) {
  return {
    data: {
      checked: field.value || false,
      group: field.group,
    },
  };
}

/**
 */

function serializeDateField(field, settings) {
  // field.dateFormat comes from the layout set by the SR creator,
  // if exists it has to take priority over the settings.dateFormat
  // settings.dateFormat comes from account settings.

  let content = null;
  // If field.value is present, it will be a numeric timestamp that came from
  // the DateField models' toData()
  if (field.value) {
    content = fecha.format(
      new Date(field.value),
      field.dateFormat || settings.dateFormat || 'MM / DD / YYYY',
    );
  }
  return {
    data: {
      fontSize: field.fontSize,
      fontFamily: field.fontFamily,
      format: field.dateFormat || settings.dateFormat || null,
      content,
    },
  };
}

/**
 */

function serializeTextField(field) {
  return {
    data: {
      content: field.value ? field.value.trim() : null,
      validation_type: field.validationType || null,
      fontFamily: field.fontFamily,
      masked: field.masked || false,
    },
  };
}

/**
 */

function serializeDefaultField(field) {
  return {
    data: {
      id: field.id,
      width: field.width,
      height: field.height,
      required: field.required,
      api_id: field.apiId || 'api',
      fontSize: field.fontSize,
      lineHeight: field.lineHeight,
      padding: field.padding,
    },
    x: field.x,
    y: field.y,
    z: 500,
  };
}

function serializeHyperlinkField(field) {
  return {
    data: {
      content: field.value ? field.value.trim() : null,
      fontFamily: field.fontFamily,
      contentUrl: field.url,
    },
  };
}

/**
 */

function serializeFieldType(field) {
  const componentType = {
    signature: 'inputsignature',
    date: 'inputdate',
    initials: 'inputinitials',
    text: 'inputtextbox',
    checkbox: 'inputcheckmark',
    dropdown: 'inputdropdown',
    radiobutton: 'inputradiobutton',
    hyperlink: 'inputhyperlink',
  }[field.type];

  if (!componentType) {
    throw new Error(`Unknown component type: ${field.type}`);
  }

  return {
    componentType,
  };
}
