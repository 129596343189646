import React from 'react';

export function useForceUpdate() {
  // The count is not used or returned. It exists only to cause the component
  // using this hook to re-render.
  const [, setCount] = React.useState(0);

  return React.useCallback(() => {
    // I just picked % 10 because I didn't want it to be able to count
    // infinitely. I think any number would work fine.
    setCount((count) => (count + 1) % 10);
  }, []);
}
