import React from 'react';

export function usePreviousValue<T>(value: T): undefined | T {
  const ref = React.useRef(value);
  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
