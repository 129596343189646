export const TYPE_SIGNATURE = 'S';
export const TYPE_INITIALS = 'I';
export type SignatureTypeCode = typeof TYPE_INITIALS | typeof TYPE_SIGNATURE;
export const TAB_DRAW = 'C';
export const TAB_TYPE = 'T';
export const TAB_UPLOAD = 'U';
export const TAB_EMAIL = 'E';
export const SIGNATURE_TYPE_CANVAS = TAB_DRAW;
export const SIGNATURE_TYPE_TYPED = TAB_TYPE;
export const SIGNATURE_TYPE_UPLOAD = TAB_UPLOAD;
export const SIGNATURE_TYPE_EMAIL = TAB_EMAIL;
export type CreateTypeCode =
  | typeof SIGNATURE_TYPE_CANVAS
  | typeof SIGNATURE_TYPE_EMAIL
  | typeof SIGNATURE_TYPE_TYPED
  | typeof SIGNATURE_TYPE_UPLOAD;
