import React from 'react';
import classnames from 'classnames';
import { DisplayField } from 'signer-app/signature-request';
import * as Dropdown from 'signer-app/signature-request/display-field/dropdown';
import {
  signatureRequestContext,
  zoomContext,
} from 'signer-app/signature-request/context';
import { UIIcon } from '@dropbox/dig-icons';
import { FailFill } from '@dropbox/dig-icons/assets';
import { signerContext } from 'signer-app/signer-signature-document/context';
import TextField from 'signer-app/signer-signature-document/text-field';
import styles from 'signer-app/signer-signature-document/signer-field.module.css';
import SignaturePlaceholder from 'signer-app/signer-signature-document/signature-placeholder';
import { Field } from 'signer-app/types/editor-types';
import { CheckboxField } from 'js/sign-components/generated/types/HelloRequest';
import { SignerContextShape } from 'signer-app/signer-signature-document/types';

export function isHidden(domNode: HTMLElement | null) {
  // When the modal is open it marks the site wrapper as hidden.
  let n = domNode;
  while (n) {
    if (
      n.attributes &&
      // @ts-expect-error - TS doesn't know about the `aria-hidden` attribute DPC_REMOVE
      n.attributes['aria-hidden'] &&
      // @ts-expect-error - TS doesn't know about the `aria-hidden` attribute DPC_REMOVE
      n.attributes['aria-hidden'].value === 'true'
    ) {
      return true;
    }
    n = n.parentNode as HTMLElement;
  }

  return false;
}

export const debugRenderContext = React.createContext((_id: string) => {});

type SignerFieldProps = {
  fieldData: Field;
  textScale: number;
  isActive: boolean;
  updateFields: (updates: Record<string, any>) => void;
  selectField: (id: string) => void;
  validationErrors: SignerContextShape['validationErrors'];
  getInstantValidationErrorHack: SignerContextShape['getInstantValidationErrorHack'];
  runZoomIntoField?: boolean;
  isOverlay?: boolean;
  documentPreview?: boolean;
};
function SignerField(props: SignerFieldProps) {
  const {
    fieldData,
    textScale,
    isActive,
    updateFields,
    selectField,
    validationErrors,
    getInstantValidationErrorHack,
    runZoomIntoField = false,
  } = props;
  const selfRef = React.useRef<HTMLDivElement>(null);
  const fieldRef = React.useRef<{ focus: () => void }>(null);
  const firstZoomIntoFieldRun = React.useRef(false);
  const { zoomIntoField } = React.useContext(zoomContext);
  const debugRender = React.useContext(debugRenderContext);
  debugRender(fieldData.id);

  const handleFocus = React.useCallback(() => {
    if (!isActive) {
      selectField(fieldData.id);
    }
  }, [fieldData.id, isActive, selectField]);

  React.useEffect(() => {
    if (firstZoomIntoFieldRun.current === false && runZoomIntoField) {
      zoomIntoField(fieldData);
      firstZoomIntoFieldRun.current = true;
    }
    // run this the first time runZoomIntoField is set to true, ignore zoomIntoField changes
  }, [runZoomIntoField]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTextFieldTouchEnd = React.useCallback(() => {
    zoomIntoField(fieldData);
  }, [fieldData, zoomIntoField]);

  React.useEffect(() => {
    if (fieldData.type === 'dropdown') {
      if (isActive) {
        // This has to be delayed because without it clicking the dropdown will:
        // 1. focus this field
        // 2. (without delay) this code will open the menu
        // 3. the click handler is triggered and closes the menu
        let isOpen = true;
        setTimeout(() => {
          if (isOpen) {
            Dropdown.openMenu(fieldData.id);
          }
        }, 100);

        return () => {
          isOpen = false;
          Dropdown.closeMenu();
        };
      }
    }
    return () => {};
  }, [fieldData.id, fieldData.type, isActive]);

  React.useEffect(() => {
    if (
      isActive &&
      selfRef.current != null &&
      fieldRef.current != null &&
      !isHidden(selfRef.current)
    ) {
      // This needs to be delayed slightly so that when you tab from one field
      // to the next, `handleFocus` can change the selected field before this
      // triggers and pulls the focus back. DEV-8823
      const t = setTimeout(() => {
        if (!selfRef.current?.contains(document.activeElement)) {
          fieldRef.current?.focus();

          if (
            !selfRef.current?.contains(document.activeElement) &&
            NODE_ENV === 'develop'
          ) {
            // eslint-disable-next-line no-console
            console.warn('Focus failed', fieldData.id);
          }
        }
      }, 1);

      // It's imporant to clear the timeout if the component re-renders. Without
      // this, when you tab from one field to the next, the two end up fighting
      // for focus in an endless loop.
      return () => {
        clearTimeout(t);
      };
    }
    return () => {};
  }); // Run on every render so that clicking next re-focusses the correct field
  // even if it was already marked "active"

  const updateField = React.useCallback(
    (update) => {
      if (fieldData.type === 'radiobutton') {
        if (update.checked) {
          updateFields({
            [fieldData.id]: { checked: true },
          });
        } else if (update.checked === false) {
          // radio buttons can't be de-selected. You can only move the selection
          // to another button in the group.
          return;
        }
      }

      updateFields({ [fieldData.id]: update });
    },
    [fieldData.id, fieldData.type, updateFields],
  );

  const onChange = React.useCallback(
    (e) => {
      const update =
        e.target.checked != null
          ? { checked: e.target.checked }
          : { value: e.target.value };
      updateField(update);
    },
    [updateField],
  );

  const asCheckbox = fieldData as CheckboxField;
  const checkboxKeypressHandler = React.useCallback(
    (e) => {
      let key = e.key;
      // Safari doesn't support .key, so we have to fall back to .keyCode
      key = key || e.keyCode;
      switch (key) {
        case 'Enter':
        case ' ':
          e.preventDefault();
          updateField({ checked: !asCheckbox.checked });
          break;
        default:
      }
    },
    [asCheckbox.checked, updateField],
  );

  const onKeyUp = React.useCallback(
    (evt) => {
      // Using `Enter` for submission is not standard but leaving it here for
      // legacy purposes
      if (evt.key === 'Enter') {
        updateField({ checked: !asCheckbox.checked });
      }
    },
    [asCheckbox.checked, updateField],
  );

  const checkboxTouchEndHandler = React.useCallback(() => {
    // iOS <=13 doesn't respect <label htmlFor="" targeting checkboxes, we have
    // to use this to simulate that behavior. Android devices can't run this
    // code because the two operations cancel each other out.
    if (navigator.userAgent.match(/iphone|ipad/gi) !== null) {
      const versionMatch = navigator.userAgent.match(/Version\/(\d+)/);

      // NOTE: At the moment the mobile emulation in Chrome uses version 13 in
      // its header, but it DOES respect `<label htmlFor=""`. This will still
      // look broken in Chrome emulating an iPhone
      if (versionMatch && Number(versionMatch[1]) <= 13) {
        // Setting field to active so it can trigger validation errors
        if (!isActive) {
          selectField(asCheckbox.id);
        }
        updateField({ checked: !asCheckbox.checked });
      }
    }
  }, [isActive, asCheckbox.checked, asCheckbox.id, updateField, selectField]);

  let field;

  switch (fieldData.type) {
    case 'signature':
    case 'initials':
      field = (
        <SignaturePlaceholder
          {...props}
          fieldData={fieldData}
          textScale={textScale}
          ref={fieldRef}
        />
      );
      break;
    case 'text':
      field = (
        <TextField
          {...props}
          fieldData={fieldData}
          ref={fieldRef as any}
          getInstantValidationErrorHack={getInstantValidationErrorHack!}
          isOverlay={props.isOverlay!}
          documentPreview={props.documentPreview!}
          updateField={updateField}
          onTouchEnd={handleTextFieldTouchEnd}
          onChange={onChange}
        />
      );
      break;
    case 'dropdown':
      field = (
        <DisplayField
          {...props}
          hideControls={Boolean(fieldData.readOnly)}
          ref={fieldRef}
          onChange={onChange}
        />
      );
      break;
    case 'checkbox':
    case 'radiobutton':
      field = (
        <DisplayField
          {...props}
          onKeyPress={checkboxKeypressHandler}
          // Prefer `keydown` to `keypress` if additional a11y fixes are
          // enabled
          onKeyUp={onKeyUp}
          onTouchEnd={checkboxTouchEndHandler}
          ref={fieldRef}
          onChange={onChange}
        />
      );
      break;
    case 'hyperlink':
      field = (
        // @ts-expect-error
        <DisplayField {...props} fieldData={fieldData} ref={fieldRef} />
      );
      break;
    default: {
      field = <DisplayField {...props} ref={fieldRef} onChange={onChange} />;
    }
  }

  return (
    <div onFocus={handleFocus} ref={selfRef} className={styles.signerField}>
      {/**
       * This element is serving as an additional visual indicator that there
       * is an error with this field so that we're not relying solely on
       * color. Since it's visual only, it's hidden from screen readers
       */}
      {validationErrors[fieldData.id] ? (
        <div
          className={styles.errorIndicator}
          style={{
            // @ts-expect-error
            '--textScale': textScale,
          }}
        >
          <UIIcon src={FailFill} aria-hidden="true" />
        </div>
      ) : null}
      {fieldData.type === 'checkbox' && <div className={styles.checkbox} />}
      {field}
      <div
        style={{
          // The old signer app makes the required * 20px tall, but this needs
          // to account for textScale/zoom
          fontSize: `${20 * textScale}px`,
        }}
        className={classnames(styles[fieldData.type], {
          // Only adding the field border for non readOnly fields
          [styles.fieldBorder]:
            !fieldData.readOnly && fieldData.type !== 'hyperlink',
          [styles.required]: fieldData.required,
          [styles.active]: isActive,
          [styles.error]: validationErrors[fieldData.id] != null,
        })}
      />
    </div>
  );
}

const MemoSignerField = React.memo(SignerField);
type Props = {
  fieldData: Field;
  textScale: number;
};
export default function SignerFieldContext(props: Props) {
  const { selectedFieldIds, isOverlay, documentPreview } = React.useContext(
    signatureRequestContext,
  );
  const {
    updateFields,
    selectField,
    validationErrors,
    getInstantValidationErrorHack,
    isPreview,
  } = React.useContext(signerContext);
  const [zoomIntoField, setZoomIntoField] = React.useState(false);
  const zoomIntoFieldHasRun = React.useRef(false);
  // MAJOR ICK but the PR can't be merged without a test pretending we're on a touch screen
  // to trigger zoomIntoField
  const isSmTouchscreen = React.useMemo(
    () =>
      ('ontouchstart' in window || typeof jest !== 'undefined') &&
      window.innerWidth <= 820,
    [],
  );

  React.useEffect(() => {
    const isSelected = selectedFieldIds[0] === props.fieldData.id;
    const isTextField = props.fieldData.type === 'text';
    if (
      !zoomIntoFieldHasRun.current &&
      !isPreview &&
      isSelected &&
      isTextField &&
      isSmTouchscreen
    ) {
      setZoomIntoField(true);
      zoomIntoFieldHasRun.current = true;
    }
    // For redirect from preview to focus on first field, selectedFieldIds is initially blank
  }, [selectedFieldIds]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MemoSignerField
      {...props}
      updateFields={updateFields}
      selectField={selectField}
      validationErrors={validationErrors}
      getInstantValidationErrorHack={getInstantValidationErrorHack}
      isOverlay={isOverlay}
      runZoomIntoField={zoomIntoField}
      documentPreview={documentPreview}
      isActive={selectedFieldIds.includes(props.fieldData.id)}
    />
  );
}
