import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { getWebflowUrl } from 'signer-app/utils/url-helpers';
import { getBrandName } from 'signer-app/utils/brand';
import digStyles from 'signer-app/signer-experience/dig-workaround.module.css';

export function LegalConfirm({ legalVersion }: { legalVersion: number }) {
  return legalVersion === 1 ? (
    <FormattedMessage
      id="signer.confirm.legalMessage1"
      description="text in signer flow, informing that signer\'s signature/ID has been inputted in all needed fields"
      defaultMessage="I agree to be legally bound by this document and the {brandName} <termsurl>Terms of Service</termsurl>. Click on 'I Agree' to sign this document."
      values={{
        brandName: getBrandName('S'),
        termsurl(...chunks: React.ReactNode[]) {
          return (
            <a
              className={classnames('whitelabel-link', digStyles.focusRing)}
              href={getWebflowUrl('terms')}
              rel="noopener noreferrer"
              target="_blank"
            >
              {chunks}
            </a>
          );
        },
      }}
    />
  ) : (
    <FormattedMessage
      id="signer.confirm.legalMessage2"
      description="text in signer flow, informing that signer\'s signature/ID has been inputted in all needed fields"
      defaultMessage="Almost done. By clicking 'I Agree' you are legally signing this document and agreeing to the eSignature <termsurl>Terms of Service</termsurl>."
      values={{
        termsurl(...chunks: React.ReactNode[]) {
          return (
            <a
              className={classnames('whitelabel-link', digStyles.focusRing)}
              href={getWebflowUrl('terms')}
              rel="noopener noreferrer"
              target="_blank"
            >
              {chunks}
            </a>
          );
        },
      }}
    />
  );
}
