type Value = string | number | boolean | undefined;
type QueryParams = Record<string, Value | Value[]>;

type Separator = '?' | '&' | '';

/**
 * @deprecated Try using a `URLSearchParams` object instead
 */
export function queryParams(obj: QueryParams, separator: Separator = '?') {
  const search = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return value
          .flatMap((v) =>
            v != null ? [`${key}=${encodeURIComponent(v)}`] : [],
          )
          .join('&');
      }

      if (value != null) {
        return `${key}=${encodeURIComponent(value)}`;
      }
      return null;
    })
    .filter((tmp) => tmp != null)
    .join('&');

  return search.length > 0 ? `${separator}${search}` : '';
}

/**
 * @deprecated Try using a `URLSearchParams` object instead
 */
export function appendQueryParameters(url: string, obj: QueryParams) {
  const separator = url.includes('?') ? '&' : '?';

  return url + queryParams(obj, separator);
}
