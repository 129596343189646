/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import {
  DocumentAddress,
  useZoomContext,
} from 'signer-app/signature-request/context';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import ResilientImage from 'signer-app/utils/resilient-image';
import Handle from 'signer-app/signature-request/handle';
import styles from 'signer-app/signature-request/document/page.module.css';
import PositionContext from 'signer-app/signature-request/position-context';
import { ORIGIN_VIEWPORT } from 'signer-app/signature-request/constants';
import { Field, Page } from 'signer-app/types/editor-types';
import {
  SignViewportObserver,
  useElementObserver,
} from 'signer-app/utils/use-viewport-observer';
import { attachSessionInfoToUrl } from 'signer-app/utils/url-helpers';

function useImgLoaded() {
  const [imgLoaded, setState] = React.useState(
    // Tests don't actually load the <img, so the onLoad will never fire
    navigator.userAgent.includes('jsdom'),
  );

  const handleLoad = React.useCallback(() => setState(true), []);

  return [imgLoaded, handleLoad] as const;
}

const NOOP = (tmp: any) => tmp;

/**
 * Handle page rotation and set appropriate styles for the doc images
 * @param rotationDegree
 * @param height
 * @param width
 * @param page
 */
function handlePageRotation(
  rotationDegree: string,
  height: string,
  width: string,
  page: Page,
) {
  const imgStyle: React.CSSProperties = { height, width };

  // if it's the default rotation degree, just return the default style
  if (rotationDegree === '0deg') {
    return imgStyle;
  }

  imgStyle.transform = `rotate(${rotationDegree})`;

  // if the orientations changed, apply styles below
  if (page.newOrientation !== page.orientation) {
    imgStyle.width = height;
    imgStyle.height = width;
    imgStyle.maxWidth = height;
    imgStyle.transformOrigin = 'top left';
    // change translate x & y between 90 & -90 degrees
    imgStyle.transform +=
      rotationDegree === '90deg'
        ? 'translate(0, -100%)'
        : 'translate(-100%, 0)';
  }

  return imgStyle;
}

type Props = {
  Field: React.ComponentType<any>;
  fields: Array<Field>;
  margin: string | number | undefined;
  page: Page;
  pageIndex: number;
  showImg: boolean;
  width: string;
  className: string;
  showUneditableMessage?: boolean;
  rotationDegree?: string;
  observer?: SignViewportObserver;
  wrapImg?: (node: React.ReactNode) => React.ReactNode;
  onPageClick: (
    event: React.MouseEvent,
    pageIndex: number,
    address: DocumentAddress,
  ) => void;
  'data-qa-ref'?: string;
};
function PageComponent(props: Props) {
  const {
    Field,
    wrapImg = NOOP,
    fields,
    margin,
    onPageClick = NOOP,
    page,
    pageIndex,
    showImg,
    width,
    className,
    rotationDegree = '0deg',
    showUneditableMessage = false,
    observer,
  } = props;
  const pageRef = React.useRef(null);
  const { fromScreenCoords } = useZoomContext();
  const imgStyle = handlePageRotation(rotationDegree, '100%', '100%', page);
  const center = React.useRef<HTMLDivElement>(null);

  // Firefox drags the image instead of letting us handle drag
  const blockNativeDrag = React.useCallback((e) => e.preventDefault(), []);
  const [imgLoaded, handleLoad] = useImgLoaded();
  const isVisible = useElementObserver(observer, pageRef);
  const onClick = React.useCallback(
    (event) => {
      // Mobile touch targets should be at leat 48px square.  This will convert
      // 48px into document coordinates
      const mobileTargetSize = 48;
      const address = fromScreenCoords(
        {
          pageIndex,
          x: event.clientX,
          y: event.clientY,

          width: mobileTargetSize,
          height: mobileTargetSize,
          // This shouldn't really be necessary
          documentId: '',
        },
        ORIGIN_VIEWPORT,
      );

      return onPageClick(event, pageIndex, address);
    },
    [fromScreenCoords, onPageClick, pageIndex],
  );

  let useFallback = false;
  let src = attachSessionInfoToUrl(page.src);
  if (page.cdnSrc) {
    useFallback = true;
    src = page.cdnSrc;
  }
  // This padding and the .aspectContainer are able to force the page into the
  // specified aspect ratio. https://css-tricks.com/aspect-ratio-boxes/
  const paddingTop = `${(page.height / page.width) * parseFloat(width)}%`;

  return (
    <div
      id={`page-${pageIndex}`}
      data-qa-ref={props['data-qa-ref'] || `page-${pageIndex}`}
      className={classnames(styles.page, className, {
        [styles.imgHidden]: showImg === false,
        [styles.isBackdrop]: true,
      })}
      style={{ width, margin, paddingTop }}
      ref={pageRef}
    >
      <div className={styles.aspectContainer}>
        <PositionContext>
          {isVisible && showImg
            ? wrapImg(
                <div>
                  <ResilientImage
                    className={styles.img}
                    style={imgStyle}
                    data-qa-ref="page-image"
                    draggable={false}
                    onMouseDown={blockNativeDrag}
                    onLoad={handleLoad}
                    src={src}
                    fallbackUrl={
                      useFallback ? attachSessionInfoToUrl(page.src) : ''
                    }
                    onClick={onClick}
                  />
                </div>,
              )
            : null}
          <div ref={center} className={styles.center} />
          <Tooltip.Control
            auto
            open={showUneditableMessage}
            placement="top"
            triggerRef={center}
          >
            <FormattedMessage
              id=""
              description="On hover, this tooltip is displayed to explain the associated icon"
              defaultMessage={'Template cannot be edited.'}
            />
          </Tooltip.Control>
          {((isVisible && imgLoaded) || showImg === false) &&
            fields.map((f) => (
              <Handle key={f.id} fieldData={f} Field={Field} />
            ))}
        </PositionContext>
      </div>
    </div>
  );
}

export default React.memo(PageComponent);
