/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  camelcase,
  global-require,
  import/no-extraneous-dependencies,
  max-len,
  no-console,
  no-void,
  react/no-unescaped-entities
*/
import './index.scss';

import React from 'react';
import Logo from 'signer/components/common/logo';
import VerifyEmail from 'hellosign/components/auth/verify-email';
import TemplateLinkLoginOrSignup from 'hellosign/components/auth/template-link-login-or-signup';
import LoginInPerson from 'hellosign/components/auth/login-in-person';
import HeaderAlert from 'signer/components/common/header-alert';
import HeaderAlertTypes from 'signer/components/common/header-alert/header-alert-types';
import createReactClass from 'create-react-class';

const TemplateLink = createReactClass({
  getInitialState() {
    return {
      verifyEmail: this.props.app.templateLink.verifyEmail || false,
    };
  },

  onLoginOrSignupSuccess(data) {
    if (data.success === true) {
      this.setState({
        verifyEmail: true,
        emailAddress: data.email_address,
      });
    } else {
      console.error('unable to get or create account', data);
    }
  },

  renderInPersonForm() {
    const clazz = navigator.userAgent.match(/iPad/)
      ? 'm-login-in-person--ipad-wrapper'
      : '';

    return (
      <div className={clazz}>
        <LoginInPerson
          guid={this.props.app.templateLink.shortGuid}
          title={this.props.app.templateLink.templateLinkTitle}
          fields={this.props.login.signupForm.fields}
          values={this.props.login.signupForm.values}
          namespace={this.props.login.signupForm.namespace}
          {...this.props}
        />
      </div>
    );
  },

  renderWelcomeInstructions() {
    const title = this.props.app.templateLink.templateLinkTitle;

    return (
      <HeaderAlert
        type={HeaderAlertTypes.INSTRUCTIONS}
        message=""
        shouldTimeout={false}
      >
        <div className="in-person-instructions">
          <h4>
            Add <strong>{title}</strong> to your home screen
          </h4>
          <p>
            Click the
            <img src={require('./assets/addtohomescreen_icon_40x40.png')} />
            then 'Add to home screen'.
          </p>
        </div>
      </HeaderAlert>
    );
  },

  render() {
    const isInPerson = !!this.props.app.templateLink.isInPerson;
    const verifyEmail = !!this.state.verifyEmail;
    const is_ios_webview = window.navigator.standalone === false; // as opposed to 'app view' (launched from homescreen)

    const logo = this.props.app.isDesktop ? <Logo dark={true} /> : void 0;
    const welcomeInstructions =
      isInPerson && is_ios_webview ? this.renderWelcomeInstructions() : void 0;
    let modalContent;

    if (verifyEmail) {
      modalContent = (
        <VerifyEmail
          shortGuid={this.props.app.templateLink.shortGuid}
          emailAddress={this.state.emailAddress}
          csrf_token={this.props.login.signupForm.values._csrf_token}
        />
      );
    } else if (isInPerson) {
      modalContent = this.renderInPersonForm();
    } else {
      modalContent = (
        <TemplateLinkLoginOrSignup
          shortGuid={this.props.app.templateLink.shortGuid}
          title={this.props.app.templateLink.templateLinkTitle}
          csrf_token={this.props.login.signupForm.values._csrf_token}
          onSuccess={this.onLoginOrSignupSuccess}
          tosUrl={this.props.tosUrl}
          privacyPolicyUrl={this.props.privacyPolicyUrl}
        />
      );
    }

    return (
      <div className="m-signer-mobile-template-link">
        {welcomeInstructions}
        {logo}
        <div className="m-signer-mobile-template-link--doc-background">
          <div className="m-signer-mobile-template-link--modal">
            {modalContent}
          </div>
        </div>
      </div>
    );
  },
});

module.exports = TemplateLink;
