/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  eqeqeq,
  import/no-extraneous-dependencies,
  no-void,
  react/no-string-refs,
  max-len
*/
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import FixedModal from 'common/components/fixed-modal';
import { Button } from '@dropbox/dig-components/buttons';
import { ErrorMessage } from 'common/messages';
import errorCodes from 'signer/api/errors';
import Loader from 'signer-app/parts/loader';
import logger from 'common/logger';
import CloseImageSrc from 'common/assets/modal-close@2x.png';
import {
  postMessage,
  messages as messageTypes,
} from 'js/sign-components/common/messages';
import { signerDeclineModalMessages } from 'signer-app/signer-decline-modal/signer-decline-modal.intl';
import './index.scss';

class ConfirmDecline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };

    this.decline = this.decline.bind(this);
    this.close = this.close.bind(this);
    this.checkLength = this.checkLength.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidMount() {
    logger.track('ConfirmDecline.componentDidMount');
  }

  checkLength() {
    const reason = this.refs.declineReason.value;
    const errors = this.state.errors;
    if (!reason) {
      errors.noReason = true;
    } else {
      errors.noReason = false;
    }
    this.setState({ errors });
  }

  hasErrors() {
    const errorKeys = Object.keys(this.state.errors);
    const errors = errorKeys.filter((key) => this.state.errors[key] === true);
    return errors.length > 0;
  }

  decline() {
    this.checkLength();
    if (this.hasErrors()) {
      return;
    }

    const reason = this.refs.declineReason.value.trim();
    const { signatureRequest, router, notifier } = this.props.app;
    this.listeningAsyncResponse = true;
    logger.track('ConfirmDecline.decline');
    signatureRequest
      .decline(reason)
      .then((response) => {
        if (this.props.app.isEmbedded()) {
          HelloSign.XWM.send(
            'decline',
            this.props.app.signatureRequest.embeddedData.parentUrl,
          );

          // Embedded V2
          postMessage(
            {
              type: messageTypes.USER_DECLINE_REQUEST,
              payload: {
                signatureId:
                  this.props.app.signatureRequest.embeddedData.signatureId,
                reason,
              },
            },
            this.props.app.signatureRequest.embeddedData.parentUrl,
          );
        }

        if (response.redirect_url != void 0) {
          window.location = response.redirect_url;
        } else {
          router.redirect('declineComplete');
        }
      })
      .catch(() => {
        this.listeningAsyncResponse = false;
        this.close();
        const error = new Error(
          this.props.intl.formatMessage(
            signerDeclineModalMessages.declineFailed,
          ),
        );
        error.code = errorCodes.SERVER;
        notifier.notify(ErrorMessage.create(error, this));
      });
  }

  close() {
    this.props.app.router.hideFullScreenModal();
  }

  render() {
    const app = this.props.app;

    const closeButton = (
      <div className="close-button" onClick={this.close}>
        <img src={CloseImageSrc} />
      </div>
    );

    let errors;
    if (this.state.errors.noReason) {
      errors = (
        <div className="error">
          <FormattedMessage {...signerDeclineModalMessages.noReasonError} />
        </div>
      );
    }

    const mainContent = (
      <div className="main-content">
        <h1>
          <FormattedMessage {...signerDeclineModalMessages.title} />
        </h1>
        <p>
          <FormattedMessage {...signerDeclineModalMessages.description} />
        </p>
        <div>
          <h2>
            <FormattedMessage {...signerDeclineModalMessages.reasonTitle} />
            <span className="asterisk">*</span>
          </h2>
          <small>
            <FormattedMessage
              {...signerDeclineModalMessages.sharedOnlyWithSender}
            />
          </small>
          <textarea
            data-testid="decline-reason"
            ref="declineReason"
            maxLength={255}
          />
          {errors}
        </div>
      </div>
    );

    const ctaBar = (
      <div className="cta-bar">
        <Button
          data-testid="signer-cancel-btn"
          className="whitelabel-secondary-button"
          onClick={this.close}
          type="button"
          variant="opacity"
        >
          <FormattedMessage {...signerDeclineModalMessages.cancelButton} />
        </Button>
        <Button
          data-testid="signer-decline-btn"
          onClick={this.decline}
          className="whitelabel-primary-button"
          type="button"
          variant="primary"
        >
          <FormattedMessage {...signerDeclineModalMessages.declineButton} />
        </Button>
      </div>
    );

    const spinner = <Loader className="loader" />;

    const modalStyle = app.isMobile ? { borderRadius: 0 } : { borderRadius: 5 };

    const fixedModalAttrs = {
      width: 600,
      modalStyle,
      hideOnOverlayClick: true,
      onOverlayClick: this.close,
    };

    return (
      <FixedModal {...this.props} {...fixedModalAttrs}>
        <div className="m-modal-confirm-decline">
          {closeButton}
          {this.listeningAsyncResponse ? spinner : mainContent}
          {this.listeningAsyncResponse ? '' : ctaBar}
        </div>
      </FixedModal>
    );
  }
}

export default injectIntl(ConfirmDecline);
