import React from 'react';
import classnames from 'classnames';
import { useRefReady } from 'signer-app/utils/use-ref-ready';
import {
  signatureRequestContext,
  zoomContext,
  ORIGIN_PAGE_CONTAINER,
  getBoundingBox,
} from 'signer-app/signature-request';
import { signerContext } from 'signer-app/signer-signature-document/context';
import styles from 'signer-app/signer-signature-document/signer-field.module.css';
import { CheckboxField, RadioField } from 'signer-app/types/editor-types';
import { DocumentAddress } from 'signer-app/signature-request/context';

type GroupableFields = CheckboxField | RadioField;

type Props = {
  groupedFields: Record<string, Array<GroupableFields>>;
};

function GroupedFields({ groupedFields }: Props) {
  const { pageContainerRef, selectedFieldIds } = React.useContext(
    signatureRequestContext,
  );
  const { toScreenCoords, textScale } = React.useContext(zoomContext);
  const { validationErrors } = React.useContext(signerContext);
  const readyForPages = useRefReady(pageContainerRef);

  // Don't render groups until we have a page container so that positions can be
  // calculated accurately.
  if (!readyForPages) {
    return null;
  }

  const nodes = Object.keys(groupedFields).map((group) => {
    const convertedFields = groupedFields[group].map(
      (f) => toScreenCoords(f, ORIGIN_PAGE_CONTAINER) as DocumentAddress,
    );
    const boundingBox = getBoundingBox(convertedFields);

    const activeField = groupedFields[group].find((f) =>
      selectedFieldIds.includes(f.id),
    );

    const margin = Math.min(boundingBox.height, boundingBox.width) * 0.2;

    // All groups have a requirement, but if the lower bound is 0 it isn't
    // really required.
    const requirement = groupedFields[group][0].requirement;
    const required = requirement?.indexOf('require_0') === -1;

    return (
      <div
        style={{
          // The old signer app makes the required * 20px tall, but this needs
          // to account for textScale/zoom
          fontSize: `${20 * textScale}px`,
          left: boundingBox.x - margin,
          top: boundingBox.y - margin,
          width: boundingBox.width + margin * 2,
          height: boundingBox.height + margin * 2,
        }}
        className={classnames(styles.groupBorder, styles.group, {
          [styles.required]: required,
          [styles.active]: activeField != null,
          [styles.error]:
            activeField && validationErrors[activeField.id] != null,
        })}
        key={group}
      />
    );
  });
  return <>{nodes}</>;
}

export default React.memo(GroupedFields);
