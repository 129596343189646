import React from 'react';

/**
 * This function just redirects to
 * the given URL. Pulled this out to its
 * own function to make unit testing easier.
 *
 *
 * jest.mock('hellospa/common/utils/redirect', () => ({
 *   redirectTo: jest.fn(),
 * }));
 *
 * @param url
 */
export const redirectTo = (url: string) => {
  window.location.assign(url);
  // This will never resolve, so it holds this promise active until the page
  // unloads
  return new Promise(() => {});
};

export function RedirectTo({ url }: { url: string }) {
  React.useEffect(() => {
    redirectTo(url);
  }, [url]);

  return null;
}
