import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import styles from 'signer-app/parts/loader-spinner/loader-spinner.module.css';
import loadingSpinner from 'signer-app/parts/loader-spinner/assets/loading-spinner.gif';

const messages = defineMessages({
  loading: {
    id: '825a9337ee867f1b63446db1f8a752b0df80cf666040fbd3de308e31ed920fbc',
    description: 'Loading message.',
    defaultMessage: 'Loading…',
  },
});

export default function LoaderSpinner() {
  const intl = useIntl();

  return (
    <div>
      <img
        alt={intl.formatMessage(messages.loading)}
        src={loadingSpinner}
        className={styles.LoaderSpinner}
      />
    </div>
  );
}
