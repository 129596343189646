import { defineMessages } from 'react-intl';

const hsMessages = defineMessages({
  requiredMessage: {
    id: 'error.field.requiredMessage',
    description: 'generic error message when required field is not filled',
    defaultMessage: 'This field is required',
  },
  fieldsMissing: {
    id: 'error.field.fieldsMissing',
    description: 'generic error message when required field is not filled',
    defaultMessage: 'Required fields are missing. Please click \'Edit\'.',
  },
  minNotMetMessage: {
    id: 'error.field.minNotMetMessage',
    description: 'generic error message when minimum required boxes are not selected',
    defaultMessage: 'Please select at least {min} { min, plural, =1 {item} other {items} }',
  },
  maxExceededMessage: {
    id: 'error.field.maxExceededMessage',
    description: 'generic error message when more then the limit of boxes are selectex',
    defaultMessage: 'You have selected too many boxes. Please uncheck one.',
  },
  generalErrorMessage: {
    id: 'error.general.message',
    description: 'general error message',
    defaultMessage: 'An error occurred',
  },
});

export default hsMessages;
