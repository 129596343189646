import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import { queryParams } from 'js/sign-components/common/fetch';
import type { FolderPathBody } from 'hellospa/components/deep-integration/dropin/types';
import type {
  SaveDropboxPathResponse,
  SaveStatusResponse,
} from 'hellospa/page/prep-and-send/data/types/deep-integration';
import type {
  DefaultSavePathRequest,
  DefaultSavePathResponse,
} from 'hellospa/components/deep-integration/folder-picker/types';

/**
 * Make a POST to save the folder path and filename for the dropin
 * @param  {FolderPathBody} body
 * @return {void}
 */
export const saveDropboxFolderPath = async ({
  filename,
  folderPath,
  guid,
}: FolderPathBody): Promise<SaveDropboxPathResponse> => {
  const url = '/dropbox/savePath';

  const formData = new FormData();
  formData.append('guid', guid);
  formData.append('filename', filename);
  formData.append('save_path', folderPath);
  formData.append('csrf_token', getCSRFToken());

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });
  return response.json();
};

export const saveDefaultSavePath = async ({
  defaultSavePath,
}: DefaultSavePathRequest): Promise<DefaultSavePathResponse> => {
  const url = '/dropbox/saveDefaultSavePath';

  const formData = new FormData();
  formData.append('default_save_path', defaultSavePath);
  formData.append('csrf_token', getCSRFToken());

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });
  return response.json();
};

export const pollSavingBackToDropboxStatus = async (
  transmissionGroupGuid: string,
): Promise<SaveStatusResponse> => {
  const url = `/dropbox/saveStatus${queryParams({
    guid: transmissionGroupGuid,
    c: Math.random(),
  })}`;

  const response = await hsFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
  return response.json();
};
