/* eslint-disable import/prefer-default-export */

// Often when mapping or filtering arrays TypeScript knows that you can end up
// with empty arrays.
//
// const fields: Array<Field> = ids
//   // TypeScript knows that .find can return undefined
//   .map(id => fields.find(f => f.id === id))
//   // so this filter asures TS that you have an Array<Field> and not
//   // an Array<Field | undefined>
//   .filter(notEmpty)

import React from 'react';
import StringHelpers from './string-helpers';
import { sentryEnvironment } from 'js/sign-components/env';

export function notEmpty<T>(item: T | null | undefined): item is T {
  return item != null;
}

export function isLocalEnvironment(): boolean {
  return sentryEnvironment === 'development';
}

export function isProduction(): boolean {
  return sentryEnvironment === 'production';
}

export function unreachable(_: never) {}

export class UnreachableError extends Error {
  constructor(_unreachableItem: never) {
    super(`Unexpected value found at runtime: ${_unreachableItem as string}`);
    this.name = 'UnreachableError';
  }
}

export type RecursivePartial<T> = T extends Object
  ? { [Key in keyof T]?: RecursivePartial<T[Key]> }
  : T;

/**
 * This generates a partial (all properties are optional) of a union, but still
 * require the one property that identifies its type.
 */
export type PartialUnion<T, TypeKey extends keyof T> = Partial<T> & {
  [P in TypeKey]: T[P];
};

// I don't know why we don't seem to have Omit even though we're on 3.5
export type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export type PropsOf<T extends React.ComponentType<any>> =
  T extends React.ComponentType<infer P> ? P : never;

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>;

export type MakeRequired<T, K extends keyof T> = Required<Pick<T, K>>;

/**
 * Similar to Omit, but will turn keys into undefined instead of removing the
 * key
 */
export type ForbidKeys<T, U> = {
  [k in keyof T]: k extends U ? undefined : T[k];
};

export type ValueOfArray<T> = T[keyof T & number];

export type ValuesOfRecord<T extends Record<any, any>> = T[keyof T];

export function groupBy<T extends { [key in K]: T[K] }, K extends keyof T>(
  key: K,
  items: T[],
): Record<Required<T>[K], T[]> {
  return items.reduce(
    (memo, value) => {
      const tmp = value[key];
      if (tmp) {
        memo[tmp] = memo[tmp] || [];
        memo[tmp].push(value);
      }
      return memo;
    },
    {} as Record<T[K], T[]>,
  );
}

export function keyBy<T extends { [key in K]: string }, K extends keyof T>(
  key: K,
  items: T[],
): Record<T[K], T> {
  return items.reduce(
    (memo, value) => {
      memo[value[key]] = value;
      return memo;
    },
    {} as Record<T[K], T>,
  );
}

// This should match the logic in BE for validating title
export function containsEmailOrUrl(title: string): boolean {
  const emailRegex = /\S+@\S+\.\S+/;
  const urlRegex = /https?:\/\/|www\./i;
  return Boolean(title.match(emailRegex)) || Boolean(title.match(urlRegex));
}

function hasOwnProperty<O extends {}, P extends PropertyKey>(
  obj: O,
  prop: P,
): obj is O & Record<P, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

type TypeGuard<T> = (obj: unknown) => obj is T;

export const isString = (o: unknown): o is string => typeof o === 'string';
export function readUnknown<P extends PropertyKey, T = unknown>(
  obj: unknown,
  prop: P,
  // I couldn't make this optional by defaulting it to isUnknown
  typeGuard: TypeGuard<T>,
): T | undefined {
  if (typeof obj === 'object' && obj != null && hasOwnProperty(obj, prop)) {
    const tmp: unknown = obj[prop];
    if (typeGuard(tmp)) {
      return tmp;
    }
  }
  return undefined;
}

export function snakeCaseObj(obj: Record<string, any>): Record<string, any> {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    throw new Error('Input must be a non-null object.');
  }

  const snakeObj: Record<string, any> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const formattedKey = StringHelpers.toSnakeCase(key);
      snakeObj[formattedKey] = obj[key];
    }
  }

  return snakeObj;
}

/**
 * Overwrites one type with another.
 *
 * @see https://stackoverflow.com/questions/43080547/how-to-override-type-properties-in-typescript
 * @example
 *
 * type WidgetProps = {
 *   foo: string,
 * }
 *
 * type OverwrittenWidgetProps = Overwrite<WidgetProps, {
 *   foo: number,
 * }>
 */
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
