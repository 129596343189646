import { Actions } from './index';
import * as Yup from 'yup';
import { Settings } from 'js/sign-components/generated/types/HelloRequest';

export { Settings };

export const settingsSchema = Yup.object<Settings>({
  addCoverPage: Yup.boolean(),
  recipientOrder: Yup.boolean(),
  qes: Yup.boolean(),
  nom151: Yup.boolean(),
  recipientReassignment: Yup.boolean(),
  requireSignerInfo: Yup.boolean(),
  sendHighResFax: Yup.boolean(),
  hasForm: Yup.boolean(),
});

const updateAccountParamsSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  alwaysSendMeACopy: Yup.boolean(),
  alwaysSendOthersACopy: Yup.boolean(),
});

export type UpdateAccountParams = Yup.InferType<
  typeof updateAccountParamsSchema
>;

export type UpdateAccountResponse = {
  success: boolean;
};

export interface UpdateSettingsAction {
  type: Actions.UpdateSettings;
  payload: Partial<Settings>;
}

export type SettingsActions = UpdateSettingsAction;
