/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  default-case,
  import/no-extraneous-dependencies,
  react/prop-types
*/
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Loader from 'signer-app/parts/loader';
import errorCodes from 'signer/api/errors';
import setInterval from 'common/utils/set-interval';
import logger from 'common/logger';
import createReactClass from 'create-react-class';
import { postMessage, messages } from 'js/sign-components/common/messages';
import hsMessages from 'signer/components/common/translations/hs-messages';
import { intl } from 'hellospa/common/hs-intl-provider';

const POLL_INTERVAL = 1000;

const ErrorPage = createReactClass({
  timer: null,
  propTypes: {
    iconName: PropTypes.string,
    message: PropTypes.string,
    code: PropTypes.string,
    pollCallback: PropTypes.func,
    intl: PropTypes.object,
  },

  //  ----  INITIALIZATION  -----------------------------

  getDefaultProps() {
    return {
      iconName: 'close',
      message: intl.formatMessage(hsMessages.generalErrorMessage),
    };
  },

  //  ----  LIFECYCLE  ----------------------------------

  clearPollTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },

  componentDidMount() {
    logger.track('ErrorPage.componentDidMount', { code: this.props.code });
    logger.error(
      `Error page displayed to signer because of error ${
        this.props.code != null
          ? `code: ${this.props.code}`
          : `message: ${this.props.messageFallback}`
      }`,
    );

    if (typeof this.props.app !== 'undefined' && this.props.app.isEmbedded()) {
      // Embedded V2
      postMessage(
        {
          type: messages.APP_ERROR,
          payload: {
            code: this.props.code,
            signatureId:
              this.props.app.signatureRequest.embeddedData.signatureId,
          },
        },
        this.props.app.signatureRequest.embeddedData.parentUrl,
      );
    }

    this.timer = null;

    switch (this.props.code) {
      case errorCodes.NETWORK:
        // If the request isn't ready, saving it over and over again will never
        // succeed.
        if (!this.props.app.signatureRequest.isReady()) {
          break;
        }
        this.timer = setInterval(() => {
          this.props.app.signatureRequest.save().then(() => {
            // No need to poll anymore
            this.clearPollTimer();

            // Notify parent component that polling is over
            if (this.props.pollCallback) {
              this.props.pollCallback();
            }
          });
        }, POLL_INTERVAL);
        break;
    }
  },

  componentWillUnmount() {
    this.clearPollTimer();
  },

  //  ----  RENDERING  ----------------------------------

  render() {
    let content;

    switch (this.props.code) {
      case errorCodes.NETWORK:
        if (this.props.app.signatureRequest.isReady()) {
          content = this.renderNetworkErrorPage();
        } else {
          // If the signature request isn't ready, then the network error isn't
          // really recoverable.
          content = this.renderGenericErrorPage();
        }

        break;

      case errorCodes.NO_VALID_SIG_TYPES:
        content = this.renderNoValidSignatureTypesErrorPage();
        break;

      default:
        content = this.renderGenericErrorPage();
    }

    return content;
  },

  renderGenericErrorPage() {
    const iconClassName = `ion-ios-${this.props.iconName}-outline`;
    return (
      <div className="m-error-page">
        <div className="m-error-page--header">
          <span className="m-error-page--header--title">
            <i className={iconClassName}></i>
            <FormattedMessage id={this.props.message} />
          </span>
        </div>
      </div>
    );
  },

  renderNetworkErrorPage() {
    return (
      <div className="m-error-page is-network">
        <div className="m-error-page--icon"></div>
        <div className="m-error-page--title">
          <FormattedMessage
            id="error.network.title"
            description="generic error message when the network is lost"
            defaultMessage="Your connection has been lost."
          />
        </div>
        <div className="m-error-page--message">
          <FormattedMessage
            id="error.network.message"
            description="text, asking signer to wait till the network is reconnecte again"
            defaultMessage="Please wait until your connection is reestablished to continue."
          />
        </div>
        <Loader className="m-error-page--loader" />
      </div>
    );
  },

  renderNoValidSignatureTypesErrorPage() {
    return (
      <div className="m-error-page is-no-valid-signature-types">
        <div className="m-error-page--icon"></div>
        <div className="m-error-page--title">
          <FormattedMessage
            id="error.noValidSignatureTypes.title"
            description="error message in signer flow, informing that signer can't sing in using mobile phone"
            defaultMessage="Can't sign on mobile"
          />
        </div>
        <div className="m-error-page--message">
          <FormattedMessage
            id="error.noValidSignatureTypes.message"
            description="warning message in signer flow, asking user to use computer for this kind of document to complete signing"
            defaultMessage="Warning: This document can only be completed from a computer due to the settings set by the person that sent you this document."
          />
        </div>
      </div>
    );
  },
});

module.exports = ErrorPage;
