export class SignatureEditAreaHelper {
  public static scaleCrop(
    imageScaledSize: DOMRect,
    unrotatedImageSize: { width: string; height: string },
    cropSize: Pick<DOMRect, 'width' | 'height' | 'x' | 'y'>,
    rotationDegrees: number,
  ) {
    const imageOriginalSize =
      rotationDegrees === 0 || rotationDegrees === 180
        ? {
            width: parseFloat(unrotatedImageSize.width),
            height: parseFloat(unrotatedImageSize.height),
          }
        : {
            width: parseFloat(unrotatedImageSize.height),
            height: parseFloat(unrotatedImageSize.width),
          };
    const scaleY = imageOriginalSize.height / imageScaledSize.height;
    const scaleX = imageOriginalSize.width / imageScaledSize.width;
    let scaledPositionX = 0;
    let scaledPositionY = 0;
    let scaledWidth = imageOriginalSize.width;
    let scaledHeight = imageOriginalSize.height;

    scaledPositionX = Math.floor(scaleX * cropSize.x);
    scaledPositionY = Math.floor(scaleY * cropSize.y);
    scaledWidth = Math.floor(scaleX * cropSize.width);
    scaledHeight = Math.floor(scaleY * cropSize.height);

    if (scaledWidth > imageOriginalSize.width) {
      scaledWidth = imageOriginalSize.width;
    }

    if (scaledWidth + scaledPositionX > imageOriginalSize.width) {
      scaledWidth = imageOriginalSize.width - scaledPositionX;
    }

    if (scaledHeight > imageOriginalSize.height) {
      scaledHeight = imageOriginalSize.height;
    }

    if (scaledHeight + scaledPositionY > imageOriginalSize.height) {
      scaledHeight = imageOriginalSize.height - scaledPositionY;
    }

    return {
      x: scaledPositionX,
      y: scaledPositionY,
      width: scaledWidth,
      height: scaledHeight,
    };
  }
}
