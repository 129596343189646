import { create, extend } from 'common/utils/class';

export const CHANGE = 'change';
export const RESPONSE = 'response';
export const ERROR = 'error';

export const LOAD = 'load';
export const INSERT = 'insert';
export const UPDATE = 'update';
export const REMOVE = 'remove';

export const INITIALIZE = 'initialize';

export function BaseMessage(type, props = {}) {
  Object.assign(this, {
    type,
    ...props,
  });
}

BaseMessage.create = create;
BaseMessage.extend = extend;

export function ChangeMessage(target, changes = []) {
  BaseMessage.call(this, CHANGE, {
    target,
    changes,
  });
}

// make instanceof work
BaseMessage.extend(ChangeMessage);

export function ResponseMessage(response, requestMessage) {
  BaseMessage.call(this, RESPONSE, {
    data: response,
    requestMessage,
  });
}

BaseMessage.extend(ResponseMessage);

export function RequestMessage(type, apiEndpointAlias, props) {
  BaseMessage.call(this, type, {
    apiEndpointAlias,
    ...props,
  });
}

BaseMessage.extend(RequestMessage, {
  resolve() {},
  reject() {},
});

export function ErrorMessage(error, target) {
  BaseMessage.call(this, ERROR, {
    error,
    target,
  });
}

BaseMessage.extend(ErrorMessage);

export function InitializeMessage() {
  BaseMessage.call(this, INITIALIZE);
}

BaseMessage.extend(InitializeMessage);
