import React from 'react';
import Progress from 'signer-app/parts/progress';
import DotsLoader from 'signer-app/parts/loader';
import styles from 'signer-app/parts/full-page-loader/full-page-loader.module.css';

type Props = React.PropsWithChildren<{
  showDotsLoader?: boolean;
  progress?: number;
  loop?: boolean;
  onTweenedProgress?: (progress: number) => void;
}>;

export default function FullPageLoade(props: Props) {
  let children;

  if (props.showDotsLoader) {
    children = <DotsLoader>{props.children}</DotsLoader>;
  } else {
    children = (
      <Progress
        className="whitelabel-loader"
        onTweenedProgress={props.onTweenedProgress}
        progress={props.progress ?? 0}
        loop={props.loop ?? false}
      >
        {props.children}
      </Progress>
    );
  }

  return <div className={styles.loader}>{children}</div>;
}
