import React from 'react';
import classnames from 'classnames';
import { useHtmlIDs } from 'signer-app/utils/use-html-ids';
import { RadioField } from 'signer-app/types/editor-types';
import styles from 'signer-app/signature-request/display-field/checkbox.module.css';
import RadioIcon from 'signer-app/icons/radio-button';
import { signatureRequestContext } from 'signer-app/signature-request/context';
import 'signer-app/screenreaders.module.css';

export type Props = {
  fieldData: RadioField;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLLabelElement>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const RadioButton = React.forwardRef<HTMLInputElement, Props>(
  function NewRadioButton(props, ref) {
    const { className, fieldData, onChange, onKeyUp, onTouchEnd } = props;
    const htmlId = useHtmlIDs();
    const context = React.useContext(signatureRequestContext);

    return (
      <React.Fragment>
        <input
          ref={ref}
          type="radio"
          id={htmlId('radio')}
          name={fieldData.group}
          value={fieldData.id}
          onChange={onChange}
          checked={fieldData.checked || false}
          data-field={fieldData.id}
          aria-label={fieldData.name}
          onKeyUp={onKeyUp}
          onTouchEnd={onTouchEnd}
          // Disabled seems to exhibit the previous "readonly" behavior. We
          // don't want this field to be focusable or interactive when in
          // readonly mode
          disabled={fieldData.readOnly}
          className="visuallyHidden"
        />
        <label
          className={classnames(styles.radioLabel, className)}
          htmlFor={htmlId('radio')}
        >
          <RadioIcon
            className={styles.radio}
            checked={fieldData.checked || false}
            isOverlay={context.isOverlay || false}
          />
        </label>
      </React.Fragment>
    );
  },
);

export default RadioButton;
