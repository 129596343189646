/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import createReactClass from 'create-react-class';
import Button from '../button';

const BlueButton = createReactClass({
  render() {
    const props = Object.assign(
      {
        buttonClass: this.props.className,
        buttonHoverTextColor: 'white',
        buttonColor: 'cerulean',
        buttonTextColor: 'white',
      },
      this.props,
    );

    if (this.props.app && this.props.app.isMobile) {
      // We want the hover state to the same as the default state on mobile
      // to make it play nice with touch events
      props.buttonHoverColor = 'cerulean';
    }

    return <Button {...props} />;
  },
});

module.exports = BlueButton;
