/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import Hammer from 'hammerjs';
import { throttle } from 'lodash';
import {
  clampZoom,
  SignatureRequestContextShape,
  zoomContext,
  ZoomContextShape,
} from 'signer-app/signature-request/context';
import styles from 'signer-app/signature-request/document.module.css';
import { useDragLayer, XYCoord } from 'react-dnd';
import invariant from 'invariant';

type Idle = { status: 'idle' };

type Blocked = { status: 'blocked' };

type Active = {
  readonly status: 'active';
  readonly startZoom: number;
  readonly zoomRefWidth: number;
  readonly zoomRefOrigin: XYCoord;
  readonly originalCenter: XYCoord;
  readonly scrollLeft: number;
  readonly scrollTop: number;

  // These get updated on every event
  scale: number;
  // At the end of the gesture after manipulating the DOM, usePinchAndZoom will
  // notify the rest of the editor that the zoom has changed exactly once.
  newZoom: number;
};

type HammerEvent = {
  center: XYCoord;
  scale: number;
  srcEvent: PointerEvent;
  pointerType: 'mouse' | 'touch';
  velocityX: number;
  velocityY: number;
};

/**
 * This is all contained in a class because it needs to operate outside React's
 * normal render cycle.
 *
 * pinch/zoom code can't be verified in a headless browser */
/** istanbul-ignore-next */
class HammerManager {
  readonly hammer: any;

  private state: Idle | Active | Blocked = { status: 'idle' };

  constructor(
    private scrollContainerRef: SignatureRequestContextShape['pageContainerRef'],
    private zoomRef: React.MutableRefObject<HTMLElement | null>,
    // zoomContext is public so that the hook can keep it updated when it
    // changes.
    public zoomContext: ZoomContextShape,
  ) {
    if (!this.scrollContainerRef.current) {
      throw new Error(
        'The scrollContianerRef needs to be ready before HammerJS is setup',
      );
    }

    // If the browser scrolls while panning around, it will cancel the gesture.
    document.body.classList.add(styles.blockScroll);

    this.hammer = new Hammer(this.scrollContainerRef.current, {
      // This can't use `pan-x pan-y`, because if Safari scrolls, it cancells
      // the pinch gesture.
      touchAction: 'none',
    });
    const hammer = this.hammer;
    hammer.get('pinch').set({ enable: true });
    hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });
    // Pan events have a scale of exactly 1, so they can be handled the same as a pinch
    // that doesn't change the scale.
    hammer.on('pinchstart panstart', this.start);
    hammer.on('pan pinchin pinchout', this.move);
    hammer.on('panend pinchend pinchcancel', this.end);

    // Only runs in Storybook
    // this.drawDebuggingMarkers();
  }

  destroy = () => {
    const hammer = this.hammer;
    hammer.on('pinchstart panstart', this.start);
    hammer.on('pan pinchin pinchout', this.move);
    hammer.on('panend pinchend pinchcancel', this.end);
    hammer.destroy();
    // clean up blockScroll style to enable scrolling
    // since pinch and zoom is not used for the mobile form view, we need to remove the
    // blockScroll class on unmounting this component to allow scrolling in the mobile form view
    document.body.classList.remove(styles.blockScroll);
  };

  setBlocked = (blocked: boolean) => {
    const stateSnapshot = this.state;
    if (
      blocked &&
      stateSnapshot.status === 'active' &&
      this.scrollContainerRef.current &&
      this.zoomRef.current
    ) {
      if (stateSnapshot.scale !== 1) {
        this.zoomRef.current.style.width = `${stateSnapshot.zoomRefWidth}px`;
      }

      this.scrollContainerRef.current.scrollLeft = stateSnapshot.scrollLeft;
      this.scrollContainerRef.current.scrollTop = stateSnapshot.scrollTop;
      this.state = { status: 'blocked' };
    }

    if (!blocked && stateSnapshot.status === 'blocked') {
      this.state = { status: 'idle' };
    }
  };

  // move needed to be throttled because handling the events too fast caused the
  // iPhone to flicker.
  move = throttle((event: HammerEvent) => {
    const stateSnapshot = this.state;

    if (
      stateSnapshot.status === 'active' &&
      this.scrollContainerRef.current &&
      this.zoomRef.current
    ) {
      stateSnapshot.scale = Math.floor(event.scale * 1000) / 1000;
      if (event.srcEvent.shiftKey) {
        stateSnapshot.scale = 1.5;
      }

      // Update newZoom and scale, keeping it clamped within the min/max zoom
      stateSnapshot.newZoom = stateSnapshot.startZoom * stateSnapshot.scale;
      const clamped = clampZoom(stateSnapshot.newZoom);
      if (clamped !== stateSnapshot.newZoom) {
        stateSnapshot.newZoom = clamped;
        stateSnapshot.scale = clamped / stateSnapshot.startZoom;
      }

      const result = calculateNewScrollPosition({
        containerPosition: stateSnapshot.zoomRefOrigin,
        finalTouch: event.center,
        initialTouch: stateSnapshot.originalCenter,
        initialScroll: {
          x: stateSnapshot.scrollLeft,
          y: stateSnapshot.scrollTop,
        },
        zoomFactor: stateSnapshot.scale,
      });

      const newWidth = stateSnapshot.zoomRefWidth * stateSnapshot.scale;
      this.zoomRef.current.style.width = `${newWidth}px`;

      this.scrollContainerRef.current.scrollLeft = result.scrollLeft;
      this.scrollContainerRef.current.scrollTop = result.scrollTop;
    }
  }, 10);

  start = (event: HammerEvent) => {
    const stateSnapshot = this.state;

    if (
      stateSnapshot.status === 'idle' &&
      event.pointerType === 'touch' &&
      this.scrollContainerRef.current &&
      this.zoomRef.current
    ) {
      const scrollLeft = this.scrollContainerRef.current.scrollLeft;
      const scrollTop = this.scrollContainerRef.current.scrollTop;
      const bbox = this.zoomRef.current.getBoundingClientRect();

      this.state = {
        status: 'active',
        startZoom: this.zoomContext.zoom,
        scrollLeft,
        scrollTop,
        originalCenter: event.center,
        zoomRefWidth: bbox.width,
        zoomRefOrigin: {
          x: bbox.x,
          y: bbox.y,
        },
        scale: event.scale,
        newZoom: this.zoomContext.zoom * event.scale,
      };
    }
  };

  handleGlide = (e: HammerEvent) => {
    invariant(
      this.scrollContainerRef.current != null,
      'scrollContainerRef.current is null',
    );
    const { scrollLeft, scrollTop } = this.scrollContainerRef.current;

    let velocityX = e.velocityX;
    let velocityY = e.velocityY;
    const deceleration = 0.95;

    let glideX = 0;
    let glideY = 0;

    let deltaStart = Date.now();
    const i = setInterval(() => {
      // Cancel if the user starts a new gesture
      if (this.state.status !== 'idle') {
        clearInterval(i);
        return;
      }

      if (this.scrollContainerRef.current) {
        // In order to get the velocity to work, we need to calculate the
        // distance at each step instead of computing the current position from
        // the start and time.
        const deltaTime = Date.now() - deltaStart;
        deltaStart = Date.now();

        const x = deltaTime * velocityX;
        const y = deltaTime * velocityY;

        if (Math.abs(x) > 1 && Math.abs(y) > 1) {
          glideX += x;
          glideY += y;

          this.scrollContainerRef.current.scrollLeft = scrollLeft - glideX;
          this.scrollContainerRef.current.scrollTop = scrollTop - glideY;

          // Apply deceleration
          velocityX *= deceleration;
          velocityY *= deceleration;
        } else {
          clearInterval(i);
        }
      }
    }, 10);
  };

  end = (e: HammerEvent) => {
    const stateSnapshot = this.state;
    if (stateSnapshot.status === 'active') {
      this.state = { status: 'idle' };
      this.zoomContext.setZoom(stateSnapshot.newZoom, null);
    }
    this.handleGlide(e);
  };
}

/**
 * NOTE: This updates the DOM directly
 *
 * Normally in React, events should change data, then React will re-render.  If
 * I try to change the zoom continuously while pinching/zooming on an iPhone,
 * it doesn't perform well enough.  This hook uses HammerJS to detect
 * pinch/zoom and updates the DOM directly. At ther end of the gesture, the
 * zoom will be updated once, causing React to re-render.
 *
 * Additional notes on usePinchAndZoom are available at:
 * https://www.dropbox.com/scl/fi/uql8hh15mn3ni0guostk2/DEV-18729-Zoom.paper?dl=0&rlkey=onarp0ieapxtbpq1ratdkfrzo
 */
export function usePinchAndZoom(
  // scrollContainerRef is the div that can be scrolled with scrollTop/scrolLeft
  scrollContainerRef: SignatureRequestContextShape['pageContainerRef'],
  // zoomRef is the div that is made larger or smaller to scale pages
  zoomRef: React.MutableRefObject<HTMLElement | null>,
  // isOverlay is a boolean to know if it's rendering from overlay
  isOverlay: boolean,
) {
  const managerRef = React.useRef<HammerManager>();

  const currentZoomContext = React.useContext(zoomContext);
  const isDragging = useDragLayer((monitor) => monitor.isDragging());
  if (managerRef.current) {
    managerRef.current.zoomContext = currentZoomContext;
    managerRef.current.setBlocked(isDragging);
  }

  React.useEffect(() => {
    // Only create a manager once
    // If we create a manager when it's overlay it breaks
    // When rendering the overlay
    if (!managerRef.current && !isOverlay) {
      managerRef.current = new HammerManager(
        scrollContainerRef,
        zoomRef,
        currentZoomContext,
      );
    }
  }, [isOverlay, currentZoomContext, scrollContainerRef, zoomRef]);

  React.useEffect(() => {
    const manager = managerRef.current;

    return () => {
      if (manager) {
        manager.destroy();
      }
    };
  }, []);
}

type ZoomScrollCalculationParams = {
  initialTouch: XYCoord;
  finalTouch: XYCoord;
  initialScroll: XYCoord;
  containerPosition: XYCoord;
  zoomFactor: number;
};

/* pinch/zoom code can't be verified in a headless browser */
/** istanbul-ignore-next */
function calculateNewScrollPosition({
  initialTouch,
  finalTouch,
  initialScroll,
  containerPosition,
  zoomFactor,
}: ZoomScrollCalculationParams): { scrollLeft: number; scrollTop: number } {
  // Convert screen coordinates to container-relative coordinates
  const initialRelativeToContainer = {
    x: initialTouch.x - containerPosition.x,
    y: initialTouch.y - containerPosition.y,
  };

  // Apply zoom factor to the initial touch point relative to the container
  const scaledPosition = {
    x: initialRelativeToContainer.x * zoomFactor,
    y: initialRelativeToContainer.y * zoomFactor,
  };

  // Calculate the delta of the scaling
  const delta = {
    x: scaledPosition.x - initialRelativeToContainer.x,
    y: scaledPosition.y - initialRelativeToContainer.y,
  };

  // Adjust the scroll position for the scaling delta
  const newScroll = {
    scrollLeft: initialScroll.x + delta.x,
    scrollTop: initialScroll.y + delta.y,
  };

  // Adjust for the movement of the finger
  newScroll.scrollTop -= finalTouch.y - initialTouch.y;
  newScroll.scrollLeft -= finalTouch.x - initialTouch.x;

  // Ensure scrollTop is not negative
  newScroll.scrollTop = Math.max(0, newScroll.scrollTop);
  newScroll.scrollLeft = Math.max(0, newScroll.scrollLeft);

  return newScroll;
}
