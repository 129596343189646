/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import ReactDOM from 'react-dom';
import BaseObject from 'common/object/base';
import { InitializeMessage, INITIALIZE } from 'common/messages';
import { NotifierCollection, TypeNotifier } from 'common/notifiers';

/**
 * BaseApplication is the main entry point into the application - it initializes everything from the root component, routing,
 * messaging systems, and so forth.
 */

function BaseApplication(properties = {}) {
  BaseObject.call(this, {
    // collection of registered notifiers throughout the application
    notifierCollection: NotifierCollection.create(),

    // properties defined on the application instance once created
    ...properties,
  });

  this.notifierCollection.push(
    TypeNotifier.create(INITIALIZE, this._renderRootComponent.bind(this)),
  );
}

BaseObject.extend(BaseApplication, {
  /**
   */

  dispose() {
    this._unmount();
  },

  /**
   */

  _unmount() {
    if (!this._mounted) return;
    this._mounted = false;
    ReactDOM.unmountComponentAtNode(this.element);
  },

  /**
   * Dispatches an initialization message to the rest of the application
   */

  initialize() {
    this.notifierCollection.notify(InitializeMessage.create());
    return this;
  },

  /**
   * called immediately after initialization. Renders the *root* component to the element property
   * defined in this application instance.
   */

  _renderRootComponent() {
    if (!this.element) return;

    this._unmount();
    this._mounted = true;
    ReactDOM.render(
      this._createRootComponent(this._createRootComponentProps()),
      this.element,
    );
  },

  /**
   */

  _createRootComponent(props) {
    const RootcomponentClass = this.rootComponentClass;
    return <RootcomponentClass {...props} />;
  },

  /**
   */

  _createRootComponentProps() {
    return {
      // might be define in constructor -- typically by test cases
      ...(this.rootComponentProps || {}),
      app: this,
    };
  },
});

module.exports = BaseApplication;
