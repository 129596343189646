/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  prefer-spread
*/
import BaseCollection from 'common/models/base/collection';
import extend from 'lodash/extend';
import Signature from './signature';

const Signatures = BaseCollection.extend({
  apiEndpointAlias: 'signatures',

  mixins: [require('common/models/mixins/collection')],

  createModel(properties) {
    return Signature.create(
      extend(
        {
          notifier: this.notifier,
        },
        properties,
      ),
    );
  },

  add(properties) {
    const signature = this.createModel({
      data: properties,
    });

    return signature.insert().then((errOrResponse) => {
      // The response here may actually be an error because all API calls
      // to the /upload endpoint are always resolved.  We do this so we
      // can prevent the signer API service from sending a global error
      // message and re-rendering
      if (errOrResponse.error || errOrResponse instanceof Error) {
        return Promise.reject(errOrResponse);
      }
      this.unshift(signature);
      return Promise.resolve(signature);
    });
  },

  addFromEdit(signature) {
    return signature.edit().then(() => {
      this.unshift(signature);
      return Promise.resolve(signature);
    });
  },

  find(guid) {
    for (let i = 0; i < this.length; i++) {
      const sig = this[i];
      if (sig && sig.guid === guid) {
        return sig;
      }
    }
  },

  //  ----  DATA  ---------------------------------------

  onDataChange(data) {
    const signatures = [];
    if (data && data.count) {
      for (let i = 0; i < data.count; i++) {
        signatures.push(this.createModel({ data: data.list[i] }));
      }
    }

    // this.mergeSourceData(signatures); // TODO - more optimial
    this.splice.apply(this, [0, this.length].concat(signatures));
  },
});

module.exports = Signatures;
