import React from 'react';
import ReactModal from 'react-modal';
import { defineMessages, useIntl } from 'react-intl';
import classnames from 'classnames';
import DeprecatedButton from 'common/components/button';
import { MODAL_BASE_INDEX, LayerContext } from '@dropbox/dig-components/layer';
import Button, { ButtonType } from 'hellospa/foundation/control/button';
import 'hellospa/components/deep-integration/modal/index.scss';
import './hello-modal.scss';
import HSLogo from './HelloSignLogo.svg';

const messages = defineMessages({
  close: {
    id: '',
    description: 'title text of closing button of modal',
    defaultMessage: 'Close',
  },
});

export function isHidden(domNode) {
  // When the modal is open it marks the site wrapper as hidden.
  let n = domNode;
  while (n) {
    if (
      n.attributes &&
      n.attributes['aria-hidden'] &&
      n.attributes['aria-hidden'].value === 'true'
    ) {
      return true;
    }
    n = n.parentNode;
  }

  return false;
}

let initialized = false;
export function initAppElement() {
  if (!initialized) {
    // React based pages have a root element,
    // PHP based pages use a site-wrapper,
    // Signer app uses signer-mobile-application
    const wrapper =
      document.getElementById('root') ||
      document.getElementById('site-wrapper') ||
      document.getElementById('signer-mobile-application');
    if (wrapper != null) {
      initialized = true;
      ReactModal.setAppElement(wrapper);
    }
  }
}

export { ReactModal };

const className = {
  base: 'hello-modal__content',
  afterOpen: 'hello-modal__content--afterOpen',
  beforeClose: 'hello-modal__content--beforeClose',
};
const overlayClassName = {
  base: 'hello-modal__overlay',
  afterOpen: 'hello-modal__overlay--afterOpen',
  beforeClose: 'hello-modal__overlay--beforeClose',
};

function ModalButton(incomingProps) {
  const {
    type,
    text,
    onClick,
    buttonLink,
    extraClasses,
    disabled,
    openInNewWindow,
    onRequestClose,
  } = incomingProps;
  let props = {};

  switch (type) {
    case 'cancel':
      props = {
        buttonClass: 'hello-modal__cancel-button',
        buttonBorderColor: 'cinder-block',
        buttonColor: 'cinder-block',
        buttonTextColor: 'white',
        buttonHoverBorderColor: 'black',
        onClick: onRequestClose,
      };
      break;
    case 'primary':
      props = {
        buttonClass: 'hello-modal__primary-button',
        buttonBorderColor: 'color-primary-button',
        buttonColor: 'color-primary-button',
        buttonTextColor: 'white',
        buttonHoverBorderColor: 'black',
      };
      break;
    case 'secondary':
      props = {
        buttonClass: 'hello-modal__secondary-button',
        buttonBorderColor: 'plume',
        buttonColor: 'plume',
        buttonTextColor: 'black',
      };
      break;
    case 'delete':
      props = {
        buttonClass: 'hello-modal__delete-button',
        buttonBorderColor: 'red',
        buttonColor: 'red',
        buttonTextColor: 'white',
      };
      break;
    // This is specific button settings for Dropbox where we had to follow their colors.
    // For all HS buttons use one of the provided types from above
    case 'dropbox':
      props = {
        buttonBorderColor: 'dropbox-blue',
        buttonColor: 'dropbox-blue',
        buttonTextColor: 'white',
        iconUri: 'v2/modules/home/integrations/dropbox_icon_white.svg',
        buttonId: 'view_on_dropbox_link',
      };
      break;
    default:
      throw new Error(`Invalid button type ${type}`);
  }

  if (disabled) {
    props = {
      buttonClass: 'hello-modal__disabled-button',
      buttonBorderColor: 'cinder-block',
      buttonColor: 'cinder-block',
      buttonTextColor: 'white',
      buttonHoverBorderColor: 'cinder-block',
      disabled: true,
    };
  }

  if (incomingProps['data-qa-ref']) {
    props['data-qa-ref'] = incomingProps['data-qa-ref'];
  }
  if (incomingProps['data-testid']) {
    props['data-testid'] = incomingProps['data-testid'];
  }
  return (
    <DeprecatedButton
      className={extraClasses || ''}
      key={text}
      buttonTextUppercase={false}
      onClick={onClick}
      buttonText={text}
      buttonLink={buttonLink}
      openInNewWindow={openInNewWindow}
      {...props}
    />
  );
}

// This wrapper exists so that we can apply our application-standard styling and
// behaviors in one location.
export default function HelloModal(allModalProps) {
  initAppElement();
  const {
    isOpen,
    isCompact,
    isFullScreen,
    buttons,
    scrollable = true,
    contentLabel,
    onRequestClose,
    children,
    extraClasses,
    extraBodyClasses,
    footerDisclosure,
    showCloseIcon = true,
    shouldCloseOnEsc = true,
    withFlag = false,
    withFlagSide = false,
    sideFlagContent = null,
    footerOverride,
    modalBaseIndex = MODAL_BASE_INDEX,
    ...props
  } = allModalProps;

  const intl = useIntl();

  let footer = buttons ? (
    <div className="hello-modal__footer">
      {footerDisclosure && (
        <div className="hello-modal__footer-disclosure">
          <p>{footerDisclosure}</p>
        </div>
      )}
      <div className="hello-modal__footer-buttons">
        {buttons.map((button) =>
          React.isValidElement(button) ? (
            // This allows for using hellospa/foundation/control/button. Please
            // don't use random other components here.
            button
          ) : (
            <ModalButton
              key={button.text}
              {...button}
              onRequestClose={allModalProps.onRequestClose}
            />
          ),
        )}
      </div>
    </div>
  ) : null;
  if (footerOverride) {
    footer = footerOverride;
  }

  const style = {
    overlay: {
      zIndex: modalBaseIndex,
    },
    content: {},
  };

  return (
    <ReactModal
      style={style}
      {
        ...props /* props should be able to override style, but you probably just want width */
      }
      className={{
        ...className,
        base: classnames(className.base, props.contentClasses, {
          'hello-modal__content--with-flag': withFlag || withFlagSide,
          'hello-modal__content--fullscreen': isFullScreen,
        }),
      }}
      ariaHideApp={
        // This doesn't work in an enzyme test because document.getElementById()
        // doesn't look at enzyme's DOM. Even if you add an appropriate div to
        // the test, it can't be found.
        NODE_ENV !== 'test'
      }
      overlayClassName={overlayClassName}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <LayerContext.Provider value={{ zIndex: modalBaseIndex }}>
        {withFlag && (
          <div className={classnames('hello-modal__flag')}>
            <img
              aria-hidden
              className={classnames('hello-modal__flag-hs-logo')}
              src={HSLogo}
            />
            {props.flag.title && (
              <h2 className={classnames('hello-modal__flag-header')}>
                {props.flag.title}
              </h2>
            )}
            {props.flag.image && (
              <img className="hello-modal__flag-image" src={props.flag.image} />
            )}
          </div>
        )}
        {withFlagSide && (
          <div className="hello-modal__flag-content">{sideFlagContent}</div>
        )}
        <div
          data-qa-ref="hello-modal"
          className={classnames(
            'hello-modal',
            {
              'hello-modal--compact': isCompact,
            },
            extraClasses,
          )}
        >
          {(contentLabel || showCloseIcon) && (
            <div className="hello-modal__title" data-qa-ref="hello-modal-title">
              <span className="hello-modal__contentLabel">{contentLabel}</span>
              {showCloseIcon ? (
                <Button
                  title={intl.formatMessage(messages.close)}
                  kind={ButtonType.Subtle}
                  className="hello-modal__close"
                  onClick={onRequestClose}
                  data-qa-ref="hello-modal--close-button"
                />
              ) : null}
            </div>
          )}
          <div
            className={classnames(
              'hello-modal__body',
              {
                'hello-modal__body--scrollable': scrollable,
              },
              extraBodyClasses,
            )}
          >
            {children}
          </div>
          {footer}
        </div>
      </LayerContext.Provider>
    </ReactModal>
  );
}
