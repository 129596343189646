import hsFetch from 'js/sign-components/common/hs-fetch';
import type { VerifyData } from 'hellospa/components/help/snap-engage/snap-engage-iframe';

export async function fetchVerifiedSnapEngageData(scaled: boolean) {
  let fetchUrl = '/home/help/snap-engage-hash';

  if (scaled) {
    fetchUrl += `?scaled=${scaled}`;
  }

  const res = await hsFetch(fetchUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  const resp: VerifyData = await res.json();

  return resp;
}
