import { Rule } from 'signer-app/conditional-logic/types';
import {
  FieldGroups,
  getFieldIdsFromRules,
} from 'signer-app/conditional-logic/utils';

// Tests whether two arrays have any items in common
// intersection(['a','b'], ['b','c']) => true
// intersection(['a','b'], ['c', 'd']) => false
const intersection = <T>(a: T[], b: T[]): boolean =>
  a.some((item) => b.indexOf(item) >= 0);

function findTargetRules(
  fieldGroups: FieldGroups,
  rule: Rule,
  rules: Rule[],
): Rule[] {
  const fieldIds = getFieldIdsFromRules(fieldGroups, [rule], 'actions');

  return rules.filter((r) =>
    intersection(fieldIds, getFieldIdsFromRules(fieldGroups, [r], 'triggers')),
  );
}

function findNestedDepth(fieldGroups: FieldGroups, rule: Rule, rules: Rule[]) {
  let a = [rule];
  let depth = 0;

  while (a.length > 0) {
    depth++;
    a = a.reduce((tmp, r) => {
      return tmp.concat(findTargetRules(fieldGroups, r, rules));
    }, [] as Rule[]);

    // If this branch's depth is longer than the number of rules, we must have
    // found a loop.
    if (depth > rules.length) {
      throw new Error('loop');
    }
  }
  return depth;
}

const sortedMap = new WeakMap<Rule[], boolean>();

export const isSorted = (rules: Rule[]) =>
  rules.length === 0 || sortedMap.get(rules) === true;

export function buildDepthMap(
  fieldGroups: FieldGroups,
  rules: Rule[],
): Map<Rule, number> {
  return rules.reduce((map, r: Rule) => {
    map.set(r, findNestedDepth(fieldGroups, r, rules));
    return map;
  }, new Map<Rule, number>());
}

export default function sortRules(
  fieldGroups: FieldGroups,
  rules: Rule[],
): Rule[] {
  if (!sortedMap.has(rules)) {
    const depthMap = buildDepthMap(fieldGroups, rules);

    const sortedRules = [...rules].sort((a, b) => {
      const depthA = depthMap.get(a);
      const depthB = depthMap.get(b);
      // TypeScript knows that .get() might not return anything, but the code
      // above sets a value for every entry.
      if (!depthA || !depthB) {
        throw new Error("This shouldn't be possible");
      }

      // If they're the same depth, sort by ID to get a deterministic sort
      if (depthA === depthB) {
        return a.id < b.id ? -1 : 1;
      }

      return depthB - depthA;
    });

    sortedMap.set(sortedRules, true);
    return sortedRules;
  }
  return rules;
}
