/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-param-reassign,
  no-restricted-syntax,
  no-throw-literal
*/
import HelloSign from 'common/utils/hellosign';
import extend from 'lodash/extend';
import { postMessage, messages } from 'js/sign-components/common/messages';

// FIXME - no globals here! Move HelloSign into common directory
module.exports = function (message) {
  if (typeof HelloSign === 'undefined') {
    return;
  }

  let eventData;
  const data = message.signatureRequest.embeddedData;
  let eventName;

  if (data.typeCode === 'S') {
    eventName = HelloSign.EVENT_SIGNED;
    eventData = {
      signature_id: data.signatureId,
    };
  } else if (data.typeCode === 'R' || data.typeCode === 'X') {
    eventName = HelloSign.EVENT_SENT;
    eventData = {
      signature_request_id: message.signatureRequest.guid,
      signature_request_info: {
        // FIXME: not implemented for now since we don't need it
      },
    };
  } else if (data.typeCode === 'T') {
    eventName = HelloSign.EVENT_TEMPLATE_CREATED;
    eventData = {
      template_info: {
        // TODO: Not impemented for now since we don't need it
      },
    };
  } else {
    throw `Invalid type of embedded flow: "${data.typeCode}"`;
  }

  const evt = extend(eventData, { event: eventName });

  // Message parent window
  HelloSign.XWM.send(evt, data.parentUrl);

  // Embedded v2
  postMessage(
    {
      type: messages.USER_SIGN_REQUEST,
      payload: {
        signatureId: data.signatureId,
      },
    },
    data.parentUrl,
  );

  // Redirect or close
  if (data.redirectUrl) {
    // Redirect + add event data to url parameters
    let url = data.redirectUrl;
    const parts = url.split('://');
    if (parts.length > 1) {
      // Remove the protocol and force it to be the same as the iframe to prevent the redirection
      // from being blocked by protocol mismatch
      url = `//${parts.slice(1).join('://')}`;
    }
    for (const k in evt) {
      url += url.indexOf('?') > 0 ? '&' : '?';
      url += `${k}=${(function serializeMessageValue(value) {
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        return encodeURIComponent(value);
      })(evt[k])}`;
    }
    window.location = url;
  }
};
