/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  max-len,
  no-void
*/
import './index.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import FullPageLoader from 'signer-app/parts/full-page-loader';
import errorCodes from 'signer/api/errors';
import setTimeout from 'common/utils/set-timeout';
import logger from 'common/logger';
import createReactClass from 'create-react-class';
import Logo from 'signer-app/parts/logo-v2';

import { ErrorMessage } from 'common/messages';

const SignatureRequestStatus = createReactClass({
  //  ----  INITIALIZATION  -----------------------------

  getDefaultProps() {
    return {
      message: 'Loading...',
    };
  },

  getInitialState() {
    return {
      progress: 0,
      fauxProgress: 0,
      tweenedProgress: 0,
      checkProgressCount: 0,
      signerReadyBackoffTries: 0,
    };
  },

  //  ----  LIFECYCLE  ----------------------------------

  componentDidMount() {
    logger.track('SignatureRequestStatus.componentDidMount');
    this.checkProgress();
    this.isMounted = true;
  },

  componentWillUnmount() {
    this.isMounted = false;
  },

  //  ----  BEHAVIOR  -----------------------------------

  checkProgress() {
    const signatureRequest = this.props.app.signatureRequest;

    signatureRequest.getProgress().then((result) => {
      if (!this.isMounted) {
        return;
      }

      if (result.isInFlightEditing) {
        window.location = '/info/externalCantSignNow';
        return;
      }

      // Increment false progress by 10% only up to 50%, and
      // and add in half of the real progress so that the total
      // equals 100%.
      this.setState(({ fauxProgress }) => {
        const newFauxProgress =
          fauxProgress >= 0.5 ? fauxProgress : fauxProgress + 0.1;

        return {
          fauxProgress: newFauxProgress,
          progress: newFauxProgress + result.progress / 2,
        };
      });

      const readyBackoff = 5000 * (this.state.signerReadyBackoffTries + 1);

      if (this.isMounted && this.state.checkProgressCount < 10) {
        if (result.progress === 1 && result.ready === true) {
          const onSignatureRequestLoad = () => {
            if (!signatureRequest.isReady()) {
              this.setState({
                checkProgressCount: this.state.checkProgressCount + 1,
              });
              this.setState({
                signerReadyBackoffTries: 1 + this.state.signerReadyBackoffTries,
              });
              // Add slight timeout for tests - this prevents the callstack from being locked up (CC)
              setTimeout(
                this.checkProgress,
                NODE_ENV === 'test' ? 1 : readyBackoff,
              );
            } else if (
              this.props.app.isMobile &&
              signatureRequest.hasMobileSignatureTypes() === false
            ) {
              // TODO: this isn't the correct place to dispatch an error message. This needs to come from the source,
              // likely from the SignatureRequest model. (CC)
              this.props.app.notifier.notify(
                ErrorMessage.create({
                  code: errorCodes.NO_VALID_SIG_TYPES,
                }),
              );
            }
          };

          // Load the sig request, then check if it's *really* ready. There's a case where the load()
          // request returns ready=false data - this is a race condition. If this is the case, continue checking
          // progress until isReady() is true. (CC)
          signatureRequest
            .load()
            .then(onSignatureRequestLoad, onSignatureRequestLoad);
        } else {
          this.setState({
            signerReadyBackoffTries: 1 + this.state.signerReadyBackoffTries,
          });
          // Poll some more if it's still not ready
          setTimeout(
            this.checkProgress,
            NODE_ENV === 'test' ? 1 : readyBackoff,
          );
        }
      }

      if (this.state.checkProgressCount >= 10) {
        this.props.app.notifier.notify(
          ErrorMessage.create({
            code: null,
            message: 'No valid /signer/load response',
          }),
        );
      }
    });
  },

  onTweenedProgress(progress) {
    this.setState({
      tweenedProgress: progress,
    });
  },

  //  ----  RENDERING  ----------------------------------

  render() {
    // just an aesthetic thing - want to show the loading happen before a response from the server.
    // var progress = 0.05 + Math.max(0, this. - 0.05);
    const progress = this.state.progress;
    const showDotsLoader = this.props.intl.locale !== 'en-US';
    return (
      <div className="m-signature-request-status whitelabel-page">
        <FullPageLoader
          progress={progress}
          onTweenedProgress={this.onTweenedProgress}
          showDotsLoader={showDotsLoader}
          loop={false}
        >
          <span className="m-signature-request-status--large-message">
            <FormattedMessage
              id="signer.document.loader.text"
              description="text in signer flow informing singer that document is being prepared for signing"
              defaultMessage="Preparing document"
              values={{
                progress: Math.round(this.state.tweenedProgress * 100),
              }}
            />
          </span>
        </FullPageLoader>
        {!this.props.app.customLogoUri && !this.props.app.hasCustomStyles ? (
          <Logo
            className="m-signature-request-status--logo"
            siteCode="S"
            variant="wordmark"
            size={40}
          />
        ) : null}
      </div>
    );
  },
});

module.exports = SignatureRequestStatus;
