import * as yup from 'yup';
import { Actions } from './index';

const externalFileContentsSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
});

export type ExternalFileContents = yup.InferType<
  typeof externalFileContentsSchema
>;

export const externalFolderDataResponseSchema = yup.object().shape({
  files: yup.array().of(externalFileContentsSchema),
  folders: yup.array().of(externalFileContentsSchema),
  pageSize: yup.number(),
});

export type ExternalFolderDataResponse = yup.InferType<
  typeof externalFolderDataResponseSchema
>;

export const externalFolderAuthResponseSchema = yup.object().shape({
  boxReAuth: yup.boolean().default(false),
  googleReAuth: yup.boolean().default(false),
  authUrl: yup.string(),
});

export type ExternalFolderAuthResponse = yup.InferType<
  typeof externalFolderAuthResponseSchema
>;

export type ExternalFolderResponse =
  | ExternalFolderDataResponse
  | ExternalFolderAuthResponse;

export const externalServiceAuthStatusSchema = yup.object().shape({
  authorized: yup.boolean().default(false).required(),
  failed: yup.boolean().default(false).nullable(),
  errorMessage: yup.string().nullable(),
});

export type ExternalServiceAuthStatusResponse = yup.InferType<
  typeof externalServiceAuthStatusSchema
>;

export enum UploadIntegrationType {
  Box = 'B',
  Dropbox = 'D',
  Evernote = 'E',
  GoogleDrive = 'G',
  OneDrive = 'T',
}

export type DropboxIntegration = {
  id: UploadIntegrationType.Dropbox;
  key: string;
  nonce: string;
  enabled: boolean;
};

export type OneDriveIntegration = {
  id: UploadIntegrationType.OneDrive;
  token?: string;
  clientId: string;
  nonce: string;
  enabled: boolean;
};

export type GoogleDriveIntegration = {
  id: UploadIntegrationType.GoogleDrive;
  key: string;
  token?: string;
  loginHint?: string;
  abbrKey: string;
  enabled: boolean;
};

export type BoxIntegration = {
  id: UploadIntegrationType.Box;
  enabled: boolean;
};

export type EvernoteIntegration = {
  id: UploadIntegrationType.Evernote;
  enabled: boolean;
};

// setting the keys as single characters so we can use it with the backend
export const uploadIntegrationSchema = yup.object().shape({
  [UploadIntegrationType.Dropbox]: yup
    .object<DropboxIntegration>()
    .shape({
      id: yup.mixed().oneOf([UploadIntegrationType.Dropbox]),
      key: yup.string().required(),
      nonce: yup.string().required(),
      enabled: yup.boolean().required().default(false),
    })
    .notRequired()
    .default(undefined),
  [UploadIntegrationType.OneDrive]: yup
    .object<OneDriveIntegration>()
    .shape({
      id: yup.mixed().oneOf([UploadIntegrationType.OneDrive]),
      token: yup.string().notRequired(),
      clientId: yup.string().required(),
      nonce: yup.string().required(),
      enabled: yup.boolean().required().default(false),
    })
    .notRequired()
    .default(undefined),
  [UploadIntegrationType.GoogleDrive]: yup
    .object<GoogleDriveIntegration>()
    .shape({
      id: yup.mixed().oneOf([UploadIntegrationType.GoogleDrive]),
      key: yup.string().required(),
      token: yup.string().notRequired().default(undefined),
      loginHint: yup.string().notRequired().default(''),
      abbrKey: yup.string().required(),
      enabled: yup.boolean().required().default(false),
    })
    .notRequired()
    .default(undefined),
  [UploadIntegrationType.Box]: yup
    .object<BoxIntegration>()
    .shape({
      id: yup.mixed().oneOf([UploadIntegrationType.Box]),
      enabled: yup.boolean().required().default(false),
    })
    .notRequired()
    .default(undefined),
  [UploadIntegrationType.Evernote]: yup
    .object<EvernoteIntegration>()
    .shape({
      id: yup.mixed().oneOf([UploadIntegrationType.Evernote]),
      enabled: yup.boolean().required().default(false),
    })
    .notRequired()
    .default(undefined),
});

export type UploadIntegrations = yup.InferType<typeof uploadIntegrationSchema>;

export interface UpdateIntegrationStatusAction {
  type: Actions.UpdateIntegrationStatus;
  payload: {
    integration: UploadIntegrationType;
    status: boolean;
  };
}

export type IntegrationActions = UpdateIntegrationStatusAction;

export type UploadIntegrationsResponse = {
  uploadIntegrations: UploadIntegrations;
  allowIntegrationsUpload: boolean;
};
