import { createSimpleDataContext } from 'signer-app/utils/create-simple-data-context';

import { ButtonProps } from '@dropbox/dig-components/buttons';
import { BannerProps } from '@dropbox/dig-components/banner';
import { defineMessages } from 'react-intl';

export const notificationMessages = defineMessages({
  freeTrialExpiring: {
    id: '1424528b6dfa697a77ecf196e2f7887f341f2f0c325c6856836056b93c18929a',
    description: 'notification, free trial expiring',
    defaultMessage:
      'Your free trial with Sign is going to expire in {days} day(s)!',
  },
  freeSubExpiring: {
    id: '5991f855881a7a79d86489727ac80effb390e91d48362b82acaecfabc8c9d4b8',
    description: 'notification, free sub expiring',
    defaultMessage: 'Your free trial subscription is going to expire soon.',
  },
  alreadyLoggedIn: {
    id: '9d09f05dfcdc9e95a75340dffc6b68de4ba5f2ced497d03dc7b19f4c3053b85b',
    description: 'text informing user that user is already logged in',
    defaultMessage: 'Looks like you’re already logged in with {account_email}.',
  },
  finishAcceptingInvitation: {
    id: '1f7286c7e0cacbbb66b9a30d8185488571f1d48e6df4bcf17d16de2d0d4449ee',
    description:
      'text in popup, asking user to login to respond the team invitation',
    defaultMessage: 'Please log in to finish accepting this team invitation.',
  },
  confirmAccountBeforeActivatingIntegrations: {
    id: 'e4afeb740d95591dac254b939169c19d1fb622b4d8565cbdee2e86ea2c6d8fd4',
    description:
      'error message in popup, asking user to confirm account before activating integrations with third party applications',
    defaultMessage:
      'You must confirm that you own {account_email} before you activate integrations.',
  },
  confirmAccountBeforeTakingAction: {
    id: '912590e7f52bdd1f8cc15f56ef6739976c5027d0816a6b508a71ba3359f3ab85',
    description:
      'error message in popup asking user to confirm account before taking action',
    defaultMessage:
      'You must confirm that you own {account_email} before you can perform this action.',
  },
  confirmAccountBeforeSigning: {
    id: '72f5ed5f75e33e8af29754b328618c00ecf632d872132733ad5a62cfa9aa1b11',
    description: 'notification header, asking user to confirm email address',
    defaultMessage:
      'You must confirm that you own {account_email} before signing a document.',
  },
  clickTheActivateLink: {
    id: 'b619e95b735328aacfc8edcb3aea93e08fb31d4319dd4d1736454b0fc8bebd0c',
    description:
      'text in popup message asking user to click the activate link that was sent in email',
    defaultMessage:
      'Click the Activate link in the confirmation sent to your new email address',
  },
  contactSupportChangingEmailAddressFailed: {
    id: '75394e7479ab82c0acb577abc33f9036d52359ba77b19e76cad6f202a33fe192',
    description:
      'error message in popup, asking user to contact support as changing email address failed',
    defaultMessage:
      "Can't change email address. Please try again or {openLink}contact us{closeLink}",
  },
  setUpGoogleAppsIntegration: {
    id: 'e9dbe5278af941e93fbd022d6339dde608bd10068e0166eef065fc16d5d83a8e',
    description:
      'pop-up, header, error, integrations, Google Apps integration, informs user that before using this integration user needs to set it up',
    defaultMessage: 'Your team needs to be configured.',
  },
  unableToPurchasePlan: {
    id: 'c9f917ff2a8d11b4d87d6a076795a393826d3b282e620bf3d27d684223df550c',
    description:
      'notification header, informing that users are unable to purchase a plan that is on a different billing scheme',
    defaultMessage:
      "To add a plan, you'll need to adjust your current plan. Please {openATag}contact us{closeATag}.",
  },
  accountDeleted: {
    id: '09bc7cf1ff8369770dc5a3a897e1b5f08f77b5fd5a171a447960f3967e957513',
    description:
      'notification header, informing the user that the account has been deleted',
    defaultMessage:
      'You deleted your Sign account. If this was a mistake, you can {openLink}sign up again{closeLink}.',
  },
  sendingLimitReached: {
    id: 'b39edaf78546cd179c3830d53fdf473e4063eb2fbe82d68abdf750391564678f',
    description:
      'error, popup, title, informs user that sending limit has been reached and redirects with link',
    defaultMessage:
      "Due to account security, you can't send any more signature requests until tomorrow. For assistance today, please {openLink}contact us{closeLink}",
  },
});

export type NotificationID = string;
export type NotificationBannerDataShape = {
  type?: BannerProps['type']; // default "attention"
  animate?: boolean; // default true
  withCloseButton?: boolean; // default true
  onRequestClose?: () => void;
  heapId?: string; // if defined, displayed event sent to heap
  autoClose?: boolean; // default true
  delay?: number; // default 7500
  actions?: ButtonProps[];
  withLeftIcon?: BannerProps['withLeftIcon'];

  message?: React.ReactNode | string;
  html?: string;
  msg?: {
    messageId: keyof typeof notificationMessages;
    values?: Record<string, string | number>;
  };
};

export type NotificationsShape = [
  NotificationID,
  NotificationBannerDataShape,
][];
export type NotificationBannerContextShape = {
  notifications: NotificationsShape;
  sendNotification: (data: NotificationBannerDataShape) => NotificationID;
  clearNotification: (id: NotificationID) => void;
  clearNotifications: () => void;
};

export const NotificationBannerContext =
  createSimpleDataContext<NotificationBannerContextShape>(
    'notificationBanners',
  );

export const useNotificationBanners = NotificationBannerContext.useContext;
