import React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router';
import ErrorBoundary from 'signer-app/parts/error-boundary/error-boundary';

export type RouteBoundaryProps = RouteProps & {
  // This implementation only supports the component property.
  component: NonNullable<RouteProps['component']>;
  props?: Record<string, any>;
  name: string;
};

export function RouteBoundary({
  name,
  props,
  ...routerProps
}: RouteBoundaryProps) {
  const Component = React.useMemo(() => {
    function WrappedComponent(componentProps: RouteComponentProps) {
      return (
        <ErrorBoundary boundaryName={name}>
          <routerProps.component {...componentProps} {...props} />
        </ErrorBoundary>
      );
    }
    return WrappedComponent;
  }, [name, props, routerProps]);

  if (routerProps.render) {
    throw new Error("This implementation doesn't support render props");
  }

  return <Route {...routerProps} component={Component} />;
}
