/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  guard-for-in,
  no-restricted-syntax,
  @typescript-eslint/no-use-before-define
*/

/**
 */

module.exports = function (styles) {
  return _jsonToCSS({
    '.whitelabel-page': {
      'background-color': `${styles.page_background_color} !important`,
    },
    '.whitelabel-header': {
      'background-color': styles.header_background_color,
      color: styles.text_color2,
    },
    '.whitelabel-loader .signature-fill': {
      stroke: `${styles.primary_button_color} !important`,
    },
    '.whitelabel-dot-loader circle': {
      fill: styles.primary_button_color,
    },
    '.whitelabel-secondary-header': {
      'background-color': styles.primary_button_color,
      color: styles.primary_button_text_color,
    },
    '.whitelabel-primary-button': {
      'background-color': `${styles.primary_button_color} !important`,
      color: `${styles.primary_button_text_color} !important`,
      'border-color': `${styles.primary_button_color} !important`,
    },
    '.whitelabel-signature-modal-tab': {
      color: `${styles.primary_button_color} !important`,
    },
    '.whitelabel-signature-modal-tab:before': {
      'background-color': `${styles.primary_button_color} !important`,
    },
    '.whitelabel-signature-modal-save-carousel-tab-item .m-sign-modal--tabs--saved--carousel--item--delete':
      {
        color: `${styles.primary_button_color} !important`,
      },
    '.whitelabel-signature-modal-save-carousel-tab-item .m-sign-modal--tabs--saved--carousel--item--content':
      {
        'border-color': `${styles.primary_button_color} !important`,
      },
    '.whitelabel-primary-button:hover': {
      'background-color': `${styles.primary_button_color_hover} !important`,
      color: `${styles.primary_button_text_color_hover} !important`,
    },
    '.whitelabel-secondary-button': {
      'background-color': styles.secondary_button_color,
      color: styles.secondary_button_text_color,
      'border-color': `${styles.primary_button_color} !important`,
    },
    '.whitelabel-link': {
      color: styles.link_color,
    },
    '.whitelabel-secondary-button:hover': {
      'background-color': `${styles.secondary_button_color_hover} !important`,
      color: `${styles.secondary_button_text_color_hover} !important`,
    },
    '.whitelabel-fields-left': {
      color: `${styles.text_color1} !important`,
      'margin-left': '8px',
    },
    '.whitelabel-fields-left .m-signature-request--header-fields-left-count': {
      'background-color': `${styles.primary_button_color} !important`,
      color: `${styles.primary_button_text_color} !important`,
      border: 'none !important',
      'box-shadow': 'none !important',
    },
    '.whitelabel-signature-modal-tab.is-selected': {
      'border-left-color': `${styles.primary_button_color} !important`,
    },
  });
};

/**
 */

function _jsonToCSS(json) {
  let buffer = '';

  for (const className in json) {
    const styles = json[className];
    buffer += `${className}{\n`;

    for (const styleName in styles) {
      buffer += `${styleName}: ${styles[styleName]};\n`;
    }

    buffer += '}\n\n';
  }

  return buffer;
}
