/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-continue,
  no-restricted-syntax,
  prefer-rest-params
*/
import qs from 'qs';
import BaseObject from 'common/object/base';

/**
 */

function Location() {
  BaseObject.apply(this, arguments);
}

module.exports = BaseObject.extend(Location, {
  /**
   * Initial properties
   */

  state: {},
  query: {},

  /**
   * Stringifies the location into something like /path/to/route?query=value
   */

  toString() {
    let path = this.pathname;

    if (this.query && !!Object.keys(this.query).length) {
      const q = {};

      // remove blank queries
      for (const key in this.query) {
        const v = this.query[key];
        if (v === '') continue;
        q[key] = v;
      }

      path += `?${qs.stringify(q)}`;
    }

    return path;
  },
});
