/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  react/no-string-refs,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import setTimeout from 'common/utils/set-timeout';
import createReactClass from 'create-react-class';
import HeaderAlertTypes from './header-alert-types';

require('./index.scss');

const DEFAULT_ALERT_TTL = 4;

// TODO: Make this class a bit more general, so we can use it in the original context (signature-request.jsx) as well.

const HeaderAlert = createReactClass({
  propTypes: {
    // For a listing of possible types, see header-alert-types.js
    type: PropTypes.string,
    icon: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    shouldTimeout: PropTypes.bool,
  },

  /**
   */

  getDefaultProps() {
    return {
      type: HeaderAlertTypes.ERROR,
      icon: null,
      message: 'Unkown error',
      shouldTimeout: false,
    };
  },

  componentDidMount() {
    if (this.props.shouldTimeout) {
      this.closeAlertAfterTimeout();
    }
  },

  /**
   */

  closeAlertAfterTimeout() {
    const timeout = 1000 * DEFAULT_ALERT_TTL;

    setTimeout(() => {
      this.hideAlert();
    }, timeout);
  },

  /**
   */

  hideAlert() {
    const node = this.refs.headerAlert;
    if (node) {
      node.style.display = 'none';
    }
  },

  /**
   */

  render() {
    // NOTE: The key ensures that the alert is re-rendered without 'display:none'
    // when it changes, otherwise it stays hidden forever
    const key = (
      this.props.type +
      this.props.icon +
      this.props.message
    ).replace(/\s|\n/g, '');
    return (
      <div
        key={window.Base64 ? Base64.encode(key) : key}
        ref="headerAlert"
        className={`m-signer-mobile-header-alert ${this.props.type}`}
        onClick={this.hideAlert}
      >
        <div className="m-signer-mobile-header-alert-icon">
          {<i className={this.props.icon || this.props.type}></i>}
        </div>
        <div className="m-signer-mobile-header-alert-message">
          {this.props.message}
        </div>
        {this.props.children}
        <span
          data-qa-ref="alert-close"
          className="m-signer-mobile-header-alert-close ion-close"
          onClick={this.hideAlert}
        ></span>
      </div>
    );
  },
});

module.exports = HeaderAlert;
