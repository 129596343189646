import React from 'react';
import classnames from 'classnames';
import { useHtmlIDs } from 'signer-app/utils/use-html-ids';
import { CheckboxField } from 'signer-app/types/editor-types';
import { PREPARER } from 'signer-app/signature-request/constants';
import styles from 'signer-app/signature-request/display-field/checkbox.module.css';
import 'signer-app/screenreaders.module.css';

const NOOP = () => {};

type Props = {
  fieldData: CheckboxField;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLElement>) => void;
  className?: string;
};

const Checkbox = React.forwardRef<HTMLInputElement, Props>(function Checkbox(
  props: Props,
  ref,
) {
  const { fieldData, onChange, onKeyUp, onTouchEnd, className } = props;
  const htmlId = useHtmlIDs();
  const checked = Boolean(fieldData.signer === PREPARER || fieldData.checked);
  const readOnly = fieldData.readOnly || false;

  return (
    <React.Fragment>
      <input
        ref={ref}
        data-qa-ref="checkbox"
        data-testid="checkbox"
        type="checkbox"
        id={htmlId('checkbox')}
        onChange={fieldData.signer === PREPARER ? NOOP : onChange}
        checked={checked || false}
        data-field={fieldData.id}
        aria-label={fieldData.name}
        onKeyUp={onKeyUp}
        onTouchEnd={onTouchEnd}
        // Disabled seems to exhibit the previous "readonly" behavior. We
        // don't want this field to be focusable or interactive when in
        // readonly mode
        disabled={readOnly}
        className="visuallyHidden"
      />
      <label
        htmlFor={htmlId('checkbox')}
        data-testid="checkbox-label"
        data-qa-ref="checkbox-label"
      >
        <div className="visuallyHidden">{fieldData.name}</div>
        <div
          className={classnames(className, styles.checkbox, {
            [styles.checked]: checked,
          })}
        />
      </label>
    </React.Fragment>
  );
});

export default Checkbox;
