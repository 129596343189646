import {
  Rule,
  AllowedTriggerField,
  Action,
} from 'signer-app/conditional-logic/types';
import { Field, FieldTypes } from 'signer-app/types/editor-types';
import { unreachable } from 'signer-app/utils/unreachable';

export function isAllowedFieldType(
  type: Field['type'],
): type is AllowedTriggerField {
  switch (type) {
    case FieldTypes.Text:
    case FieldTypes.Checkbox:
    case FieldTypes.Dropdown:
    case FieldTypes.Radio:
      return true;
    default:
      return false;
  }
}

type RuleLocations = 'all' | 'triggers' | 'actions';
export type FieldGroups = Record<string, string[]>;

export function getFieldIdsFromRules(
  fieldGroups: FieldGroups,
  rules: Rule[],
  type: RuleLocations = 'all',
): string[] {
  const allRulesActionIds = rules
    .flatMap((rule) => rule.actions)
    .flatMap((action: Action) => {
      switch (action.type) {
        case 'change-field-visibility':
          return [action.fieldId];
        case 'change-group-visibility':
          return fieldGroups[action.groupId] ?? [];
        default:
          unreachable(action);
          return [];
      }
    });

  const allRulesTriggerIds = rules.flatMap((rule) =>
    rule.triggers.map((t) => t.id),
  );

  if (type === 'triggers') {
    return allRulesTriggerIds;
  } else if (type === 'actions') {
    return allRulesActionIds;
  }

  return allRulesActionIds.concat(allRulesTriggerIds);
}
