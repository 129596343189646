import { TourName } from 'signer-app/context/signer-app-client/tours';

const TOURS_KEY = 'tours';

export type TourShape = {
  completed: boolean;
};

export type TourDataShape = Partial<Record<TourName, TourShape>>;

/**
 * Sets the tour data in local storage.
 */
export const setTourData = (tours: TourDataShape) => {
  try {
    window.localStorage.setItem(TOURS_KEY, JSON.stringify(tours));
  } catch (err) {
    // User may be in private browsing mode.
    // Fail silently.
  }

  return tours;
};

/**
 * Gets the tour data from local storage. If no such data
 * exists, an empty array is returned.
 */
export const getTourData = () => {
  try {
    const toursJson = window.localStorage.getItem(TOURS_KEY);
    let tours: TourDataShape = {};

    if (toursJson) {
      try {
        tours = JSON.parse(toursJson);
      } catch (err) {
        // Ignore; JSON is malformed. Ignore existing data
        // and start from scratch.
        setTourData({});
      }
    }
    return tours;
  } catch (err) {
    // User may be in private browsing mode.
    // Fail silently.
  }

  return {};
};

/**
 * Merges existing tour data with new tour data.
 */
export const mergeTourData = (newTourData: TourDataShape) => {
  return setTourData({
    ...getTourData(),
    ...newTourData,
  });
};
