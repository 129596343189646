import React from 'react';
import classnames from 'classnames';

import styles from 'signer-app/signature-request/display-field/hyperlink.module.css';
import textStyles from 'signer-app/signature-request/display-field/text.module.css';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
} from 'signer-app/signature-request/constants';
import HyperlinkInterstitialModal from 'signer-app/signature-request/hyperlink-interstitial-modal';
import { HyperlinkField } from 'signer-app/types/editor-types';

type Props = {
  fieldData: HyperlinkField;
  height: number;
  width: number;
  textScale: number;
  isActive: boolean;
  documentPreview: boolean;
  isOverlay: boolean;
  getInstantValidationErrorHack: () => void;
  validationErrors: string[];
  selectedField: string | null;
  updateFields: (fieldId: string, update: any) => void;
  runZoomIntoField: (fieldId: string) => void;
  selectField: (fieldId: string) => void;
};
export default class Hyperlink extends React.PureComponent<Props> {
  self = React.createRef<HTMLDivElement>();

  state = { openModal: false };

  focus = (focusOptions: FocusOptions) => {
    this.self.current?.focus(focusOptions);
  };

  handleClick = () => {
    this.setState({ openModal: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  renderModal(fieldData: HyperlinkField) {
    return (
      <HyperlinkInterstitialModal
        link={fieldData.url}
        isOpen={this.state.openModal}
        onRequestClose={this.handleClose}
      />
    );
  }

  render() {
    const {
      fieldData,
      height,
      width,
      textScale,
      isActive,
      documentPreview,
      isOverlay,
      getInstantValidationErrorHack,
      validationErrors,
      selectedField,
      updateFields,
      runZoomIntoField,
      selectField,
      ...props
    } = this.props;

    const fontFamily = fieldData.fontFamily || DEFAULT_FONT_FAMILY;
    const fontSize = fieldData.fontSize || DEFAULT_FONT_SIZE;

    const className = classnames(
      textStyles[`font--${fontFamily}`],
      styles.hyperlink,
    );

    const style = {
      height,
      width,
      fontSize: `${fontSize}px`,
    };

    return (
      <React.Fragment>
        <div
          style={style}
          ref={this.self}
          {...props}
          className={className}
          title={fieldData.url}
          id={`${fieldData.type}-${fieldData.id}`}
          onClick={this.handleClick}
          tabIndex={-1}
          role="button"
        >
          {fieldData.value}
        </div>
        {this.renderModal(fieldData)}
      </React.Fragment>
    );
  }
}
