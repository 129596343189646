import phonenumbers from 'google-libphonenumber';
/* global i18n: true */

global.i18n = global.i18n || {};
i18n.phonenumbers = phonenumbers;
const intl = window.hsIntl;
const messages = window.hsMessages;

/* eslint-disable guard-for-in */

// NOTE: IMPORTANT! If you remove or add a country to this list, make sure you
// also add its country code to
// HFUtils::$country_code_to_name_and_page_multiplier_map
i18n.phonenumbers.PhoneNumberUtil.countryNamesByShortCode = {
  AD: 'Andorra',
  // "AL": "Albania",
  AG: 'Antigua And Barbuda',
  AI: 'Anguilla',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AX: 'Finland',
  // "BD": "Bangladesh",
  BE: 'Belgium',
  // "BG": "Bulgaria",
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BR: 'Brazil',
  BB: 'Barbados',
  BS: 'Bahamas',
  CA: 'Canada',
  CH: 'Switzerland',
  // "CL": "Chile",
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GD: 'Grenada',
  JE: 'Jersey',
  IM: 'Isle of Man',
  GG: 'Guernsey',
  // "GE": "Georgia",
  // "GF": "French Guiana",
  GI: 'Gibraltar',
  GP: 'Guadeloupe',
  GR: 'Greece',
  GU: 'Guam',
  HK: 'Hong Kong',
  HR: 'Croatia',
  HU: 'Hungary',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  // "IQ": "Iraq",
  IS: 'Iceland',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  KH: 'Cambodia',
  KN: 'Saint Kitts And Nevis',
  KR: 'Korea',
  KY: 'Cayman Islands',
  LA: 'Laos',
  LC: 'Saint Lucia',
  LU: 'Luxembourg',
  // "LV": "Latvia",
  // "MA": "Morocco",
  // "MC": "Monaco",
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  // "MQ": "Martinique",
  // "MT": "Malta",
  MS: 'Montserrat',
  MY: 'Malaysia',
  NL: 'Netherlands',
  NO: 'Norway',
  NZ: 'New Zealand',
  PA: 'Panama',
  PE: 'Peru',
  // "PK": "Pakistan",
  PL: 'Poland',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PY: 'Paraguay',
  // "RE": "Reunion",
  RO: 'Romania',
  SE: 'Sweden',
  SG: 'Singapore',
  // SI: "Slovenia",
  SK: 'Slovakia',
  // "SM": "San Marino",
  TC: 'Turks And Caicos Islands',
  TH: 'Thailand',
  TR: 'Türkiye',
  TT: 'Trinidad And Tobago',
  TW: 'Taiwan',
  US: 'United States',
  UY: 'Uruguay',
  VA: 'Vatican City',
  VC: 'Saint Vincent And Grenadines',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, US',
  ZA: 'South Africa',
  AE: 'United Arab Emirates',
  SA: 'Saudi Arabia',
  SX: 'Sint Maarten',
  QA: 'Qatar',
  EG: 'Egypt',
};

// Countries we prioritize the number matches for. Be sure to match the strings
// with the country names in countryNamesByShortCode
i18n.phonenumbers.PhoneNumberUtil.priorityCountries = [
  'United States',
  'Canada',
  'United Kingdom',
  'Australia',
];

i18n.phonenumbers.PhoneNumberUtil.prototype.getCountryNameByCountryShortCode =
  function getCountryNameByCountryShortCode(countryShortCode) {
    return i18n.phonenumbers.PhoneNumberUtil.countryNamesByShortCode[
      countryShortCode
    ]
      ? i18n.phonenumbers.PhoneNumberUtil.countryNamesByShortCode[
          countryShortCode
        ]
      : countryShortCode;
  };

const cleanNumber = (n) =>
  String(n).replace(/\D/g, '').replace(/^0/, '').replace(/^\+/, '');

i18n.phonenumbers.PhoneNumberUtil.prototype.getFormattedPhoneOptions =
  function getFormattedPhoneOptions(
    input,
    varphoneutils,
    selectedRegion = '',
    allowMexico = false,
  ) {
    let results = [];
    let selectedRegionResult = null;
    const explicitInternationalCode = input[0] === '+';
    // prevent email addresses like 555-555-5555@gmail.com to be formatted as phone numbers
    if (input.indexOf('@') >= 0) {
      return [];
    }

    // remove unnecessary characters
    const number = cleanNumber(input);

    if (number.toString().length < 5) {
      return results;
    }

    if (allowMexico) {
      i18n.phonenumbers.PhoneNumberUtil.countryNamesByShortCode.MX = 'Mexico';
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const region in i18n.phonenumbers.PhoneNumberUtil
      .countryNamesByShortCode) {
      let n;
      let valid;
      let exact = false;

      try {
        // parse number and check if it's valid
        n = varphoneutils.parseAndKeepRawInput(`+${number.toString()}`, region);
        valid = varphoneutils.isValidNumberForRegion(n, region);

        if (valid) {
          exact = true;
        }
      } catch (e) {
        /* do nothing */
      }

      // if not valid try without leading +
      if (!valid && !explicitInternationalCode) {
        try {
          n = varphoneutils.parseAndKeepRawInput(number, region);
          valid = varphoneutils.isValidNumberForRegion(n, region);
        } catch (e) {
          /* do nothing */
        }
      }

      // push to results
      if (valid) {
        const countryCode = varphoneutils
          .getMetadataForRegion(region)
          .getCountryCodeOrDefault();

        // this is necessary for certain short numbers. Example: 4281402
        n.setCountryCode(countryCode);

        let formattedNumber;
        try {
          // In the version of libphonenumber committed to this repo, this
          // sometimes throws. See +1 833-123-1234
          formattedNumber = varphoneutils.formatOutOfCountryCallingNumber(n);
        } catch (e) {
          continue; // eslint-disable-line no-continue
        }

        const result = {
          region,
          country: varphoneutils.getCountryNameByCountryShortCode(region),
          number: formattedNumber,
        };

        if (region === selectedRegion) {
          selectedRegionResult = result;
        }

        if (exact) {
          results.unshift(result);
        } else {
          results.push(result);
        }
      }
    }

    // Prepend priority countries when the user is NOT specifying a country code
    // explicity (using a plus)
    if (!explicitInternationalCode) {
      const priorityMatches = [];
      const { priorityCountries } = i18n.phonenumbers.PhoneNumberUtil;

      for (let i = 0; i < results.length; i++) {
        const currentResult = results[i];
        // Add priority countries if they're in the list
        if (priorityCountries.indexOf(currentResult.country) > -1) {
          priorityMatches.push(currentResult);
        }
      }

      priorityMatches.sort(
        (a, b) =>
          priorityCountries.indexOf(a.country) -
          priorityCountries.indexOf(b.country),
      );
      results = Array.prototype.concat(priorityMatches, results);
    }

    if (explicitInternationalCode && selectedRegionResult) {
      results = Array.prototype.concat([selectedRegionResult], results);
    }

    // Deduplicate array
    results = $.grep(results, (v, k) => {
      // Filter if index != first match index
      return $.inArray(v, results) === k;
    });

    return results;
  };

/*
 @number - internationally formatted fax number (digit wise, eg: these are all
 the same: '1555-555-5555' '15555555555' '1 (555) 555-5555'
 */
i18n.phonenumbers.PhoneNumberUtil.prototype.formatInternationalFaxNumber =
  function formatInternationalFaxNumber(number, options) {
    // strip to digits
    const digits = number.replace(/\D/g, '');

    if (digits.toString().length < 5) {
      return number;
    }

    // loop through options looking for an exact match
    for (let i = 0; i < options.length; i++) {
      const n = options[i].number.replace(/\D/g, '');

      // if match, return
      if (n === digits) {
        // eslint-disable-next-line no-param-reassign
        number = options[i].number;
        break;
      }
    }

    // return originally passed number, no formatting option found
    return number;
  };

i18n.phonenumbers.PhoneNumberUtil.prototype.updateDestinationOptions =
  function updateDestinationOptions(el, destination, options) {
    // reset changed
    el.attr('changed', 'false');

    if (options.length >= 1) {
      el.val(options[0].number);
      destination
        .html(
          `<div class="destination_text">${options[0].country.toLowerCase()}</div>`,
        )
        .fadeIn();
    }

    if (options.length > 1) {
      destination.addClass('multiple_destinations');
      let destinationOptionsHtml = '';
      for (let i = 0; i < options.length; i++) {
        const destinationClass = `destination_option${i === 0 ? ' selected' : ''}`;

        if (i === 1) {
          // we keep the number and country the same as whatever is currently selected
          destinationOptionsHtml += `<div class="${destinationClass}"><a target="_blank" rel="noopener noreferrer" href="http://www.hellofax.com/info/internationalFaxPricing">${intl.formatMessage(messages.countriesSupported)}</a></div>`;
        }

        destinationOptionsHtml += `<div class="${destinationClass}" number="${options[i].number}" country="${options[i].country}">${options[i].number} - ${options[i].country.toLowerCase()}</div>`;
      }

      destination.append(destinationOptionsHtml);
    } else {
      destination.removeClass('multiple_destinations');
    }

    el.attr('changed', el.val());
  };
// to detect country code leading a fax number

i18n.phonenumbers.PhoneNumberUtil.prototype.calculateMultiplier =
  function calculateMultiplier(number, countryCodeToFaxPagesMultiplierMap) {
    const regExpString = Object.keys(countryCodeToFaxPagesMultiplierMap).join(
      '|',
    );
    const countryCodeRegex = new RegExp(regExpString);
    if (number.indexOf('@') > 0) {
      return 0;
    }

    const countryCode = number
      .toString()
      .replace(/\D/g, '')
      .match(countryCodeRegex);
    let multiplier = 1;

    if (
      countryCode &&
      countryCode.length > 0 &&
      countryCodeToFaxPagesMultiplierMap[countryCode[0]]
    ) {
      multiplier = countryCodeToFaxPagesMultiplierMap[countryCode[0]];
    }
    return multiplier;
  };

i18n.phonenumbers.PhoneNumberUtil.prototype.hasMultiplier =
  function hasMultiplier(
    number,
    varphoneutils,
    countryCodeToFaxPagesMultiplierMap,
  ) {
    return (
      varphoneutils.calculateMultiplier(
        number,
        countryCodeToFaxPagesMultiplierMap,
      ) > 1
    );
  };
