/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import './index.scss';

import React from 'react';
import createReactClass from 'create-react-class';
import Header from '../header';

const DarkHeader = createReactClass({
  render() {
    return (
      <Header className={`dark ${this.props.className || ''}`} {...this.props}>
        {this.props.children}
      </Header>
    );
  },
});

module.exports = DarkHeader;
