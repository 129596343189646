import React from 'react';
import useThunkReducer from 'signer-app/signature-modal/contexts/signature-modal/use-thunk-reducer';
import reducer from 'signer-app/signature-modal/contexts/signature-modal/reducer';
import { SignatureModalContextShape } from 'signer-app/signature-modal/contexts/signature-modal/types';

export const Context = React.createContext<SignatureModalContextShape>({
  state: {
    isLoadingFont: false,
    currentFontIndex: null,
    count: 0,
  },
  dispatch: () => {},
});

export const SignatureModalContextProvider = (
  props: React.PropsWithChildren<object>,
) => {
  const initialState = React.useContext(Context);
  const [state, dispatch] = useThunkReducer(reducer, initialState);
  const value = React.useMemo(() => ({ state, dispatch }), [dispatch, state]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export const useSignatureModalContext = () => React.useContext(Context);
export const withSignatureModalContext = (
  Component: React.ComponentType<
    React.PropsWithRef<{
      signatureModalContext: ReturnType<typeof useSignatureModalContext>;
    }>
  >,
) => {
  return (
    // eslint-disable-next-line react/display-name
    React.forwardRef((props, ref) => {
      const value = useSignatureModalContext();
      return (
        <Component
          {...props}
          signatureModalContext={value}
          // @ts-ignore ID why the ref type complains
          ref={ref}
        />
      );
    })
  );
};
