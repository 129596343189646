/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
import { intl } from 'hellospa/common/hs-intl-provider';
import hsMessages from 'signer/components/common/translations/hs-messages';
import Field from './field';

module.exports = Field.extend({
  /**
   */

  getValidationError() {
    if (this.required && !this.value) {
      return new Error(intl.formatMessage(hsMessages.requiredMessage));
    }
  },

  /**
   */

  hasValue() {
    return !!this.value;
  },

  /**
   */

  hasGroup() {
    return !!this.group && !this.group.root;
  },

  /**
   */

  setValue(v) {
    this.value = v;
    if (this.hasGroup() && this.hasValue()) {
      this.group.edited = true;
    }
  },
});
