/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/prefer-default-export,
  no-alert,
  no-restricted-globals
*/
import HelloSign from 'common/utils/hellosign';
import { messages, postMessage } from 'js/sign-components/common/messages';

// TODO - this this method needs to create an action which needs
// to be handled by some dispatch handler. (CC)
export function closeWindow(app) {
  if (HelloSign.XWM) {
    HelloSign.XWM.send('close', app.embeddedData.parentUrl);
  }

  // Embedded v2
  postMessage(
    {
      type: messages.USER_CANCEL_REQUEST,
    },
    app.embeddedData.parentUrl,
  );
}

export function confirmCloseWindow(app) {
  const closeMessage = app.intl.messages['signer.cancel.confirm'];

  if (confirm(closeMessage)) {
    closeWindow(app);
  }
}
