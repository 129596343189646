/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Foundation components are not intended to be edited to
 * satisfy specific use cases. If you would like to propose
 * a change to this component, please reach out to a
 * frontend engineer.
 *
 * @author Asa Ayers <asa.ayers@hellosign.com>
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

import React from 'react';
import classnames from 'classnames';
import styles from './button.scss';
import { Link, LinkProps } from 'react-router-dom';

// I'll remove this in a future PR. QA is relying on this for automation
// for in the <PaymentForm
const btnMarker = { 'data-btn': 'foundation' };

// https://www.figma.com/file/N7i3rROqbDjTqopXdE9KrSMC/Foundation-Components?node-id=1083%3A12
export enum ButtonType {
  Primary,
  Outline,
  Subtle,
  Link,
  Warning,
  Selectable,
  // Icon,
}

export enum ButtonSize {
  Small,
  Standard,
  Large,
}

type CommonButtonProps = React.PropsWithChildren<{
  // HTML buttons already have a type prop
  kind?: ButtonType;
  size?: ButtonSize;
  marginless?: boolean;

  // Used only for selectable (kind={ButtonType.Selectable})
  // buttons. Do not attempt to use this yourself unless
  // you know what you're doing.
  selected?: Boolean;

  className?: React.ButtonHTMLAttributes<HTMLButtonElement>['className'];
  disabled?: React.ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
}>;

export type ButtonProps = CommonButtonProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
    onClick?: (
      evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void | Promise<unknown>;
  };

export type ButtonLinkProps = CommonButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: React.AnchorHTMLAttributes<HTMLAnchorElement>['href'];
    to: never;
  };

export type ButtonRouterLinkProps = CommonButtonProps & LinkProps;

function ButtonImpl(
  {
    kind,
    size,
    disabled = false,
    children,
    selected,
    onClick,
    className,
    ...props
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const [isProcessing, setProcessing] = React.useState(false);
  const isMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClick = React.useCallback(
    async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        const promise = onClick(evt);

        if (promise) {
          setProcessing(true);
          try {
            await promise;
          } finally {
            if (isMounted.current) {
              setProcessing(false);
            }
          }
        }
      }
    },
    [onClick],
  );

  return (
    <button
      ref={ref}
      {...btnMarker}
      onClick={handleClick}
      disabled={disabled || isProcessing}
      className={classnames(styles.button, className, {
        // Disabled is a class so that we can disable the
        // button while processing without getting the
        // disabled style.
        [styles.disabled]: disabled,
        [styles.processing]: isProcessing,
      })}
      {...props}
    >
      {children}
    </button>
  );
}

const ButtonButton = React.forwardRef(ButtonImpl);

function ButtonLinkImpl(
  {
    kind,
    size,
    children,
    to,
    ...props
  }: ButtonLinkProps | ButtonRouterLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  if (to) {
    // The docs say the ref is forwarded to the underlying <a but TypeScript doesn't like our ref
    return (
      <Link to={to} ref={ref as any} {...btnMarker} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <a ref={ref} {...btnMarker} {...props}>
      {children}
    </a>
  );
}

const ButtonLink = React.forwardRef(ButtonLinkImpl);

export type BaseButtonProps =
  | ButtonProps
  | ButtonLinkProps
  | ButtonRouterLinkProps;

function BaseButton(
  {
    kind = ButtonType.Primary,
    size = ButtonSize.Standard,
    marginless = false,
    selected = false,
    className,
    ...rest
  }: BaseButtonProps,
  ref: React.Ref<any>,
) {
  const props = {
    ...rest,
    kind,
    size,
    className: classnames(styles.button, className, {
      [styles.marginless]: marginless,

      // Button size
      [styles.small]: size === ButtonSize.Small,
      [styles.large]: size === ButtonSize.Large,

      // Button type
      [styles.primary]: kind === ButtonType.Primary,
      [styles.outline]: kind === ButtonType.Outline,
      [styles.subtle]: kind === ButtonType.Subtle,
      [styles.link]: kind === ButtonType.Link,
      [styles.warning]: kind === ButtonType.Warning,
      [styles.selectable]: kind === ButtonType.Selectable,

      // Button selection for selectable buttons
      [styles.selected]: kind === ButtonType.Selectable && selected,
    }),
  };

  if ('href' in props || 'to' in props) {
    return <ButtonLink {...props} ref={ref} />;
  } else {
    return <ButtonButton {...props} ref={ref} />;
  }
}

// Naming this helps VSCode/TypeScript find it for auto-imports
/**
 * @deprecated use dig-components/buttons instead
 */
const Button = React.forwardRef(BaseButton);
export default Button;
