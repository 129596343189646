import styles from 'signer-app/signature-modal/signature-modal.module.css';

import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'react-intl';
import { throttle } from 'lodash';
import { Modal } from '@dropbox/dig-components/modal';
import { Button as DigButton } from '@dropbox/dig-components/buttons';
import { Tabs } from '@dropbox/dig-components/tabs';
import { Text } from '@dropbox/dig-components/typography';
import { ErrorBanner } from 'signer-app/utils/error-handling/error-banner';
import constants from 'signer-app/signature-modal/constants';
import { SignatureType } from 'signer-app/signature-modal/types';
import { UIIcon } from '@dropbox/dig-icons';
import {
  SignatureLine,
  KeyboardLine,
  CameraUploadLine,
  CloudDoneLine,
} from '@dropbox/dig-icons/assets';

import { TabIconContainer } from 'signer-app/signature-modal/common/tab-icon-container';
import Draw from 'signer-app/signature-modal/draw/index';
import { TypeSignature } from 'signer-app/signature-modal/type/type';
import Upload from 'signer-app/signature-modal/upload/upload';
import Saved from 'signer-app/signature-modal/saved/saved';
import { ValueOfArray } from 'signer-app/utils/value-of-array';
import { SignatureModalContext } from 'signer-app/signature-modal/signature-modal-context/context';
import { SigData } from 'signer-app/context/signer-app-client/signature';
import invariant from 'invariant';
import { Box } from '@dropbox/dig-foundations';

const MOBILE_MEDIA_QUERY =
  '(max-width: 767px), (max-width: 900px) and (min-aspect-ratio: 3/2)';

// Please note that if you change any localized message in
// this enture directory, it must be retranslated into
// Czech manually. This is because Czech is supported
// exlcusively in our Signer app for legacy purposes, but
// translations are not covered by Smartling.
const messages = defineMessages({
  back: {
    id: 'e3339e496629d9ff2e1572b0744f70220e5f205d38f349c5e9a41e4c56d82ff9',
    description: 'Text for a button which closes a modal.',
    defaultMessage: 'Back',
  },
  addYourSignature: {
    id: '9072d3d5a7916c9a53d10e4e0e97c20ace250fd34fa611253946272b00f378e6',
    description:
      'Title for a modal where a user is able to type or draw in the signature that will appear on the document.',
    defaultMessage: 'Add your signature',
  },
  addYourInitials: {
    id: '76929a3f8298b816f5326ee45e1af910adfe0f005c84c0711362ba5169c73dd1',
    description:
      'Title for a modal where a user is able to type or draw in their initials that will appear on the document.',
    defaultMessage: 'Add your initials',
  },
  footerDisclosureSignature: {
    id: '4a2dd551828c734cb054281c250d4836b06162530e255f37d8d7e60206821e23',
    description:
      'Disclosure text that the user must agree to when signing a document.',
    defaultMessage:
      'I understand this is a legal representation of my signature.',
  },
  footerDisclosureInitials: {
    id: '21560fa03cc1c3cc5e3ed59c632d12a3ea7fd2e3a08d1c5502491a5113606ffb',
    description:
      'Disclosure text that the user must agree to when signing a document.',
    defaultMessage:
      'I understand this is a legal representation of my initials.',
  },
  close: {
    id: 'b56d58676cd724ee4bdfb291f933554fa8fd8739be764004cf93d934744db9df',
    description: 'title text of closing button of modal',
    defaultMessage: 'Close',
  },
  drawTabAriaLabel: {
    id: 'fc33afe09973f2a11ad39edda2a078a3426159f6eed00b74873b7b9eedc67ff4',
    description:
      'Screen reader label for the tab where user can draw their signature.',
    defaultMessage: 'Draw your signature',
  },
  typeTabAriaLabel: {
    id: '54a51e0edcdde9d841e3e7d93937d22e49c485d05fc6a64e55be5382c56bee59',
    description:
      'Screen reader label for the tab where user can type in their signature.',
    defaultMessage: 'Type in your signature',
  },
  uploadTabAriaLabel: {
    id: '8899c7033574762a92d3f489a0b7fcd49e34255f9df80c8594e8e3785033c1fe',
    description:
      'Screen reader label for the tab where user can upload a photo of their signature.',
    defaultMessage: 'Upload a photo of your signature',
  },
  savedTabAriaLabel: {
    id: '449fee316cc9352c99b8db6213096738be86cd716a234a6fb72f95cf703984d0',
    description:
      'Screen reader label for the tab where user can pick a saved signature.',
    defaultMessage: 'Choose one of your saved signatures',
  },
});

type Tab = ValueOfArray<typeof constants.TABS>;
function isTab(tab: string): tab is Tab {
  return constants.TABS.includes(tab as Tab);
}

export type SignatureModalProps = SignatureModalContext & {
  intl: IntlShape;
  isOpen: boolean;
  type: SignatureType;
  loading: boolean;
  onClose: () => void;
  onInsert: (signatureData: SigData | null, insertEverywhere: boolean) => void;
  buttonText?: string;
  initialTypeInValue: string;
};

export type SignatureData = SigData;

interface SignatureModalBody {
  getSignatureData: () => SigData | null;
}

const TAB_ID_PREFIX = 'signature-modal-tab-';
const tabId = (tab: Tab) => `${TAB_ID_PREFIX}${tab}`;

class SignatureModal extends React.PureComponent<SignatureModalProps> {
  static defaultProps = {
    isOpen: true,
    savedSignatures: [],
  };

  state = {
    isMobile: false,
    enableInsert: false,
  };

  signatureModalBodyRef = React.createRef<HTMLElement & SignatureModalBody>();

  onWindowResize = throttle(() => this.updateIsMobile(), 50);

  updateIsMobile() {
    const isMobile = window.matchMedia?.(MOBILE_MEDIA_QUERY).matches;
    this.setState({ isMobile });
  }

  isTabEnabled(tab: Tab): boolean {
    if (this.props.enabledTabs) {
      return this.props.enabledTabs.includes(tab);
    }
    return true;
  }

  shouldShowTab(tab: Tab): boolean {
    return this.isTabEnabled(tab);
  }

  noSavedSignatureAvailable() {
    const { savedSignatures } = this.props;
    return Array.isArray(savedSignatures) && savedSignatures.length === 0;
  }

  // ideally this logic should be moved higher in the component hierarchy
  // so that proper selectedTab is passed down and not modified from
  // a lower component, which primary focus' should be on the UI/UX
  updateSelectedTabIfCurrentUnavailable() {
    const { selectedTab, enabledTabs, onTabSelect } = this.props;
    if (
      !this.shouldShowTab(selectedTab) ||
      (selectedTab === constants.TAB_SAVED && this.noSavedSignatureAvailable())
    ) {
      if (this.shouldShowTab(constants.TAB_DRAW)) {
        onTabSelect(constants.TAB_DRAW);
      } else {
        const anyEnabledTab = enabledTabs?.find((type) => type !== selectedTab);
        if (anyEnabledTab) {
          onTabSelect(anyEnabledTab);
        }
      }
    }
  }

  enableInsertButtonCallback = (enableInsert: boolean) => {
    this.setState({
      enableInsert,
    });
  };

  cleanup() {
    this.props.clearUploadedSignature();

    // If the user was looking at the saved signatures tab
    // but did not have any saved signatures, then the next
    // time the user opens the signature modal we no
    // longer want to be on the saved signatures tab, so
    // instead we'll change to the draw tab.
    if (
      this.props.selectedTab === constants.TAB_SAVED &&
      this.props.savedSignatures.length === 0
    ) {
      this.props.onTabSelect(constants.TAB_DRAW);
    }
  }

  onRequestClose = () => {
    this.cleanup();
    if (this.props.error && this.props.clearSignatureError) {
      this.props.clearSignatureError();
    }
    this.props.onClose();
  };

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.updateIsMobile();
    this.updateSelectedTabIfCurrentUnavailable();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate(prevProps: SignatureModalProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.cleanup();
    }
  }

  getSignatureData(): SignatureData | null {
    if (this.signatureModalBodyRef.current == null) {
      return null;
    }

    const data = this.signatureModalBodyRef.current.getSignatureData();

    if (this.props.selectedTab === constants.TAB_SAVED) {
      return data;
    } else {
      return {
        ...data,
        type_code: this.props.type,
      } as any;
    }
  }

  uploadSignature = (file: File) => {
    this.props.onUploadSignature(file, this.props.type);
  };

  insertSignature = async (insertEverywhere = false) => {
    const signatureData = this.getSignatureData();

    invariant(signatureData, 'Missing signatureData');

    if (this.props.selectedTab === constants.TAB_SAVED) {
      this.props.onInsert(signatureData, insertEverywhere);
    } else if (signatureData != null) {
      try {
        // @ts-ignore
        const data = await this.props.createNewSignature(signatureData);
        this.props.onInsert(data, insertEverywhere);
      } catch (e) {
        // Errors here can be ignored becaus they're handled in createNewSignature
      }
    }
  };

  insertSignatureEverywhere = () => {
    this.insertSignature(true);
  };

  renderContentLabel() {
    const { intl, type } = this.props;

    switch (type) {
      case constants.TYPE_SIGNATURE:
        return intl.formatMessage(messages.addYourSignature);
      case constants.TYPE_INITIALS:
        return intl.formatMessage(messages.addYourInitials);
      default:
        throw new TypeError('Invalid type');
    }
  }

  renderFooterDisclosure() {
    const { intl, type } = this.props;

    switch (type) {
      case constants.TYPE_SIGNATURE:
        return intl.formatMessage(messages.footerDisclosureSignature);
      case constants.TYPE_INITIALS:
        return intl.formatMessage(messages.footerDisclosureInitials);
      default:
        throw new TypeError('Invalid type');
    }
  }

  renderButtons() {
    const { buttonText, canInsertEverywhere } = this.props;
    const enableInsert =
      this.state.enableInsert &&
      // Prevent inserting data when there is no data to insert. We probably
      // don't need enableInsert, but I'm not refactoring that as part of fixing
      // this P2.
      this.getSignatureData() != null;

    const buttons = [
      <DigButton
        variant="primary"
        data-qa-ref="singing-modal--insert-btn"
        data-testid="singing-modal--insert-btn"
        aria-describedby="signature-modal-footer-disclosure"
        className={styles.insertButton}
        onClick={() => this.insertSignature()}
        disabled={this.props.loading || !enableInsert}
        key="insert"
      >
        {!!buttonText && !canInsertEverywhere ? (
          buttonText
        ) : (
          <FormattedMessage
            id="9bff1672b61adc544d8534e321758d32dac6ef7e9b0c5cc49e318ba7c7f661e9"
            description="Text for a button that inserts the users signature into a field."
            defaultMessage="Insert"
          />
        )}
      </DigButton>,
    ];

    if (canInsertEverywhere) {
      buttons.push(
        <DigButton
          variant="primary"
          data-testid="insert-everywhere-btn"
          aria-describedby="signature-modal-footer-disclosure"
          className={styles.insertButton}
          onClick={() => this.insertSignatureEverywhere()}
          disabled={this.props.loading || !enableInsert}
          key="insert everywhere"
        >
          <FormattedMessage
            id="c4f1e4f98a949d4439365da30b5f2c0c464e2b47885d24f5c7732e709d52aaa4"
            description="Text for a button that inserts the users signature into all fields field."
            defaultMessage="Insert everywhere"
          />
        </DigButton>,
      );
    }

    return buttons;
  }

  handleTabSelection = (tabId: string) => {
    const { onTabSelect } = this.props;
    const tab = tabId.replace(TAB_ID_PREFIX, '');
    if (isTab(tab)) {
      onTabSelect(tab);
    }
  };

  render() {
    const {
      intl,
      isOpen,
      selectedTab,
      savedSignatures,
      loading,
      getSignatureUrl,
      onSavedSignatureSelect,
      onSavedSignatureRemove,
      selectedSavedSignature,
      isUploadingSignature,
      uploadedSignature,
      onRotateSignature,
      clearUploadedSignature,
      initialTypeInValue,
      type,
      getPaginatedSignatures,
      initialsCount,
      signaturesCount,
      error,
      clearSignatureError,
    } = this.props;
    const count =
      type === constants.TYPE_SIGNATURE ? signaturesCount : initialsCount;
    return (
      <Modal
        open={isOpen}
        fullScreen={this.state.isMobile}
        isCentered
        width="large"
        withCloseButton={this.props.intl.formatMessage(messages.close)}
        onRequestClose={this.onRequestClose}
        aria-labelledby="signature-modal-title"
      >
        <Modal.Header
          className={styles.modalHeader}
          hasBottomSpacing="title-standard"
        >
          <Modal.Title id="signature-modal-title">
            {this.renderContentLabel()}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body hasVerticalSpacing={false} className={styles.modalBody}>
          {loading && selectedTab !== constants.TAB_UPLOAD ? (
            <p className={styles.loading}>
              <FormattedMessage
                id="62da6497bb4cf0cea5b8ab173e34a5bbda7cc7b63bbb6f0334a5afa29bfa584c"
                description="Loading message"
                defaultMessage="Loading…"
              />
            </p>
          ) : (
            <Tabs
              selectedTab={tabId(selectedTab)}
              onSelection={this.handleTabSelection}
            >
              <Tabs.Group
                justified={this.state.isMobile}
                containerClassName={styles.tabs}
              >
                {this.shouldShowTab(constants.TAB_DRAW) && (
                  <Tabs.Tab
                    id={tabId(constants.TAB_DRAW)}
                    aria-label={intl.formatMessage(messages.drawTabAriaLabel)}
                    data-qa-ref={`signing-modal--${constants.TAB_DRAW}`}
                    data-testid={`signing-modal--${constants.TAB_DRAW}`}
                  >
                    <TabIconContainer
                      isMobile={this.state.isMobile}
                      icon={<UIIcon src={SignatureLine} />}
                    >
                      <FormattedMessage
                        id="4a93b7e6eeb4849fa9cc4f247db5068669ca1d7cbeededfaf078a17b765ef4c4"
                        description="Label text for the tab where user can draw in their signature."
                        defaultMessage="Draw"
                      />
                    </TabIconContainer>
                  </Tabs.Tab>
                )}
                {this.shouldShowTab(constants.TAB_TYPE) && (
                  <Tabs.Tab
                    id={tabId(constants.TAB_TYPE)}
                    aria-label={intl.formatMessage(messages.typeTabAriaLabel)}
                    data-qa-ref={`signing-modal--${constants.TAB_TYPE}`}
                    data-testid={`signing-modal--${constants.TAB_TYPE}`}
                  >
                    <TabIconContainer
                      isMobile={this.state.isMobile}
                      icon={<UIIcon src={KeyboardLine} />}
                    >
                      <FormattedMessage
                        id="f92cf2c311804d5f1599a446aba5788f6af02db3fd215435ce44ec4de18532dd"
                        description="Label text for the tab where user can type in their signature."
                        defaultMessage="Type"
                      />
                    </TabIconContainer>
                  </Tabs.Tab>
                )}
                {this.shouldShowTab(constants.TAB_UPLOAD) && (
                  <Tabs.Tab
                    id={tabId(constants.TAB_UPLOAD)}
                    aria-label={intl.formatMessage(messages.uploadTabAriaLabel)}
                    data-qa-ref={`signing-modal--${constants.TAB_UPLOAD}`}
                    data-testid={`signing-modal--${constants.TAB_UPLOAD}`}
                  >
                    <TabIconContainer
                      isMobile={this.state.isMobile}
                      icon={<UIIcon src={CameraUploadLine} />}
                    >
                      <FormattedMessage
                        id="56d3d6c1b908add6895a510b5f479c9408b11fd5752f826ac7ad7b42444b39b0"
                        description="Label text for an icon that allows the user to upload an image of their signature."
                        defaultMessage="Upload"
                      />
                    </TabIconContainer>
                  </Tabs.Tab>
                )}
                {this.shouldShowTab(constants.TAB_SAVED) && (
                  <Tabs.Tab
                    id={tabId(constants.TAB_SAVED)}
                    aria-label={intl.formatMessage(messages.savedTabAriaLabel)}
                    data-qa-ref={`signing-modal--${constants.TAB_SAVED}`}
                    data-testid={`signing-modal--${constants.TAB_SAVED}`}
                  >
                    <TabIconContainer
                      isMobile={this.state.isMobile}
                      icon={<UIIcon src={CloudDoneLine} />}
                    >
                      <FormattedMessage
                        id="4f3be2e4fd81ddb5fbd21e7301be5c5da31603c814463a40079392c9310251f7"
                        description="Label text for an icon that allows the user to view their saved signature."
                        defaultMessage="Saved"
                      />
                    </TabIconContainer>
                  </Tabs.Tab>
                )}
              </Tabs.Group>
              {error && (
                <ErrorBanner error={error} onClose={clearSignatureError} />
              )}
              <div className={styles.panel}>
                {this.shouldShowTab(constants.TAB_DRAW) && (
                  <Tabs.Panel
                    tabId={tabId(constants.TAB_DRAW)}
                    className={styles.panelContent}
                  >
                    <Draw
                      ref={this.signatureModalBodyRef}
                      enableInsertButtonCallback={
                        this.enableInsertButtonCallback
                      }
                      defaultCanvasHeight={180}
                      defaultCanvasWidth={652}
                    />
                  </Tabs.Panel>
                )}
                {this.shouldShowTab(constants.TAB_TYPE) && (
                  <Tabs.Panel
                    tabId={tabId(constants.TAB_TYPE)}
                    className={styles.panelContent}
                  >
                    {/* signature modal context needs to be rewritten in TS for below to compile */}
                    <TypeSignature
                      ref={this.signatureModalBodyRef}
                      enableInsertButtonCallback={
                        this.enableInsertButtonCallback
                      }
                      initialTypeInValue={initialTypeInValue}
                    />
                  </Tabs.Panel>
                )}
                {this.shouldShowTab(constants.TAB_UPLOAD) && (
                  <Tabs.Panel
                    tabId={tabId(constants.TAB_UPLOAD)}
                    className={styles.panelContent}
                  >
                    <Upload
                      ref={this.signatureModalBodyRef}
                      isUploadingSignature={isUploadingSignature}
                      uploadedSignature={uploadedSignature}
                      onRotateSignature={onRotateSignature}
                      uploadSignature={this.uploadSignature}
                      getSignatureUrl={getSignatureUrl}
                      enableInsertButtonCallback={
                        this.enableInsertButtonCallback
                      }
                      clearUploadedSignature={clearUploadedSignature}
                      isMobile={this.state.isMobile}
                    />
                  </Tabs.Panel>
                )}
                {this.shouldShowTab(constants.TAB_SAVED) && (
                  <Tabs.Panel
                    tabId={tabId(constants.TAB_SAVED)}
                    className={styles.panelContent}
                  >
                    <Saved
                      ref={this.signatureModalBodyRef}
                      type={type}
                      enableInsertButtonCallback={
                        this.enableInsertButtonCallback
                      }
                      signatures={savedSignatures}
                      getSignatureUrl={getSignatureUrl}
                      selectedSavedSignature={selectedSavedSignature}
                      onSavedSignatureRemove={onSavedSignatureRemove}
                      onSavedSignatureSelect={onSavedSignatureSelect}
                      getPaginatedSignatures={getPaginatedSignatures}
                      isMobile={this.state.isMobile}
                      count={count ?? 0}
                    />
                  </Tabs.Panel>
                )}
              </div>
            </Tabs>
          )}
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Text
            id="signature-modal-footer-disclosure"
            size="small"
            className={styles.disclosure}
          >
            {this.renderFooterDisclosure()}
          </Text>
          <Box display="flex" className={styles.buttonGroup}>
            {this.renderButtons()}
          </Box>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default function SignatureModalWrapper(
  props: Omit<SignatureModalProps, 'intl' | 'signatureModalContext'>,
) {
  const intl = useIntl();
  return <SignatureModal {...props} intl={intl} />;
}
