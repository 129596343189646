import React from 'react';
import classnames from 'classnames';
import { useForceUpdate } from 'signer-app/utils/use-force-update';
import { useEventListener } from 'signer-app/utils/use-event-listener';
import { usePreviousValue } from 'signer-app/utils/use-previous-value';
import { useRefReady } from 'signer-app/utils/use-ref-ready';
import { usePinchAndZoom } from 'signer-app/signature-request/use-pinch-and-zoom';
import { useResizeObserver } from '@dropbox/dig-components/hooks';
import FieldTooltip from 'signer-app/signature-request/field-tooltip';
import { LayerContext } from '@dropbox/dig-components/layer';
import {
  signatureRequestContext,
  fieldsContext,
  zoomContext,
  SignatureRequestContextShape,
  ZoomContextShape,
  useZoomContext,
  DocumentAddress,
} from 'signer-app/signature-request/context';
import { identity } from 'lodash';

import { delayPromise } from 'signer-app/utils/delay-promise';
import Page from 'signer-app/signature-request/document/page';
import styles from 'signer-app/signature-request/document.module.css';
import { Field } from 'signer-app/types/editor-types';
import {
  ORIGIN_PAGE_CONTAINER,
  ORIGIN_VIEWPORT,
} from 'signer-app/signature-request/constants';
import { useViewportObserver } from 'signer-app/utils/use-viewport-observer';

function useCenterDocument(ref: React.MutableRefObject<HTMLElement | null>) {
  const [centered, setCentered] = React.useState<number | boolean>(0);
  React.useEffect(() => {
    if (centered !== true) {
      if (ref.current != null && ref.current.children.length > 0) {
        const left = Math.round(
          (ref.current.scrollWidth - ref.current.clientWidth) / 2,
        );
        ref.current.scrollLeft = left;

        // I still don't know why, but sometimes I can't set scrollLeft, so this
        // will have to try again in another update.
        if (ref.current.scrollLeft === left) {
          return setCentered(true);
        }
      }

      // Force an update by incrementing the counter
      if (typeof centered === 'number' && centered < 10) {
        setCentered(centered + 1);
      }
    }
  }, [centered, ref]);
}

function useUpdateOnResize(context: SignatureRequestContextShape) {
  const { pageContainerRef, setPageContainerRef } = context;
  const prevSize = usePreviousValue(
    pageContainerRef.current &&
      pageContainerRef.current.getBoundingClientRect(),
  );
  const { nodeRef, observerEntry } = useResizeObserver();

  const node = pageContainerRef.current;
  React.useLayoutEffect(() => {
    nodeRef(node);
    return () => nodeRef(null);
  }, [node, nodeRef]);

  const zoomContext = useZoomContext();
  const centerAddressRef = React.useRef<null | DocumentAddress>(null);
  useEventListener(pageContainerRef.current, 'scroll', () => {
    centerAddressRef.current = zoomContext.fromScreenCoords(
      {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
        pageIndex: 0,
        documentId: 'id',
        height: 0,
        width: 0,
      },
      ORIGIN_VIEWPORT,
    );
  });

  /**
   * zoomContext was created with assumptions about the size of the page
   * container. After calling setPageContainerRef, we have to wait for a new
   * render to produce a NEW zoomContext in order to be able to compute the
   * correct values. So this state functions to make sure that each step happens
   * in a separate render.
   */
  const [resizing, setResizing] = React.useState<
    false | 'set-container' | 'restore-scroll'
  >(false);
  React.useLayoutEffect(() => {
    if (resizing === 'set-container' && pageContainerRef.current) {
      setPageContainerRef(pageContainerRef.current);
      setResizing('restore-scroll');
    } else if (resizing === 'restore-scroll' && centerAddressRef.current) {
      const newCenterAddress = zoomContext.fromScreenCoords(
        {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2,
          pageIndex: 0,
          documentId: 'id',
          height: 0,
          width: 0,
        },
        ORIGIN_VIEWPORT,
      );

      const current = zoomContext.toScreenCoords(
        newCenterAddress,
        ORIGIN_PAGE_CONTAINER,
      );
      const target = zoomContext.toScreenCoords(
        centerAddressRef.current,
        ORIGIN_PAGE_CONTAINER,
      );

      /**
       * current and target are screen coordinates that point to the center of
       * the screen, and the last known center from a previous scroll. To
       * restore the position we just need to move the distance between the two.
       */
      let { scrollTop, scrollLeft } = pageContainerRef.current!;
      scrollTop += Number(target.y) - Number(current.y);
      scrollLeft += Number(target.x) - Number(current.x);

      pageContainerRef.current?.scrollTo({ top: scrollTop, left: scrollLeft });
      /**
       * The document body isn't SUPPOSED to scroll, but sometimes it does and
       * needs to be reset.
       */
      document.body.scrollTo({ top: 0 });
      setResizing(false);
    }
  }, [pageContainerRef, resizing, setPageContainerRef, zoomContext]);

  React.useLayoutEffect(() => {
    if (observerEntry) {
      // observerEntry just needs to be part of the dependencies to trigger this
      // effect when a resize happens
    }
    if (pageContainerRef.current) {
      const bbox = pageContainerRef.current.getBoundingClientRect();

      if (
        !prevSize ||
        Math.abs(prevSize.width - bbox.width) > 1 ||
        Math.abs(prevSize.height - bbox.height) > 1
      ) {
        setResizing('set-container');
      }
    }
  }, [prevSize, pageContainerRef, setPageContainerRef, observerEntry]);
}

type DocumentProps = React.PropsWithChildren<{
  className?: string;
  noBackground?: boolean;
  showImg?: boolean;
  onPageClick?: React.ComponentProps<typeof Page>['onPageClick'];
  wrapPage: (c: React.ComponentType<any>) => React.ComponentType<any>;
  showUneditableMessage?: boolean;
  uneditableDocumentIds?: Set<string>;
  lazyLoadFields?: boolean;
}>;

function Document({
  className,
  noBackground,
  children = null,
  showImg = true,
  onPageClick,
  wrapPage = identity,
  showUneditableMessage = false,
  uneditableDocumentIds,
  lazyLoadFields = false,
}: DocumentProps) {
  const WrappedPage = React.useMemo(() => wrapPage(Page), [wrapPage]);

  const observer = useViewportObserver(lazyLoadFields);
  const context = React.useContext(signatureRequestContext);
  const fields = React.useContext(fieldsContext);
  const zoomContextValue = React.useContext(zoomContext);
  const { zoom, pxMaxPageWidth, fitWidthScale } = zoomContextValue;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useDOMAttachedZoomContext(zoomContextValue, context.pageContainerRef.current);

  const zoomRef = React.useRef<HTMLDivElement>(null);
  usePinchAndZoom(context.pageContainerRef, zoomRef, context.isOverlay);
  useCenterDocument(context.pageContainerRef);
  useUpdateOnResize(context);
  // Page positions depend on the size of pageContainerRef, so they can't render
  // until it's in place and fitWidthScale has been calculated for this container.
  const readyForPages =
    useRefReady(context.pageContainerRef) &&
    (fitWidthScale > 0 ||
      // since JSDOM doesn't do layout fitWidthScale doesn't get calculated in tests.
      navigator.userAgent.includes('jsdom'));
  const fieldsByPage = React.useMemo(() => {
    const fieldsByPage: Field[][] = context.pages.map(() => []);
    fields.forEach((f) => fieldsByPage[f.pageIndex].push(f));
    return fieldsByPage;
  }, [fields, context.pages]);
  const pagePositions = React.useMemo(() => {
    const maxWidth = Math.max(...context.pages.map((p) => p.width));

    return context.pages.map((page, pageIndex) => {
      const widthPercent = (page.width / maxWidth) * 100;

      let margin = '0';
      if (context.topGap) {
        // with `display: flex` margins dont' overlap, so the top and bottom can
        // have the same margin on all pages.
        margin = `${context.pageGap}px 0`;
      } else if (pageIndex > 0) {
        // Fall back to Editor < 2.3 behavior of only applying a top margin
        margin = `${context.pageGap}px 0 0 0`;
      }

      return {
        width: `${widthPercent}%`,
        margin,
      };
    });
  }, [context.pageGap, context.pages, context.topGap]);

  const [containerStyle, setContainerStyle] = React.useState({
    width: 1024,
  });
  React.useEffect(() => {
    setContainerStyle({
      width: pxMaxPageWidth * zoom,
    });
  }, [setContainerStyle, pxMaxPageWidth, zoom]);

  return (
    <div
      className={classnames(styles.document, className, {
        [styles.noBackground]: noBackground,
      })}
      ref={
        // This container is used to measure the amount of space available to
        // pages.
        context.setPageContainerRef
      }
      data-test-id="page-container"
      data-testid="page-container"
      onScroll={context.onScroll}
    >
      <div
        className={styles.pageContainer}
        data-testid="document__pageContainer"
        style={containerStyle}
        ref={zoomRef}
      >
        {readyForPages &&
          context.pages.map((page, pageIndex) => (
            <WrappedPage
              key={`page${pageIndex}-${page.src}`}
              onPageClick={onPageClick}
              {...pagePositions[pageIndex]}
              showImg={showImg}
              pageIndex={pageIndex}
              page={page}
              observer={observer}
              fields={fieldsByPage[pageIndex]}
              showUneditableMessage={
                showUneditableMessage &&
                uneditableDocumentIds?.has(page.documentId)
              }
            />
          ))}
        {context.documentPreview && (
          <LayerContext.Provider value={{ zIndex: 1024 }}>
            <FieldTooltip
              activeFields={context.selectedFieldIds}
              fields={fields}
              isPortaled={false}
              isOpen={true}
            />
          </LayerContext.Provider>
        )}
      </div>
      {children}
    </div>
  );
}

export default Document;

const contextCache = new WeakMap<HTMLDivElement, any>();
function useDOMAttachedZoomContext(
  zoomContext: ZoomContextShape,
  pageContainer: HTMLDivElement | null,
) {
  React.useLayoutEffect(() => {
    if (pageContainer && contextCache.get(pageContainer) !== zoomContext) {
      contextCache.set(pageContainer, zoomContext);

      const event = new Event('updated-zoom');
      pageContainer.dispatchEvent(event);
    }
  }, [pageContainer, zoomContext]);
}
export function useZoomContextFromPageContainer() {
  const forceUpdate = useForceUpdate();
  const [pageContainer, setPageContainer] =
    React.useState<HTMLDivElement | null>(null);
  useEventListener(pageContainer, 'updated-zoom', forceUpdate);
  React.useEffect(() => {
    async function findPageContainer(tries = 0): Promise<undefined | void> {
      const el = document.querySelector<HTMLDivElement>(
        '[data-test-id=page-container]',
      );
      if (el !== pageContainer) {
        setPageContainer(el);
      } else if (el == null && tries < 10) {
        await delayPromise(100);
        return findPageContainer(tries + 1);
      }
    }

    findPageContainer();
  }, [pageContainer]);
  const zoom = contextCache.get(pageContainer as HTMLDivElement);
  return zoom;
}
