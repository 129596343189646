/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
import BaseCollection from 'common/models/base/collection';
import Snapshot from './snapshot';

module.exports = BaseCollection.extend({
  idProperty: 'guid',
  createModel(props) {
    props.snapshots = this;
    props.notifier = this.notifier;
    props.signatureRequest = this.signatureRequest;
    return Snapshot.create(props);
  },
});
