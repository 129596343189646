import React from 'react';
import TouchBackend from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';

// https://react-dnd.github.io/react-dnd/docs/backends/touch
export const hasNative = document && document.elementsFromPoint;

// This is a fallback for older browsers. Jest doesn't need it, so it doesn't
// get tested and we might not need it anymore.
// istanbul ignore next
export function getDropTargetElementsAtPoint(
  x: number,
  y: number,
  dropTargets: HTMLElement[],
) {
  return dropTargets.filter((t: HTMLElement) => {
    const rect = t.getBoundingClientRect();
    return (
      x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
    );
  });
}

type Props = React.PropsWithChildren<{
  // ReactDND doesn't export the type I need and typeof TouchBackend
  // doesn't seem to be compatible with the TestBackend
  backend?: any;
}>;
// https://github.com/react-dnd/react-dnd/issues/1558
// This is a hack to work around "Cannot have two HTML5 backends at the same time".
// To reproduce the error in storybook:
// 1. remove `context={context}`
// 2. Simply viewing the Editor in the storybook sets up ReactDND
// 3. Turn off a `FEATURE_*`. This causes the whole Editor to have to re-mount.
// 4. Perform any drag operation some options are:
//      * Drag to create a field
//      * Drag to select some fields
//      * Drag to move one or more fields. Sometimes this fails immediately but doesn't error.
// 5. Drag to create a new field
//      * This will crash the Editor
const context = {};

// I'm out of time for debugging this. If a context is not provided react-dnd
// attaches the backend to the window using
// Symbol.for('__REACT_DND_CONTEXT_INSTANCE__') as the key. It's supposed to
// clean that up when it unmounts, because if it mounts and finds that it's
// already setup it throws. It seems like if I provide my own context, then
// cleanup happens just fine.
export default function TouchProvider({ children, backend }: Props) {
  return (
    <DndProvider
      context={context}
      backend={backend || TouchBackend}
      options={{
        enableMouseEvents: true,
        getDropTargetElementsAtPoint:
          !hasNative && getDropTargetElementsAtPoint,
      }}
    >
      {children}
    </DndProvider>
  );
}
