// allow references to brand names
/* eslint-disable no-restricted-syntax */
import { hsHost } from 'signer-app/utils/env';
import { siteCodes } from 'signer-app/utils/brand';
import { UnreachableError } from 'signer-app/utils/unreachable';

export type SiteCode = (typeof siteCodes)[keyof typeof siteCodes];

export const SITE_CODE_HF = 'hellofax';
export const SITE_CODE_HS = 'hellosign';
export const SITE_CODE_HW = 'helloworks';
export const SITE_CODES = Object.values(siteCodes);

export function getSite(siteCode: SiteCode) {
  switch (siteCode) {
    case siteCodes.dropbox:
      throw new Error('Not Implemented');
    case siteCodes.fax:
      return SITE_CODE_HF;
    case siteCodes.sign:
      return SITE_CODE_HS;
    case siteCodes.forms:
      return SITE_CODE_HW;
    default:
      throw new UnreachableError(siteCode);
  }
}

export function isHelloFax() {
  // eslint-disable-next-line no-bitwise
  return !!~location.hostname.indexOf(SITE_CODE_HF);
}
export function isHelloSign() {
  // eslint-disable-next-line no-bitwise
  return !!~location.hostname.indexOf(SITE_CODE_HS);
}
export function getCurrentSubdomain() {
  const hostParts = window.location.host.split('.');
  if (hostParts.length > 1) {
    return hostParts[0];
  } else {
    const defaultSubdomain = hsHost?.match(/^(\w+)/)?.[1];
    return defaultSubdomain ?? '';
  }
}
export function urlHelper(
  inputString: string,
  site?:
    | typeof SITE_CODE_HF
    | typeof SITE_CODE_HS
    | typeof SITE_CODE_HW
    | SiteCode,
  useSubdomain?: string,
) {
  if (IS_STORYBOOK) {
    return `${window.location.host}/${inputString}`;
  }

  let finalSite = site;
  if (finalSite && finalSite.length === 1) {
    finalSite = getSite(site as SiteCode);
  }

  if (
    !finalSite ||
    finalSite?.length === 0 ||
    (finalSite !== SITE_CODE_HF && finalSite !== SITE_CODE_HS)
  ) {
    finalSite = isHelloSign() ? SITE_CODE_HS : SITE_CODE_HF;
  }
  const subDomain = useSubdomain || getCurrentSubdomain();
  // If you aren't using `use_subdomain`, then `const whatever =
  // HfReactHelper.urlHelper('api/whatever/', site)` can just be replaced with
  // `const whatever = '/api/whatever/'`.
  // find something like dev-hellosign
  const match = hsHost?.match(/(\w+-)\w+.com/);
  let prefix = '';
  if (match) prefix = match[1];
  // eslint-disable-next-line no-useless-escape
  const urlHelperUrl = `\/\/__subdomain__.${prefix || ''}__site__.com`;
  const url = `${urlHelperUrl.replace('__subdomain__', subDomain).replace('__site__', finalSite)}/${inputString}`;

  return url;
}

export function getMarketingLocaleFormatForFaq() {
  const locale = window.locale;
  switch (locale) {
    case 'da-DK':
      return 'da';
    case 'fr-FR':
      return 'fr-fr';
    case 'de-DE':
      return 'de';
    case 'sv-SE':
      return 'sv';
    case 'es-ES':
      return 'es-es';
    case 'id-ID':
      return 'id';
    case 'it-IT':
      return 'it';
    case 'ja-JP':
      return 'ja';
    case 'ko-KR':
      return 'ko';
    case 'ms-MY':
      return 'ms';
    case 'nl-NL':
      return 'nl';
    case 'pl-PL':
      return 'pl';
    case 'ru-RU':
      return 'ru';
    case 'zh-CN':
      return 'zh-cn';
    case 'zh-TW':
      return 'zh-tw';
    case 'nb-NO':
      return 'no';
    case 'en-GB':
      return 'en-gb';
    case 'es-LA':
      return 'es-419';
    case 'pt-BR':
      return 'pt-br';
    default:
      return 'en-us';
  }
}

export function getMarketingLocaleFormat(locale = window.locale) {
  switch (locale) {
    case 'da-DK':
      return 'da';
    case 'fr-FR':
      return 'fr';
    case 'nb-NO':
      return 'no';
    case 'en-GB':
      return 'gb';
    case 'de-DE':
      return 'de';
    case 'sv-SE':
      return 'sv';
    case 'es-LA':
      return 'es';
    case 'es-ES':
      return 'spa';
    case 'id-ID':
      return 'id';
    case 'it-IT':
      return 'it';
    case 'ja-JP':
      return 'ja';
    case 'ko-KR':
      return 'ko';
    case 'ms-MY':
      return 'ms';
    case 'nl-NL':
      return 'nl';
    case 'pl-PL':
      return 'pl';
    case 'pt-BR':
      return 'pt';
    case 'ru-RU':
      return 'ru';
    case 'th-TH':
      return 'th';
    case 'uk-UA':
      return 'uk';
    case 'zh-CN':
      return 'hans';
    case 'zh-TW':
      return 'hant';
    default:
      return '';
  }
}

export function getWwwHost(siteCode: SiteCode = siteCodes.sign) {
  switch (siteCode) {
    case siteCodes.forms:
      return 'helloworks.com';
    case siteCodes.fax:
      return 'hellofax.com';
    case siteCodes.sign:
    default:
      return 'hellosign.com';
  }
}
export function getLocalizedWebflowUrl(siteCode: SiteCode = 'S') {
  const host = getWwwHost(siteCode);

  let url = `https://www.${host}`;

  if ('locale' in window && window.locale !== 'en-US') {
    const subdomain = getMarketingLocaleFormat();
    if (subdomain !== '') {
      url = url.replace('www', subdomain);
    }
  }

  return url;
}

export function attachSessionInfoToUrl(url: string) {
  if (window.hellofaxJS) {
    return window.hellofaxJS.attachSessionInfoToUrl(url);
  }
  return url;
}

export function getFaqDomain(siteCode: SiteCode = siteCodes.sign) {
  switch (siteCode) {
    case siteCodes.forms:
      return 'https://helloworks.zendesk.com';
    case siteCodes.fax:
    case siteCodes.sign:
    default:
      return 'https://faq.hellosign.com';
  }
}

export function getFaqUrl(pathname = '', siteCode: SiteCode = siteCodes.sign) {
  const domain = getFaqDomain(siteCode);
  const localizedFaqUrl = `${domain}/hc/${getMarketingLocaleFormatForFaq()}`;
  return `${localizedFaqUrl + (pathname ? `/${pathname}` : '')}`;
}

export function getWebflowUrl(pathname = '', site: SiteCode = 'S') {
  const localizedWebflowUrl = getLocalizedWebflowUrl(site);

  return `${localizedWebflowUrl}/${pathname}`;
}
