/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import baseform from 'common/components/base-form';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import Button from 'common/components/button';

const messages = defineMessages({
  continueBtn: {
    id: '', // IDs are procedurally generated
    description: 'button text in inperson signing flow form',
    defaultMessage: 'Continue',
  },
  fieldEmailPlaceholder: {
    id: '',
    description: 'Placeholder for email address field',
    defaultMessage: 'email@example.com',
  },
  errorInvalidEmail: {
    id: '',
    description: 'error message for invalid email address',
    defaultMessage: 'Invalid email address',
  },
});

const LogInInPerson = baseform.createFormClass({
  propTypes: {
    guid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  },

  onEmailChange(event) {
    this.setState({
      emailAddress: event.target.value.trim(),
    });
  },

  onSubmit(e) {
    e.preventDefault();

    const emailAddress = $('#email_address').val();
    const { intl } = this.props;

    if (!HfReactHelper.isValidEmailAddress(emailAddress)) {
      this.setState({
        errors: {
          named: {
            email_address: intl.formatMessage(messages.errorInvalidEmail),
          },
        },
      });
    } else {
      $('#login-form').submit();
    }
  },

  render() {
    let errors = '';
    if (this.state.errors) {
      errors = (
        <div className="row">
          <div className="columns small-12 text-center m-login-in-person--errors">
            {this.renderErrorText()}
          </div>
        </div>
      );
    }
    return (
      <div className="m-login-in-person row">
        <h4>{this.props.title}</h4>
        <div>
          <FormattedMessage
            id=""
            description="text in inperson signing flow, asking user to sign and enter email address in input field"
            defaultMessage="To review and sign this document, enter your email address:"
          />
        </div>
        <form
          id="login-form"
          className="m-login-in-person--form"
          action={HfReactHelper.urlHelper('signer/signInPerson')}
          method="POST"
        >
          {this.renderField('email_address', {
            id: 'email_address',
            className: '',
            maxLength: '99',
            placeholder: this.props.intl.formatMessage(
              messages.fieldEmailPlaceholder,
            ),
            onChange: this.onEmailChange,
            tabIndex: '0',
            'data-format': 'email',
          })}
          <div className="row">
            <div className="columns small-12">
              <Button
                data-testid="login-in-person-submit"
                onClick={this.onSubmit}
                buttonClass="right"
                buttonText={this.props.intl.formatMessage(messages.continueBtn)}
                buttonColor="ocean-blue"
                buttonHoverColor="castle-rock"
                buttonTabIndex="1"
              />
            </div>
          </div>
          <input type="hidden" name="short_guid" value={this.props.guid} />
        </form>
        {errors}
      </div>
    );
  },
});

module.exports = injectIntl(LogInInPerson);
