import React from 'react';
import { ORIGIN_PAGE } from 'signer-app/signature-request/constants';
import {
  signatureRequestContext,
  ZoomContextShape,
  zoomContext,
} from 'signer-app/signature-request/context';

export interface PositionContextShape {
  toScreenCoords: ZoomContextShape['toScreenCoords'];
}

export const positionContext = React.createContext({} as PositionContextShape);

// :'( .toFixed returns a string, so it needs to be converted back to a number
const toFixed = (n: number) => Number(n.toFixed(4));

export default function PositionContext({
  children,
}: React.PropsWithChildren<object>) {
  // Grab the whole context here, because if sfContext changes, value needs to
  // be recomputed. I tried just pulling `toScreenCoords` out of this, but the
  // fields get out of sync with the view.
  const srContext: any = React.useContext(signatureRequestContext);
  const { toScreenCoords } = React.useContext(zoomContext);
  const parent = React.useContext(positionContext);

  const value = React.useMemo((): PositionContextShape => {
    return {
      toScreenCoords(address, origin) {
        const pages = srContext.pages;
        if (origin === ORIGIN_PAGE) {
          const page = pages[address.pageIndex];

          const x = (address.x / page.width) * 100;
          const y = (address.y / page.height) * 100;
          const width = (address.width / page.width) * 100;
          const height = (address.height / page.height) * 100;
          const documentId = pages[address.pageIndex].documentId;

          return {
            pageIndex: address.pageIndex,
            x: `${toFixed(x)}%`,
            y: `${toFixed(y)}%`,
            width: `${toFixed(width)}%`,
            height: `${toFixed(height)}%`,
            documentId,
          };
        }

        // If there is a PositionContext above this one, let it handle any
        // positions that this context can't.
        if (parent && parent.toScreenCoords) {
          return parent.toScreenCoords(address, origin);
        }

        // If there is no higher PositionContext, use the toScreenCoords from
        // zoomContext
        return toScreenCoords(address, origin);
      },
    };
  }, [parent, srContext.pages, toScreenCoords]);

  return (
    <positionContext.Provider value={value}>
      {children}
    </positionContext.Provider>
  );
}
