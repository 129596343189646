import {
  validatePrepAndSendRequest,
  validateEditorData,
  validateTemplate,
} from 'js/sign-components/generated/validators/validateHelloRequest';
import { ErrorObject, DefinedError, ValidateFunction } from 'ajv';

function parseErrorObject(err: ErrorObject): string {
  // https://ajv.js.org/api.html#error-parameters
  // DefinedError is a type for all pre-defined keywords errors, validate.errors
  // has type ErrorObject[] - to allow user-defined keywords with any error
  // parameters.  Users can extend DefinedError to include the keywords errors
  // they defined.
  switch ((err as DefinedError).keyword) {
    default: {
      const { instancePath, message, ...rest } = err;
      return `${instancePath} ${message} [${JSON.stringify(rest)}]`;
    }
  }
}
/**
 * Basic validators should throw when there is an error
 */

export type BasicValidator<T> = (data: any) => data is T;
/**
 * The errors this throws is not intended to show to users. There is nothing
 * they can do about schema-level errors.
 */

export function assertDataIsValid<T>(
  validator: ValidateFunction<T> | BasicValidator<T>,
  data: unknown,
): asserts data is T {
  if (!validator(data)) {
    if ('errors' in validator) {
      // eslint-disable-next-line no-restricted-syntax
      for (const err of validator.errors ?? []) {
        throw new Error(parseErrorObject(err));
      }
    } else {
      // If you're using a BasicValidator, it should throw when there's an
      // error.  If you're using a validator from `npm run generate:types`, then
      // it doesn't throw, but will attach that .errors array you see above.
      throw new Error('validator failed without providing any error');
    }
  }
}
// These functions were autogenerated by the script located at
// `scripts/generate-json-validators`
// so they aren't exactly well-formatted enough to be debug-friendly
// Please use the schemas located at schemas/HelloRequest, to debug validation errors.
export { validatePrepAndSendRequest, validateTemplate, validateEditorData };
