import delay from 'hellospa/common/utils/delay';
import hsFetch from 'js/sign-components/common/hs-fetch';

/**
 * Polls transmissionStatus endpoint to determine if the signed document is ready.
 * Used in the gmail integration.
 * @returns True if the signed document is ready, false if the poll timed out.
 */
export async function pollTransmissionStatus(
  superGroupGuid: string,
): Promise<boolean> {
  /* eslint-disable no-await-in-loop */
  // Disabling no-await-in-loop because we are polling an endpoint.
  const MAX_POLL_COUNT = 50;

  for (let i = 0; i < MAX_POLL_COUNT; i++) {
    const transmissionStatusResponse = await hsFetch(
      `/home/transmissionStatus?guid=${superGroupGuid}`,
    );

    if (!transmissionStatusResponse.ok) {
      throw new Error(
        `transmissionStatus responded with an error: ${await transmissionStatusResponse.text()}`,
      );
    }

    const transmissionStatusJson = await transmissionStatusResponse.json();
    if (transmissionStatusJson.conv_ready === true) {
      return transmissionStatusJson.conv_ready;
    }

    await delay(1000, 0);
  }

  return false;
}

/**
 * Calls readyCallback endpoint and then closes the window.
 * Used in the gmail integration.
 */
export async function sendReadyCallback(superGroupGuid: string): Promise<void> {
  await hsFetch(
    `/send/readyCallback?guid=${superGroupGuid}&c=${Math.random()}`,
  );
  // Mimics the old behavior of the gmail addon flow.
  window.close();
}
