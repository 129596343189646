import * as Yup from 'yup';
import { SignerFile } from './file';

import { Actions } from './index';

export type ExampleFields = {
  fields: string[];
};

export type BulkSendInitFormData = {
  lockedTitle?: string | null;
  lockedMessage?: string | null;
};

export type BulkSendInitResponse = {
  signerKey: string;
  isCcEnabled: boolean;
  form?: BulkSendInitFormData;
};

export type BulkSendData = {
  exampleFields: string[];
  signerFile: SignerFile | null;
  dataKey: string;
};

export const bulkSendDataSchema = Yup.object<BulkSendData>({
  exampleFields: Yup.array().of(Yup.string()).ensure(),
  signerFile: Yup.object<SignerFile>().nullable().default(null),
  dataKey: Yup.string(),
});

export type ValidateBulkSendRequest = {
  signerKey: string;
  signerFile: {
    tmpName: string;
  };
  templateIds: string[];
};

export interface SetBulkSendData {
  type: Actions.SetBulkSendData;
  payload: Partial<BulkSendData>;
}

export type BulkSendActions = SetBulkSendData;

export type BulkSendEducationStatusResponse = {
  success: boolean;
  isAcknowledged: boolean;
};
