/* eslint-disable no-void */
import React from 'react';
import style from 'signer-app/parts/loader/index.module.css';

type Props = React.PropsWithChildren<{
  className?: string;
}>;
type State = {
  numCircles: number;
  currentCircleIndex: number;
  circleRadius: number;
};

export default class Loader extends React.Component<Props, State> {
  private _timer: ReturnType<typeof setInterval> | undefined;

  state = {
    numCircles: 5,
    currentCircleIndex: 0,
    circleRadius: 10,
  };

  dots = React.createRef<SVGGElement>();

  componentDidMount() {
    const numBubbles =
      this.dots.current?.querySelectorAll('circle').length ?? 0;
    let i = 0;

    this._timer = setInterval(() => {
      this.setState({
        currentCircleIndex: i,
      });
      i = (i + 1) % numBubbles;
    }, 500);
  }

  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
    }
  }

  render() {
    const circles = [];
    const radius = this.state.circleRadius;
    const circWidth = radius * 2;
    const padding = radius;

    // width and padding
    const wandp = circWidth + padding;

    for (let i = this.state.numCircles; i--; ) {
      circles.push(
        <circle
          key={`circle${i}`}
          className={
            i === this.state.currentCircleIndex ? style.currentDot : void 0
          }
          cx={wandp * i + radius}
          cy={radius}
          r={radius}
        ></circle>,
      );
    }

    const width = wandp * circles.length - padding;
    let classNames = `${style.loaderDots} whitelabel-dot-loader`;

    if (this.props.className) {
      classNames += ` ${this.props.className}`;
    }

    return (
      <div className={classNames}>
        <svg width={'100%'} viewBox={`0 0 ${width} ${circWidth}`} version="1.1">
          <g ref={this.dots}>{circles}</g>
        </svg>
        {this.props.children ? (
          <span className={style.message}>{this.props.children}</span>
        ) : (
          void 0
        )}
      </div>
    );
  }
}
