/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  max-len,
  no-nested-ternary,
  no-restricted-properties,
  no-restricted-syntax,
  one-var,
  react/no-find-dom-node
*/
import React from 'react';
import PropTypes from 'prop-types';
import { extend } from 'lodash';
import ReactDOM from 'react-dom';
import DOM from 'react-dom-factories';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import createReactClass from 'create-react-class';

const FormHiddenField = createReactClass({
  propTypes: {
    name: PropTypes.string,
    value: PropTypes.string,
  },

  render() {
    // Cannot pass null to React input fields. Either pass in undefined for
    // uncontrolled components or an empty string for controlled components
    const value = this.props.value || undefined;

    return <input type="hidden" name={this.props.name} value={value} />;
  },
});

const FormField = createReactClass({
  propTypes: {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    attributes: PropTypes.object,
    options: PropTypes.object,
    fields: PropTypes.array,
    values: PropTypes.object,
  },

  unescapeHTML(html) {
    const escapeEl = document.createElement('textarea');
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  },

  // Populates <option> children for a select element
  // Assumes that the data coming from Symfony is formatted as either:
  // type 'select': [value,value,value] (array index is value)
  // type 'hfwidgetformchoice': [{ value: value, label: label},...]
  // Use the latter when you need to preserve the ordering of the list
  generateSelectOptions() {
    const self = this;
    let options = [];
    let choices, choicesAsOrderedObjects;
    if (this.props.type === 'hfwidgetformchoice') {
      choices = this.props.options.ordered_choices;
      choicesAsOrderedObjects = true;
    } else {
      choices = this.props.options.choices;
    }

    if (Array.isArray(choices)) {
      options = choices.map((item, index) => {
        let value, label;
        if (choicesAsOrderedObjects) {
          value = item.value;
          label = item.label;
        } else {
          value = index;
          label = item;
        }

        return (
          <option key={`option-value-${index}`} value={value}>
            {self.unescapeHTML(label)}
          </option>
        );
      });
    } else if (typeof choices === 'object') {
      options = Object.keys(choices).map((key) => {
        return (
          <option key={key} value={key}>
            {self.unescapeHTML(choices[key])}
          </option>
        );
      });
    }
    return options;
  },

  render() {
    let tag;
    let children;
    const attrs = this.props.attributes || {};

    switch (this.props.type) {
      case 'input':
        attrs.type = 'text';
        // Cannot pass null to React input fields. Either pass in undefined for
        // uncontrolled components or an empty string for controlled components
        attrs.value = attrs.value || this.props.value || undefined;
        attrs.name = this.props.name;
        tag = DOM.input;
        break;

      case 'inputcheckbox':
        attrs.type = 'checkbox';
        attrs.checked =
          attrs.checked != null
            ? attrs.checked
            : this.props.value
              ? 'checked'
              : undefined;
        attrs.name = this.props.name;
        tag = DOM.input;
        break;

      case 'inputfile':
        attrs.type = 'file';
        attrs.value = attrs.value || this.props.value;
        attrs.name = this.props.name;
        tag = DOM.input;
        break;

      case 'inputpassword':
        attrs.type = 'password';
        // Cannot pass null to React input fields. Either pass in undefined for
        // uncontrolled components or an empty string for controlled components
        attrs.value = attrs.value || this.props.value || undefined;
        attrs.name = this.props.name;
        tag = DOM.input;
        break;

      case 'select':
      case 'choice':
      case 'hfwidgetformchoice':
      case 'selectmany':
        attrs.value = attrs.value || this.props.value || undefined;
        attrs.name = this.props.name;
        if (this.props.type == 'selectmany') {
          attrs.multiple = 'multiple';
        }
        children = this.generateSelectOptions();
        tag = DOM.select;
        break;

      case 'selectradio':
        attrs.type = 'radio';
        attrs.value = attrs.value || this.props.value;
        attrs.name = this.props.name;
        tag = DOM.input;
        break;

      case 'textarea':
        // Cannot pass null to React textarea fields. Either pass in
        // undefined for uncontrolled components or an empty string for
        // controlled components
        attrs.value = attrs.value || this.props.value || undefined;
        attrs.name = this.props.name;
        tag = DOM.textarea;
        break;

      default:
        throw new Error(`Form field type not supported: ${this.props.type}`);
    }

    return tag(attrs, children); // Tag function is selected from ReactDOM above
  },
});

const BaseFormSpecs = {
  displayName: 'CustomForm',

  propTypes: {
    name: PropTypes.string, // Form name
    method: PropTypes.string, // Form method (POST, GET, ...)
    action: PropTypes.string, // Form target
    fields: PropTypes.array, // name => field info
    values: PropTypes.object, // name => value
    errors: PropTypes.object, // { 'global': [...], 'named': { name => message } }
    namespace: PropTypes.string, // Namespace pattern for field names
  },

  getInitialState() {
    return {
      errors: this.props.errors,
      hasErrors:
        this.props.errors &&
        (this.props.errors.global || this.props.errors.named),
    };
  },

  getFieldName(field) {
    if (field) {
      const token =
        (field.options ? field.options.id_format : undefined) || '%s';
      return this.props.namespace.replace(token, field.name);
    }
  },

  getFieldValue(field) {
    let v = this.props.values ? this.props.values[field.name] : undefined;
    if (v === undefined && field.default) {
      v = field.default;
    }
    return v;
  },

  /**
        Grabs the user-entered value using the React refs for the field
        @param string fieldname - the non-namespaced name of the field
        @return mixed | undefined;
    */
  getDOMValueForFieldname(fieldname) {
    let field, value;

    const filtered = this.props.fields.filter(
      (field) => field.name === fieldname,
    );
    if (filtered.length > 0) {
      field = filtered[0];
    }

    if (field) {
      const namespacedName = this.getFieldName(field);
      const node = ReactDOM.findDOMNode(this.refs[namespacedName]);
      if (node) {
        if (node.type === 'checkbox') {
          value = node.checked;
        } else {
          value = node.value;
        }
      }
    }
    return value;
  },

  renderHiddenFields() {
    const hiddenFields = [];
    let k;
    let v;
    let t;
    let field;

    // Render hidden input fields
    // FIXME: calling "in" operator on array. This will break with es5 shims (CC)
    for (k in this.props.fields) {
      field = this.props.fields[k];
      if (field && field.options.is_hidden) {
        v = this.getFieldValue(field);
        t = parseInt(Math.random() * Math.pow(10, 6), 10);
        hiddenFields.push(
          <FormHiddenField
            key={`hidden-field-${t}`}
            name={this.getFieldName(field)}
            value={v}
          ></FormHiddenField>,
        );
      }
    }

    return hiddenFields;
  },

  renderCsrfField() {
    let k;
    let v;
    let field;

    // FIXME: calling "in" operator on array. This will break with es5 shims (CC)
    for (k in this.props.fields) {
      field = this.props.fields[k];

      if (field && field.name === HfReactHelper.HfConstants.csrfFieldName) {
        v = this.getFieldValue(field);
        return (
          <FormHiddenField
            name={this.getFieldName(field)}
            value={v}
          ></FormHiddenField>
        );
      }
    }
  },

  renderField(name, attributes) {
    if (this.props.fields) {
      let field;
      let k;

      for (k in this.props.fields) {
        if (this.props.fields[k] && this.props.fields[k].name === name) {
          field = this.props.fields[k];
          break;
        }
      }

      if (field) {
        if (field.attributes) {
          // Rewrite some of the attributes to deal with React
          for (k in field.attributes) {
            if (k === 'class') {
              field.attributes.className = field.attributes.class;
              delete field.attributes.class;
            }
          }
        }

        const v = this.getFieldValue(field);

        return (
          <FormField
            name={this.getFieldName(field)}
            ref={this.getFieldName(field)}
            type={field.type}
            value={v}
            options={field.options}
            attributes={attributes}
          ></FormField>
        );
      }
    }
  },

  renderErrorText() {
    if (this.state.errors) {
      if (this.state.errors.global) {
        return <p>{this.state.errors.global}</p>;
      } else if (this.state.errors.named) {
        const errs = [];
        for (const k in this.state.errors.named) {
          if (this.state.errors.named[k]) {
            errs.push(<p key={`error-${k}`}>{this.state.errors.named[k]}</p>);
          }
        }
        return errs;
      }
    }
  },

  renderFormTag(formContent) {
    return (
      <form
        name={this.props.name}
        method={this.props.method}
        action={this.props.action}
      >
        {formContent}
      </form>
    );
  },

  getFormData() {
    // Should be implemented by the actual form component
    return null;
  },

  submitAsync(okCallback, errCallback) {
    let data = this.getFormData();

    if (data === null) {
      data = {};
      $('input, select, textarea', ReactDOM.findDOMNode(this)).each(
        function () {
          const el = $(this);
          const name = el.attr('name');
          if (name) {
            if (el.attr('type') === 'checkbox') {
              let isChecked = false;
              if (typeof el.prop === 'function') {
                if (el.prop('checked')) {
                  isChecked = true;
                }
              } else if (
                el.attr('checked') === 'checked' ||
                el.attr('checked') === true
              ) {
                isChecked = true;
              }
              data[name] = isChecked;
            } else {
              data[name] = el.val();
            }
          }
        },
      );
    }

    $.ajax({
      context: this,
      url: this.props.action,
      type: this.props.method || 'POST',
      dataType: 'json',
      data,
      success: okCallback,
      error: errCallback,
    });
  },
};

exports.createFormClass = function (specs = {}) {
  // Copy to prevent the original from being altered
  const baseSpecs = extend({}, BaseFormSpecs);

  if (specs.getInitialState) {
    const baseGetInitialStates = baseSpecs.getInitialState;
    const extGetInitialStates = specs.getInitialState;
    specs.getInitialState = function () {
      const baseStates = baseGetInitialStates.call(this);
      const extStates = extGetInitialStates.call(this);
      return extend(baseStates, extStates);
    };
  }

  if (specs.propTypes) {
    const basePropTypes = extend({}, baseSpecs.propTypes); // Copy
    specs.propTypes = extend(basePropTypes, specs.propTypes);
  }

  const comp = createReactClass(extend(baseSpecs, specs));

  return comp;
};
