/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  guard-for-in,
  max-len,
  no-restricted-syntax,
  prefer-rest-params,
  prefer-spread
*/
import Model from './model';

function Collection(properties) {
  // collections CANNOT be an instanceof Array if the "new" operator is involved. See below create() implementation.
  if (!(this instanceof Array)) {
    throw new Error('you must instantiate collections via Collection.create()');
  }

  Model.call(this, properties);
}

Object.assign(Collection.prototype, Array.prototype, Model.prototype, {
  length: 0,
  modelClass: Model,

  // DEPRECATED  - use bracket syntax instead
  at(index) {
    return this[index];
  },
  createModel(properties) {
    const Model = this.modelClass;

    // use factory method if it exists (newer). Otherwise use older method here
    return Model.create ? Model.create(properties) : new Model(properties);
  },
  toJSON() {
    return this.toData();
  },
  toData() {
    return this.map((item) => {
      return item && item.toJSON ? item.toJSON() : item;
    });
  },
  onDataChange(data) {
    this.mergeSourceData(data);
  },
  mergeSourceData(data) {
    const mergedSource = [];

    for (let i = 0, n = data.length; i < n; i++) {
      const newItem = data[i];

      // always create a new model so we can do a proper comparison
      let model = this.createModel({ data: newItem });

      // check existing models to see if we need to update anything
      for (let j = this.length; j--; ) {
        const existingItem = this[j];

        // item found? Use that instance instead
        if (existingItem.equals(model)) {
          existingItem.setProperties({ data: newItem });
          model = existingItem;
          break;
        }
      }

      mergedSource.push(model);
    }

    const removedItems = this.filter((item) => {
      return mergedSource.indexOf(item) === -1;
    });

    this.splice.apply(this, [0, this.length].concat(mergedSource));

    removedItems.forEach((item) => {
      item.dispose();
    });
  },
  dispose() {
    Model.prototype.dispose.call(this);
    for (const i of this) this[i].dispose();
    return this;
  },
});

Collection.extend = Model.extend;

/**
 * creates a new observable collection. This should be used instead of the 'new' operator.
 */

Collection.create = function () {
  const context = [];
  for (const k in this.prototype) context[k] = this.prototype[k];
  this.apply(context, arguments);
  return context;
};

module.exports = Collection;
