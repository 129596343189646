/**
 * Used to delay the execution of a function
 * You can add some randomness in the delay time with the second param
 * Both params are in miliseconds (1000 = 1 second)
 *
 * @param {number} delayTime
 * @param {number|undefined} randomnessTimeframe
 */
export const delayPromise = (delayTime: number, randomnessTimeframe?: number) =>
  new Promise((resolve) => {
    const randomness = randomnessTimeframe
      ? Math.floor(Math.random() * randomnessTimeframe)
      : 0;

    return setTimeout(resolve, delayTime + randomness);
  });
