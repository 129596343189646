import React from 'react';
import { makeContextCacher } from 'signer-app/utils/legacy-context-utilities';
import {
  useSignerAppClient,
  SignerAppClient,
} from 'signer-app/context/signer-app-client';
import { OnSignatureDataType } from 'signer-app/signature-modal/signature-modal-context/signature-provider-intercept';

import LoaderSpinner from 'signer-app/parts/loader-spinner';
import { attachSessionInfoToUrl } from 'signer-app/utils/url-helpers';
import {
  SignatureModalContextProvider,
  SignatureModalContext,
  SignatureModalTab,
} from 'signer-app/signature-modal/signature-modal-context/context';
import CONSTANTS from 'signer-app/signature-modal/constants';
import {
  CreateTypeCode,
  SignatureTypeCode,
} from 'signer-app/types/signature-types';
import invariant from 'invariant';
import { identity } from 'lodash';
import { SigData } from 'signer-app/context/signer-app-client/signature';

type UploadData = Parameters<
  SignerAppClient['signatures']['uploadSignatureFile']
>[0];

type UserSettings = {
  firstName: string;
  lastName: string;
};

type ProviderProps = {
  isMobile: boolean;
  signature?: SigData;
  primarySignatureGuid: string | undefined;
  firstName: string;
  lastName: string;
  appContext: SignerAppClient;
  children: React.ReactNode;
  defaultSignatureType?: CreateTypeCode;
  allowedSignatureTypes?: Record<SignatureModalTab, boolean>;
  hideSpinner: boolean;
  isEmbedded: boolean;
  isDropbox: boolean;
  canInsertEverywhere: boolean;
  allowColorSig: boolean;
  confirmDeleteMessage?: string;
};

type ProviderState = Pick<
  SignatureModalContext,
  | 'signaturesCount'
  | 'initialsCount'
  | 'savedSignatures'
  | 'savedInitials'
  | 'selectedSavedSignature'
  | 'isUploadingSignature'
  | 'uploadedSignature'
  | 'initialsInitialTypeInValue'
  | 'signatureInitialTypeInValue'
  | 'selectedTab'
  | 'enabledTabs'
  | 'isEmbedded'
  | 'isDropbox'
  | 'canInsertEverywhere'
  | 'error'
>;

export type FromSignaturesData = {
  /* eslint-disable camelcase */
  type_code: SignatureTypeCode;
  create_type_code: CreateTypeCode;
  signature_id: string;

  created_at: string;
  last_used_at: string | null;
  width: number;
  height: number;
  signature?: SigData['signature'];

  /* eslint-enable camelcase */
};

type EmptyProviderState = {
  signaturesCount: never;
  initialsCount: never;
  savedSignatures: never;
  savedInitials: never;
  selectedSavedSignature: never;
  isUploadingSignature: never;
  uploadedSignature: never;
  initialsInitialTypeInValue: never;
  signatureInitialTypeInValue: never;
  selectedTab: never;
  enabledTabs: never;
  isEmbedded: never;
  isDropbox: never;
  canInsertEverywhere: never;
  error: never;
};

/**
 * <SignatureProvider holds the data and handles some of the logic for how the
 * signature modal works. It was created when I was building the Editor because
 * the signature moal required a lot of data that I didn't want to drill down
 * all the way to the signature field on the page.
 *
 * I think this could be done a lot better if we try replacing the signature
 * modal from top to bottom. Instead of this split context thing, the new
 * implementation can just fetch its own data.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535230557410&cot=14
 */
class SignatureProvider extends React.PureComponent<
  ProviderProps,
  EmptyProviderState | ProviderState
> {
  retries = 0;

  contextCache = makeContextCacher() as <T>(t: T) => T;

  /** Convert signature data list to signatures list */
  fromSignaturesData(data: FromSignaturesData[]): SigData[] {
    const signatures: SigData[] = [];
    if (data) {
      return data.map((item) => ({
        x: 0,
        y: 0,
        ...item,
        type: item.type_code,
        createType: item.create_type_code,
        guid: item.signature_id,
        createdAt: item.created_at
          ? new Date(Number(item.created_at) * 1000)
          : null,
        lastUsedAt: item.last_used_at
          ? new Date(Number(item.last_used_at) * 1000)
          : null,
        width: item.width,
        height: item.height,
        signature: item.signature || null,
      }));
    }
    return signatures;
  }

  getInitialValue(userSettings: UserSettings, type = 'signature') {
    let initialValue = '';
    if (userSettings.firstName) {
      initialValue +=
        type === 'signature'
          ? userSettings.firstName
          : userSettings.firstName[0];
    }

    if (userSettings.lastName) {
      initialValue +=
        type === 'signature'
          ? ` ${userSettings.lastName}`
          : userSettings.lastName[0];
    }

    return initialValue;
  }

  async componentDidMount() {
    let initiallySelectedSignature: SigData | undefined;
    let signatures: SigData[] = [];
    const { primarySignatureGuid, firstName, lastName } = this.props;

    signatures = await this.getPaginatedSignatures(CONSTANTS.TYPE_SIGNATURE);
    this.getPaginatedSignatures(CONSTANTS.TYPE_INITIALS);
    const selected = primarySignatureGuid;
    // eslint-disable-next-line prefer-const
    initiallySelectedSignature = signatures.find(
      (item) => item.guid === selected,
    );

    // allowed signature types are passed in as an object with key as the type and a boolean value,
    const allowedSignatureTypes =
      this.props.allowedSignatureTypes != null
        ? (
            Object.keys(this.props.allowedSignatureTypes) as SignatureModalTab[]
          ).filter((t) => {
            return this.props.allowedSignatureTypes![t] === true;
          })
        : [...CONSTANTS.TABS];

    let defaultTab: SignatureModalTab = CONSTANTS.TAB_SAVED;
    // if it's embedded, don't default to the Saved Signatures tab
    if (this.props.isEmbedded && !this.props.isDropbox) {
      // check if the default tab is part of the allowed signature types
      if (allowedSignatureTypes.includes(CONSTANTS.TAB_DRAW)) {
        defaultTab = CONSTANTS.TAB_DRAW;
      } else {
        // get the first type that's not the saved signatures tab
        defaultTab = allowedSignatureTypes.find(
          (type) => type !== CONSTANTS.TAB_SAVED,
        )!;
      }
    }

    // check if the default type is part of allowed types
    const defaultSignatureType =
      this.props.defaultSignatureType &&
      allowedSignatureTypes.includes(this.props.defaultSignatureType)
        ? this.props.defaultSignatureType
        : defaultTab;

    this.setState({
      // Set initial data
      savedSignatures: signatures,
      selectedSavedSignature: initiallySelectedSignature || signatures[0],
      // Default states
      initialsInitialTypeInValue: this.getInitialValue(
        { firstName, lastName },
        'initial',
      ),
      signatureInitialTypeInValue: this.getInitialValue({
        firstName,
        lastName,
      }),
      isUploadingSignature: false,
      uploadedSignature: null,
      selectedTab: defaultSignatureType,
      enabledTabs: allowedSignatureTypes,
      isEmbedded: this.props.isEmbedded,
      isDropbox: this.props.isDropbox,
      canInsertEverywhere: this.props.canInsertEverywhere,
      error: null,
    });
  }

  async componentDidUpdate(prevProps: ProviderProps) {
    if (
      prevProps.allowedSignatureTypes !== this.props.allowedSignatureTypes ||
      prevProps.defaultSignatureType !== this.props.defaultSignatureType
    ) {
      const allowedSignatureTypes =
        this.props.allowedSignatureTypes != null
          ? (
              Object.keys(this.props.allowedSignatureTypes) as CreateTypeCode[]
            ).filter((t) => {
              return this.props.allowedSignatureTypes?.[t] === true;
            })
          : [...CONSTANTS.TABS];

      let defaultTab: SignatureModalTab = CONSTANTS.TAB_SAVED;
      // if it's embedded, don't default to the Saved Signatures tab
      if (this.props.isEmbedded && !this.props.isDropbox) {
        // Embedded is not supposed to have the saved signatures tab
        const savedIndex = allowedSignatureTypes.indexOf(CONSTANTS.TAB_SAVED);
        if (savedIndex >= 0) {
          allowedSignatureTypes.splice(savedIndex, 1);
        }

        // check if the default tab is part of the allowed signature types
        if (allowedSignatureTypes.includes(CONSTANTS.TAB_DRAW)) {
          defaultTab = CONSTANTS.TAB_DRAW;
        } else {
          // get the first type that's not the saved signatures tab
          defaultTab = allowedSignatureTypes.find(
            (type) => type !== CONSTANTS.TAB_SAVED,
          )!;
        }
      }

      // check if the default type is part of allowed types
      const defaultSignatureType =
        this.props.defaultSignatureType &&
        allowedSignatureTypes.includes(this.props.defaultSignatureType)
          ? this.props.defaultSignatureType
          : defaultTab;

      this.setState({
        enabledTabs: allowedSignatureTypes,
        selectedTab: defaultSignatureType || 'C',
      });
    }
  }

  handleSelectTab = (selectedTab: SignatureModalContext['selectedTab']) =>
    this.setState({ selectedTab });

  getSignatureUrl = (
    signature: { guid: string },
    threshold?: number,
    degrees?: number,
  ) => {
    let thresholdAndDegrees = '';
    if (threshold !== undefined && degrees !== undefined) {
      thresholdAndDegrees = `&threshold=${threshold}&degrees=${degrees}`;
    }
    let makeColorSig = '';
    if (this.props.allowColorSig === true) {
      makeColorSig = '&allow_color_signature=true';
    }
    // Maybe this should be moved into attachmentURL?
    const withSession = attachSessionInfoToUrl(
      `/attachment/view?sig_guid=${signature.guid}${thresholdAndDegrees}${makeColorSig}`,
    );

    return this.props.appContext.signatures.signatureURL(
      withSession,
      signature.guid,
    );
  };

  onSavedSignatureRemove = async (signature: SigData) => {
    try {
      if (
        this.props.confirmDeleteMessage &&
        // eslint-disable-next-line no-alert
        !window.confirm(this.props.confirmDeleteMessage)
      ) {
        return;
      }

      const success = await this.props.appContext.signatures.removeSignature(
        signature.guid,
      );
      if (success) {
        if (signature.type === CONSTANTS.TYPE_SIGNATURE) {
          this.setState({
            savedSignatures: this.state.savedSignatures.filter(
              (s) => s !== signature,
            ),
          });
        } else {
          this.setState({
            savedInitials: this.state.savedInitials.filter(
              (s) => s !== signature,
            ),
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({
        error: error as Error,
      });
    }
  };

  onSavedSignatureSelect = (selectedSavedSignature: SigData) => {
    this.setState({
      selectedSavedSignature,
    });
  };

  onRotateSignature = (signature: SigData) => {
    return this.props.appContext.signatures.rotateSignature(
      signature.guid,
      CONSTANTS.ROTATE_DEGREES_AMOUNT,
    );
  };

  onUploadSignature = async (file: File, type: SignatureTypeCode) => {
    this.setState({ isUploadingSignature: true });

    const upload: UploadData = {
      file,
      type,
      allowColorSig: this.props.allowColorSig,
      createType: CONSTANTS.SIGNATURE_TYPE_UPLOAD,
    };
    try {
      const response =
        await this.props.appContext.signatures.uploadSignatureFile(upload);
      const sig: SigData = {
        guid: response.signature_id,
        width: response.width,
        height: response.height,
        create_type_code: 'U',
        type,
        type_code: type,
        signature: {
          image: '',
        },
        x: 0,
        y: 0,
      };
      sig.signature!.image = this.getSignatureUrl(sig);

      this.setState({
        uploadedSignature: sig,
        isUploadingSignature: false,
      });
    } catch (error) {
      this.setState({
        error: error as Error,
      });
    }
  };

  createNewSignature = (data: OnSignatureDataType): Promise<SigData> => {
    const uploadFunc =
      data.create_type_code === CONSTANTS.SIGNATURE_TYPE_CANVAS
        ? this.props.appContext.signatures.uploadCanvas
        : this.props.appContext.signatures.upload;
    return uploadFunc(data)
      .then((obj): SigData => {
        if (obj) {
          const signatures = this.fromSignaturesData(
            // I know this works, but I'm not sure why the types aren't fully
            // lining up.
            [obj] as any[],
          );
          if (typeof obj.signature_id !== 'undefined') {
            // Replace signature_id with signature_guid,
            // because that's how we expect it in the code later
            obj.signature_guid = obj.signature_id;
            // @ts-expect-error DPC_REMOVE
            delete obj.signature_id;
          }
          // At this point, an uploaded signature needs a guid
          // and we can't recover from this
          invariant(
            obj.signature_guid != null,
            "Uploaded signature but could not determine it's guid",
          );
          if (data.type_code === CONSTANTS.TYPE_SIGNATURE) {
            this.setState({
              savedSignatures: signatures,
            });
          } else {
            this.setState({
              savedInitials: signatures,
            });
          }
          return {
            create_type_code: data.create_type_code,
            type_code: data.type_code,
            type: data.type_code,
            guid: obj.signature_guid,
            height: obj.height,
            signature: {
              image: this.getSignatureUrl({ guid: obj.signature_guid }),
            },
            width: obj.width,
            x: 0,
            y: 0,
          };
        }
        throw new Error('Missing upload response');
      })
      .catch((error) => {
        this.setState({
          error,
        });
        throw error;
      });
  };

  clearUploadedSignature = () => {
    this.setState({
      uploadedSignature: null,
    });
  };

  clearSignatureError = () => {
    this.setState({
      error: null,
    });
  };

  getPaginatedSignatures = async (
    type: typeof CONSTANTS.TYPE_SIGNATURE | typeof CONSTANTS.TYPE_INITIALS,
  ) => {
    const { list, count } =
      await this.props.appContext.signatures.fetchSignatures(type);
    const data = this.fromSignaturesData(list);

    if (type === CONSTANTS.TYPE_INITIALS) {
      this.setState({
        savedInitials: data,
        initialsCount: count,
      });
    } else {
      this.setState({
        savedSignatures: data,
        signaturesCount: count,
      });
    }
    return data;
  };

  render() {
    if (this.state?.savedSignatures == null && !this.props.hideSpinner) {
      return <LoaderSpinner />;
    }

    const {
      signaturesCount,
      initialsCount,
      savedSignatures,
      savedInitials,
      selectedSavedSignature,
      isUploadingSignature,
      uploadedSignature,
      initialsInitialTypeInValue,
      signatureInitialTypeInValue,
      selectedTab,
      enabledTabs,
      isEmbedded,
      isDropbox,
      canInsertEverywhere,
      error,
    } = this.state || ({} as ProviderState);

    const value = this.contextCache(
      identity<SignatureModalContext>({
        getSignatureUrl: this.getSignatureUrl,
        onSavedSignatureRemove: this.onSavedSignatureRemove,
        onSavedSignatureSelect: this.onSavedSignatureSelect,
        onRotateSignature: this.onRotateSignature,
        onUploadSignature: this.onUploadSignature,
        createNewSignature: this.createNewSignature,
        onTabSelect: this.handleSelectTab,
        clearUploadedSignature: this.clearUploadedSignature,
        getPaginatedSignatures: this.getPaginatedSignatures,
        clearSignatureError: this.clearSignatureError,
        signaturesCount,
        initialsCount,
        savedSignatures,
        savedInitials,
        selectedSavedSignature,
        // isMobile: this.props.isMobile || false,
        isUploadingSignature,
        uploadedSignature,
        initialsInitialTypeInValue,
        signatureInitialTypeInValue,
        selectedTab,
        enabledTabs,
        isEmbedded,
        isDropbox,
        canInsertEverywhere,
        error,
      }),
    );

    return (
      <SignatureModalContextProvider value={value}>
        {this.props.children}
      </SignatureModalContextProvider>
    );
  }
}

export default function SignatureProviderImplWrapper(
  props: Omit<ProviderProps, 'appContext'>,
) {
  const appContext = useSignerAppClient();
  return <SignatureProvider {...props} appContext={appContext} />;
}
