declare global {
  const IS_STORYBOOK: string;
  const NODE_ENV: string;
  const WDYR_ENABLED: string;
  const SENTRY_ENVIRONMENT: string;
  const SENTRY_RELEASE: string;
  const COMMIT_HASH: string;
}

const runtimeEl = document.getElementById('hs-env');
const runtimeEnvironment: Record<string, string> = runtimeEl
  ? JSON.parse(runtimeEl.innerHTML)
  : {};

/**
 * I only put values in because some tests rely on them. In the webapp
 * everything should be supplied through hs-env
 */
/* eslint-disable import/no-mutable-exports, prefer-const */
export let {
  /* eslint-disable no-restricted-syntax */
  cdnURL = 'cdn.dev-hellosign.com',
  hfHost = 'app.dev-hellofax.com',
  hsHost = 'app.dev-hellosign.com',
  hwHost = 'app.dev-helloworks.com',
  hsName = 'Dropbox Sign',
  hfName = 'Dropbox Fax',
  hwName = 'Dropbox Forms',
  dbxName = 'Dropbox',

  hfSupportEmail = 'support@hellofax.com',
  hsSupportEmail = 'support@hellosign.com',
  hwSupportEmail = 'hwsupport@hellosign.com',
  sentryDSN = '(missing)',
  sentryEnvironment = '(missing)',
  developersSite = '(missing)',
} = runtimeEnvironment;

export function forceHFHost(host: string) {
  hfHost = host;
}
export function forceHSHost(host: string) {
  hsHost = host;
}
