import 'signer-app/screenreaders.module.css';
import styles from 'signer-app/signature-modal/upload/upload.module.css';

import React from 'react';
import { Text } from '@dropbox/dig-components/typography';
import { Button } from '@dropbox/dig-components/buttons';
import Dropzone, { FileRejection } from 'react-dropzone';
import classnames from 'classnames';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import CONSTANTS from 'signer-app/signature-modal/constants';
import * as signatureTypes from 'signer-app/signature-modal/constants/signature-types';
import SignatureEditArea from 'signer-app/signature-modal/upload/signature-edit-area';
import { Bounds } from 'signer-app/signature-modal/upload/croppable';
import UploadProgressBar from 'signer-app/signature-modal/upload/upload-progress-bar';
import { SignatureModalContext } from 'signer-app/signature-modal/signature-modal-context/context';

export const MAX_FILE_SIZE = 40 * 1000 * 1000; // 40 MB
const CONTRAST_MID_VALUE =
  (CONSTANTS.CONTRAST_MAX_THRESHOLD + CONSTANTS.CONTRAST_MIN_THRESHOLD) / 2;

export interface UploadProps {
  uploadedSignature: SignatureModalContext['uploadedSignature'];
  isUploadingSignature: boolean;
  intl: IntlShape;
  onRotateSignature: SignatureModalContext['onRotateSignature'];
  clearUploadedSignature: () => void;
  getSignatureUrl: SignatureModalContext['getSignatureUrl'];
  uploadSignature: (file: File) => void;
  enableInsertButtonCallback: (enable: boolean) => void;
  isMobile: boolean;
}

interface UploadState {
  showFileSizeError: boolean;
  showFileFormatError: boolean;
  isUploadingSignature: boolean;
  uploadProgress: number;
  contrast: number;
  rotation: number;
  bounds: Bounds | null;
}

// adds a full stop for punctuation so that screen reader can announce it more naturally
const VisuallyHiddenFullStop = () => <span className="visuallyHidden">.</span>;

class Upload extends React.PureComponent<UploadProps, UploadState> {
  uploadButtonRef = React.createRef<HTMLButtonElement>();

  state: UploadState = {
    showFileSizeError: false,
    showFileFormatError: false,
    isUploadingSignature: false,
    uploadProgress: 0,
    contrast: CONTRAST_MID_VALUE,
    rotation: 0,
    bounds: null,
  };

  updateProgressInterval: NodeJS.Timer | null = null;

  onDropAccepted = ([file]: File[]) => {
    this.upload(file);

    // Clear errors
    this.setState({
      showFileSizeError: false,
      showFileFormatError: false,
    });
  };

  onDropRejected = ([{ file }]: FileRejection[]) => {
    const showFileSizeError = file.size > MAX_FILE_SIZE;
    const showFileFormatError = !/image\/(png|jpg|jpeg|bmp|gif)/.test(
      file.type,
    );

    this.setState({
      showFileSizeError,
      showFileFormatError,
    });
  };

  upload(file: File) {
    this.setState(
      {
        isUploadingSignature: true,
      },
      () => {
        this.props.uploadSignature(file);
      },
    );
  }

  componentDidUpdate(prevProps: UploadProps) {
    this.props.enableInsertButtonCallback(this.shouldEnableInsert());

    // We don't have any visibility into this, so we'll
    // have to make some assumptions based on the props
    // passed to this component.
    if (!prevProps.uploadedSignature && this.props.uploadedSignature) {
      this.setState({
        isUploadingSignature: false,
      });
    }

    if (prevProps.uploadedSignature && !this.props.uploadedSignature) {
      // prevent focus from leaving the upload component on delete
      // when signature gets deleted then focus back on the upload button
      this.uploadButtonRef.current?.focus();
    }
  }

  getSignatureData() {
    const signature = this.props.uploadedSignature;
    const { contrast, rotation, bounds } = this.state;

    if (signature === null) {
      return {};
    }

    const signatureData: Record<string, any> = {
      create_type_code: signatureTypes.UPLOAD,
      guid: signature.guid,
      threshold: contrast,
      degrees: rotation,
    };

    if (bounds) {
      signatureData.is_cropped = true;
      signatureData.x = bounds.left;
      signatureData.y = bounds.top;
      signatureData.width = bounds.width;
      signatureData.height = bounds.height;
    } else {
      signatureData.width = signature.width;
      signatureData.height = signature.height;
    }

    return signatureData;
  }

  shouldEnableInsert() {
    return !!this.props.uploadedSignature;
  }

  handleClearUploadedSignature = () => {
    this.props.clearUploadedSignature();
    this.setState({
      contrast: CONTRAST_MID_VALUE,
      rotation: 0,
    });
  };

  handlePreviewChange = (
    changes: Partial<{
      bounds: Bounds | null;
      rotation: number;
      contrast: number;
    }>,
  ) => {
    const { uploadedSignature, onRotateSignature } = this.props;

    if (changes.rotation && uploadedSignature !== null) {
      onRotateSignature(uploadedSignature);
    }

    const {
      bounds = this.state.bounds,
      rotation = this.state.rotation,
      contrast = this.state.contrast,
    } = changes;

    this.setState({
      bounds,
      rotation,
      contrast,
    });
  };

  renderDropzone() {
    const { isUploadingSignature, showFileFormatError, showFileSizeError } =
      this.state;
    return (
      <>
        <Dropzone
          onDropAccepted={this.onDropAccepted}
          onDropRejected={this.onDropRejected}
          multiple={false}
          disabled={isUploadingSignature}
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/bmp': ['.bmp'],
            'image/gif': ['.gif'],
          }}
          maxSize={MAX_FILE_SIZE}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={styles.dropzone} tabIndex={-1}>
              {isUploadingSignature ? (
                <UploadProgressBar />
              ) : (
                <>
                  <Text id="image-upload-description" color="faint">
                    <FormattedMessage
                      id="164a26be3112ac846aa6e3f022afe2d9c59c3f5589b5cb4994fb5a744f78732c"
                      description="Text for an upload dropzone where user can drag and drop an image file containing a photo of their signature."
                      defaultMessage="Drop a photo of your signature here to upload"
                    />
                    <VisuallyHiddenFullStop />
                  </Text>
                  <Text
                    id="image-upload-acceptable-file-formats"
                    role={showFileFormatError ? 'alert' : undefined}
                    color={showFileFormatError ? 'error' : 'faint'}
                    className={classnames({
                      visuallyHidden: !showFileFormatError,
                    })}
                  >
                    <FormattedMessage
                      id="27d887c3aeadf3f77a8b07a59bb53e8c1a0ed242216251f75d6a38dd7660c197"
                      description="Text indicating acceptable file formats for an upload field."
                      defaultMessage="Acceptable file formats: png, jpg, jpeg, bmp, gif"
                    />
                    <VisuallyHiddenFullStop />
                  </Text>
                  <Text
                    id="image-upload-max-file-size"
                    role={showFileSizeError ? 'alert' : undefined}
                    color={showFileSizeError ? 'error' : 'faint'}
                  >
                    <FormattedMessage
                      id="9a09e238c31eb69893d99ea8fbf12d51c0ce53e0f8128486a9612efa18678ee2"
                      description="Text indicating max acceptable file size for an upload field."
                      defaultMessage="Max file size: 40 MB"
                    />
                    <VisuallyHiddenFullStop />
                  </Text>
                </>
              )}
              <input data-testid="upload-input" {...getInputProps()} />
              <Button
                ref={this.uploadButtonRef}
                variant="primary"
                disabled={isUploadingSignature}
                className={classnames(
                  styles.uploadButton,
                  'whitelabel-primary-button',
                )}
                data-qa-ref="signing-modal--image-upload-button"
                data-testid="signing-modal--image-upload-button"
                aria-describedby="image-upload-description image-upload-acceptable-file-formats image-upload-max-file-size"
              >
                <FormattedMessage
                  id="b17e9d8b878d780811448b5c0d39a377c9a14568e1a4987166aded71f2df8353"
                  description="Text for an upload button which opens the file chooser when clicked."
                  defaultMessage="Upload photo"
                />
              </Button>
            </div>
          )}
        </Dropzone>
      </>
    );
  }

  render() {
    const { getSignatureUrl, uploadedSignature, isMobile } = this.props;
    const hasUploadedSignature = uploadedSignature !== null;
    const { rotation, contrast, isUploadingSignature } = this.state;

    return (
      <div aria-busy={isUploadingSignature}>
        {!isUploadingSignature && hasUploadedSignature ? (
          <SignatureEditArea
            getSignatureUrl={getSignatureUrl}
            signature={uploadedSignature}
            clearUploadedSignature={this.handleClearUploadedSignature}
            onPreviewChange={this.handlePreviewChange}
            contrast={contrast}
            rotation={rotation}
            isMobile={isMobile}
          />
        ) : (
          this.renderDropzone()
        )}
      </div>
    );
  }
}

export default injectIntl(Upload, { forwardRef: true });
