import React from 'react';
import { useLatestRef } from 'signer-app/utils/use-latest-ref';

export function useCatchError() {
  const [error, setError] = React.useState<Error | unknown>();
  // I need this to be stable across renders for when it's used in useEffect.
  const impl = React.useRef(async <T,>(p: Promise<T>): Promise<T> => {
    try {
      return await p;
    } catch (error) {
      setError(error);
      throw error;
    }
  });

  if (error) {
    throw error;
  }

  return impl.current;
}

/**
 * This wraps a callback function so that if it throws, it triggers the
 * ErrorBoundary. It's here for unrecoverable/unexpected errors.
 */
export function useCatchCallback<Args extends unknown[], Value>(
  callback: (...args: Args) => Promise<Value>,
): typeof callback {
  const catchError = useCatchError();
  const ref = useLatestRef(callback);

  return React.useCallback(
    async (...args: Args) => {
      return catchError(ref.current(...args));
    },
    [catchError, ref],
  );
}
