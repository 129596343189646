import React from 'react';

let htmlCounter = 1;
export function useHtmlIDs() {
  const id = React.useMemo(() => htmlCounter++, []);
  return (name: string) => `${name}_${id}`;
}
if (typeof beforeEach === 'function') {
  // Each test needs to reset this so that snapshots don't change.
  beforeEach(() => {
    htmlCounter = 1;
  });
}
