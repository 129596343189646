/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  eqeqeq,
  no-param-reassign,
  @typescript-eslint/no-use-before-define
*/
import { ERROR } from 'common/messages';
import TypeNotifier from 'common/notifiers/type';

export default {
  create(app) {
    const router = app.router;

    function showPreview(location) {
      location.setProperties({
        state: {
          contentPage: 'signatureRequestPreview',
          signatureRequest: app.signatureRequest,
          validate: false,
        },
      });
    }

    // TODO - don't use index. use GUID instead
    function sign(location, field) {
      const validate = String(location.query.validate) === 'true';

      location.setProperties({
        state: {
          contentPage: 'signatureRequest',
          validate,
          prevField: location.state.currentField,
          currentField: field,
          signatureRequest: app.signatureRequest,
        },
      });
    }

    // Home
    router.addRoute('home', '/', (location) => {
      showPreview(location);
    });

    // Preview
    router.addRoute('preview', '/preview', showPreview);

    // Sign
    router.addRoute('sign', '/sign', (location) => {
      sign(location);
    });
    router.addRoute('signNext', '/sign/:field.guid', (location) => {
      sign(location, location.params.field);
    });

    // Attachments
    router.addRoute('attachments', '/attachments', (location) => {
      location.setProperties({
        state: {
          contentPage: 'signatureRequestAttachments',
          signatureRequest: app.signatureRequest,
          validate: true,
        },
      });
    });

    // Confirm
    router.addRoute('confirm', '/confirm', (location) => {
      location.setProperties({
        state: {
          contentPage: 'signatureRequestConfirm',
          signatureRequest: app.signatureRequest,
          validate: true,
        },
      });
    });

    // Decline complete
    router.addRoute('declineComplete', '/declineComplete', (location) => {
      location.setProperties({
        state: {
          contentPage: 'signatureRequestDeclineComplete',
          signatureRequest: app.signatureRequest,
        },
      });
    });

    // delegation complete
    router.addRoute('delegateComplete', '/delegateComplete', (location) => {
      location.setProperties({
        state: {
          contentPage: 'signatureRequestDelegateComplete',
          signatureRequest: app.signatureRequest,
        },
      });
    });

    // complete QES
    router.addRoute(
      'completeQualifiedSignature',
      '/completeQualifiedSignature',
      (location) => {
        location.setProperties({
          state: {
            contentPage: 'completeQualifiedSignature',
            signatureRequest: app.signatureRequest,
          },
        });
      },
    );

    // Complete
    router.addRoute('complete', '/complete', (location) => {
      if (app.isEmbedded()) {
        app.notifier.notify({
          type: 'finalizeEmbeddedFlow',
          signatureRequest: app.signatureRequest,
        });

        // Don't render the final view if we are redirecting
        if (app.signatureRequest.embeddedData.redirectUrl) {
          return;
        }
      } else if (!app.templateLink) {
        const signingRedirectUrl = app.signatureRequest.signingRedirectUrl;
        if (signingRedirectUrl) {
          document.location = signingRedirectUrl;
          return; // Same, for non-embedded redirects
        }
      }

      // Finalize embedded flow
      location.setProperties({
        state: {
          contentPage: 'signatureRequestComplete',
          signatureRequest: app.signatureRequest,
        },
      });
    });

    router.notifier.push(
      TypeNotifier.create(ERROR, (message) => {
        if (message.error.code !== 404) return;
        router.location.setProperties({
          state: {
            contentPage: 'not-found',
          },
        });
      }),
    );
  },
};
