import { defineMessages } from 'react-intl';

export const signerDeclineModalMessages = defineMessages({
  noReasonError: {
    id: 'signer.decline.modal.noReasonError',
    description:
      'error message in modal of signer flow, asking user to put reason behind declining the signature request',
    defaultMessage: 'Please provide a reason.',
  },
  title: {
    id: 'signer.decline.modal.title',
    description:
      'title text of modal in signer flow, when declining the signature request',
    defaultMessage: 'Decline to sign this document',
  },
  description: {
    id: 'signer.decline.modal.description',
    description:
      'text in modal of signer flow, informing that decline of request will be informed to sender and all changes will be cleared',
    defaultMessage:
      'Declining this document will clear anything you have entered and notify the sender.',
  },
  reasonTitle: {
    id: 'signer.decline.modal.reasonTitle',
    description:
      'label text in modal for signer flow, asking singer to put reason for decline',
    defaultMessage: 'Reason for declining',
  },
  sharedOnlyWithSender: {
    id: 'signer.decline.modal.sharedOnlyWithSender',
    description:
      'text in modal of signer flow, informing that the decline reasin will only be sent to sender',
    defaultMessage: 'This information will be shared only with the sender.',
  },
  cancelButton: {
    id: 'signer.decline.modal.cancelButton',
    description:
      'button text in modal of signer flow, when clicked closes the modal',
    defaultMessage: 'Cancel',
  },
  declineButton: {
    id: 'signer.decline.modal.declineButton',
    description:
      'button text in modal of signer flow, when cliked declines the signature request',
    defaultMessage: 'Decline',
  },
  declineFailed: {
    id: 'c1ad697132b3e83402d71ce45357125f7aaccb75f5c2fbf2d6b90e241deac2ed',
    description:
      'error message in modal of signer flow, shows when tyring to decline the signature request',
    defaultMessage: 'Decline failed.',
  },
});
