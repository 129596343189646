/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  global-require,
  guard-for-in,
  max-len,
  no-cond-assign,
  no-nested-ternary,
  no-param-reassign,
  no-plusplus,
  no-restricted-syntax,
  no-var,
  no-void,
  vars-on-top
*/
import recycle from 'common/models/utils/recycle';
import BaseModel from 'common/models/base/model';
import CONSTANTS from 'signer-app/signature-modal/constants';
import HFReactHelper from 'js/sign-components/common/hf-react-helper';
import { isHidden } from 'signer/components/main/pages/sig-doc';
import Snapshots from './snapshots';

module.exports = BaseModel.extend({
  /**
   */

  mixins: [require('common/models/mixins/model')],

  /**
   */

  apiEndpointAlias: 'signatureRequest',

  /**
   */

  snapshots: [],
  fields: [],
  convertedPages: 0,
  totalPages: 0,

  /**
   */

  isNew() {
    return this.guid == void 0;
  },

  /**
   */

  getAllFields() {
    return Array.prototype.concat.apply(
      [],
      this.snapshots.map((snapshot) => {
        return snapshot.fields;
      }),
    );
  },

  /**
   */

  getRequiredFieldsLeftCount() {
    return this.fields.filter((field) => {
      return (
        field.required && (!field.hasValue() || field.getDataValidationError())
      );
    }).length;
  },

  getFieldByGuid(guid) {
    return this.fields.find((field) => field.guid === guid);
  },

  getNumEmptySimilarFields(field) {
    return this.fields.filter((f) => {
      return field.guid !== f.guid && field.type === f.type && !f.hasValue();
    }).length;
  },

  getNumEditableFields() {
    return this.fields.filter((f) => {
      return Boolean(f.editable);
    }).length;
  },

  getNumEmptyEditableFields() {
    return this.fields.filter((f) => {
      return Boolean(f.editable) && !f.isAutoFill && !f.hasValue();
    }).length;
  },

  getNumUntouchedAutoFillFields() {
    return this.fields.filter((f) => {
      return (
        Boolean(f.editable) &&
        Boolean(f.isAutoFill) &&
        f.value === f.autoFillValue
      );
    }).length;
  },

  isDirty() {
    const numCleanFields =
      this.getNumEmptyEditableFields() + this.getNumUntouchedAutoFillFields();
    return this.getNumEditableFields() !== numCleanFields;
  },

  /**
   */

  setFieldValues(type, value, force) {
    // Replaces all values if force=true,
    // Leaves values already set as is otherwise.
    force = force !== undefined ? force : false;

    let setCount = 0;

    this.fields.forEach((field) => {
      if (field.type === type && (force || !field.value)) {
        field.value = value;
        setCount++;
      }
    });

    return setCount;
  },

  /**
   */

  getNextInvalidField(startField) {
    const start = startField ? this.getFieldIndex(startField) : -1;
    for (let i = start + 1, n = this.fields.length; i < n; i++) {
      const field = this.fields[i];
      if (field.getValidationError() || field.getDataValidationError()) {
        return field;
      }
    }
  },

  /**
   */

  getNextEditableField(startField) {
    const start = startField ? this.getFieldIndex(startField) : -1;
    for (let i = start + 1, n = this.fields.length; i < n; i++) {
      const field = this.fields[i];
      if (field.editable) {
        return field;
      }
    }
    return void 0;
  },

  /**
   */

  getFieldIndex(field) {
    return this.fields.indexOf(this.findField(field));
  },

  /**
   */

  _i: 0,

  /**
   */

  findField(compare) {
    for (var field, i = this.fields.length; i--; ) {
      if ((field = this.fields[i]).equals(compare)) {
        return field;
      }
    }
  },

  /**
   */

  getFirstRequiredEmptyField() {
    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i];
      if (field.required && !field.hasValue()) {
        return field;
      }
    }
  },

  /**
   */

  getNextEmptyField(startField) {
    const field = this.getNextEditableField(startField);
    return field
      ? !field.hasValue()
        ? field
        : this.getNextEmptyField(field)
      : void 0;
  },

  /**
   */

  getPrevEditableField(startField) {
    // reverted from 5f36ede1a1859d3915d4dff7b6bc010ac397257e
    // return the field index or return -1 (not found). Note that -1 is important since it's outside of the possible array indices -- this is how JavaScript does it with [].indexOf(value). Also note that -1 will prevent the for loop from accessing an empty array.
    const start = startField ? this.getFieldIndex(startField) : -1;
    for (let i = start - 1; i >= 0; i--) {
      const field = this.fields[i];
      if (field.editable && !field[isHidden]) {
        return field;
      }
    }
  },

  /**
   */

  getWidth() {
    let biggestWidth = 0;
    this.snapshots.forEach((snapshot) => {
      snapshot.pages.forEach((page) => {
        biggestWidth = Math.max(page.width, biggestWidth);
      });
    });
    return biggestWidth;
  },

  /**
   */

  getNextInvalidFieldId(start) {
    const reqField = this.getNextInvalidField(start);
    return reqField ? reqField.guid : void 0;
  },

  /**
   */

  isValid() {
    return !this.getNextInvalidField(-1);
  },

  /**
   */

  decline(reason) {
    this.declineReason = reason;
    return this.fetch('decline');
  },

  delegate({ name, email, reason }) {
    this.newSignerName = name;
    this.newSignerEmail = email;
    this.delegateReason = reason;
    return this.fetch('delegate');
  },

  optIn(esignDisclosureId) {
    return this.fetch('optIn', { esignDisclosureId });
  },

  /**
   */

  complete() {
    return this.fetch('complete');
  },

  uploadAttachment(guid, file) {
    return this.fetch('uploadAttachment', { guid, file });
  },

  /**
   */

  authorize(code) {
    this.accessCode = code;
    return this.load();
  },

  /**
   */

  sendSms() {
    this.triggerSms = 1;
    return this.load();
  },

  /**
   */

  checkSmsCode(code) {
    this.triggerSms = 0;
    this.smsAuthCode = code;
    return this.load();
  },

  /**
   */

  selectSigner(key, tsmGuid, onLoad) {
    this.selectedSigner = key;
    this.transmissionGuid = tsmGuid;
    return this.load(onLoad);
  },

  /**
   */

  toData() {
    // signature request document might not be ready yet -- only totalPages and convertedPages will
    // exist, so return a blank object since there is nothing to serialize. Also note that this condition
    // will pass when signatureRequest.save() is called *before* the signature request is ready. (CC)
    if (!this.snapshots) {
      return {};
    }

    return {
      id: this.guid,
      snapshots: this.snapshots.toData(),
      isComingFromSmsDelivery: this.isSmsSignatureDelivery(),
    };
  },

  /**
   */

  fromData(data) {
    // not ready? Don't return anything.
    if (!data.ready) {
      return {
        totalPages: data.total_pages,
        convertedPages: data.converted_pages,
      };
    }

    // FIXME: Investigate further why this is even needed
    // c.f. HS-193

    const signatureRequestData = data.signatureRequestData;
    const docs = [];
    let doc;

    if (Array.isArray(signatureRequestData.attachment_slots)) {
      signatureRequestData.attachment_slots =
        signatureRequestData.attachment_slots.filter((slot) => {
          return slot.signer === signatureRequestData.signer.index;
        });
      if (signatureRequestData.attachment_slots.length === 0) {
        delete signatureRequestData.attachment_slots;
      }
    }

    for (const k in signatureRequestData.documents) {
      doc = signatureRequestData.documents[k];
      doc.guid = doc.snapshot;
      docs.push(doc);
    }

    const snapshots = recycle(this.snapshots, Snapshots, {
      data: docs,
      notifier: this.notifier,
      signatureRequest: this,
    });

    // Filter for our current signer
    const fieldIndex = {};
    const bySignerIndex = function (field) {
      if (fieldIndex[field.guid] != void 0) {
        // NOTE: Ignore duplicates, this should never happen but we
        // want to be able to handle this if it does.
        return false;
      }
      fieldIndex[field.guid] = true;
      return field.getSignerIndex() === signatureRequestData.signer.index;
    };

    let fields = snapshots.map((snapshot) => {
      return snapshot.fields;
    });

    // FIXME: move this line within the map() code above. (CC)
    const sortMethod = function (a, b) {
      return a.order > b.order ? 1 : -1;
    };
    for (let i = 0, n = fields.length; i < n; i++) {
      fields[i] = fields[i].filter(bySignerIndex).sort(sortMethod);
    }

    fields = Array.prototype.concat.apply([], fields);

    return {
      guid: signatureRequestData.id,
      snapshots,
      title: signatureRequestData.title,
      signer: signatureRequestData.signer,
      fields,
      settings: signatureRequestData.settings,
      isTest: signatureRequestData.isTest,
      expiresAt: signatureRequestData.expiresAt,
      transmissionGuid: signatureRequestData.transmissionGuid,
      isSigned: false,
      signingRedirectUrl: signatureRequestData.signingRedirectUrl,
      xOffset: signatureRequestData.xOffset,
    };
  },

  /**
   */

  getProgress() {
    return this.fetch('getSignatureRequestProgress', {
      statusToken: this.statusToken,
      transmissionGuid: this.transmissionGuid,
    }).then((response) => {
      let progress = 0;

      if (
        response.ready ||
        (response.pages_conversion_total === response.pages_converted &&
          response.pages_persistence_total === response.pages_synced)
      ) {
        progress = 1;
      } else if (
        response.pages_conversion_total ||
        response.pages_persistence_total
      ) {
        const done = response.pages_converted + response.pages_synced;
        const total =
          response.pages_conversion_total + response.pages_persistence_total;
        progress = done / total;
      }

      return Promise.resolve({
        ready: response.ready,
        progress,
        isInFlightEditing: response.isInFlightEditing,
      });
    });
  },

  /**
   */

  isReady() {
    // Snapshot data is only available once the signature request is ready
    return this.snapshots && this.snapshots.length > 0;
  },

  isEmbedded() {
    return !!this.embeddedData.typeCode;
  },

  /**
   */
  isEmbeddedSigning() {
    return this.isEmbedded() && this.embeddedData.isEmbeddedSigning;
  },

  hasMixedFormat() {
    return this.data.hasMixedFormat;
  },

  /**
   */

  hasMobileSignatureTypes() {
    if (!this.settings) {
      // Not yet loaded; return null value instead of boolean
      return void 0;
    }
    return (
      this.settings.allowedSignatureTypes[CONSTANTS.SIGNATURE_TYPE_CANVAS] ||
      this.settings.allowedSignatureTypes[CONSTANTS.SIGNATURE_TYPE_TYPED]
    );
  },

  /**
   */

  setSelectedSignature(type, signature) {
    if (!this.selectedSignature) {
      this.selectedSignature = {};
    }
    this.selectedSignature[type] = signature;
  },

  getSelectedSignature(type) {
    if (this.selectedSignature) {
      return this.selectedSignature[type];
    }
  },

  getDownloadLink() {
    // https://www.dev-hellosign.com/attachment/downloadCopy?o=1&guid=87b75de74c3758df909c6eccf9b82e3618a8ae47
    const url = `/attachment/downloadCopy?o=1&guid=${this.guid}`;
    return this.makeUrl(url);
  },

  makeUrl(uri) {
    let prefix = '';
    if (
      document.location &&
      document.location.href.indexOf('/webapp_dev.php') > 0
    ) {
      prefix = '/webapp_dev.php';
    }
    return prefix + HFReactHelper.attachSessionInfoToUrl(uri);
  },

  isQualifiedSignature() {
    return this.data.signatureRequestData.isQualifiedSignature;
  },

  isEIdEnabled() {
    return this.data.signatureRequestData.isEIdEnabled;
  },

  isSmsSignatureDelivery() {
    return this.data.signatureRequestData.isComingFromSmsDelivery;
  },

  allowColorSignature() {
    // in cases where multiple signers have the same e-mail, we may not have defined data yet
    if (this.data && this.data.signatureRequestData) {
      return this.data.signatureRequestData.allowColorSignature;
    }
    return false;
  },
});
