/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
const BaseModel = require('common/models/base/model');

module.exports = BaseModel.extend({
  fromData(data) {
    let uri = data.hfSource;

    // TODO - remove this. Should never access a property on the global scope. (CC)
    // define hellofaxJS as a property of app (this.app.helloFaxJS.attachSessionInfoToUrl)
    if (window.hellofaxJS) {
      uri = hellofaxJS.attachSessionInfoToUrl(uri);
    }

    return {
      width: data.width || 680,
      height: data.height || 800,
      hfSource: uri,
      cdnSource: data.cdnSource || null,
    };
  },
});
