import classNames from 'classnames';
import React from 'react';
import './debug-toolbar.module.css';

const visibleClass = 'hs-debug-tools--visible';
const activatedButtonClass = 'activated';

export function DebugToolbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [overlayVisible, setOverlayVisibility] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const hideAllPanels = React.useCallback(() => {
    const toolbar = ref.current!;

    const getAllButtons = () => {
      return toolbar.querySelectorAll(
        '.hs-debug-tools__toolbar > ul > li > button',
      );
    };
    const getAllPanels = () => {
      return toolbar.querySelectorAll('.hs-debug-tools__panel');
    };
    getAllPanels().forEach((el) => {
      el.classList.remove(visibleClass);
    });
    getAllButtons().forEach((el) => {
      el.classList.remove(activatedButtonClass);
    });
  }, []);

  React.useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.buttonHandler = (evt: any) => {
        const button: HTMLElement = evt.currentTarget!;
        // @ts-ignore
        const selectedPanel = button.parentElement.querySelector(
          '.hs-debug-tools__panel',
        );
        const isSelectedPanelVisible =
          selectedPanel?.classList.contains(visibleClass);
        const thisPanelNewState = !isSelectedPanelVisible;

        // Hide all panels
        hideAllPanels();

        // Show/hide overlay and selected panel
        if (thisPanelNewState && selectedPanel) {
          button.classList.add(activatedButtonClass);
          selectedPanel.classList.add(visibleClass);
          setOverlayVisibility(true);
        } else {
          setOverlayVisibility(false);
        }

        button.blur();
      };
    }
  }, [hideAllPanels]);

  return (
    <div className="hs-debug-tools" id="hs-debug-tools" ref={ref}>
      <div
        className={classNames('hs-debug-tools__overlay', {
          visible: overlayVisible,
        })}
      ></div>
      <div className="hs-debug-tools__wrapper">
        <div
          className={classNames('hs-debug-tools__toolbar', {
            open: isOpen,
          })}
        >
          <ul>
            <li>
              <button
                title="Toggle Debug Tools"
                className="hs-debug-tools__toolbar-toggle"
                onClick={() =>
                  setIsOpen((o) => {
                    if (o) {
                      hideAllPanels();
                    }
                    return !o;
                  })
                }
              >
                <svg width="10" height="32" viewBox="0 0 10 18" fill="none">
                  <path d="M1.35355 9.35355L0.646447 10.0607L1.35355 9.35355ZM8.29289 0.292893L0.646446 7.93934L2.06066 9.35355L9.70711 1.70711L8.29289 0.292893ZM0.646447 10.0607L8.29289 17.7071L9.70711 16.2929L2.06066 8.64645L0.646447 10.0607ZM0.646446 7.93934C0.0606581 8.52513 0.0606618 9.47488 0.646447 10.0607L2.06066 8.64645C2.25592 8.84171 2.25592 9.15829 2.06066 9.35355L0.646446 7.93934Z"></path>
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
