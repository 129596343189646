/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  global-require
*/
import CallbackNotifier from 'common/notifiers/callback';

const commands = {
  finalizeEmbeddedFlow: require('./finalize-embedded-flow'),
};

export default {
  create(app) {
    app.notifier.push(
      CallbackNotifier.create((message) => {
        if (commands[message.type]) {
          return commands[message.type](message);
        }
      }),
    );
  },
};
