import { ReactElement } from 'react';
import type { HelloSign } from 'hellosign-embedded';

export enum SetupTabIds {
  Connection = 'connection',
  SignatureStatus = 'signatureStatus',
  DocDestinationPath = 'docDestinationPath',
  UserAccess = 'userAccess',
}

export enum ErrorCode {
  CSRFProtection = 'csrf_protection',
  IntegrationConnectionRequried = 'sp_integration_connection_required',
  AccessNotGranted = 'sp_access_not_granted',
  PrepAndSendFileError = 'pns_file_error',
  PrepAndSendNoFileSelectedError = 'no_downloadable_urls',
}

// Error messages coming from HS API
export enum ErrorMessage {
  EmptyFile = 'Could not upload empty file(s).',
  UnsupportedFileType = 'Unsupported file type',
  FileSizeTooBig = 'File(s) must be under',
}

interface setupTab {
  id: SetupTabIds;
}

export const setupTabs: setupTab[] = [
  {
    id: SetupTabIds.Connection,
  },
  {
    id: SetupTabIds.DocDestinationPath,
  },
  {
    id: SetupTabIds.UserAccess,
  },
];

export type SetupPageProps = {
  spSiteUrl: string;
  hasAppOnlyPermissions: boolean;
  hasApiApp: boolean;
  contextTokenUrl: string;
  spLanguage?: string;
  userEmail?: string;
};

export type StatusPageProps = {
  statusType: WorkflowStatusType;
  returnLink?: string;
  signedFolderLocation?: string;
  userEmail?: string;
  description?: string;
  state: string;
};

export type SendForSignatureProps = {
  spSiteUrl: string;
  spListId: string;
  spListItemId: string;
  returnLink: string;
  contextTokenUrl: string;
  spLanguage: HelloSign.Locales[keyof HelloSign.Locales];
  userEmail?: string;
};

export type SPFolder = {
  id: string;
  path: string;
  rootId?: string;
};

export type SPUserBase = {
  id: number;
  title: string;
  email: string;
  isTeamMember: boolean;
};

export type SPUser = {
  id: number;
  title: string;
  email: string;
  isTeamMember: boolean;
  isSelected: boolean;
};

export type SPAuthUrl = {
  authUrl: string;
  state: string;
  success: boolean;
  clientId?: string;
};

export enum WorkflowStatusType {
  SentConfirmation = 'sentConfirmation',
  SelfSignConfirmation = 'selfSignConfirmation',
  ConnectAgreement = 'connectAgreement',
  ConnectCheckEmail = 'connectCheckEmail',
  ConnectWelcome = 'connectWelcome',
  ErrorHSNotAuthorized = 'errorHSNotAuthorized',
  ErrorUserNoHSAccess = 'errorUserNoHSAccess',
  ErrorAdminPageNoAccess = 'errorAdminPageNoAccess',
  ErrorUnexpected = 'errorUnexpected',
  ErrorMicrosoftToken = 'errorMicrosoftToken',
  ErrorNoHSAccount = 'errorNoHSAccount',
  ErrorConnectHSRequired = 'errorConnectHSRequired',
  ErrorIntegrationConnectRequired = 'errorIntegrationConnectRequired',
  ErrorPnSFileError = 'errorPnSFileError',
  ErrorPnSNoFileSelectedError = 'PrepAndSendNoFileSelectedError',
  ErrorPnSEmptyFile = 'PrepAndSendEmptyFile',
  ErrorPnSUnsupportedFileType = 'PrepAndSendUnsupportedFileType',
  ErrorPnSFileSizeTooBig = 'PrepAndSendFileSizeTooBig',
}

export type WorkflowStatusData = {
  title: string;
  description?: string | ReactElement;
  image?: ReactElement;
  buttonText?: string;
};

export type SharePointNamespaceState = {
  permissionGranted: boolean;
  hsConnected: boolean;
  redirectToContextTokenUrl: boolean;
  workflowError: WorkflowStatusType | null;
};

export enum Actions {
  SetPermissionGranted = 'sharepoint/SetPermissionGranted',
  SetHSConnected = 'sharepoint/SetHSConnected',
  SetRedirectToContextTokenUrl = 'sharepoint/RedirectToContextTokenUrl',
  SetWorkflowError = 'sharepoint/DisplayWorkflowError',
}

export interface SetPermissionGranted {
  type: Actions.SetPermissionGranted;
  payload: boolean;
}

export interface SetHSConnected {
  type: Actions.SetHSConnected;
  payload: boolean;
}
export interface SetRedirectToContextTokenUrl {
  type: Actions.SetRedirectToContextTokenUrl;
  payload: boolean;
}
export interface SetWorkflowError {
  type: Actions.SetWorkflowError;
  payload: WorkflowStatusType | null;
}

export type SharePointAction =
  | SetPermissionGranted
  | SetHSConnected
  | SetRedirectToContextTokenUrl
  | SetWorkflowError;
