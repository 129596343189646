/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseModel from 'common/models/base/model';

/**
 */

module.exports = BaseModel.extend({
  /**
   */

  ROLE_CODE_ADMIN: 'a',
  ROLE_CODE_DEVELOPER: 'd',
  ROLE_CODE_MEMBER: 'm',
  ROLE_CODE_TEAM_MANAGER: 'r',
  ROLE_NAMES: {
    a: (
      <FormattedMessage
        id=""
        defaultMessage="Admin"
        description="Team Page, role type"
      />
    ),
    d: (
      <FormattedMessage
        id=""
        defaultMessage="Developer"
        description="Team Page, role type"
      />
    ),
    m: (
      <FormattedMessage
        id=""
        defaultMessage="Member"
        description="Team Page, role type"
      />
    ),
    r: (
      <FormattedMessage
        id=""
        defaultMessage="Team Manager"
        description="Team Page, role type"
      />
    ),
  },

  numTemplates: 0,
  roleCode: 'm',

  mixins: [require('common/models/mixins/model')],

  apiEndpointAlias: 'teamMembers',
  shouldResetPassword: false,

  /**
   */

  toData() {
    return {
      guid: this.guid,
      emailAddress: this.emailAddress,
      isLocked: this.isLocked,
      roleCode: this.roleCode,
      pending: this.pending,

      // TODO - remove this!!
      csrfToken: this.csrfToken,
    };
  },

  /**
   * promotes or demotes a user depending on the new role code
   */

  promote(newRoleCode, event) {
    this.setProperties({ roleCode: newRoleCode });
    this.save(event);
  },

  /**
   */

  resetPassword(event) {
    this.shouldResetPassword = true;
    this.save(event).then(() => {
      window.location.reload();
    });
  },

  /**
   */

  unlock(event) {
    this.setProperties({ isLocked: false });
    this.save(event);
  },

  /**
   */

  lock(event) {
    this.setProperties({ isLocked: true });
    this.save(event);
  },

  /**
   */

  getRoleTypeLabel() {
    return this.ROLE_NAMES[this.roleCode || this.ROLE_CODE_MEMBER];
  },

  /**
   */

  canModifyTeam() {
    return this.isAdmin() || this.isDeveloper();
  },

  /**
   */

  isLoggedInUser() {
    // ideally we'd go off user ids, but this works for now (CC)
    return this.isUser(this.user);
  },

  /**
   */

  isUser(user) {
    if (!user) return false;
    // ideally we'd go off user ids, but this works for now (CC)
    return this.emailAddress === user.emailAddress;
  },

  /**
   */

  equals(model) {
    return model.guid === this.guid && model.emailAddress === this.emailAddress;
  },

  /**
   */

  isAdmin() {
    return this.roleCode === this.ROLE_CODE_ADMIN;
  },

  /**
   */

  isDeveloper() {
    return this.roleCode === this.ROLE_CODE_DEVELOPER;
  },

  /**
   */

  isMember() {
    return this.roleCode === this.ROLE_CODE_MEMBER;
  },

  /**
   */

  isManager() {
    return this.roleCode === this.ROLE_CODE_TEAM_MANAGER;
  },
});
