import React from 'react';
import { createSimpleDataContext } from 'js/sign-components/common/create-simple-data-context';
import { SignerAppClient } from 'signer-app/context/signer-app-client';

const { Provider, useContext: useSignAppClient } =
  createSimpleDataContext<SignerAppClient>('SignAppClient');
export { useSignAppClient };
type ProviderProps = React.PropsWithChildren<{
  client: SignerAppClient;
}>;

export function SignAppClientProvider({ client, children }: ProviderProps) {
  return <Provider value={client}>{children}</Provider>;
}
