/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  no-empty,
  no-void,
  prefer-rest-params
*/
import extend from 'lodash/extend';
import BaseObject from 'common/object/base';
import { ResponseMessage, ErrorMessage } from 'common/messages';
import _request from 'signer-app/context/signer-app-client/_request';
import errorCodes from './errors';

/**
 * TODO: possibly move this file over to the common directory (CC)
 */

/*

Usage:

var service = new Service();

service.execute({
name: 'getProgress',
apiEndpointAlias: 'signatureRequest'
}).then(function(data) {

}, catchError);

*/

function isValidJson(text) {
  try {
    JSON.parse(text);
    return true;
  } catch (e) {
    return false;
  }
}

function APIService() {
  BaseObject.apply(this, arguments);

  //  makes the actual call the the API server. This method
  //  is exposed here so that it can be stubbed out. It should not be called directly within the
  //  app (CC)

  if (!this._request) {
    this._request = _request;
  }
}

BaseObject.extend(APIService, {
  /**
   */

  notify(message) {
    if (message.apiEndpointAlias != void 0 && message.type != void 0) {
      return this.execute(message);
    }
  },

  /**
   */

  execute(request) {
    const type = request.type || request.name;
    const apiEndpointAlias = request.apiEndpointAlias;

    const endpoint = this.endpoints[apiEndpointAlias];

    // look for an endpoint such as { signatureRequest: { getStatus() { }}}
    if (!endpoint) {
      throw new Error(`apiEndpointAlias "${apiEndpointAlias}" does not exist`);
    }

    // from the endpoint, pull out the mapping function. e.g: signatureRequest.getStatus
    const mapRequestOptions = endpoint[type];

    if (!mapRequestOptions) {
      throw new Error(
        `action "${type}" does not exist on "${apiEndpointAlias}" endpoint`,
      );
    }

    // get the information that needs to get blasted off to the server
    const requestOptions = mapRequestOptions(request);

    // some cases where requestOps is the actual request
    let response = requestOptions.then ? requestOptions : null;
    if (!response) {
      // add cache buster to every API request.
      // Note that some query params are strings - wanna ignore those (CC)
      if (typeof requestOptions.query !== 'string') {
        requestOptions.query = Object.assign({}, requestOptions.query, {
          _c: Date.now(),
        });
      }

      response = this._request(requestOptions).then((resp) => {
        if (resp.success !== false) {
          return Promise.resolve(resp.data || resp);
        } else {
          return Promise.reject(resp.error);
        }
      });
    }

    if (requestOptions.transformResponse) {
      response = response.then(requestOptions.transformResponse);
    }

    // check if handleError is false -- this maybe the case when
    // parts of the app needs to handle error responses from the server. Otherwise
    // the error will be dispatched by the this object and handled by a global listener (CC)
    if (request.handleError !== false) {
      response.catch(this._onRequestError.bind(this));
    }

    response.then(this._onRequestComplete.bind(this, request));

    return response;
  },

  /**
   */

  _onRequestComplete(request, response) {
    // slight hack. Need to delay notification until after the requestor
    // recieves response. (CC)
    setTimeout(() => {
      this.notifier.notify(ResponseMessage.create(response, request));
    }, 10);
  },

  /**
   */

  _onRequestError(error) {
    // Format error
    let ex;
    if (error && error.crossDomain === true) {
      // NOTE: SuperAgent returns a Cross-Domain exception when there's no network
      // TODO: Switch to using error.xhr.statusCode
      ex = {
        code: errorCodes.NETWORK,
      };
    } else if (error && error.response && isValidJson(error.response.text)) {
      try {
        ex = JSON.parse(error.response.text);
        ex = ex.error;
      } catch (e) {}
    } else {
      ex = {
        ...error,
        code: error.code || errorCodes.UNKNOWN,
      };
    }

    // Merge the decoded api error into the generic error
    // so we can access info from the api error in then/catch callbacks
    extend(error, ex);

    // emit an error so that components & other parts of the app can update their state (CC)
    this.notifier.notify(ErrorMessage.create(error, this));
  },
});

/**
 */

module.exports = APIService;
