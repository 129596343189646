import { create, extend } from 'common/utils/class';

function BaseNotifier() {}

extend(BaseNotifier, {
  notify() {
    // OVERRRIDE ME
  },
});

BaseNotifier.create = create;
BaseNotifier.extend = extend;

export default BaseNotifier;
