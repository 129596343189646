const fontCharMapDecode = (str: string) => {
  const rows = str.split('\n');
  const chunks: any = {};
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    // find the row identifier
    const key = row?.match(/^([a-z0-9]{4}): /)?.[1] ?? '';
    if (key.length !== 4) {
      throw new Error('Key not found');
    }
    // find the 32 bit chunks
    chunks[key] = row.match(/[a-z0-9]{8}/g);
    for (let j = 0; j < chunks[key]!.length; j++) {
      chunks[key][j] = parseInt(chunks[key]![j], 16);
    }
    if (chunks[key].length !== 8) {
      throw new Error('Row data not found');
    }
  }
  return chunks;
};
export const FONT_CHAR_MAP = {
  'La Belle Aurore': fontCharMapDecode(
    '0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n' +
      '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n' +
      '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n' +
      '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n' +
      '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000',
  ),
  'Mr De Haviland': fontCharMapDecode(
    '0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n' +
      '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n' +
      '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n' +
      '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n' +
      '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000',
  ),
  'Nothing You Could Do': fontCharMapDecode(
    '0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffff7fff ffffffff ffffffff\n' +
      '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n' +
      '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n' +
      '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n' +
      '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000',
  ),
  Zeyada: fontCharMapDecode(
    '0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n' +
      '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n' +
      '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n' +
      '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n' +
      '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000',
  ),
  'Dawning of a New Day': fontCharMapDecode(
    '0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffff7fff ffffffff ffffffff\n' +
      '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n' +
      '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n' +
      '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n' +
      '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000',
  ),
  'Herr Von Muellerhoff': fontCharMapDecode(
    '0000: 00000000 ffffffff bfffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n' +
      '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n' +
      '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n' +
      '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n' +
      '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000',
  ),
  'Over the Rainbow': fontCharMapDecode(
    '0000: 00000000 ffffffff ffffffff 7fffffff 00000000 ffffffff ffffffff ffffffff\n' +
      '0001: 00000000 00000000 000c0000 01000000 00000000 00000000 00000000 00000000\n' +
      '0002: 00000000 00000000 00000000 00000000 00000000 00000000 10000040 00000000\n' +
      '0020: 771f07ff 06008044 80000000 00000000 00000000 00001000 00000000 00000000\n' +
      '0021: 00000000 00000004 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00e0: 00000001 00000000 00000000 00000000 00000000 00000000 00000000 00000000\n' +
      '00fb: 00000006 00000000 00000000 00000000 00000000 00000000 00000000 00000000',
  ),
};
