import { ValidateFunction } from 'ajv';
import {
  assertDataIsValid,
  BasicValidator,
} from 'signer-app/utils/ajv-validation';

/**
 *
 *
  const response = await hsFetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  assertValidResponse(response);
 */
export function assertValidResponse(response: Response) {
  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }
}

export async function assertDataSchemaResponse<T>(
  response: Response,
  validator: ValidateFunction<T> | BasicValidator<T>,
): Promise<T> {
  assertValidResponse(response);

  const data: unknown = await response.json();

  // AJV validators are a bit akward. They don't actually throw, which means
  // this code from node_modules/ajv/dist/types/index.d.ts claims that it
  // returns `data is T`, which would be a type guard that throws. But the
  // actual implementation returns a boolean and sets this `errors` property on
  // the function.
  //
  // export interface ValidateFunction<T = unknown> {
  //   (this: Ajv | any, data: any, dataCxt?: DataValidationCxt): data is T;
  //   errors?: null | ErrorObject[];
  try {
    assertDataIsValid(validator, data);
  } catch (e: any) {
    e.message = `${response.url}: ${e.message}`;
    // eslint-disable-next-line no-console
    console.error('DATA', data);
    throw e;
  }
  return data;
}

export async function assertBasicSuccess(response: Response) {
  assertValidResponse(response);

  const data = await response.json();
  if (data.success) {
    return;
  }
  throw new Error('Unknown error');
}
