/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import CONSTANTS from 'signer-app/signature-modal/constants';

import SignatureModal, {
  SignatureModalProps,
} from 'signer-app/signature-modal/signature-modal';
import { SignatureModalContextProvider } from 'signer-app/signature-modal/contexts/signature-modal/context';
import { CanvasContextProvider } from 'signer-app/signature-modal/contexts/canvas/context';

export type ValueOfArray<T> = T[keyof T & number];

export type Tab = ValueOfArray<typeof CONSTANTS.TABS>;

export { CONSTANTS };

type Props = Omit<SignatureModalProps, 'canvasContext' | 'intl'>;

export default function SignIntegratedSignatureModal(props: Props) {
  return (
    <CanvasContextProvider>
      <SignatureModalContextProvider>
        <SignatureModal {...props} />
      </SignatureModalContextProvider>
    </CanvasContextProvider>
  );
}
