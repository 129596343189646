import { Rule } from 'signer-app/conditional-logic/types';
import { Field } from 'signer-app/types/editor-types';
import { notEmpty } from 'signer-app/utils/not-empty';
import { unreachable } from 'signer-app/utils/unreachable';

export default function cleanRules(rules: Rule[], fields: Field[]): Rule[] {
  // get all field IDs that are available
  // this is to handle fields that get deleted if they are
  // part of a group when they aren't explicitly selected
  const fieldIds = fields.map((f) => f.id);
  const groupIds = fields.flatMap((f) =>
    'group' in f && f.group ? [f.group] : [],
  );
  const mappedRules =
    rules?.map((rule) => {
      // contains triggers that were not deleted
      const triggers = rule.triggers.filter((t) => fieldIds.includes(t.id));
      // if all trigger ids don't match, then it was deleted,
      // so, delete the rule completely because it's not valid
      if (triggers.length === 0) {
        return null;
      }

      // Remove any actions where the associted field or group has been removed.
      const actions = rule.actions.filter((a) => {
        switch (a.type) {
          case 'change-field-visibility':
            return fieldIds.includes(a.fieldId);
          case 'change-group-visibility':
            return groupIds.includes(a.groupId);
          default:
            unreachable(a);
        }
        return false;
      });

      // if there are no actions left, we need to delete the whole rule
      if (actions.length === 0) {
        return null;
      }

      // If there were no changes, return the original rule object
      if (
        actions.length === rule.actions.length &&
        triggers.length === rule.triggers.length
      ) {
        return rule;
      }

      return {
        ...rule,
        actions,
        triggers,
      };
    }) ?? [];

  const updatedRules = mappedRules.filter(notEmpty);

  // If there were no changes made to any rules, then return the original array.
  if (
    updatedRules.length === rules.length &&
    updatedRules.every((r, index) => r === rules[index])
  ) {
    return rules;
  }

  return updatedRules;
}
