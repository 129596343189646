import React from 'react';
import styles from 'signer-app/signature-request/display-field/signature.module.css';
import { useSignatureModalContext } from 'signer-app/signature-modal/signature-modal-context/context';

interface ImageProps {
  alt?: string;
  id: string;
}

const Image = React.forwardRef<any, ImageProps>(
  ({ alt = '', id, ...props }, ref) => {
    const signatureProps = useSignatureModalContext();
    const src = signatureProps.getSignatureUrl({ guid: id });

    return (
      <div
        {...props}
        ref={ref}
        style={{
          height: '100%',
          width: '100%',
          cursor: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <img alt={alt} className={styles.image} src={src} />
      </div>
    );
  },
);
Image.displayName = 'Image';

export default Image;
