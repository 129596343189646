import hsFetch from 'js/sign-components/common/hs-fetch';
import { HomePage } from 'js/sign-components/generated/types/HelloRequest';
import { assertDataSchemaResponse } from 'signer-app/utils/response-helpers';
import { validateHomePage } from 'js/sign-components/generated/validators/validateHelloRequest';

export async function getDelinquencyReminderModalData(): Promise<HomePage> {
  const url = '/endpoint/getIsDelinquencyReminderModalData';
  const response = await hsFetch(url, {
    method: 'GET',
  });
  if (response.status === 200) {
    return assertDataSchemaResponse(response, validateHomePage);
  }
  throw new Error(response.statusText);
}
