import { HeapProperties } from 'js/sign-components/common/heap';
import {
  Document,
  Field,
  SignerOrder,
  Signer,
  DocumentRotation,
} from 'signer-app/types/editor-types';

export type SaveEmbeddedRequestResponse = {
  /* eslint-disable camelcase */
  success: boolean;
  signature_request_id: string;
  signature_request_info: {
    title: string;
    message: string;
    expires_at?: Date | null | undefined;
    signatures: Array<{
      signer_name: string;
      signer_email_address: string;
      order: null | number;
    }>;
    cc_email_addresses: string[];
  };
  /* eslint-enable camelcase */
};

export type RequestEventData = {
  /* eslint-disable camelcase */
  success: SaveEmbeddedRequestResponse['success'];
  signatureRequestId: SaveEmbeddedRequestResponse['signature_request_id'];
  signatureRequestInfo: {
    title: SaveEmbeddedRequestResponse['signature_request_info']['title'];
    expiresAt: SaveEmbeddedRequestResponse['signature_request_info']['expires_at'];
    message: SaveEmbeddedRequestResponse['signature_request_info']['message'];
    signatures: SaveEmbeddedRequestResponse['signature_request_info']['signatures'];
    ccEmailAddresses: SaveEmbeddedRequestResponse['signature_request_info']['cc_email_addresses'];
  };
  /* eslint-enable camelcase */
};

export type LookupMessageType<T extends MessageType> =
  T extends MessagesWithData['type']
    ? Extract<MessagesWithData, { type: T }>
    : { type: T };

export type AppError = {
  type: MessageType.APP_ERROR;
  payload: {
    // Every place in the code I found hellosign:error, it's passing undefined here :shrug:
    signatureId: undefined;
    code: 'nothing_to_sign' | 'unknown';
  };
};

type MessagesWithData =
  | AppError
  | DeepIntegrationSendRecipients
  | EditorView
  | EditorValidationType
  | MobileValidationType
  | MobileContinue
  | MobileHeapEvent
  | UserCreateTemplate
  | DeepIntegrationFinished
  | UserCancelRequest
  | UserPrepSendLog
  | UserPrepSendEditorDeleteDoc
  | UserSendRequest
  | UserPrepSendEditorContinue
  | SignerAppDemoWhiteLabeling
  | SignerAppDemoLogoUrl
  | SignerAppUserDeclineRequest;

/**
 * MessageType along with MessagesWithData define the types for
 * useWindowMessage and postTo. If you aren't attaching any data and are just
 * sending a `{ type: 'some:string' }`, then you only need this MessageType.
 * If you also need other data, you can add another type to the union
 * MessagesWithData.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764538186201210
 */
export enum MessageType {
  EditorContinue = 'editor:continue',
  EditorAutoSave = 'editor:autoSave',
  EditorView = 'editor:setView',
  EditorAutoSaveDone = 'editor:autoSaveDone',
  EditorValidation = 'editor:editorValidation',
  MobileValidation = 'mobile:editorValidation',
  MobileContinue = 'mobile:continue',
  MobileHeapEvent = 'mobile:heapEvent',
  SharePoint = 'sharepoint',
  // used in jquery.hellofax.js
  InternalUserLogout = 'internal:userLogout',
  // used in jquery.hellofax.js
  InternalSessionTimeoutWarning = 'internal:sessionTimeoutWarning',
  IntegrationUserLogout = 'mobile:userLogout',
  IntegrationSessionTimeoutWarning = 'mobile:sessionTimeoutWarning',
  DeepIntegrationSendRecipients = 'dropbox:deepIntegrationSendRecipients',
  // used for forms education/onboarding tour
  EditorContinueFormsEducationTour = 'editor:continueFormsEducationTour',
  SignerAppDemoWhiteLabeling = 'signerAppDemo:whiteLabeling',
  SignerAppDemoLogoUrl = 'signerAppDemo:logoUrl',

  // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  // eslint-disable-next-line no-restricted-syntax
  ReloadSendTemplate = 'hellosign:reloadSendTemplate',

  // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  // eslint-disable-next-line no-restricted-syntax
  DeepIntegrationWaitingForRecipients = 'hellosign:deepIntegrationWaitingForRecipients',

  // Uses of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  /* eslint-disable no-restricted-syntax */
  APP_CONFIGURE = 'hellosign:configure',
  APP_ERROR = 'hellosign:error',
  APP_INITIALIZE = 'hellosign:initialize',
  USER_CANCEL_REQUEST = 'hellosign:userCancelRequest',
  USER_CREATE_TEMPLATE = 'hellosign:userCreateTemplate',
  USER_DECLINE_REQUEST = 'hellosign:userDeclineRequest',
  USER_FINISH_REQUEST = 'hellosign:userFinishRequest',
  USER_PREP_SEND_EDITOR_CONTINUE = 'hellosign:userPrepSendEditorContinue',
  USER_PREP_SEND_LOG = 'hellosign:userPrepSendLog',
  USER_REASSIGN_REQUEST = 'hellosign:userReassignRequest',
  USER_SEND_REQUEST = 'hellosign:userSendRequest',
  USER_SIGN_REQUEST = 'hellosign:userSignRequest',
  USER_PREP_SEND_EDITOR_DELETE_DOC = 'hellosign:userPrepSendEditorDeleteDoc',
  DEEP_INTEGRATION_FINISHED = 'hellosign:deepIntegrationFinished',
  /* eslint-enable no-restricted-syntax */
  GoogleDriveOAuthSucceed = 'gdrive:readonlyOAuthSucceed',
}

interface UserPrepSendEditorContinue {
  type: MessageType.USER_PREP_SEND_EDITOR_CONTINUE;

  // eslint-disable-next-line camelcase
  is_template: boolean;
  // eslint-disable-next-line camelcase
  is_template_link: boolean;
  documents: Array<Document>;
  fields: Array<Field>;
  signers: Array<Signer>;
  snapshots: Array<unknown>;
  // eslint-disable-next-line camelcase
  signer_order: SignerOrder;
  rotate: DocumentRotation;
  // eslint-disable-next-line camelcase
  signer_options: unknown;
}

interface UserSendRequest {
  type: MessageType.USER_SEND_REQUEST;
  payload: RequestEventData;
}

interface UserCreateTemplate {
  type: MessageType.USER_CREATE_TEMPLATE;
  payload:
    | {
        // eslint-disable-next-line camelcase
        template_guid: string;
      }
    | {
        templateId: string;
      };
}

interface DeepIntegrationFinished {
  type: MessageType.DEEP_INTEGRATION_FINISHED;
  payload: {
    filePath: string;
    guid?: string;
    signatureRequestId: string;
  };
}

interface UserCancelRequest {
  type: MessageType.USER_CANCEL_REQUEST;
  payload?: { path: string };
}

interface UserPrepSendLog {
  type: MessageType.USER_PREP_SEND_LOG;
  payload:
    | {
        action: 'prep_send_paywall';
        // eslint-disable-next-line camelcase
        num_docs_left: number;
      }
    | {
        name: 'prep_send_self_sign_click';
      };
}

interface UserPrepSendEditorDeleteDoc {
  type: MessageType.USER_PREP_SEND_EDITOR_DELETE_DOC;
  guid: string;
  // eslint-disable-next-line camelcase
  parent_guid: string;
}

export interface DeepIntegrationRecipient {
  name: string;
  email: string;
  accessCode?: string;
  smsAuthNumber?: string;
}

export type DeepIntegrationSendRecipients = {
  type: MessageType.DeepIntegrationSendRecipients;
  payload: {
    selfSign: boolean;
    recipientOrder?: boolean;
    recipientReassignment?: boolean;
    signers?: Array<DeepIntegrationRecipient>;
  };
};

export type EditorView = {
  type: MessageType.EditorView;
  view: 'edit' | 'preview';
};

export type EditorValidationType = {
  type: MessageType.EditorValidation;
  isBackDisabled: boolean;
  isValid: boolean;
  numFields: number;
  errorMessage?: string;
};

type MobileValidationType = {
  type: MessageType.MobileValidation;
  isValid: boolean;
  numFields: number;
  errorMessage?: string;
};
type MobileContinue = Omit<MobileValidationType, 'type'> & {
  type: MessageType.MobileContinue;
};

type MobileHeapEvent = {
  type: MessageType.MobileHeapEvent;
  eventName: string;
  properties: HeapProperties;
};

export type SignerAppDemoWhiteLabeling = {
  type: MessageType.SignerAppDemoWhiteLabeling;
  whiteLabeling: Record<string, string>;
};

export type SignerAppDemoLogoUrl = {
  type: MessageType.SignerAppDemoLogoUrl;
  url: string;
};

type SignerAppUserDeclineRequest = {
  type: MessageType.USER_DECLINE_REQUEST;
  payload: {
    signatureId: string;
    reason: string;
  };
};
