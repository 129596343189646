import React from 'react';
import classnames from 'classnames';
import measureLineHeight from 'line-height';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
} from 'signer-app/signature-request/constants';
import styles from 'signer-app/signature-request/display-field/text.module.css';
import { TextField } from 'signer-app/types/editor-types';

const NOOP = () => {};

// These get spread into all display fields, but this one doesn't use them.
type UnusedProps = {
  height?: any;
  width?: any;
  textScale?: any;
  isActive?: any;
  selectField?: any;
  updateFields?: any;
  runZoomIntoField?: any;
};

type Props = UnusedProps & {
  fieldData: TextField;
  className?: string;
  disabled?: boolean;
  style?: React.StyleHTMLAttributes<HTMLTextAreaElement>;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  validationErrors?: any;
  isOverlay: boolean;
  zoomIntoField?: (field: TextField) => void;
};
export default class TextArea extends React.PureComponent<Props> {
  divRef = React.createRef<HTMLDivElement>();

  textRef = React.createRef<HTMLTextAreaElement>();

  measureLineHeight = () => {
    if (typeof jest !== 'undefined') {
      // JSDom can't measure the line height, so for tests I think fontSize is
      // good enough
      return this.props.fieldData.fontSize;
    }

    return measureLineHeight(this.textRef.current!);
  };

  focus = (focusOptions?: FocusOptions) => {
    const target = this.props.disabled ? this.divRef : this.textRef;
    return target.current!.focus(focusOptions);
  };

  onFocus = () => {
    const { zoomIntoField, fieldData } = this.props;

    // Only trigger on mobile
    if (zoomIntoField && window.innerWidth < 768) {
      zoomIntoField!(fieldData);
    }
  };

  render() {
    const {
      fieldData,
      height,
      width,
      textScale,
      isActive,
      validationErrors,
      selectField,
      updateFields,
      zoomIntoField,
      runZoomIntoField,
      isOverlay,
      ...props
    } = this.props;

    const fontFamily = fieldData.fontFamily || DEFAULT_FONT_FAMILY;
    const fontSize = fieldData.fontSize || DEFAULT_FONT_SIZE;
    const readOnly = fieldData.readOnly || false;
    const fontWeight =
      fieldData.fontWeight && fieldData.fontWeight >= 700 ? 'bold' : 'normal';
    const color = fieldData.fontColor;

    const className = classnames(
      props.className,
      styles.textarea,
      styles[`font--${fontFamily}`],
    );

    const style = {
      height: '100%',
      width: '100%',
      fontSize: `${fontSize}px`,
      fontWeight,
      color,
      ...props.style,
    };

    const value = fieldData.value || '';

    if (isOverlay && value === '') {
      return null;
    }

    return (
      <div
        ref={this.divRef}
        tabIndex={
          // Something in here must be able to be focused for tabbing between
          // fields to work. If the text area is editable, then it gets focus,
          // but if it's disabled then this div needs a tab index. The signer
          // app uses a readOnly flag where nothing in here should have a tab
          // index.
          props.disabled && !readOnly ? 0 : -1
        }
        style={{ outline: 'none', height: '100%' }}
      >
        <textarea
          placeholder={fieldData.placeholder}
          onChange={NOOP}
          {...props}
          onFocus={this.onFocus}
          ref={this.textRef}
          className={className}
          value={value}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="on"
          wrap="off"
          aria-invalid={validationErrors && !!validationErrors[fieldData.id]}
          readOnly={readOnly}
          required={fieldData.required}
          style={{
            ...style,
            color: style.color ?? undefined,
          }}
        />
      </div>
    );
  }
}
