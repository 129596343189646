/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  global-require,
  import/no-extraneous-dependencies,
  max-len,
  no-plusplus,
  no-restricted-syntax,
  no-return-assign,
  @typescript-eslint/no-use-before-define,
  no-void
*/

import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';
import BaseModel from 'common/models/base/model';
import extend from 'lodash/extend';
import * as Sentry from '@sentry/browser';
import {
  validationRegex,
  validationTypes,
} from 'signer-app/signer-experience/signer-validation-constants';
import hsMessages from 'signer/components/common/translations/hs-messages';

const messages = defineMessages({
  numbers_only: {
    id: 'error.validation.numbers_only',
    description:
      'error message for input field in signer flow, shows when inputted data in not numbers only',
    defaultMessage: 'This field only accepts numbers',
  },
  letters_only: {
    id: 'error.validation.letters_only',
    description:
      'error message for input field in signer flow, shows when inputted data in not letters only',
    defaultMessage: 'This field only accepts letters',
  },
  phone_number: {
    id: 'error.validation.phone_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid phone number',
    defaultMessage: 'Please enter a 10- or 11-digit telephone number',
  },
  bank_routing_number: {
    id: 'error.validation.bank_routing_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid bank routing number',
    defaultMessage: 'Please enter a 9-digit routing number',
  },
  bank_account_number: {
    id: 'error.validation.bank_account_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid account number',
    defaultMessage: 'Please enter a minimum of 6 digits',
  },
  email_address: {
    id: 'error.validation.email_address',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid email address',
    defaultMessage: 'Please enter a valid email address',
  },
  zip_code: {
    id: 'error.validation.zip_code',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid zip code',
    defaultMessage: 'Please enter a 5- or 9-digit zip code',
  },
  social_security_number: {
    id: 'error.validation.social_security_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid social security number',
    defaultMessage: 'Please enter a 9-digit social security number',
  },
  employer_identification_number: {
    id: 'error.validation.employer_identification_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid employee ID number',
    defaultMessage: 'Please enter a 9-digit employer identification number',
  },
  custom_regex: {
    id: 'error.validation.custom_regex',
    description:
      'error message for input field in signer flow, shows when inputted data does not pass regex validation',
    defaultMessage: 'The field is invalid.',
  },
  custom_regex_with_format: {
    id: 'error.validation.custom_regex_with_format',
    description:
      'error message for input field in signer flow, shows when inputted data does not pass regex validation',
    defaultMessage: 'The field is invalid. Required format: {format}',
  },
});

module.exports = BaseModel.extend({
  /**
   */

  mixins: [require('common/models/mixins/model')],

  /**
   */

  getValidationError() {
    // The backend assumes that if "required" does not exist on the field, the field is infact required.
    if (this.required !== undefined && !this.required) {
      return;
    }
    if (this.value == void 0) {
      return new Error(intl.formatMessage(hsMessages.requiredMessage));
    }
  },

  /**
   * Get data validation type (i.e. numbers_only, letters_only) error
   */

  getDataValidationError() {
    if (!this.hasDataValidation() || this.value == void 0) {
      return;
    }
    let regex;
    if (this.validationType === validationTypes.VALIDATION_TYPE_CUSTOM_REGEX) {
      if (this.validationCustomRegex == null) {
        // When the value is empty, then everything is valid
        return;
      }
      try {
        regex = new RegExp(this.validationCustomRegex);
      } catch (e) {
        Sentry.captureMessage(
          `Error while initializing new custom regex ${e.message}`,
        );
        return new Error(this.getValidationMsg());
      }
    } else {
      if (!validationRegex[this.validationType]) {
        throw new Error('There was an error with validation');
      }
      regex = validationRegex[this.validationType];
    }

    const startRegexTestTime = Date.now();
    let validationError;
    if (!regex.test(this.value.trim().replace(/\n/g, ''))) {
      // we want to process this error on the front end, not throw it
      validationError = new Error(this.getValidationMsg());
    }
    this.logOvertimeValidation(regex, startRegexTestTime);
    return validationError;
  },

  logOvertimeValidation(regex, startTime) {
    const logTimeout = 2000; // When more than 2s we capture a log
    const now = Date.now();
    if (now > startTime + logTimeout) {
      Sentry.captureMessage(`Regex ${regex} took more than ${logTimeout}ms`);
    }
  },

  /**
   */

  hasValue() {
    return this.value != void 0;
  },

  /**
   */

  hasDataValue() {
    return this.data.value != void 0;
  },

  /**
   */

  hasDataValidation() {
    if (this.validationType != void 0) {
      for (const name in validationTypes) {
        if (this.validationType == validationTypes[name]) {
          return true;
        }
      }
    }

    return false;
  },

  getValidationMsg() {
    if (this.validationType === validationTypes.VALIDATION_TYPE_CUSTOM_REGEX) {
      let withFormat = '';
      if (this.validationCustomRegexFormatLabel) {
        this.validationOptions = {
          format: this.validationCustomRegexFormatLabel,
        };
        withFormat = '_with_format';
      }
      return intl.formatMessage(
        messages[`${this.validationType}${withFormat}`],
        { format: this.validationCustomRegexFormatLabel },
      );
    }
    return intl.formatMessage(messages[this.validationType]);
  },

  /**
   */

  save() {
    this.signatureRequest.save();
  },

  /**
   */

  equals(field) {
    return field && field.guid === this.guid;
  },

  /**
   */

  toJSON() {
    return this.toData();
  },

  /**
   * Caclulates the absolute y position of a field in the document, as
   * field.y is relative to its parent snapshot
   */

  getAbsolutePosition() {
    if (this._absolutePosition) return this._absolutePosition;

    let y = 0;

    for (let i = 0, n = this.snapshots.length; i < n; i++) {
      const currentSnapshot = this.snapshots[i];

      // End once we find the snapshot we're on
      if (this.snapshot === currentSnapshot) break;

      // Otherwise add the height of each page
      for (let j = 0; j < currentSnapshot.pages.length; j++) {
        y += currentSnapshot.pages[j].height;
      }
    }

    return (this._absolutePosition = {
      x: this.x,
      y: this.y + y,
    });
  },

  /**
   */

  getSignerIndex() {
    return this.data.signer;
  },

  /**
   */

  toData() {
    return extend({}, this.data, {
      id: this.guid,
      width: this.width,
      height: this.height,
      required: this.required,
      editable: this.editable,
      isAutoFill: this.isAutoFill,
      autoFillValue: this.autoFillValue,
      order: this.order,
      validate: this.validationOptions,
      value: this.value,
      type: this.type,
      x: this.x,
      y: this.y,
      z: this.z,
    });
  },

  /**
   */

  fromData(data) {
    return {
      guid: data.id || createFieldGUID(),
      width: data.width,
      height: data.height,
      required: data.required,
      order: data.order,
      editable: data.editable,
      isAutoFill: data.isAutoFill,
      autoFillValue: data.autoFillValue,
      validationOptions: data.validate,
      value: data.value === '' ? void 0 : data.value,
      type: data.type,

      // TODO - need to calc
      x: data.x,
      y: data.y,
      z: data.z,
      pageNum: data.pageNum,
      docNum: data.docNum,
    };
  },

  /**
   */

  isAfter(field) {
    return (
      field.docNum < this.docNum ||
      (field.docNum === this.docNum && field.order < this.order)
    );
  },

  /**
   */

  isBefore(field) {
    return !this.isAfter(field);
  },
});

let i = 0;

function createFieldGUID() {
  return `field-${i++}`;
}
