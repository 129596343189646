import React from 'react';
import { useForceUpdate } from 'signer-app/utils/use-force-update';

type AnyFunc = (...args: any[]) => any;

const componentMap = new WeakMap<
  React.ComponentType<any> | AnyFunc,
  Array<() => void>
>();
export function useIsLastMountedInstanceOf(
  Component: React.ComponentType<any> | AnyFunc,
) {
  const forceUpdate = useForceUpdate();
  if (!componentMap.has(Component)) {
    componentMap.set(Component, []);
  }
  const stack = componentMap.get(Component)!;

  React.useEffect(() => {
    function notifyOtherInstances() {
      stack.forEach((otherInstance) => otherInstance());
    }

    stack.unshift(forceUpdate);
    notifyOtherInstances();
    return () => {
      const idx = stack.indexOf(forceUpdate);
      stack.splice(idx, 1);
      notifyOtherInstances();
    };
  }, [forceUpdate, stack]);

  return (
    // This must be the first one mounted because useEffect hasn't run yet
    stack.length === 0 || stack[0] === forceUpdate
  );
}
