import * as ActionTypes from 'signer-app/signature-modal/contexts/signature-modal/action-types';
import fonts from 'signer-app/signature-modal/constants/fonts';

import { loadWebFont } from 'signer-app/signature-modal/utils/fonts';
import type { SignatureModalThunk } from 'signer-app/signature-modal/contexts/signature-modal/types';

export type SignatureModalActions =
  | { type: typeof ActionTypes.CHANGE_FONT }
  | { type: typeof ActionTypes.LOAD_FONT_REQUEST }
  | {
      type: typeof ActionTypes.LOAD_FONT_SUCCESS;
      payload: { fontIndex: number };
    }
  | {
      type: typeof ActionTypes.LOAD_FONT_FAILURE;
      payload: { fontIndex: number };
    };

export function loadFont(fontIndex: number): SignatureModalThunk {
  const font = fonts[fontIndex];

  return (dispatch) => {
    dispatch({ type: ActionTypes.LOAD_FONT_REQUEST });

    loadWebFont(font, (success) => {
      if (success) {
        dispatch({
          type: ActionTypes.LOAD_FONT_SUCCESS,
          payload: {
            fontIndex,
          },
        });
      } else {
        dispatch({
          type: ActionTypes.LOAD_FONT_FAILURE,
          payload: {
            fontIndex,
          },
        });
      }
    });
  };
}

export function nextFont(): SignatureModalThunk {
  return (dispatch, getState) => {
    const state = getState();
    const nextFontIndex = ((state.currentFontIndex ?? 0) + 1) % fonts.length;

    dispatch(loadFont(nextFontIndex));
  };
}

export function changeFont(fontIndex: number): SignatureModalThunk {
  return (dispatch) => {
    dispatch(loadFont(fontIndex));
  };
}
