/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  no-param-reassign,
  no-void,
  prefer-rest-params
*/

/**
 */

module.exports = Object.assign({}, require('./watch-notifications'), {
  /**
   */

  onCreateModel(model) {
    model.notifier = this.notifier;
  },

  /**
   */

  getQuery() {
    return {};
  },

  /**
   */

  notify(message) {
    if (
      message.apiEndpoints !== this.apiEndpoints ||
      !/response/.test(message.type)
    )
      return;
    if (!/insert|update|remove/.test(message.requestMessage.type)) return;

    // In the `/upload` endpoint, we always resolve the API response even if
    // it was unsuccessful.  Checking if the data is actually an error here
    // prevents making another HTTP call when the upload endpoint fails.
    if (message.data instanceof Error) return;

    // just reload. Any caching should be done by the service layer.
    this.load();
  },

  /**
   */

  load(onLoad) {
    if (!onLoad) onLoad = function () {};

    this.setProperties({
      error: void 0,
      loading: true,
    });

    const beforeOnLoad = function (error, data) {
      if (!error) {
        this.setProperties({
          data,
        });
      }

      this.setProperties({
        error,
        loading: false,
      });

      onLoad.apply(this, arguments);
    }.bind(this);

    return Promise.resolve(
      this.notifier.notify({
        type: 'load',
        multi: true,
        apiEndpointAlias: this.apiEndpointAlias,
        target: this,
        query: this.getQuery(),
      }),
    ).then(([data]) => {
      beforeOnLoad(void 0, data);
    }, beforeOnLoad);
  },
});
