/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import classnames from 'classnames';
import BlueButton from 'common/components/buttons/blue';
import Link from 'common/components/link';
import createReactClass from 'create-react-class';
import digStyles from 'signer/components/common/dig-workaround.module.scss';

require('./index.scss');

const HeaderButton = createReactClass({
  render() {
    if (this.props.alias || this.props.href) {
      return (
        <Link
          className={`m-signer-mobile-header-button ${this.props.className}`}
          {...this.props}
        >
          {this.props.children}
        </Link>
      );
    }

    return (
      <BlueButton
        {...this.props}
        className={classnames(
          'm-signer-mobile-header-button',
          this.props.className,
          digStyles.focusRing,
        )}
        buttonText={this.props.buttonText || this.props.children}
      />
    );
  },
});

module.exports = HeaderButton;
