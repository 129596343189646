import styles from 'signer-app/signature-modal/saved/saved.module.css';

import React, { KeyboardEventHandler } from 'react';
import uniqBy from 'lodash/uniqBy';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { EmptyTraySpot } from '@dropbox/dig-illustrations/dist/cjs/spot/empty-tray';
import { Text } from '@dropbox/dig-components/typography';
import classnames from 'classnames';
import { Signature, SignatureType } from 'signer-app/signature-modal/types';
import SavedSignature from 'signer-app/signature-modal/saved/saved-signature';
import { SigData } from 'signer-app/context/signer-app-client/signature';

export interface SavedProps {
  intl: IntlShape;
  type: SignatureType;
  enableInsertButtonCallback: (enableInsert: boolean) => void;
  signatures: SigData[];
  getSignatureUrl: (signature: SigData) => string;
  selectedSavedSignature?: SigData;
  onSavedSignatureRemove: (signature: SigData) => void;
  onSavedSignatureSelect: (signature: SigData) => void;
  getPaginatedSignatures?: (type: SignatureType, page: number) => void;
  count: number;
  isMobile: boolean;
}

const selectSignatureLegacyWay = (signature: SigData) => {
  // HACK Unfortunately we have to do this to support legacy code.
  window.primarySignatureGuid = signature.guid;
};

class Saved extends React.PureComponent<SavedProps> {
  signatureRefsByGuid: { [key: string]: HTMLElement } = {};

  onSelectSignature = (signature: SigData) => () => {
    this.selectSignature(signature);
  };

  focusOnSignature = (signature: Signature) => {
    const ref = this.signatureRefsByGuid[signature.guid];
    if (ref) {
      ref.focus();
    }
  };

  scrollSignatureIntoView = (signatureGuid: string) => {
    const ref = this.signatureRefsByGuid[signatureGuid];
    if (ref) {
      ref.scrollIntoView?.();
    }
  };

  onKeyDown: KeyboardEventHandler<HTMLElement> = (e) => {
    const uniqSignatures = this.getUniqueSignatures();
    let selectedIndex = this.props.selectedSavedSignature
      ? uniqSignatures.findIndex(
          (sig) => sig.guid === this.props.selectedSavedSignature?.guid,
        )
      : -1;
    selectedIndex = Math.max(0, selectedIndex);

    const signaturesPerRow = this.getSignaturesPerRow();
    let nextSignature = null;
    switch (e.key) {
      case 'ArrowUp': {
        e.preventDefault();
        if (selectedIndex - signaturesPerRow >= 0) {
          nextSignature = uniqSignatures[selectedIndex - signaturesPerRow];
        }
        break;
      }
      case 'ArrowDown':
        e.preventDefault();
        if (selectedIndex + signaturesPerRow <= uniqSignatures.length - 1) {
          nextSignature = uniqSignatures[selectedIndex + signaturesPerRow];
        }
        break;
      case 'ArrowLeft':
        e.preventDefault();
        if (selectedIndex % signaturesPerRow > 0) {
          nextSignature = uniqSignatures[selectedIndex - 1];
        }
        break;
      case 'ArrowRight':
        e.preventDefault();
        if (
          selectedIndex < uniqSignatures.length - 1 &&
          (selectedIndex + 1) % signaturesPerRow > 0
        ) {
          nextSignature = uniqSignatures[selectedIndex + 1];
        }
        break;
      default:
      // do nothing
    }

    if (nextSignature) {
      this.selectSignature(nextSignature);
    }
  };

  componentDidMount() {
    if (window.primarySignatureGuid || this.props.selectedSavedSignature) {
      const selectedGuid =
        window.primarySignatureGuid || this.props.selectedSavedSignature?.guid;
      const signature =
        Array.isArray(this.props.signatures) &&
        this.props.signatures.find(({ guid }) => guid === selectedGuid);
      if (signature) {
        this.props.onSavedSignatureSelect(signature);
        this.scrollSignatureIntoView(signature.guid);
      }
    } else if (
      Array.isArray(this.props.signatures) &&
      this.props.signatures.length > 0
    ) {
      const firstSignature = this.props.signatures[0];
      this.props.onSavedSignatureSelect(firstSignature);
      this.scrollSignatureIntoView(firstSignature.guid);
    }

    this.fetchPaginatedSignatures();
    this.props.enableInsertButtonCallback(this.shouldEnableInsert());
  }

  componentDidUpdate() {
    this.props.enableInsertButtonCallback(this.shouldEnableInsert());
  }

  selectSignature(signature: SigData) {
    selectSignatureLegacyWay(signature);
    this.props.onSavedSignatureSelect(signature);
    this.focusOnSignature(signature);
  }

  getSignatureData() {
    return this.props.selectedSavedSignature;
  }

  getUniqueSignatures() {
    const { signatures } = this.props;
    return signatures.length && Array.isArray(signatures)
      ? uniqBy(signatures, (sig) => sig.guid)
      : [];
  }

  getUniqueSignaturesGrid() {
    const uniqSignatures = this.getUniqueSignatures();
    const signaturesPerRow = this.getSignaturesPerRow();
    const uniqSignaturesGrid: SigData[][] = [];
    let col = 0;
    let row = 0;
    for (let i = 0; i < uniqSignatures.length; i++) {
      if (col >= signaturesPerRow) {
        col = 0;
        row++;
      }
      if (row >= uniqSignaturesGrid.length) {
        uniqSignaturesGrid.push([]);
      }
      uniqSignaturesGrid[row].push(uniqSignatures[i]);
      col++;
    }
    return uniqSignaturesGrid;
  }

  getSignaturesPerRow() {
    return this.props.isMobile ? 1 : 3;
  }

  shouldEnableInsert() {
    return Boolean(
      this.props.signatures.length && !!this.props.selectedSavedSignature,
    );
  }

  fetchPaginatedSignatures = (page = 1) => {
    if (this.props.getPaginatedSignatures) {
      this.props.getPaginatedSignatures(this.props.type, page);
    }
  };

  render() {
    const { selectedSavedSignature, onSavedSignatureRemove, getSignatureUrl } =
      this.props;
    const isSelected = (
      signature: Signature,
      rowIndex: number,
      colIndex: number,
    ) =>
      selectedSavedSignature
        ? signature.guid === selectedSavedSignature.guid
        : rowIndex === 0 && colIndex === 0;
    const signaturesPerRow = this.getSignaturesPerRow();
    const uniqSignaturesGrid = this.getUniqueSignaturesGrid();
    return (
      <div>
        {uniqSignaturesGrid.length > 0 ? (
          <table
            role="grid"
            aria-label="Saved signatures"
            className={styles.signatureGrid}
            data-qa-ref="signing-modal--saved-signatures"
          >
            <tbody>
              {uniqSignaturesGrid.map((row, rowIndex) => (
                <tr role="row" key={rowIndex}>
                  {row.map((signature, colIndex) => (
                    <td
                      role="gridcell"
                      aria-selected={isSelected(signature, rowIndex, colIndex)}
                      ref={(el) => {
                        if (el) {
                          this.signatureRefsByGuid[signature.guid] = el;
                        }
                      }}
                      tabIndex={
                        isSelected(signature, rowIndex, colIndex) ? 0 : -1
                      }
                      className={classnames(styles.signatureGridCell, {
                        [styles.selected]: isSelected(
                          signature,
                          rowIndex,
                          colIndex,
                        ),
                      })}
                      key={signature.guid}
                      data-qa-ref={`carousel-item-${rowIndex * signaturesPerRow + colIndex}`}
                      onKeyDown={this.onKeyDown}
                      onMouseDown={this.onSelectSignature(signature)}
                    >
                      <SavedSignature
                        index={rowIndex * signaturesPerRow + colIndex + 1}
                        isSelected={isSelected(signature, rowIndex, colIndex)}
                        signature={signature}
                        signatureSrc={getSignatureUrl(signature)}
                        onDelete={onSavedSignatureRemove}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.noSignatureContainer}>
            <EmptyTraySpot />
            <Text tagName="p">
              <FormattedMessage
                id="4b72bf76dd354a18f59c882f24c066dac2a36702e314ef54c63700a2d809340b"
                description="Short notice shown to the user when they do not have any saved signatures"
                defaultMessage="Signatures you’ve used will appear here."
              />
            </Text>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(Saved, { forwardRef: true });
