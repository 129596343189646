import React from 'react';
import {
  SignAppClientProvider,
  useSignAppClient,
} from 'js/sign-components/sign-app-client/context';
import * as authentication from 'signer-app/context/signer-app-client/authentication';
import * as signer from 'signer-app/context/signer-app-client/signer';
import * as signerApi from 'signer-app/context/signer-app-client/signer-api';
import * as tours from 'signer-app/context/signer-app-client/tours';
import signatureActions from 'signer-app/context/signer-app-client/signature';

export type SignerAppClient = {
  signatures: ReturnType<typeof signatureActions>;
  authentication: typeof authentication;
  signer: typeof signer;
  signerApi: typeof signerApi;
  tours: typeof tours;
};

// export const {
//   useContext: useSignerAppClient, Provider: SignerAppClientProvider,
// } = createSimpleDataContext<SignerAppClient>('signer-app-client');

export const useSignerAppClient = (): SignerAppClient => {
  return useSignAppClient();
};

type ProviderProps = React.PropsWithChildren<{
  client: SignerAppClient;
}>;
export const SignerAppClientProvider = ({
  client,
  ...props
}: ProviderProps) => (
  <SignAppClientProvider client={client as any} {...props} />
);
