import React from 'react';

type LabelNode = {
  label: string;
  childNodes: LabelNode[];
};
function nodeToStrings(node: LabelNode): string[] {
  if (node.childNodes.length > 0) {
    const prefix = node.label.length > 0 ? `${node.label}/` : '';

    return node.childNodes.flatMap(nodeToStrings).map((s) => `${prefix}${s}`);
  }
  return [node.label];
}

type LabelContextShape = {
  register: (childRoute: LabelNode) => void;
  unregister: (childRoute: LabelNode) => void;
  activeComponents: string;
};
export const labelContext = React.createContext<null | LabelContextShape>(null);

type LabelContextProps = React.PropsWithChildren<{
  label: string;
}>;

export function withLabel<P>(
  Component: React.ComponentType<P>,
  label: string,
): React.ComponentType<P> {
  function LabeledComponent(props: P) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <LabelContext label={label}>
        <Component {...(props as any)} />
      </LabelContext>
    );
  }

  return LabeledComponent;
}

export function LabelContext({ label, children }: LabelContextProps) {
  // "child" here does not mean it's a direct child of this component.
  const [childNodes, setChildNodes] = React.useState<LabelNode[]>([]);
  const parentContext = React.useContext(labelContext);

  const self = React.useMemo(
    () => ({
      label,
      childNodes,
    }),
    [childNodes, label],
  );

  React.useEffect(() => {
    if (parentContext) {
      parentContext.register(self);
      return () => parentContext.unregister(self);
    }
    return undefined;
  }, [label, parentContext, self]);

  const register = React.useCallback((childRoute) => {
    setChildNodes((routes) => [...routes, childRoute]);
  }, []);

  const unregister = React.useCallback((childRoute: LabelNode) => {
    setChildNodes((routes) => routes.filter((r) => r !== childRoute));
  }, []);

  const activeComponents = React.useMemo(() => {
    if (!parentContext) {
      return nodeToStrings(self).sort().join('\n');
    }
    return parentContext.activeComponents;
  }, [parentContext, self]);

  const contextValue = React.useMemo<LabelContextShape>(
    () => ({
      register,
      unregister,
      activeComponents,
    }),
    [register, activeComponents, unregister],
  );

  return (
    <labelContext.Provider value={contextValue}>
      {children}
    </labelContext.Provider>
  );
}
