import React from 'react';
import Hammer from 'hammerjs';
import moment, { Moment } from 'moment';
import styles from 'js/sign-components/common/hooks.module.css';
import { useSiteCode } from 'js/sign-components/common/use-site-code';
import { getBrandName } from 'js/sign-components/common/brand';
import { useEventListener } from 'signer-app/utils/use-event-listener';

export function useShowOnLongPress(timeout = 2000) {
  const ref = React.useMemo(() => React.createRef<HTMLElement>(), []);
  const [{ isActive, isHolding }, setState] = React.useState({
    isActive: false,
    isHolding: false,
  });

  // When the user releases the screen, it should remain active for {timeout}
  // milliseconds, unless the user taps away.
  React.useEffect(() => {
    if (isActive && !isHolding) {
      let tmpTimeout: NodeJS.Timeout | null = setTimeout(() => {
        tmpTimeout = null;
        setState({
          isActive: false,
          isHolding: false,
        });
      }, timeout);
      return () => {
        if (tmpTimeout) {
          clearTimeout(tmpTimeout);
        }
      };
    }
    return () => {};
  }, [isActive, isHolding, timeout]);

  // I pulled this out into its own variable to force the `useEffect` to trigger
  // when the target gets set.
  const target = ref.current;

  // Tapping away should instantly clear isActive
  useEventListener(target, 'blur', () => {
    if (isActive) {
      setState({
        isActive: false,
        isHolding: false,
      });
    }
  });
  useEventListener(target, 'touchend', () => {
    if (isActive) {
      setState({
        isActive,
        isHolding: false,
      });
    }
  });

  // A long press on mobile triggers the context menu
  useEventListener(target, 'contextmenu', (event) => {
    if (isActive) {
      event.preventDefault();
    }
  });

  React.useEffect(() => {
    if (!target) {
      return;
    }

    const hammer = new Hammer(target, {
      recognizers: [[Hammer.Press]],
    });
    hammer.on('press', () => {
      setState({
        isActive: true,
        isHolding: true,
      });
    });

    return () => {
      hammer.destroy();
    };
  }, [target, ref]);

  const r: [typeof ref, typeof isActive] = [ref, isActive];
  return r;
}

/**
 * This might not be an exhaustive list, but it matches everything we
 * currently have
 */
type BasicMediaQuery =
  | `(max-width: ${number}px)`
  | `(min-width: ${number}px)`
  | `(orientation: ${'landscape' | 'portrait'})`
  | `(min-aspect-ratio: ${number}/${number})`;

type CompoundQueries<T extends string> = `${T}, ${T}` | `${T} and ${T}`;

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 */
type MediaQuery =
  // This can be just a basic query
  | BasicMediaQuery
  // or a compound query
  /**
   * TypeScript didn't like making this recursive, so my workaround is to just
   * go 2 levels deep here.
   */
  | CompoundQueries<
      // Each side can either be a BasicMediaQuery
      // or a compound containing only basic queries.
      BasicMediaQuery | CompoundQueries<BasicMediaQuery>
    >;

export function useMatchMedia(query: MediaQuery) {
  const [matches, setMatches] = React.useState(false);
  React.useLayoutEffect(() => {
    // https://caniuse.com/matchmedia
    const mq = window.matchMedia(query);

    const onChange = () => {
      setMatches(mq.matches);
    };

    if (!mq.addEventListener && !mq.removeEventListener) {
      // JSDom doesn't implement these
      mq.addEventListener = () => {};
      mq.removeEventListener = () => {};
    }

    // Safari <=13 doesn't have addEventListener.
    if (mq.addEventListener == null) {
      mq.addListener(onChange);
      onChange();
      return () => {
        mq.removeListener(onChange);
      };
    }

    mq.addEventListener('change', onChange);
    onChange();
    return () => {
      mq.removeEventListener('change', onChange);
    };
  }, [query]);

  return matches;
}
export const useIsMobile = () => useMatchMedia('(max-width: 480px)');

export declare function beforeEach(f: Function): void;

export function useOnClickAway(
  isEnabled: boolean,
  callback: (event: Event) => void,
): React.MutableRefObject<HTMLDivElement | null> {
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClick = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback(event);
    }
  };

  const targetElement = isEnabled ? document.body : null;
  useEventListener(targetElement, 'click', handleClick);
  return ref;
}

let selectNoneCounter = 0;
export function useGlobalDisableSelect(enabled = true) {
  React.useEffect(() => {
    if (!enabled) {
      return () => {};
    }
    selectNoneCounter++;
    document.body.classList.add(styles.disableSelect);

    return () => {
      selectNoneCounter--;
      if (selectNoneCounter === 0) {
        document.body.classList.remove(styles.disableSelect);
      }
    };
  }, [enabled]);
}

// https://usehooks.com/useDebounce/
export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

let midnight = moment({ hour: 0, minute: 0 });
let january1 = moment({ month: 0 });
export function useVariableDateFormat(dateStr: string) {
  const date = moment(dateStr);
  const dateFormat = React.useMemo(() => {
    if (date.isAfter(midnight)) {
      return 'h:mm a';
    } else if (date.isAfter(january1)) {
      return 'MMM D';
    } else {
      return 'M/D/YY';
    }
  }, [date]);

  return date.format(dateFormat);
}

if (typeof beforeEach === 'function') {
  const m = midnight;
  const j = january1;
  beforeEach(() => {
    midnight = m;
    january1 = j;
  });
}
useVariableDateFormat.setToday = (date: Moment) => {
  if (typeof beforeEach === 'function') {
    midnight = moment(date).set('hour', 0).set('minute', 0);
    january1 = moment(date).set('month', 0);
  }
};

export function useOnMount(effect: Parameters<typeof React.useEffect>[0]) {
  React.useEffect(
    () => {
      return effect();
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [],
  );
}

export function useNewKeyOnChange(deps: React.DependencyList) {
  return React.useMemo(() => {
    return Math.random().toString(36);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

/**
 * Clipboard helper.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard
 * @author Nathan Buchar <nbuchar@dropbox.com>
 * @example
 *
 * ```
 * const [isClipboardSupported, copyToClipboard] = useClipboard();
 *
 * // ...
 *
 * {isClipboardSupported && (
 *   <button onClick={() => copyToClipboard('hello')}>
 *     Click me!
 *   </button>}
 * )}
 * ```
 */
export function useClipboard(): [boolean, (value: string) => void] {
  const isClipboardSupported = React.useMemo(() => {
    try {
      return !!navigator.clipboard.writeText;
    } catch (err) {
      return false;
    }
  }, []);

  const copyToClipboard = React.useCallback(
    (value: string): Promise<void> => {
      if (isClipboardSupported) {
        return navigator.clipboard.writeText(value);
      } else {
        return Promise.reject();
      }
    },
    [isClipboardSupported],
  );

  return [isClipboardSupported, copyToClipboard];
}

export function useDocumentTitle(title: string | null): void {
  const shouldReplace = title !== null;
  const originalTitle = React.useRef(document.title);
  const siteCode = useSiteCode();
  const brandName = getBrandName(siteCode);

  if (shouldReplace) {
    const formattedTitle = `${title} | ${brandName}`;

    if (document.title !== formattedTitle) {
      document.title = formattedTitle;
    }
  }

  React.useEffect(() => {
    if (shouldReplace) {
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.title = originalTitle.current;
      };
    } else {
      return undefined;
    }
  }, [shouldReplace]);
}

/**
 * Returns a function that calculates the time since the component was mounted (in milliseconds).
 */
export function useGetTimeSinceMount(): () => number {
  const mountedAtRef = React.useRef(Date.now());
  // Store the time when the component is mounted (as a timestamp).
  useOnMount(() => {
    mountedAtRef.current = Date.now();
  });
  return () => {
    return Date.now() - mountedAtRef.current;
  };
}
