declare let WebFont: any;

/**
 * @callback loadWebFontCallback
 * @param {boolean} success
 */

/**
 * Abstracts webfontloader so that it's a bit more
 * developer friendly.
 *
 * @param {Object} obj
 * @param {loadWebFontCallback} fn
 * @returns {void}
 */
export function loadWebFontFromSource(
  obj: { google: { families: any[] } },
  fn: (arg0: boolean) => void,
) {
  // Does not work in Storybook
  if (typeof WebFont === 'undefined') {
    fn(false);
    return;
  }

  // This can't run in tests, so it needs to be excluded from coverage.
  /* istanbul ignore next */
  WebFont.load({
    active: () => fn(true),
    fontinactive: () => fn(false),
    ...obj,
  });
}

/**
 * Attempts to load the given font from the HelloSign CDN.
 *
 * @param {string} font
 * @param {loadWebFontCallback} fn
 * @returns {void}
 */
export function loadWebFontFromCDN(
  font: string,
  fn: { (success: any): void; (arg0: boolean): void },
) {
  import(`./${font.toLowerCase().replace(/\s/g, '-')}.module.css`).then(() => {
    fn(true);
  });
}

/**
 * Attempts to load the given font from Google Fonts.
 *
 * @param {string} font
 * @param {loadWebFontCallback} fn
 * @returns {void}
 */
export function loadWebFontFromGoogle(
  font: any,
  fn: { (success: any): void; (arg0: boolean): void },
) {
  loadWebFontFromSource(
    {
      google: {
        families: [font],
      },
    },
    fn,
  );
}

/**
 * Attempts to load the given using Google Fonts or the
 * HelloSign CDN as a fallback.
 *
 * @param {string} font
 * @param {loadWebFontCallback} fn
 * @returns {void}
 */
export function loadWebFont(
  font: string,
  fn: { (success: any): void; (arg0: any): void },
) {
  loadWebFontFromGoogle(font, (success) => {
    // Tests run in JSODOM, so we can't load fonts from Google. This should just
    // ignore the success branch.
    /* istanbul ignore if */
    if (success) {
      fn(success);
    } else {
      loadWebFontFromCDN(font, (success) => {
        fn(success);
      });
    }
  });
}
