import hsFetch from 'js/sign-components/common/hs-fetch';
import type {
  ActionReturn,
  EndpointResponse,
  UserPostSignUpFields,
} from 'hellospa/components/user-post-sign-up-form/types';
import type {
  RecoverPasswordFormFields,
  RecoverPasswordFormReturn,
} from 'hellospa/components/recover-password-form/types';
import type {
  SetPasswordFormFields,
  setPasswordActionReturn,
  setPasswordEndpointResponse,
} from 'hellospa/components/set-password-form/types';

export async function userPostSignUpConfirm(
  fields: UserPostSignUpFields,
): Promise<ActionReturn> {
  try {
    const response = await hsFetch('/endpoint/account/postSignUpConfirm', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(fields),
    });
    const result: EndpointResponse = await response.json();

    if (result.success && !result.error) {
      return { isError: false };
    }
    if (!result.success && result.error) {
      return { isError: true, errors: result.error };
    }
  } catch (e) {
    throw e;
  }
  return { isError: true };
}

export async function userCancelOfferResponse(
  switchToAnnualBilling: boolean,
  displayedDiscountPrice: string,
): Promise<{
  isError: boolean;
  error?: string;
}> {
  try {
    const response = await hsFetch('/endpoint/account/acceptCancelOffer ', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        switchToAnnualBilling,
        displayedDiscountPrice,
      }),
    });
    const result: {
      success: boolean;
      error?: string;
    } = await response.json();

    if (result.success && !result.error) {
      return { isError: false };
    }
    if (!result.success && result.error) {
      return { isError: true, error: result.error };
    }
  } catch (e) {
    throw e;
  }
  return { isError: true };
}

export async function submitPhoneNumber(
  countryCode: string,
  phoneNumber: string,
): Promise<{
  isError: boolean;
  error?: string;
}> {
  try {
    const response = await hsFetch('/home/help/live-support/phone-callback', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        countryCode,
        phoneNumber,
      }),
    });
    const result: {
      success: boolean;
      error?: {
        message: string;
      };
    } = await response.json();
    if (result.error) {
      return { isError: true, error: result.error.message };
    }
    if (result.success) {
      return { isError: false };
    }
  } catch (e) {
    throw e;
  }
  return { isError: true };
}

export async function userTrialExtensionResponse(
  accepted: boolean = true,
): Promise<{
  isError: boolean;
  error?: string;
}> {
  try {
    const response = await hsFetch('/endpoint/account/trialExtensionAccept', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        trialAccepted: accepted,
      }),
    });
    const result: {
      success: boolean;
      error?: string;
    } = await response.json();

    if (result.success && !result.error) {
      return { isError: false };
    }
    if (!result.success && result.error) {
      return { isError: true, error: result.error };
    }
  } catch (e) {
    throw e;
  }
  return { isError: true };
}

export async function recoverPasswordResponse(
  fields: RecoverPasswordFormFields,
): Promise<RecoverPasswordFormReturn> {
  try {
    const formData = new FormData();
    formData.append('recover[email_address]', fields.email);
    formData.append('recover[redirect_url]', fields.redirectUrl);
    const response = await hsFetch('/account/recoverPassword', {
      headers: {
        Accept: 'application/json',
      },
      method: 'POST',
      body: formData,
    });
    const result: setPasswordEndpointResponse = await response.json();
    if (result.success) {
      return { isError: false, submitted: true };
    }
    if (result.success && !result.errors) {
      return {
        isError: false,
        submitted: true,
      };
    }
    if (!result.success && result.errors) {
      return {
        isError: true,
        errors: result.errors,
      };
    }
  } catch (e) {
    throw e;
  }
  return { isError: true };
}

export async function setPasswordResponse(
  values: SetPasswordFormFields,
): Promise<setPasswordActionReturn> {
  try {
    const formData = new FormData();
    formData.append('set_password[new_password]', values.password);
    formData.append('nopre', values.nopre);
    formData.append('post_confirm', values.postConfirm);
    formData.append('redirect_url', values.redirectUrl);
    const response = await hsFetch('/onboarding/setPassword', {
      headers: {
        Accept: 'application/json',
      },
      method: 'POST',
      body: formData,
    });
    const result: setPasswordEndpointResponse = await response.json();
    if (result.success && !result.errors) {
      return {
        isError: false,
        redirectUrl: result.redirectUrl,
      };
    }
    if (!result.success && result.errors) {
      return {
        isError: true,
        errors: result.errors,
      };
    }
  } catch (e) {
    throw e;
  }
  return { isError: true };
}

export async function setSignatureExpirationDate(
  superGroupId: number,
  expirationDate: Date,
): Promise<{ error: string | null }> {
  try {
    const formData = new FormData();
    formData.append('super_group_id', superGroupId.toString(10));
    formData.append('expiration_date', expirationDate.toISOString());
    const response = await hsFetch('/send/SignatureExpiration', {
      method: 'POST',
      body: formData,
    });
    const result = await response.json();

    if (result.success) {
      return { error: null };
    }

    return {
      error: result.error,
    };
  } catch (e) {
    throw e;
  }
}
