import React from 'react';
import { GlobalHeader } from '@dropbox/dig-components/global_header';
import { FssWordmark } from '@dropbox/dig-logos';
import { NextButton } from 'signer-app/signer-experience/next-button';
import { FormattedMessage } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { LegalConfirm } from 'signer-app/signer-experience/legal-confirm';

export function ReviewToolbar(props: { legalVersion: number }) {
  return (
    <GlobalHeader.Wrapper>
      <GlobalHeader.Logo src={FssWordmark} href="/" />

      <div>
        <FormattedMessage
          id="signer.confirm.title"
          description="text in signer flow, informing signer the process of signing the document is almost done"
          defaultMessage="Almost done."
        />

        <LegalConfirm legalVersion={props.legalVersion} />
      </div>

      <GlobalHeader.UtilityNav>
        <Button variant="borderless">
          <FormattedMessage
            defaultMessage="Edit"
            id="83d20b5fc31227cad45cf12c9bd2242613fa00eab8c8418b707a16e91950ebf1"
            description="Button to edit the document."
          />
        </Button>
        <NextButton>
          <FormattedMessage
            defaultMessage="I agree"
            id="aff45f613f61f9fece700e8c6e12d9e7537a0a58bd8766957890717432713eda"
            description="Button to agree to the document."
          />
        </NextButton>
      </GlobalHeader.UtilityNav>
    </GlobalHeader.Wrapper>
  );
}
