import React from 'react';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import {
  DisplayField,
  signatureRequestContext,
  zoomContext,
  ORIGIN_VIEWPORT,
  ZoomContextShape,
} from 'signer-app/signature-request';
import { FormattedMessage } from 'react-intl';
import CONSTANTS from 'signer-app/signature-modal/constants';
import {
  SignatureModalContext,
  useSignatureModalContext,
} from 'signer-app/signature-modal/signature-modal-context/context';
import SignatureModal from 'signer-app/signature-modal/modal';
import { signerContext } from 'signer-app/signer-signature-document/context';
import styles from 'signer-app/signer-signature-document/signature-placeholder.module.css';
import { SignerContextShape } from 'signer-app/signer-signature-document/types';
import { SignatureField, InitialsField } from 'signer-app/types/editor-types';

let initialized = false;
export function initAppElement() {
  if (!initialized) {
    // React based pages have a root element,
    // PHP based pages use a site-wrapper,
    // Signer app uses signer-mobile-application
    const wrapper =
      document.getElementById('root') ||
      document.getElementById('site-wrapper') ||
      document.getElementById('signer-mobile-application');
    if (wrapper != null) {
      initialized = true;
      ReactModal.setAppElement(wrapper);
    }
  }
}

function useMenu(
  fromScreenCoords: ZoomContextShape['fromScreenCoords'],
  isActive: boolean,
  fieldData: SignatureField | InitialsField,
  handleOpen: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  insertSignature: SignerContextShape['insertSignature'],
  canInsertEverywhere: boolean,
) {
  const [placement, setPlacement] = React.useState('top');
  const menuRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (menuRef.current && placement === 'top') {
      // fieldData will provide the pageIndex and other position values, but the
      // only thing we care about here is converting the height of the menu to
      // address coordinates.
      const { height } = fromScreenCoords(
        {
          ...fieldData,
          height: menuRef.current.clientHeight,
        },
        ORIGIN_VIEWPORT,
      );

      // If there isn't enough room on the page to put the menu on top, switch
      // to the bottom.
      if (height > fieldData.y) {
        setPlacement('bottom');
      }
    }
  }, [fieldData, placement, fromScreenCoords]);

  const handleClear = React.useCallback(
    (e) => {
      e.preventDefault();
      insertSignature(null, fieldData, false);
    },
    [fieldData, insertSignature],
  );
  const handleInsertEverywhere = React.useCallback(
    (e) => {
      e.preventDefault();
      insertSignature(fieldData.signature, fieldData, true);
    },
    [fieldData, insertSignature],
  );

  // No menu if it's readOnly
  if (fieldData.readOnly) {
    return null;
  } else {
    return (
      isActive &&
      fieldData.signature != null && (
        <div
          ref={menuRef}
          className={classnames(styles.menuPosition, styles[placement])}
        >
          <span className={classnames(styles.arrow, styles[placement])} />
          <div className={styles.menu}>
            {canInsertEverywhere && (
              <a
                className={styles.menuItem}
                href="#"
                onClick={handleInsertEverywhere}
              >
                <FormattedMessage
                  id="signer.document.signature.insertEverywhereLabelText"
                  description="tooltip link text in signer flow, when clicked inserts the inputted data to all similar fields"
                  defaultMessage="Insert everywhere"
                />
              </a>
            )}
            <a className={styles.menuItem} href="#" onClick={handleOpen}>
              <FormattedMessage
                id="signer.document.signature.editLabelText"
                description="tooltip link text in signer flow, when clicked signer can edit the inputted data"
                defaultMessage="Edit"
              />
            </a>
            <a className={styles.menuItem} href="#" onClick={handleClear}>
              <FormattedMessage
                id="signer.document.signature.clearLabelText"
                description="tooltip link text in signer flow, when clicked clears the inputted data"
                defaultMessage="Clear"
              />
            </a>
          </div>
        </div>
      )
    );
  }
}

let lastOpenFieldId: null | string = null;
/**
 * This is a hack to work around the fact that the document is mounted on the
 * preview page. When you interact with any field, everything has to unmount,
 * navigate to the signing page, then regenerate the whole document tree again.
 * Doing so unmounts all `<SignaturePlaceholder` which may have a modal open.
 */
function useSignatureModalHack(
  isActive: boolean,
  fieldData: SignatureField | InitialsField,
) {
  if (!isActive && lastOpenFieldId === fieldData.id) {
    lastOpenFieldId = null;
  }

  // If a different instance of this component opened a modal for this field,
  // then the user must have clicked the field on the preview page and the modal
  // needs to be re-opened.
  const initialOpen = isActive && lastOpenFieldId === fieldData.id;

  const onModalOpened = (opened: boolean) => {
    lastOpenFieldId = opened ? fieldData.id : null;
  };

  return [initialOpen, onModalOpened] as const;
}

type SignaturePlaceholderProps = {
  fieldData: SignatureField | InitialsField;
  textScale: number;
  className?: string;
};

/**
 * <SignaturePlaceholder renders the signature if it exists or a placeholder,
 * and it's where the signature modal is opened if you click on the field.
 *
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764538314477712
 */
function SignaturePlaceholder(
  { fieldData, textScale, className, ...props }: SignaturePlaceholderProps,
  ref: React.ForwardedRef<any>,
) {
  initAppElement();
  const signatureModalProps = useSignatureModalContext();
  const { selectedFieldIds } = React.useContext(signatureRequestContext);
  const { fromScreenCoords } = React.useContext(zoomContext);
  const {
    insertSignature,
    startEnabled,
    activeSignatures,
    emptySignatureCount,
    emptyInitialsCount,
  } = React.useContext(signerContext);

  const isActive = React.useMemo(
    () => selectedFieldIds.includes(fieldData.id),
    [selectedFieldIds, fieldData.id],
  );
  const [initialOpen, onModalOpened] = useSignatureModalHack(
    isActive,
    fieldData,
  );
  const [state, setState] = React.useState(() => ({
    // For this to trigger it must have been a different instance because this
    // initializer only runs when the component mounts.
    open: initialOpen,
    error: null,
    isCreatingSignatureOrInitials: false,
  }));
  const handleOpen = React.useCallback(
    (e) => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
      onModalOpened(true);
      setState((state) => ({ ...state, open: startEnabled }));
    },
    [onModalOpened, startEnabled],
  );
  const handleClose = React.useCallback(() => {
    // This works on the assumption that handleClose isn't called when a modal
    // is forced closed by having its parent unmounted. When moving from the
    // preview page in the signer app, cleanup won't happen and the next
    // instance of this component can re-open the modal
    onModalOpened(false);
    // Especially in a mobile view, the signature modal and leave the page
    // scrolled down which leaves the toolbar off the screen. Scrolling
    // happens inside a <div that contains the document being signed.
    document.body.scrollTo?.(0, 0);
    setState((state) => ({
      ...state,
      open: false,
      isCreatingSignatureOrInitials: false,
    }));
  }, [onModalOpened]);

  const handleOpenOrInsert = React.useCallback(() => {
    const isSignatureField = fieldData.type === 'signature';
    // if it's a signature field and there's an active signature available, use that
    if (isSignatureField && activeSignatures.signature != null) {
      insertSignature(activeSignatures.signature, fieldData, false);
      return;
    }

    // if it's an initials field and there's an active initials available, use that
    if (!isSignatureField && activeSignatures.initials != null) {
      insertSignature(activeSignatures.initials, fieldData, false);
      return;
    }

    // otherwise open the modal for the user to do stuff
    handleOpen(false);
  }, [handleOpen, fieldData, insertSignature, activeSignatures]);
  const handleInsert = React.useCallback(
    (signature, insertEverywhere) => {
      insertSignature(signature, fieldData, insertEverywhere);
      handleClose();
    },
    [fieldData, handleClose, insertSignature],
  );

  const handleKeyPress = React.useCallback(
    (e) => {
      let key = e.key;
      // Safari doesn't support .key, so we have to fall back to .keyCode
      key = key || e.keyCode;
      switch (key) {
        case 'Enter':
        case ' ':
          e.preventDefault();
          return handleOpen(false);
        default:
      }
    },
    [handleOpen],
  );

  // If we are creating signature/initials
  // We display a message.
  const getCreatingSignatureOrInitialsLoaderContent = () => {
    if (state.isCreatingSignatureOrInitials) {
      return (
        <FormattedMessage
          id="signModal.loader.text"
          description="loading text in editor stating that signature/intials are being saved"
          defaultMessage="Saving your { type, select, S {signature} other {initials} }"
          values={{ type: fieldData.type === 'signature' ? 'S' : 'I' }}
        />
      );
    }
    return null;
  };

  // I created signatureContext so that the editorContext doesn't have to
  // contain a bunch of things that are only related to <SignatureModal. I
  // placed everything that fetches or manipulates data in the context so some
  // higher level component deals with all of that, and now <SignatureField
  // only has to care about rendering the modal, and attaching whatever the
  // user selects to this field.
  //
  // handleSignatureData makes this difficult, because this function needs to
  // talk to the network (it belongs in the context), but it also inserts the
  // signature just like handleInsert (which belongs here in <SignatureField).
  //
  // In order to work around this, my current approach is to have the
  // `createNewSignature` from context just take `sigData` and return a new `sig`
  // to use. This function's job is to call that promise, then pass the
  // created signature to `handleInsert`.
  const handleSignatureData: SignatureModalContext['createNewSignature'] =
    async (sigData) => {
      // Setting isCreatingSignatureOrInitials to trigger message
      setState((state) => ({
        ...state,
        isCreatingSignatureOrInitials: true,
      }));

      try {
        const signature = await signatureModalProps.createNewSignature(sigData);
        return signature;
      } catch (error) {
        setState((state) => ({
          ...state,
          isCreatingSignatureOrInitials: false,
        }));
        throw error;
      }
    };

  const isSignatureType = fieldData.type === 'signature';
  // We need to make sure we reset the savedSignature, because
  // when switching between signatures and initials
  // it is using the same prop: selectedSavedSignature
  let selectedSavedSignature = signatureModalProps.selectedSavedSignature;
  if (selectedSavedSignature !== undefined) {
    if (
      isSignatureType &&
      signatureModalProps.selectedSavedSignature.type === 'I' &&
      signatureModalProps.savedSignatures !== undefined &&
      signatureModalProps.savedSignatures.length > 0
    ) {
      selectedSavedSignature = signatureModalProps.savedSignatures[0];
    } else if (
      !isSignatureType &&
      signatureModalProps.selectedSavedSignature.type !== 'I' &&
      signatureModalProps.savedInitials !== undefined &&
      signatureModalProps.savedInitials.length > 0
    ) {
      selectedSavedSignature = signatureModalProps.savedInitials[0];
    }
  }

  const splitProps = isSignatureType
    ? {
        type: CONSTANTS.TYPE_SIGNATURE,
        initialTypeInValue: signatureModalProps.signatureInitialTypeInValue,
        savedSignatures: signatureModalProps.savedSignatures,
      }
    : {
        type: CONSTANTS.TYPE_INITIALS,
        initialTypeInValue: signatureModalProps.initialsInitialTypeInValue,
        savedSignatures: signatureModalProps.savedInitials,
      };

  let displayField;
  // if there is no signature and it's not readOnly,
  // allow the signer to interact with the field
  if (fieldData.signature == null && !fieldData.readOnly) {
    displayField = (
      <div
        ref={ref}
        tabIndex={0}
        onKeyPress={handleKeyPress}
        onClick={handleOpenOrInsert}
        style={{ fontSize: `${11 * textScale}px` }}
        className={styles.signaturePlaceholder}
        id={`${fieldData.type}-${fieldData.id}`}
        data-qa-ref={`${fieldData.type}-input`}
        data-testid={`${fieldData.type}-input`}
        role="button"
      >
        <FormattedMessage
          id="signer.field.label.desktopText"
          description="input text in singer flow, asking user to input signature/initial"
          defaultMessage="{ type, select, signature {Click to sign} initials {Click to initial} text {} other {} }"
          values={{ type: fieldData.type }}
        />
      </div>
    );
  } else {
    displayField = (
      <DisplayField
        {...props}
        ref={ref}
        // @ts-ignore IDK if this is necessary or not. The types of DisplayField
        // aren't clear enough
        tabIndex={0}
        data-qa-ref={`${fieldData.type}-input`}
        data-testid={`${fieldData.type}-input`}
        className={styles.displayField}
        onKeyPress={handleKeyPress}
      />
    );
  }

  // if insert everywhere is allowed by sender, check number of signatures on the page
  const canInsertEverywhere =
    signatureModalProps.canInsertEverywhere &&
    fieldData.signature == null &&
    (fieldData.type === 'signature'
      ? emptySignatureCount
      : emptyInitialsCount) > 1;
  const menu = useMenu(
    fromScreenCoords,
    isActive,
    fieldData,
    handleOpen,
    insertSignature,
    canInsertEverywhere,
  );

  const loading = state.isCreatingSignatureOrInitials;
  const loaderContent = getCreatingSignatureOrInitialsLoaderContent();

  const commonSigModalProps = {
    ...signatureModalProps,
    ...splitProps,
    loaderContent,
    loading,
    canInsertEverywhere,
    selectedSavedSignature,
    onClose: handleClose,
    onInsert: handleInsert,
    createNewSignature: handleSignatureData,
  };

  return (
    <React.Fragment>
      {displayField}
      {menu}
      <SignatureModal isOpen={state.open} {...commonSigModalProps} />
    </React.Fragment>
  );
}

export default React.forwardRef(SignaturePlaceholder);
