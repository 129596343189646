import React from 'react';

/**
 * This function generates a Provider and matching useContext hook.
 * I recommend destructuring them into different names.
 *
 * export const {
 *  Provider: AccountSettingsContext,
 *  useContext: useAccountSettingsContext,
 *  } = createSimpleDataContext<AccountSettingsLocked>('AccountSettingsLocked');
 */
export function createSimpleDataContext<T>(name: string) {
  const context = React.createContext<T | null>(null);

  type ProviderProps = React.PropsWithChildren<{
    value: T;
  }>;
  function Provider({ value, children }: ProviderProps) {
    return <context.Provider value={value}>{children}</context.Provider>;
  }

  function useContext() {
    const tmp = React.useContext(context);

    if (!tmp) {
      throw new Error(`Missing ${name}`);
    }

    return tmp;
  }

  return { Provider, useContext };
}
