import { Field } from 'signer-app/types/editor-types';
import { Rule } from 'signer-app/conditional-logic/types';
import { isSorted } from 'signer-app/conditional-logic/sort-rules';
import { unreachable } from 'signer-app/utils/unreachable';

//
// import { SENDER, PREPARER } from 'hellospa/components/editor';
const SENDER = 'sender';
const PREPARER = 'preparer';

type ErrorNotSorted = {
  type: 'NOT_SORTED';
};

type ErrorMissingField = {
  type: 'MISSING_FIELD';
  fieldId: string;
};

type ErrorMissingGroup = {
  type: 'MISSING_GROUP';
  groupId: string;
};

type ErrorInvalidSigner = {
  type: 'INVALID_SIGNER';
  fieldId: string;
};

type ErrorInvalidRegex = {
  type: 'INVALID_REGEX';
  fieldId: string;
};

type ErrorMixedSigners = {
  type: 'MIXED_SIGNERS';
  fieldIds: [string, string];
};

export type ValidationError =
  | ErrorNotSorted
  | ErrorMissingField
  | ErrorMissingGroup
  | ErrorInvalidSigner
  | ErrorInvalidRegex
  | ErrorMixedSigners;

export default function validate(
  rules: Rule[],
  fields: Field[],
): true | ValidationError {
  if (!isSorted(rules)) {
    return { type: 'NOT_SORTED' };
  }

  const fieldLookup = fields.reduce(
    (obj, f) => {
      obj[f.id] = f;
      return obj;
    },
    {} as Record<string, Field>,
  );

  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const referencedFields: Field[] = [];

    for (let t = 0; t < rule.triggers.length; t++) {
      const trigger = rule.triggers[t];
      if (fieldLookup[trigger.id] == null) {
        return {
          type: 'MISSING_FIELD',
          fieldId: trigger.id,
        };
      }

      if (trigger.operator === 'match') {
        try {
          /* eslint-disable no-new */
          new RegExp(String(trigger.value));
        } catch (e) {
          return {
            type: 'INVALID_REGEX',
            fieldId: trigger.id,
          };
        }
      }

      referencedFields.push(fieldLookup[rule.triggers[t].id]);
    }

    for (let i = 0; i < rule.actions.length; i++) {
      const action = rule.actions[i];
      switch (action.type) {
        case 'change-field-visibility':
          if (fieldLookup[action.fieldId] == null) {
            return {
              type: 'MISSING_FIELD',
              fieldId: action.fieldId,
            };
          }
          referencedFields.push(fieldLookup[action.fieldId]);
          break;
        case 'change-group-visibility': {
          const groupedFields = fields.filter(
            (f) => 'group' in f && f.group === action.groupId,
          );

          if (groupedFields.length === 0) {
            return {
              type: 'MISSING_GROUP',
              groupId: action.groupId,
            };
          }

          referencedFields.push(...groupedFields);
          break;
        }
        default:
          unreachable(action);
      }
    }

    for (let i = 0; i < referencedFields.length - 1; i++) {
      if (
        referencedFields[i].signer === SENDER ||
        referencedFields[i].signer === PREPARER
      ) {
        return {
          type: 'INVALID_SIGNER',
          fieldId: referencedFields[i].id,
        };
      }

      if (
        referencedFields[i + 1].signer === SENDER ||
        referencedFields[i + 1].signer === PREPARER
      ) {
        return {
          type: 'INVALID_SIGNER',
          fieldId: referencedFields[i + 1].id,
        };
      }

      if (referencedFields[i].signer !== referencedFields[i + 1].signer) {
        return {
          type: 'MIXED_SIGNERS',
          fieldIds: [referencedFields[i].id, referencedFields[i + 1].id],
        };
      }
    }
  }

  return true;
}
