import React from 'react';
import classnames from 'classnames';
import styles from 'signer-app/icons/icon.module.css';

type Props = React.SVGProps<SVGSVGElement> & {
  checked?: boolean;
  isOverlay?: boolean;
  className?: string;
};

const RadioButton = ({ checked, isOverlay, ...props }: Props) => {
  // hiding from overlay if not checked
  if (isOverlay && !checked) {
    return null;
  }

  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      className={classnames(styles.icon, props.className)}
    >
      <path
        d="M7 13.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
        stroke="currentColor"
      />
      {checked && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 10a3 3 0 100-6 3 3 0 000 6z"
          fill="#000"
        />
      )}
    </svg>
  );
};

export default RadioButton;
