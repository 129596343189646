/* eslint-disable dropbox-sign/isolate-folder */
// The authentication code still references the old redirect function, so this
// needs to be the same function so jest can mock it. Once this code moves out
// to DPC, we drop this export and uncomment the implementation.
export { redirectTo } from 'hellospa/common/utils/redirect';

/**
 * This function just redirects to
 * the given URL. Pulled this out to its
 * own function to make unit testing easier.
 *
 *
 * jest.mock('signer-app/utils/redirect', () => ({
 *   redirectTo: jest.fn(),
 * }));
 *
 * @param url
 */
// export const redirectTo = (url: string) => {
//   window.location.assign(url);
//   // This will never resolve, so it holds this promise active until the page
//   // unloads
//   return new Promise(() => { });
// };
