import {
  SIGNATURE_TYPE_CANVAS,
  SIGNATURE_TYPE_EMAIL,
  SIGNATURE_TYPE_TYPED,
  SIGNATURE_TYPE_UPLOAD,
  TAB_DRAW,
  TAB_EMAIL,
  TAB_TYPE,
  TAB_UPLOAD,
  TYPE_INITIALS,
  TYPE_SIGNATURE,
} from 'signer-app/types/signature-types';
import { FONT_CHAR_MAP } from 'signer-app/utils/font-char-map';

const ORIENTATION_PORTRAIT = 'P';
const ORIENTATION_LANDSCAPE = 'L';
const SCREEN_SMALL = 'small';
const SCREEN_MEDIUM = 'medium';
const SCREEN_LARGE = 'large';
const TAB_SAVED = 'S';
const TAB_ERROR = 'ERROR';
const TABS = [
  'S', // TAB_SAVED,
  'C', // TAB_DRAW,
  'T', // TAB_TYPE,
  'U', // TAB_UPLOAD,
  'E', // TAB_EMAIL,
  'ERROR', // TAB_ERROR,
] as const;
const MOBILE_TABS = [
  'S', // TAB_SAVED,
  'C', // TAB_DRAW,
  'T', // TAB_TYPE
] as const; // FIXME = remove once tests are revised
const CAROUSEL_LENGTH = 3;
const CURSIVE_FONTS = Object.keys(FONT_CHAR_MAP);
const CONTRAST_MIN_THRESHOLD = 16;
const CONTRAST_MAX_THRESHOLD = 64;
const CONTRAST_STEP_AMOUNT = 12;
const ROTATE_DEGREES_AMOUNT = 90;
const EMAIL_STATUS_MAX_RETRIES = 20;
const EMAIL_STATUS_RETRY_PERIOD = 3000;
const DRAW_TAB_REDRAW_MAX_RETRIES = 5;
const DRAW_TAB_REDRAW_INITIAL_INTERVAL = 100; // ms
const ACCEPTED_UPLOAD_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
];
const MAX_UPLOAD_SIZE = 40000000; // 40MB
const SIGNATURES_PER_PAGE = 99;
const INITIALS_PER_PAGE = 99;

export default {
  TYPE_SIGNATURE,
  TYPE_INITIALS,
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
  SCREEN_SMALL,
  SCREEN_MEDIUM,
  SCREEN_LARGE,
  TAB_SAVED,
  TAB_DRAW,
  TAB_TYPE,
  TAB_UPLOAD,
  TAB_EMAIL,
  TAB_ERROR,
  TABS,
  MOBILE_TABS,
  SIGNATURE_TYPE_CANVAS,
  SIGNATURE_TYPE_TYPED,
  SIGNATURE_TYPE_UPLOAD,
  SIGNATURE_TYPE_EMAIL,
  CAROUSEL_LENGTH,
  CURSIVE_FONTS,
  CONTRAST_MIN_THRESHOLD,
  CONTRAST_MAX_THRESHOLD,
  CONTRAST_STEP_AMOUNT,
  ROTATE_DEGREES_AMOUNT,
  EMAIL_STATUS_MAX_RETRIES,
  EMAIL_STATUS_RETRY_PERIOD,
  DRAW_TAB_REDRAW_MAX_RETRIES,
  DRAW_TAB_REDRAW_INITIAL_INTERVAL,
  ACCEPTED_UPLOAD_TYPES,
  MAX_UPLOAD_SIZE,
  SIGNATURES_PER_PAGE,
  INITIALS_PER_PAGE,
} as const;
