import BaseNotifier from './base';

function CallbackNotifier(callback) {
  this.callback = callback;
}

BaseNotifier.extend(CallbackNotifier, {
  notify(message) {
    return this.callback(message);
  },
});

export default CallbackNotifier;
