/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len
*/
import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import DarkHeader from 'signer/components/common/dark-header';
import HeaderButton from 'signer/components/common/header-button';
import Logo from 'signer/components/common/logo';
import createReactClass from 'create-react-class';
import { FormattedMessage } from 'react-intl';

const SignerChoice = createReactClass({
  propTypes: {
    app: PropTypes.object.isRequired,
    emailAddress: PropTypes.string.isRequired,
    signers: PropTypes.array.isRequired,
    selectionCallback: PropTypes.func.isRequired,
  },

  //  ----  LIFECYCLE  ----------------------------------

  getDefaultProps() {
    return {};
  },

  getInitialState() {
    return {
      hasPicked: false,
      selectedSigner: null,
    };
  },

  //  ----  BEHAVIOR  -----------------------------------

  onSignerSelection(selectedSigner, tsmGuid, evt) {
    const input = evt.target;

    if (input.checked === true) {
      this.setState({
        hasPicked: true,
        selectedSigner,
        tsmGuid,
      });
    }
  },

  onSubmit() {
    this.props.selectionCallback(this.state.selectedSigner, this.state.tsmGuid);
  },

  //  ----  RENDERING  ----------------------------------

  render() {
    return (
      <div className="m-signer-choice">
        <DarkHeader className="m-signer-choice--header" {...this.props}>
          <div className="m-signer-choice--info">
            <Logo className="logo" />
          </div>

          <div className="m-signer-choice--subheader">
            <div className="m-signer-choice--subheader--message">
              <FormattedMessage
                id="signer.choice.message.welcomeText"
                defaultMessage="Hi {emailAddress},"
                description="text, greeting the current user"
                values={{ emailAddress: this.props.emailAddress }}
              />
              <br />
              <FormattedMessage
                id=""
                defaultMessage='More than one person has been associated with your email address, please identify yourself and click "continue".'
                description="text, asking user to identify as more then one person seems to be using the same email address"
              />
            </div>
            <div className="m-signer-choice--subheader--cta">
              <HeaderButton
                onClick={this.onSubmit}
                {...this.props}
                buttonHoverColor="plume"
                buttonHoverTextColor="castle-rock"
                disabled={!this.state.hasPicked}
              >
                <FormattedMessage
                  id="signer.choice.continueButton.labelText"
                  defaultMessage="Continue"
                  description="button text, when clicked identifies the user"
                />
              </HeaderButton>
            </div>
            <br />
          </div>
        </DarkHeader>

        <div className="m-signer-choice--list">
          {this.renderSignerChoices()}
        </div>
      </div>
    );
  },

  renderSignerChoices() {
    const choices = [];
    for (let i = 0; i < this.props.signers.length; i++) {
      const signer = this.props.signers[i];
      choices.push(
        <span key={i} className="m-signer-choice--list--choice">
          <input
            id={signer.key}
            type="radio"
            className="m-signer-choice--list--choice--radio"
            name="signer_radio"
            onChange={this.onSignerSelection.bind(
              null,
              signer.key,
              signer.tsmGuid,
            )}
          />
          <label
            htmlFor={signer.key}
            className="m-signer-choice--list--choice--label"
          >
            {signer.name}
          </label>
        </span>,
      );
    }
    return choices;
  },
});

module.exports = SignerChoice;
