import React from 'react';
import { Field } from 'signer-app/types/editor-types';
import {
  Rule,
  Trigger,
  Action,
  Updates,
  AllowedTriggerField,
} from 'signer-app/conditional-logic/types';
import sortRules, {
  buildDepthMap,
  isSorted,
} from 'signer-app/conditional-logic/sort-rules';
import validate from 'signer-app/conditional-logic/validate';
import runRules, { buildUpdates } from 'signer-app/conditional-logic/run-rules';
import { FieldGroups } from 'signer-app/conditional-logic/utils';

export {
  getFieldIdsFromRules,
  isAllowedFieldType,
} from 'signer-app/conditional-logic/utils';
export { default as cleanupRules } from 'signer-app/conditional-logic/cleanup-rules';

export {
  sortRules,
  isSorted,
  Rule,
  Trigger,
  Action,
  AllowedTriggerField,
  validate,
  buildDepthMap,
};

export default runRules;

export function groupFields(fields: Field[]): FieldGroups {
  return fields.reduce((groups, field) => {
    if ('group' in field && field.group) {
      groups[field.group] = groups[field.group] ?? [];
      groups[field.group].push(field.id);
    }
    return groups;
  }, {} as FieldGroups);
}

export function useConditionalLogic(
  fields: Field[],
  rules: Rule[],
  updateFields: (updates: Updates) => void,
) {
  const fieldGroups = React.useMemo((): FieldGroups => {
    return groupFields(fields);
    // Field groups can't change at runtime. This only needs to be cmputed once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedRules = React.useMemo(() => {
    if (!rules) {
      return [];
    }
    return sortRules(fieldGroups, rules);
  }, [fieldGroups, rules]);

  React.useMemo(() => {
    if (sortedRules.length === 0) {
      return;
    }

    const updates = buildUpdates(fields, sortedRules);
    if (Object.keys(updates).length > 0) {
      updateFields(updates);
    }
  }, [fields, sortedRules, updateFields]);
}
