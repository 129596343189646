import { EmbeddedData } from 'js/sign-components/generated/types/HelloRequest';
import * as Yup from 'yup';
import { FilePollResponseStatus } from './file';

type AppInfo = {
  appName: string;
  appOwnerEmail: string;
};

type EmbeddedPollResponseDone = {
  status: FilePollResponseStatus.Ok;
};

type EmbeddedPollResponseError = {
  status: FilePollResponseStatus.Error;
  appInfo?: AppInfo;
};

type EmbeddedPollResponseConverting = {
  status: FilePollResponseStatus.Converting;
};

export type EmbeddedPollResponse =
  | EmbeddedPollResponseDone
  | EmbeddedPollResponseConverting
  | EmbeddedPollResponseError;

export { EmbeddedData };

export const embeddedDataSchema = Yup.object<EmbeddedData>({
  docGuids: Yup.array(),
  usingTemplate: Yup.bool(),
  parentUrl: Yup.string(),
  redirectUrl: Yup.string().notRequired(),
  editedTemplateGuid: Yup.string().nullable(),
  isSelfSign: Yup.bool(),
  locale: Yup.string(),

  apiApp: Yup.object<EmbeddedData['apiApp']>({
    clientId: Yup.string(),
    parentUrl: Yup.string().notRequired(),
  }),
}).notRequired();
