import React from 'react';
import { Route, RouteProps } from 'react-router';
import { labelContext, LabelContext, withLabel } from './context';

type RouteBoundaryProps = RouteProps & {
  label: string;
};
export function LabeledRoute({ label, ...props }: RouteBoundaryProps) {
  const component = React.useMemo<RouteProps['component']>(() => {
    if (props.component) {
      return withLabel(props.component, label);
    }
    return undefined;
  }, [label, props.component]);

  let render: RouteProps['render'] = props.render;
  if (render) {
    render = function renderFunc(p) {
      return <LabelContext label={label}>{props.render!(p)}</LabelContext>;
    };
  }

  return <Route {...props} component={component} render={render} />;
}

export function TrackMountedRoutes({ children }: React.PropsWithChildren<{}>) {
  return <LabelContext label="">{children}</LabelContext>;
}

export function useLabeledRoutes() {
  const context = React.useContext(labelContext);
  if (!context) {
    return '';
  }

  return context.activeComponents;
}
