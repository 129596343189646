import * as ActionTypes from 'signer-app/signature-modal/contexts/signature-modal/action-types';
import { SignatureModalActions as Action } from 'signer-app/signature-modal/contexts/signature-modal/actions';
import { SignatureModalContextState as State } from 'signer-app/signature-modal/contexts/signature-modal/types';
import { Reducer } from 'signer-app/signature-modal/contexts/signature-modal/use-thunk-reducer';

type R<T, U = Extract<Action, { type: T }>> = U extends { payload: any }
  ? (state: State, payload: U['payload']) => State
  : (state: State) => State;

const loadFontRequest: R<typeof ActionTypes.LOAD_FONT_REQUEST> = (state) => {
  return {
    ...state,
    isLoadingFont: true,
  };
};

const loadFontSuccess: R<typeof ActionTypes.LOAD_FONT_SUCCESS> = (
  state,
  { fontIndex },
) => {
  return {
    ...state,
    isLoadingFont: false,
    currentFontIndex: fontIndex,
  };
};

const loadFontFailure: R<typeof ActionTypes.LOAD_FONT_FAILURE> = (
  state,
  _unusedPayload,
) => {
  return {
    ...state,
    isLoadingFont: false,
  };
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_FONT_REQUEST:
      return loadFontRequest(state);
    case ActionTypes.LOAD_FONT_SUCCESS:
      return loadFontSuccess(state, action.payload);
    case ActionTypes.LOAD_FONT_FAILURE:
      return loadFontFailure(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
