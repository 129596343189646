/**
 * if date format does not have spaces (MM / DD / YYYY), add spaces so that
 * the editor's date format dropdown selects the correct option by default
 * account date formats in the backend have forward slashes only currently
 *
 * @param {string} dateFormat
 * @returns {string} dateFormatted
 */
export function parseDateFormat(dateFormat: string | null) {
  let dateFormatted = dateFormat;

  if (dateFormat != null && dateFormat.search(/\s\/\s/) === -1) {
    dateFormatted = dateFormat.replace(/\//g, ' / ');
  }

  return dateFormatted;
}
