import React from 'react';
import { Banner } from '@dropbox/dig-components/banner';
import { useSignerContext } from 'signer-app/signer-experience/signer-experience';

export function ValidationComponent() {
  const { validationErrors } = useSignerContext();
  const key = Object.keys(validationErrors)[0];
  const value = validationErrors[key];

  if (!value) {
    return null;
  }

  return (
    <Banner type="alert">
      <Banner.Message>{value.message}</Banner.Message>
    </Banner>
  );
}
