import React, { FormEventHandler, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@dropbox/dig-components/buttons';
import {
  FormHelperText,
  FormRow,
  FormLabel,
} from '@dropbox/dig-components/form_row';
import { Modal, ModalProps } from '@dropbox/dig-components/modal';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';

import { useSignerAppClient } from 'signer-app/context/signer-app-client';
import { signerDeclineModalMessages } from 'signer-app/signer-decline-modal/signer-decline-modal.intl';
import { onEmbeddedSignerDeclineSuccess } from 'signer-app/signer-decline-modal/signer-decline-modal.utils';
import styles from 'signer-app/signer-decline-modal/signer-decline-modal.module.css';
import { redirectTo } from 'signer-app/utils/redirect';

type Props = {
  isEmbedded: boolean;
  signatureRequestId: string;
  parentUrl?: string;
  onRequestClose: ModalProps['onRequestClose'];
};

export function SignerDeclineModal({
  isEmbedded,
  signatureRequestId,
  onRequestClose,
  parentUrl,
}: Props): JSX.Element {
  const { signerApi } = useSignerAppClient();
  const history = useHistory();
  const [formData, setFormData] = useState({
    declineReason: '',
  });
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData, setFormData],
  );

  const mutation = useMutation({
    mutationFn: () => signerApi.decline({ signatureRequestId, isEmbedded }),
    onSuccess(response) {
      if (isEmbedded && parentUrl) {
        onEmbeddedSignerDeclineSuccess({
          signatureId: signatureRequestId,
          declineReason: formData.declineReason,
          parentUrl,
        });
      }

      if (response.redirect_url) {
        redirectTo(response.redirect_url);
      } else {
        history.push('/sign-app/signature-request-decline-complete');
      }
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      mutation.mutate();
    },
    [mutation],
  );

  return (
    <Modal
      open
      isCentered
      withCloseButton="Close"
      onRequestClose={onRequestClose}
      aria-labelledby="signer-decline-modal-title"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title id="signer-decline-modal-title">
            <FormattedMessage {...signerDeclineModalMessages.title} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text tagName="p" variant="paragraph">
            <FormattedMessage {...signerDeclineModalMessages.description} />
          </Text>
          <FormRow>
            <FormLabel htmlFor="declineReason">
              <FormattedMessage {...signerDeclineModalMessages.reasonTitle} />
              &nbsp;
              <span className={styles.alert}>*</span>
            </FormLabel>
            <TextArea
              aria-describedby="decline-reason-ps"
              data-testid="textarea-decline-reason"
              disabled={mutation.isLoading}
              onChange={handleChange}
              name="declineReason"
              value={formData.declineReason}
            />
            <FormHelperText id="decline-reason-ps">
              <FormattedMessage
                {...signerDeclineModalMessages.sharedOnlyWithSender}
              />
            </FormHelperText>
          </FormRow>
          {mutation.isError && (
            <FormHelperText id="decline-reason-error" variant="alert">
              <FormattedMessage {...signerDeclineModalMessages.declineFailed} />
            </FormHelperText>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onRequestClose} type="button" variant="opacity">
            <FormattedMessage {...signerDeclineModalMessages.cancelButton} />
          </Button>
          <Button
            data-test-id="signer-decline-modal-submit"
            disabled={formData.declineReason.length === 0}
            isLoading={mutation.isLoading}
            type="submit"
            variant="primary"
          >
            <FormattedMessage {...signerDeclineModalMessages.declineButton} />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
