import React from 'react';
import { useLatestRef } from 'signer-app/utils/use-latest-ref';

export enum MessageType {
  // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  /* eslint-disable no-restricted-syntax */
  USER_DECLINE_REQUEST = 'hellosign:userDeclineRequest',
}

type SignerAppUserDeclineRequest = {
  type: MessageType.USER_DECLINE_REQUEST;
  payload: {
    signatureId: string;
    reason: string;
  };
};

type MessagesWithData = SignerAppUserDeclineRequest;

export type LookupMessageType<T = MessageType> = Extract<
  MessagesWithData,
  { type: T }
>;

/**
 * useWindowMessage is a type-safe hook for recieving messages from
 * window.addEventListener('message', onMessage);
 *
 * When you specify the type, it looks up what data is expected in MessageType
 * and MessagesWithData.
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764538186526183
 */
export function useWindowMessage<T extends MessageType>(
  type: T,
  callback: (m: LookupMessageType<T>, source: MessageEvent['source']) => void,
  { expectedOrigin = window.location.origin }: Options = {},
) {
  const callbackRef = useLatestRef(callback);

  React.useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (
        expectedOrigin === '*' ||
        expectedOrigin === event.origin ||
        // The origin property doesn't seem to get set when running in Jest
        (typeof jest !== 'undefined' && event.origin === '')
      ) {
        if (event.data && event.data.type === type) {
          callbackRef.current(event.data, event.source);
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, [callbackRef, expectedOrigin, type]);
  return null;
}

export const canPostToParent = (
  parentUrl: string | null,
): parentUrl is string =>
  parentUrl != null &&
  // In situation such as P&S is opened in new a popup window prevent sending
  // message back to the window itself if it's same as parent
  window !== window.parent;

/**
 * postTo is a type-safe way to postMessage across iframes.
 *
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764538186526166
 */
export function postTo<T extends MessageType>(
  targetWindow: Window,
  message: LookupMessageType<T>,
  // This just needs to postMessage to hellosign-web code through an iframe and
  // it needs to work in Storybook. I think the simplest solution is to just
  // always post to the same origin.
  origin = window.location.origin,
  transferrable?: Transferable[] | undefined,
) {
  targetWindow.postMessage(message, origin, transferrable);
}

type Options = {
  /**
   * By default useWindowMessage only accepts messages from its own origin. This
   * works great for making sure we only get messages from other HelloSign code.
   * I had to add this with a wild card so that the mobile app can post messages
   */
  expectedOrigin?: '*' | string;
};

/**
 * @enum {string}
 * @readonly
 */
export const messages = MessageType;

/**
 * Natively sends a cross-origin window message if the
 * user's navigator supports it, otherwise fails silently.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 * @param {any} message
 * @param {string} targetOrigin
 * @param {Transferable} [transfer]
 * @returns {void}
 */
export function postMessage<T extends MessageType>(
  message: LookupMessageType<T>,
  targetOrigin: string,
  transferrable?: Transferable[] | undefined,
) {
  if (!targetOrigin) {
    return;
  }

  if (window.parent) {
    postTo(window.parent, message, targetOrigin, transferrable);
  }

  if (typeof Office !== 'undefined') {
    Office.onReady().then(() => {
      Office.context.ui.messageParent(JSON.stringify(message)); // NOSONAR
    });
  }
}
