import Field from './field';

export default Field.extend({
  hasValue() {
    return this.value && this.value.length > 0;
  },

  hasDataValue() {
    return this.data.value && this.data.value.length > 0;
  },
});
