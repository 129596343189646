import { messages, postMessage } from 'signer-app/utils/messages';

export function onEmbeddedSignerDeclineSuccess({
  signatureId,
  declineReason,
  parentUrl,
}: {
  signatureId: string;
  declineReason: string;
  parentUrl: string;
}): void {
  // Embedded V2
  postMessage(
    {
      type: messages.USER_DECLINE_REQUEST,
      payload: {
        signatureId,
        reason: declineReason,
      },
    },
    parentUrl,
  );
}
