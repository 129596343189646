/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import sa from 'superagent';

type Ops = {
  method: string;
  url: string;
  headers?: any;
  query?: any;
  data?: any;
  form?: boolean;
};

/**
 * actually makes the requests to the server
 */

export default function _request(ops: Ops) {
  return new Promise((resolve, reject) => {
    const renamedMethods = {
      delete: 'del',
      get: 'get',
      post: 'post',
      put: 'put',
    };
    const method = ops.method.toLowerCase() as keyof typeof renamedMethods;
    const methodName = (renamedMethods[method] ||
      method) as keyof typeof renamedMethods;
    const r = sa[methodName](ops.url);

    if (ops.form) {
      r.type('form');
    }

    if (ops.headers) {
      for (const key in ops.headers) {
        r.set(key, ops.headers[key]);
      }
    }

    if (ops.query) {
      r.query(ops.query);
    }

    if (ops.data) {
      r.send(ops.data);
    }

    r.end((err, res) => {
      if (err) {
        return reject(err);
      }

      // @ts-ignore
      res = res.text;

      try {
        // @ts-ignore
        res = JSON.parse(res);
      } catch (e) {
        // This catch intentionally left empty
      }

      resolve(res);
    });
  });
}
