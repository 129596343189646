import * as Yup from 'yup';
import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';

export const asciiMessage = defineMessages({
  noAscci: {
    id: '',
    defaultMessage: 'Email contains invalid characters',
    description: 'Message if email contains non ascii & illegal characters',
  },
  unknownError: {
    id: '',
    defaultMessage:
      'Something went wrong. Please reload the page and try again.',
    description:
      'Message displayed to the user when there was an unknown error.',
  },
  notTeamMember: {
    id: '',
    defaultMessage: "This email isn't on your team.",
    description:
      'Message displayed to the user when recipient email is not in you team.',
  },
});

// check if recipient email address is part of sender Org
const validateExternalSharing = async (
  context: any,
  email: string,
  createError: any,
) => {
  if (context?.isSubmitting && context?.isDisabledExternalSignature) {
    const { isExternalSharingDisabled } =
      await context.appActions.prepAndSend.checkExternalSharing(email);
    if (isExternalSharingDisabled === undefined) {
      return createError({
        message: intl.formatMessage(asciiMessage.unknownError),
      });
    } else if (isExternalSharingDisabled) {
      return createError({
        message: intl.formatMessage(asciiMessage.notTeamMember),
      });
    }
  }

  return true;
};

export const validateEmail = async (
  { options, createError }: Yup.TestContext,
  email: string,
) => {
  const context = options.context as any;
  if (context?.useEmailDeliverabilityValidation) {
    const {
      emailValidationBypassList,
      updateEmailValidationErrors,
      emailValidationErrors,
    } = context?.emailValidationContext;

    if (!emailValidationBypassList[email] && context?.isSubmitting) {
      // VALIDATE ON SUBMIT WHEN BYPASS ISN'T ACTIVE FOR EMAIL
      try {
        const { isDeliverable, error } =
          await context.appActions.validateEmail(email);
        if (!isDeliverable && error) {
          updateEmailValidationErrors(email, error);
          return createError({
            message: error,
          });
        }
      } catch (error) {
        return true;
      }
    } else if (
      !context?.isSubmitting &&
      !emailValidationBypassList[email] &&
      emailValidationErrors[email]
    ) {
      return createError({
        message: emailValidationErrors[email],
      });
    }
  }

  return validateExternalSharing(context, email, createError);
};

// BE email uses FILTER_SANITIZE_EMAI before email validation and uses FILTER_VALIDATE_EMAIL
// this will remove these characters and fail validation so we are stopping their usage in the
// emails on the FE to stay consistent and not confuse users
// https://www.php.net/manual/en/filter.filters.sanitize.php

/* eslint-disable */
export const asciiRegex = /^[a-zA-Z0-9!#$%&'*+\-=?^_`{|}~@.\[\]]+$/;
