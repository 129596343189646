import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';

export type UpdateTaxResponse = {
  value: string;
};

export type removeCardResponse = {
  status: string;
};

export type AddressSaveResponse = {
  message: string;
  value: string;
};

export type UpdateCardResponse = {
  success: boolean;
  errorMessage?: string;
};

export async function updateTaxId(
  taxId: string,
  taxCountry: string,
): Promise<UpdateTaxResponse | null> {
  const res = await hsFetch('/endpoint/account/updateTaxId', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      csrf_token: getCSRFToken(),
      country: taxCountry,
      taxId,
    }),
  });

  if (res.status !== 200) {
    return null;
  }

  return res.json();
}

export async function onRemoveCard(): Promise<removeCardResponse> {
  const formData = new FormData();
  formData.append('csrf_token', getCSRFToken());

  const res = await hsFetch('/account/removeCard', {
    method: 'POST',
    body: formData,
  });

  return res.json();
}

export async function saveReceiptAddress(
  receiptAddress: string,
): Promise<AddressSaveResponse> {
  const res = await hsFetch('/account/updateReceiptAddress', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      csrf_token: getCSRFToken(),
      receipt_address: receiptAddress,
    }),
  });

  return res.json();
}

export async function updateBillingInfo(updateObject: {
  stripeToken: string;
  ccZip?: string;
  ccCountry: string;
  currencyCode: string;
  ccState?: string;
}): Promise<UpdateCardResponse> {
  // Pretend to fail the update when currencyCode is empty
  // This 'should' never happen, but having this
  // avoids any issues with accidentally changing currency
  if (!updateObject.currencyCode) {
    return {
      success: false,
    };
  }

  const res = await hsFetch('/account/updateCard', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      csrf_token: getCSRFToken(),
      payment: {
        stripe_token: updateObject.stripeToken,
        cc_zip: updateObject.ccZip,
        cc_country: updateObject.ccCountry,
        currency: updateObject.currencyCode,
        cc_state: updateObject.ccState,
      },
    }),
  });

  return res.json();
}
