export type Dimensions = { width: number; height: number };

export const scaleSignatureToFit = (
  { width, height }: Dimensions,
  restrictDims: Dimensions,
): Dimensions => {
  const verticalRatio = height / restrictDims.height;
  const horizontalRatio = width / restrictDims.width;
  const maxRatio = Math.max(verticalRatio, horizontalRatio);

  if (maxRatio <= 1) {
    return {
      width,
      height,
    };
  } else {
    return {
      width: Math.round(width / maxRatio),
      height: Math.round(height / maxRatio),
    };
  }
};
