import hsFetch from 'js/sign-components/common/hs-fetch';
import * as Yup from 'yup';
import type { TreeNodeInArray } from 'react-simple-tree-menu';
import {
  SPFolder,
  SPUserBase,
  SPUser,
  ErrorCode,
  SPAuthUrl,
} from 'hellospa/components/sharepoint/data/types';

export type HSActionResult = {
  success: boolean;
};

export type HsErrorResponse = {
  code?: number | string;
  message: string;
  statusCode: number;
  success: boolean;
};

export type GetFolderErrorResponse = {
  errorMsg: string;
};

export type PrepAndSendSuccess = {
  success: true;
  clientId: string;
  claimUrl: string;
  signedFolderLocation: string;
};

const folderSchema: Yup.ObjectSchema<TreeNodeInArray> =
  Yup.object<TreeNodeInArray>({
    key: Yup.string(),
    label: Yup.string(),
    fullPath: Yup.string(),
    id: Yup.string(),
    rootId: Yup.string().nullable(),
    nodes: Yup.array()
      .transform((value) => Object.values(value))
      .of(Yup.lazy(() => folderSchema.default(undefined))),
  }).camelCase();

const folderArraySchema = Yup.array().of(folderSchema);

export type FolderResponse = Yup.InferType<typeof folderSchema>;

export type FolderArray = Yup.InferType<typeof folderArraySchema>;

export async function disconnectHS(
  SPSiteUrl: string,
  removeTeamMembers: boolean,
): Promise<HSActionResult | HsErrorResponse> {
  const body = {
    SPSiteUrl,
    removeTeamMembers,
  };

  const response = await hsFetch('/sharepoint/disconnect', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.success) {
    return resp;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function getAdminConsentUrl(
  SPSiteUrl: string,
  state?: string,
): Promise<SPAuthUrl | HsErrorResponse> {
  const body = {
    SPSiteUrl,
    state,
  };

  const response = await hsFetch('/sharepoint/AdminConsentUrl', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.authUrl) {
    return {
      authUrl: resp.authUrl,
      state: resp.state,
      success: true,
    } as SPAuthUrl;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function getHelloSignAuthorizeUrl(
  SPSiteUrl: string,
  state?: string,
): Promise<SPAuthUrl | HsErrorResponse> {
  const body = {
    SPSiteUrl,
    state,
  };

  // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  // eslint-disable-next-line no-restricted-syntax
  const response = await hsFetch('/sharepoint/helloSignAuthorizeUrl', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.authUrl) {
    return {
      authUrl: resp.authUrl,
      state: resp.state,
      clientId: resp.clientId,
      success: true,
    } as SPAuthUrl;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function helloSignLogInBridgeUrl(
  SPSiteUrl: string,
  state?: string,
): Promise<SPAuthUrl | HsErrorResponse> {
  const body = {
    SPSiteUrl,
    state,
  };

  // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  // eslint-disable-next-line no-restricted-syntax
  const response = await hsFetch('/sharepoint/helloSignLogInBridgeUrl', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.authUrl) {
    return {
      authUrl: resp.authUrl,
      state: resp.state,
      success: true,
    } as SPAuthUrl;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function getFolders(
  SPSiteUrl: string,
): Promise<TreeNodeInArray[] | HsErrorResponse> {
  const body = {
    SPSiteUrl,
  };

  const response = await hsFetch('/sharepoint/getFolders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  try {
    const allFolders = await folderArraySchema.validate(resp);
    return allFolders;
  } catch (e) {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function saveFolder(
  SPSiteUrl: string,
  folderToSave: SPFolder,
): Promise<HSActionResult | HsErrorResponse> {
  const body = {
    SPSiteUrl,
    folderInfo: {
      id: folderToSave.id,
      path: folderToSave.path,
      root_id: folderToSave.rootId,
    },
  };

  const response = await hsFetch('/sharepoint/saveFolder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.success) {
    return resp;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function getSavedFolder(
  SPSiteUrl: string,
): Promise<SPFolder | HsErrorResponse> {
  const body = {
    SPSiteUrl,
  };

  const response = await hsFetch('/sharepoint/getSavedFolder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if ('id' in resp) {
    return {
      id: resp.id,
      path: resp.path,
      rootId: resp.root_id,
    };
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function getSPUsers(
  SPSiteUrl: string,
): Promise<SPUserBase[] | HsErrorResponse> {
  const body = {
    SPSiteUrl,
  };

  const response = await hsFetch('/sharepoint/getUsers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (Array.isArray(resp)) {
    return resp;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function grantSPUserAccess(
  SPSiteUrl: string,
  spUsers: SPUser[],
): Promise<HSActionResult | HsErrorResponse> {
  const emails: { [key: string]: boolean } = {};
  spUsers.forEach((user) => {
    emails[user.email] = true;
  });

  const body = {
    SPSiteUrl,
    emails,
  };

  const response = await hsFetch('/sharepoint/grantAccess', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.success) {
    return resp;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function revokeSPUserAccess(
  SPSiteUrl: string,
  spUsers: SPUser[],
): Promise<HSActionResult | HsErrorResponse> {
  const emails = spUsers.map((user) => user.email);

  const body = {
    SPSiteUrl,
    emails,
  };

  const response = await hsFetch('/sharepoint/revokeAccess', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.success) {
    return resp;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function keepSharePointSessionAlive(): Promise<HSActionResult> {
  const response = await hsFetch('/sharepoint/keepSessionAlive', {
    method: 'GET',
  });
  const responseBody = await response.json();
  return responseBody;
}

export async function sharepointPrepAndSend(
  SPSiteUrl: string,
  SPListId: string,
  SPListItemId: string,
): Promise<PrepAndSendSuccess | HsErrorResponse> {
  const body = {
    SPSiteUrl,
    SPListId,
    SPListItemId,
  };

  const response = await hsFetch('/sharepoint/prepAndSend', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.claimUrl) {
    return {
      clientId: resp.clientId,
      claimUrl: resp.claimUrl,
      signedFolderLocation: resp.signedFolderLocation,
      success: true,
    } as PrepAndSendSuccess;
  } else {
    try {
      const errorObj = JSON.parse(resp.error);
      if (errorObj.error.error_msg) {
        return {
          code: ErrorCode.PrepAndSendFileError,
          message: errorObj.error.error_msg,
          statusCode: response.status,
          success: false,
        } as HsErrorResponse;
      } else {
        throw new Error(resp.error);
      }
    } catch (e: any) {
      return {
        code: resp.error,
        message: resp.error || e.message,
        statusCode: response.status,
        success: false,
      } as HsErrorResponse;
    }
  }
}

export async function consentToTermsAndPolicy(
  state: string,
  userEmail: string,
): Promise<HSActionResult | HsErrorResponse> {
  const body = {
    userEmail,
    state,
  };

  const response = await hsFetch('/sharepoint/consentToTermsAndPolicy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.success) {
    return resp;
  } else {
    return {
      code: resp.code || resp.error,
      message: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}
