import React from 'react';
import { atom, useAtom } from 'jotai';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import { useFeatureFlag } from 'js/sign-components/common/feature-flag';

export const accessibleViewAtom = atom(false);

export function ToggleAccessibleView() {
  const [view, setView] = useAtom(accessibleViewAtom);
  const allowAccessibleSignerView = useFeatureFlag(
    'sign_core_2024_05_10_accessible_view',
  );
  if (!allowAccessibleSignerView) {
    return null;
  }

  return (
    <Split>
      <Checkbox
        checked={Boolean(view)}
        onChange={(e) => {
          setView(e.target.checked);
        }}
        id="toggle-view"
        aria-label="Accessible Signer View"
      />
      <Text tagName="label" htmlFor="toggle-view">
        Accessible Signer View
      </Text>
    </Split>
  );
}
