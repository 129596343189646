import { defineMessages } from 'react-intl';

export type codeDescription = {
  id: string;
  defaultMessage: string;
  description: string;
};

export type codeMap = {
  [key: string]: codeDescription;
};

export const errorCodes: codeMap = defineMessages({
  code400: {
    id: '01ed429b0322b2ab6582c9816d75d965ba527cfecc782310e9c67aeb949f94bc',
    defaultMessage: 'Bad Request',
    description: 'The request cannot be fulfilled due to bad syntax.',
  },
  code401: {
    id: '82b45ef35e9b04a37acec1e5868ca1db005276b052ef53f487dc1542e6df02e2',
    defaultMessage: 'Unauthorized',
    description:
      'The request was a legal request, but the server is refusing to respond to it. For use when authentication is possible but has failed or not yet been provided.',
  },
  code402: {
    id: '2ce2d742c441687e703dace2bd2121cf74fda5349b975c8039872f35207441b7',
    defaultMessage: 'Payment Required',
    description:
      'Not yet implemented by RFC standards, but reserved for future use.',
  },
  code403: {
    id: '580c69649c10b535193c3167a6788cfaeae78029f9cbc66507e7f4147e673ae3',
    defaultMessage: 'Forbidden',
    description:
      'The request was a legal request, but the server is refusing to respond to it.',
  },
  code404: {
    id: '79c52bb78e058584ba50407022b80734692823edddd1d42be044cf1ebcbacac4',
    defaultMessage: 'Not Found',
    description:
      'The requested page could not be found but may be available again in the future.',
  },
  code405: {
    id: '727f26bfa2a3a7bfb6ecbc49e71abf409f9b03d916c481de8d9bce7adc310224',
    defaultMessage: 'Method Not Allowed',
    description:
      'A request was made of a page using a request method not supported by that page.',
  },
  code406: {
    id: 'f0c235d4a6d122952e5a0211486a8c5e55a2e864a904dd963c27c3aad4a5b674',
    defaultMessage: 'Not Acceptable',
    description:
      'The server can only generate a response that is not accepted by the client.',
  },
  code407: {
    id: 'dd39f2091f62e9bc0ae833f7437000f7eb4911622b8c973e637bab3da34e3c37',
    defaultMessage: 'Proxy Authentication Required',
    description: 'The client must first authenticate itself with the proxy.',
  },
  code408: {
    id: '3d6aa49f6cdb6c45819857d3bb034622fc5fa2deaba5249856e49db703cbad28',
    defaultMessage: 'Request Timeout',
    description: 'The server timed out waiting for the request.',
  },
  code409: {
    id: 'a48135c52b1808d296933862dc834217efc1a0bf15c658a0b74807b4ec77081c',
    defaultMessage: 'Conflict',
    description:
      'The request could not be completed because of a conflict in the request.',
  },
  code410: {
    id: '2f52b813fdc4fe85dec6f74593185a7ca0232f59f163c921439f521302c960a1',
    defaultMessage: 'Gone',
    description: 'The requested page is no longer available.',
  },
  code411: {
    id: 'c718cee51031721274948c9c5e56a8daf0fe1fc658c720732f5038d21176c7d6',
    defaultMessage: 'Length Required',
    description:
      'The "Content-Length" is not defined. The server will not accept the request without it.',
  },
  code412: {
    id: '10d8cf9136c8d9b077b57701d5d476ec00e0402c2db957391bb94fc36affa836',
    defaultMessage: 'Precondition Failed',
    description:
      'The precondition given in the request evaluated to false by the server.',
  },
  code413: {
    id: '6e7e6f310aac0a2c80b4775a9d2f1842521e7a0143a001515adf20c4526898a1',
    defaultMessage: 'Request Entity Too Large',
    description:
      'The server will not accept the request, because the request entity is too large.',
  },
  code414: {
    id: '012fd13d3f084a250469cbf3c7c8a1b0b5bf33f8579cb88ecc4ed4233046233e',
    defaultMessage: 'Request-URI Too Long',
    description:
      'The server will not accept the request, because the URL is too long. Occurs when you convert a POST request to a GET request with a long query information.',
  },
  code415: {
    id: '9e47d62dfad003df9da38388e81df03a23dca7f11e9c02ba7c5ea252b7d8e19a',
    defaultMessage: 'Unsupported Media Type',
    description:
      'The server will not accept the request, because the media type is not supported.',
  },
  code416: {
    id: '7a1484d5df6361477298b7525d6004c75a6731343d0cd9c2c3aeab1fe6dfffff',
    defaultMessage: 'Requested Range Not Satisfiable',
    description:
      'The client has asked for a portion of the file, but the server cannot supply that portion.',
  },
  code417: {
    id: '8a63dc8c5197d2c3a0295af06c3ea6eaefd3df3a433ad26e6b4406ad31dd6927',
    defaultMessage: 'Expectation Failed',
    description:
      'The server cannot meet the requirements of the Expect request-header field.',
  },
  code418: {
    id: 'd230d57ba3d05438778e4fcbdfaf86cc6aabf4d289235fdff9709e8dd70a4b6c',
    defaultMessage: "I'm a teapot",
    description:
      'Any attempt to brew coffee with a teapot should result in the error code "418 I\'m a teapot". The resulting entity body MAY be short and stout.',
  },
  code419: {
    id: '320d0b9ca7c87e5a17b70dae211e66e67b1dbb40f35e5894cbdc8e89164ac83d',
    defaultMessage: 'Page Expired (Laravel Framework)',
    description:
      'Used by the Laravel Framework when a CSRF Token is missing or expired.',
  },
  code420: {
    id: '9f56f903c6369edc04e15ffb64b0b2177de438590fbb93ae5b4a510bd66c838c',
    defaultMessage: 'Method Failure (Spring Framework)',
    description:
      'A deprecated response used by the Spring Framework when a method has failed.',
  },
  code421: {
    id: 'e9feccd009d8bd6fb674f95a9e68df5d1e1a2f438cd811a5cae887ee2d14a08f',
    defaultMessage: 'Misdirected Request',
    description:
      'The request was directed at a server that is not able to produce a response (for example because a connection reuse).',
  },
  code422: {
    id: '31041decacc0c74dbb7e8ce0170af50e92f95a9a67f9fa655463a82779a46416',
    defaultMessage: 'Unprocessable Entity',
    description:
      'The request was well-formed but was unable to be followed due to semantic errors.',
  },
  code423: {
    id: '5c0cecf61f3ead21d0c140f66a702cb37ec411c8686fd4e2217c1e385f2f43ae',
    defaultMessage: 'Locked',
    description: 'The resource that is being accessed is locked.',
  },
  code424: {
    id: '5440c9ff60a7af3ff817c6d16d9e28598e03410fa3fe9449dfea53a90ce149e4',
    defaultMessage: 'Failed Dependency',
    description:
      'The request failed due to failure of a previous request (e.g., a PROPPATCH).',
  },
  code426: {
    id: '04a4dac9d6e17f5d5993bc0286abbc69ae70cd0c30ad5d5e7e6d75e7b9c2c593',
    defaultMessage: 'Upgrade Required',
    description:
      'The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.',
  },
  code428: {
    id: 'f6a3a1a403ea475c22984e14c532e1f10f32cfd8c965f90705f5776911f84ca8',
    defaultMessage: 'Precondition Required',
    description: 'The origin server requires the request to be conditional.',
  },
  code429: {
    id: '92bf3c9b13acbc949293c807af7364851ab3c4fce022af962986c516aa4f051a',
    defaultMessage: 'Too Many Requests',
    description:
      'The user has sent too many requests in a given amount of time. Intended for use with rate limiting schemes.',
  },
  code431: {
    id: '6213a73af0f3d95033a706113a1d10c22579c31d8c65964adc0e24256d82321d',
    defaultMessage: 'Request Header Fields Too Large',
    description:
      'The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.',
  },
  code440: {
    id: '57a22e443cd4c24dd8a644023bf9f774c217ade82b90d4eac828da17c9c8fb48',
    defaultMessage: 'Login Time-out',
    description:
      "The client's session has expired and must log in again. (IIS)",
  },
  code444: {
    id: '584b22ab510651e6c2f3c6e1ec82113cf9d8309c5153d49eabbfe0ef74ef9d83',
    defaultMessage: 'Connection Closed Without Response',
    description:
      'A non-standard status code used to instruct nginx to close the connection without sending a response to the client, most commonly used to deny malicious or malformed requests.',
  },
  code449: {
    id: 'a7ee2d903670d9456f232357814feaf8be971750ae03a05758bc19f046ec4f59',
    defaultMessage: 'Retry With',
    description:
      'The server cannot honour the request because the user has not provided the required information. (IIS)',
  },
  code450: {
    id: 'c155c91f2debca7012b0a6879aa58cbbc88e18e7fce8a67f546011198c1d8acc',
    defaultMessage: 'Blocked by Windows Parental Controls',
    description:
      'The Microsoft extension code indicated when Windows Parental Controls are turned on and are blocking access to the requested webpage.',
  },
  code451: {
    id: '6cfc1458442b6d1e46acf103a51c4c77ca81e3d3d550e5565b85a6ee9669403e',
    defaultMessage: 'Unavailable For Legal Reasons',
    description:
      'A server operator has received a legal demand to deny access to a resource or to a set of resources that includes the requested resource.',
  },
  code494: {
    id: '847f7f011607649b0210f1c71eec453841751fa95760a4f85816ac1e1abc9b14',
    defaultMessage: 'Request Header Too Large',
    description:
      'Used by nginx to indicate the client sent too large of a request or header line that is too long.',
  },
  code495: {
    id: 'c607eec3f39ba8fb7972ab7a4cc15cf56108df558073fb3e75effec622a1874f',
    defaultMessage: 'SSL Certificate Error',
    description:
      'An expansion of the 400 Bad Request response code, used when the client has provided an invalid client certificate.',
  },
  code496: {
    id: 'fe1e2bd1838adb3c1386597b77940e883e91ab35fa8d53106093b3957d94f04c',
    defaultMessage: 'SSL Certificate Required',
    description:
      'An expansion of the 400 Bad Request response code, used when a client certificate is required but not provided.',
  },
  code497: {
    id: '8f100bead61d29547b4db13fdd63d0a27174ef7c948c2605fa8bf73b0eb18827',
    defaultMessage: 'HTTP Request Sent to HTTPS Port',
    description:
      'An expansion of the 400 Bad Request response code, used when the client has made a HTTP request to a port listening for HTTPS requests.',
  },
  code498: {
    id: 'e23d107e88dd33185ed4530c8382e65e0b072933cdcc8159b1fa3c333a307469',
    defaultMessage: 'Invalid Token (Esri)',
    description:
      'Returned by ArcGIS for Server. Code 498 indicates an expired or otherwise invalid token.',
  },
  code499: {
    id: 'fd6b256f175ad97bbafc2bd503c12aca868d7a9a3219d912a58c728e49807cd8',
    defaultMessage: 'Client Closed Request',
    description:
      'A non-standard status code introduced by nginx for the case when a client closes the connection while nginx is processing the request.',
  },
  code500: {
    id: '2aaaf535aaeb36786e3ec6a6fab8814b41a383865aac26ca6aa487fc4ccc7bff',
    defaultMessage: 'Internal Server Error',
    description:
      'An error has occurred in a server side script, a no more specific message is suitable.',
  },
  code501: {
    id: '222e02338d8a9a395ff7fdc72f22b18104b9282ab94a0e8bdeef95e78ce70054',
    defaultMessage: 'Not Implemented',
    description:
      'The server either does not recognize the request method, or it lacks the ability to fulfill the request.',
  },
  code502: {
    id: '0665553cec4209b3b14a8ca80f5e7c4596b44840c2e269922f9219823bf0795b',
    defaultMessage: 'Bad Gateway',
    description:
      'The server was acting as a gateway or proxy and received an invalid response from the upstream server.',
  },
  code503: {
    id: '6b4c9a8a97e507925a6b0e651d0c3d04f9c5bd459f93c47c58c6d32595ddafa1',
    defaultMessage: 'Service Unavailable',
    description: 'The server is currently unavailable (overloaded or down).',
  },
  code504: {
    id: 'c2128e6832c0b551126efd681d3955fb05e6ea153d84ef51f36288b3b54071c2',
    defaultMessage: 'Gateway Timeout',
    description:
      'The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.',
  },
  code505: {
    id: '875bdebd97eed96ba29d0f7b4fccbc9f54d967546129f8440b0d6ff306511df4',
    defaultMessage: 'HTTP Version Not Supported',
    description:
      'The server does not support the HTTP protocol version used in the request.',
  },
  code506: {
    id: 'dc296e618b0c3db8b2aef899c8dec3103a2fe8d64015a247c9cafcb661d98b05',
    defaultMessage: 'Variant Also Negotiates',
    description:
      'Transparent content negotiation for the request results in a circular reference.',
  },
  code507: {
    id: '3d68bb74f3f4c8561ca2183847d6e8b3c710d50a48ab11c63a1f7441810de7b2',
    defaultMessage: 'Insufficient Storage',
    description:
      'The server is unable to store the representation needed to complete the request.',
  },
  code508: {
    id: '7aa4227789538fa8235d8095ced8c6188ce47dbfb14d1faf8aedeb03aa3c6508',
    defaultMessage: 'Loop Detected',
    description:
      'The server detected an infinite loop while processing the request (sent instead of 208 Already Reported).',
  },
  code509: {
    id: 'cd9c87987d58c78be1f9956e9465918f8a7590ed47218724029f710da20e2dfa',
    defaultMessage: 'Bandwidth Limit Exceeded',
    description:
      'The server has exceeded the bandwidth specified by the server administrator; this is often used by shared hosting providers to limit the bandwidth of customers.',
  },
  code510: {
    id: '64c8bf11ff8d11643abf7f7b28f6ca1e82c1a2f958f690c89ae9a456e194d183',
    defaultMessage: 'Not Extended',
    description:
      'Further extensions to the request are required for the server to fulfil it.',
  },
  code511: {
    id: '61cf512c8ebef1f96daf8764645ac0dcafff5950206ff02d0bc76930036d309a',
    defaultMessage: 'Network Authentication Required',
    description: 'The client needs to authenticate to gain network access.',
  },
  code520: {
    id: 'fa82adc9e254cd676dedbbca532e6e11320c869d1d7d387012ceeab79eb57e19',
    defaultMessage: 'Unknown Error',
    description:
      'The 520 error is used as a "catch-all response for when the origin server returns something unexpected", listing connection resets, large headers, and empty or invalid responses as common triggers.',
  },
  code521: {
    id: '07b0b5478b3046242ba697733fdfc0045881462a1d6c525200ad3cb19c060ddc',
    defaultMessage: 'Web Server Is Down',
    description:
      'The origin server has refused the connection from Cloudflare.',
  },
  code522: {
    id: 'a31ffc53b6a96459e9a9ca57a00a12ae76d643aa7484ce34d6162ec92410f574',
    defaultMessage: 'Connection Timed Out',
    description:
      'Cloudflare could not negotiate a TCP handshake with the origin server.',
  },
  code523: {
    id: '0c029587cdf8a7380d98371aaeefe244827ce2a1c3ab9786f9e84949ac28ce9e',
    defaultMessage: 'Origin Is Unreachable',
    description:
      'Cloudflare could not reach the origin server; for example, if the DNS records for the origin server are incorrect.',
  },
  code524: {
    id: '58c3a298448b8d720d7facb7faa9792668a7c2d8d7a9e741665d56c50e73a5c5',
    defaultMessage: 'A Timeout Occurred',
    description:
      'Cloudflare was able to complete a TCP connection to the origin server, but did not receive a timely HTTP response.',
  },
  code525: {
    id: '7d4dbf5538c8ca481961c7724c56443af894e5fd3c70534577ca03d78145a089',
    defaultMessage: 'SSL Handshake Failed',
    description:
      'Cloudflare could not negotiate a SSL/TLS handshake with the origin server.',
  },
  code526: {
    id: '145eab45b656acdf786447160bcaa02b8d3188c78d2ebf33a18910722b276767',
    defaultMessage: 'Invalid SSL Certificate',
    description:
      "Used by Cloudflare and Cloud Foundry's gorouter to indicate failure to validate the SSL/TLS certificate that the origin server presented.",
  },
  code527: {
    id: '2eaf0879945a61d4b182540aeaa959b5fe5fef961cc55fa2b189a90ba0e507b6',
    defaultMessage: 'Railgun Listener to Origin Error',
    description:
      'Error 527 indicates that the request timed out or failed after the WAN connection had been established.',
  },
  code530: {
    id: '89fe5154f1e54b70e8c979c813f73f79e1c81cba01d363ffad412696ae8125ce',
    defaultMessage: 'Origin DNS Error',
    description:
      'Error 530 indicates that the requested host name could not be resolved on the Cloudflare network to an origin server.',
  },
  code598: {
    id: '8d48d5c9e27963f90b34d3b59bd54e011d7c3f59316405a759ea5af827824d84',
    defaultMessage: 'Network Read Timeout Error',
    description:
      'Used by some HTTP proxies to signal a network read timeout behind the proxy to a client in front of the proxy.',
  },
});
