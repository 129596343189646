import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import { assertValidResponse } from 'signer-app/utils/response-helpers';

export async function deleteApp(clientId: string) {
  const url = '/oauth/deleteApp';

  const formData = new FormData();
  formData.append('client_id', clientId);
  formData.append('csrf_token', getCSRFToken());

  const resp = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  // This endpoint returns nothing. If it's a `200` response, it worked.
  assertValidResponse(resp);
}

// not camelcase because that's what coming from backend api
/* eslint-disable camelcase */
export interface ApiKeyData {
  api_key: string;
  created_at?: string;
  guid: string;
  is_legacy_key: boolean;
  is_primary_key: boolean;
  last_used_at?: string;
  name?: string;
  updated_at?: string;
}
/* eslint-enable camelcase */

type ListApiKeysResponse =
  | { success: true; data: Array<ApiKeyData> }
  | { success: false; error: string };

export async function listApiKeys(): Promise<ListApiKeysResponse> {
  const url = '/endpoint/org/listApiKey';
  const resp = await hsFetch(url);

  // TODO: Parse and validate this
  return resp.json();
}

type RenameKeyResponse = { success: true } | { success: false; error: string };

export async function renameApiKey(
  guid: string,
  name: string,
): Promise<RenameKeyResponse> {
  const url = `/endpoint/org/renameApiKey?guid=${guid}`;

  const resp = await hsFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });

  // TODO: Parse and validate this
  return resp.json();
}

type MarkKeyAsPrimaryResponse =
  | { success: true }
  | { success: false; error: string };

export async function markApiKeyAsPrimary(
  guid: string,
): Promise<MarkKeyAsPrimaryResponse> {
  const url = '/endpoint/org/markApiKeyAsPrimary';

  const resp = await hsFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ guid }),
  });

  // TODO: Parse and validate this
  return resp.json();
}

export async function deleteApiKey(guid: string) {
  const url = '/endpoint/org/deleteApiKey';

  const resp = await hsFetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ guid }),
  });

  // TODO: Parse and validate this
  return resp.json();
}

export async function generateApiKey(name: string) {
  const url = '/endpoint/org/GenerateApiKey';

  const resp = await hsFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  });

  // TODO: Parse and validate this
  return resp.json();
}

export type AccountCallbackTestResponse = {
  error?: string;
  matched?: boolean;
  message?: string;
  success?: boolean;
  response?: string;
};

export async function testCallbackURL(
  callbackUrl: string,
): Promise<AccountCallbackTestResponse> {
  const url = '/home/testEventCallback';

  const formData = new FormData();
  formData.append('callback_url', callbackUrl);
  formData.append('csrf_token', getCSRFToken());

  const resp = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  return resp.json();
}
