import * as Yup from 'yup';
import { Actions } from './index';

export interface ApiApp {
  readonly clientId: string;
  readonly parentUrl: string;
}

export const apiAppSchema = Yup.object<ApiApp>({
  clientId: Yup.string().trim().required(),
  parentUrl: Yup.string().trim().required(),
})
  .notRequired()
  .default(undefined);

export interface UpdateApiAppAction {
  type: Actions.UpdateApiApp;
  payload: ApiApp;
}

export type ApiAppActions = UpdateApiAppAction;
