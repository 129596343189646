import React, { useCallback, useState } from 'react';
import { GlobalHeader } from '@dropbox/dig-components/global_header';
import { FssWordmark } from '@dropbox/dig-logos';
import { NextButton } from 'signer-app/signer-experience/next-button';
import { defineMessages, useIntl } from 'react-intl';
import { Menu } from '@dropbox/dig-components/menu';
import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { useSignerAppParams } from 'signer-app/signer-experience/signer-experience';
import { FailLine, MoreVerticalLine } from '@dropbox/dig-icons/assets';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { SignerDeclineModal } from 'signer-app/signer-decline-modal/signer-decline-modal';

export const messages = defineMessages({
  menu: {
    id: 'bfcf1d2a0c4be80a6011518767e50c1d7660b8b30b91948e4a7b651565b11df4',
    defaultMessage: 'Menu',
    description: 'Button to open the menu.',
  },
  decline: {
    id: 'c9418f47125e581cc8d40561b46e6cfbcfa3ca788f2335c5b07bdf19cc55d826',
    defaultMessage: 'Decline',
    description: 'Button to decline signing the document.',
  },
  declineSubtitle: {
    id: 'd2373705aaca6734a1bf8fdb39b4dce8f5c6e85d4f118d57681fe8365393b9d0',
    defaultMessage: 'Decline to sign this document.',
    description: 'Button to decline signing the document.',
  },
  download: {
    id: '030347be46c81f891344210e68dae1b72623def2394d8aab399685714e2332a8',
    defaultMessage: 'Download',
    description: 'Button to download the document.',
  },
  downloadSubtitle: {
    id: 'c1b1a33384e00ce566bf21bb857bf838bd0b9be90fbef559772ead0fe3d96362',
    defaultMessage: 'Download the document.',
    description: 'Button to download the document.',
  },
});
export function SignerToolbar() {
  const { step } = useSignerAppParams();
  const intl = useIntl();
  const [modals, setModals] = useState({
    decline: false,
  });

  const handleSelection = () => {};

  const modalToggle = useCallback(
    (modalName: keyof typeof modals) => () => {
      setModals({
        ...modals,
        [modalName]: !modals[modalName],
      });
    },
    [modals, setModals],
  );
  const toggleDecline = modalToggle('decline');

  return (
    <ThemeProvider mode={step === 'preview' ? 'dark' : 'bright'}>
      <ThemeContainer rootElement={document.documentElement}>
        <GlobalHeader.Wrapper>
          <Menu.Wrapper onSelection={handleSelection}>
            {({ getTriggerProps, getContentProps }) => (
              <>
                <IconButton {...getTriggerProps()} variant="borderless">
                  <UIIcon
                    src={MoreVerticalLine}
                    aria-label={intl.formatMessage(messages.menu)}
                  />
                </IconButton>
                <Menu.Content
                  {...getContentProps()}
                  data-test-ref="signer-toolbar-menu"
                >
                  {/* These are NOT LinkItems because I want them to integrate
                  with our router */}
                  <Menu.ActionItem
                    onClick={toggleDecline}
                    value="decline"
                    withLeftAccessory={
                      <UIIcon src={FailLine} role="presentation" />
                    }
                  >
                    {intl.formatMessage(messages.decline)}
                  </Menu.ActionItem>
                  <Menu.ActionItem value="download">
                    {intl.formatMessage(messages.download)}
                  </Menu.ActionItem>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>

          <GlobalHeader.Logo src={FssWordmark} href="/" />

          <NextButton />
        </GlobalHeader.Wrapper>
        {modals.decline && (
          <SignerDeclineModal
            isEmbedded={false}
            signatureRequestId="sr-guid"
            onRequestClose={toggleDecline}
          />
        )}
      </ThemeContainer>
    </ThemeProvider>
  );
}
