import React from 'react';
import classnames from 'classnames';
import { DropdownField } from 'signer-app/types/editor-types';
import styles from 'signer-app/signature-request/display-field/dropdown.module.css';
import 'signer-app/signature-request/premium-fonts.module.css';
import textStyles from 'signer-app/signature-request/display-field/text.module.css';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
} from 'signer-app/signature-request/constants';
import { Menu } from '@dropbox/dig-components/menu';
import { StylelessButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronDownLine } from '@dropbox/dig-icons/assets';

// Other field types use a real event, but we don't have one with the dropdown.
// The only thing it really needs is the value
// https://github.com/HelloFax/hellosign-web/blob/edd601fc127b875caed37d2ea643e373b9400137/src/signer-app/signer-signature-document/signer-field.jsx#L54-L59
type EventLike = {
  target: {
    value: string;
  };
};
export type Props = {
  className?: string;
  fieldData: DropdownField;
  hideControls?: boolean;
  overrideValue?: string;
  onChange: (e: EventLike) => void;
};

export const ZERO_WIDTH_SPACE = '\u{200b}';

function findBtn(element: HTMLElement): HTMLElement | null {
  if (element.dataset && element.dataset.btn) {
    return element;
  }

  for (let i = 0; i < element.children.length; i++) {
    const result = findBtn(element.children[i] as HTMLElement);
    if (result) {
      return result;
    }
  }
  return null;
}

function DropdownFieldUI(props: Props, ref: any) {
  const { hideControls = true, fieldData, overrideValue } = props;
  const container = React.useRef<HTMLDivElement>(null);

  const handleSelection = (value: string) => {
    props.onChange({
      target: { value },
    });
  };

  React.useImperativeHandle(
    ref,
    () => ({
      measure() {
        if (container.current) {
          return {
            width: container.current.scrollWidth,
            height: container.current.scrollHeight,
          };
        }
        return { width: 0, height: 0 };
      },
      focus() {
        // react-aria-menubutton doesn't provide references to the DOM node, so I
        // have to hold a reference to my own DIV and go find the button element.
        if (container.current) {
          const btn = findBtn(container.current);
          if (btn) {
            btn.focus();
          }
        }
      },
    }),
    [],
  );

  const fontFamily = fieldData.fontFamily || DEFAULT_FONT_FAMILY;
  const fontSize = fieldData.fontSize || DEFAULT_FONT_SIZE;

  const btnText = overrideValue || fieldData.value || ZERO_WIDTH_SPACE;
  const className = classnames(
    props.className,
    styles.button,
    textStyles[`font--${fontFamily}`],
    textStyles.dropdownText,
  );

  const style = {
    fontSize: `${fontSize}px`,
    lineHeight: `${fontSize}px`,
  };

  return (
    <div
      ref={container}
      className={classnames(styles.container, {
        [styles.hideControls]: hideControls,
      })}
    >
      <Menu.Wrapper
        id={fieldData.id}
        className={styles.wrapper}
        onSelection={handleSelection}
        data-testid={`dropdown-${fieldData.id}`}
        data-field={fieldData.id}
      >
        {({ getContentProps, getTriggerProps }) => (
          <>
            <StylelessButton
              data-testid="dropdown-btn"
              style={style}
              tabIndex={0}
              {...getTriggerProps()}
              data-btn
              className={classnames(
                className,
                styles.digButton,
                textStyles[`font--${fontFamily}`],
              )}
            >
              {btnText}
              <UIIcon
                src={ChevronDownLine}
                size="small"
                className={styles.uiIcon}
                data-testid="digButtonDropdownIcon"
                isOpticallyAligned
              />
            </StylelessButton>
            <Menu.Content {...getContentProps()}>
              {fieldData.options &&
                fieldData.options.map((text) => (
                  <Menu.SelectItem key={text} value={text}>
                    <span
                      style={style}
                      className={textStyles[`font--${fontFamily}`]}
                    >
                      {text}
                    </span>
                  </Menu.SelectItem>
                ))}
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    </div>
  );
}

export default React.forwardRef(DropdownFieldUI);

export function openMenu(id: string) {
  const wrapper = document.querySelector(
    `[data-testid='dropdown-${id}']`,
  ) as HTMLElement | null;
  const btn = wrapper?.querySelector(
    '[data-testid="dropdown-btn"]',
  ) as HTMLElement | null;

  let firstItem = document.querySelector(
    '[role="menuitemradio"]',
  ) as HTMLElement | null;
  if (!firstItem) {
    btn?.click();
    firstItem = document.querySelector(
      '[role="menuitemradio"]',
    ) as HTMLElement | null;

    firstItem?.focus();
  }
}
export function closeMenu() {}
