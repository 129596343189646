import React from 'react';
import { useLatestRef } from 'signer-app/utils/use-latest-ref';

export function useEventListener<
  T extends HTMLElement | Window | Document,
  P extends Parameters<T['addEventListener']>,
>(target: T | null, ...params: P) {
  const [name, handler, options] = params;

  if (typeof handler !== 'function') {
    throw new Error(
      'handler must be a function. This hook does not support EventListenerObject',
    );
  }

  const handlerRef = useLatestRef(handler);

  React.useEffect(() => {
    // I can't call hooks conditionally, so this needs to make the effect a NOOP
    if (target == null) {
      return () => {};
    }

    try {
      const stableHandler: typeof handlerRef.current = (...args) =>
        handlerRef.current(...args);

      target.addEventListener(name, stableHandler, options);
      return function cleanup() {
        target.removeEventListener(name, stableHandler);
      };
    } catch (e) {
      // embedded flows cause a cross-origin exception
      return () => {};
    }
  }, [handlerRef, name, options, target]);
}
