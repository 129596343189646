import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';

export async function updateExpiredCCBannerInvokeStatus(): Promise<void> {
  const url = '/endpoint/updateExpiredCCBannerInvokeStatus';
  const formData = new FormData();
  formData.append('csrf_token', getCSRFToken());
  try {
    await hsFetch(url, {
      method: 'POST',
      body: formData,
    });
  } catch (e) {
    throw e;
  }
}
