/* eslint-disable camelcase */
import hsFetch, {
  getCSRFToken,
  handleException,
} from 'signer-app/utils/hs-fetch';
import {
  CreateTypeCode,
  SIGNATURE_TYPE_TYPED,
  SignatureTypeCode,
  SIGNATURE_TYPE_UPLOAD,
  SIGNATURE_TYPE_CANVAS,
} from 'signer-app/types/signature-types';
import { OnSignatureDataType } from 'signer-app/signature-modal/signature-modal-context/signature-provider-intercept';
import { UnreachableError } from 'signer-app/utils/unreachable';
import invariant from 'invariant';
import { FromSignaturesData } from 'signer-app/signature-modal/signature-modal-context/signature-provider-impl';

type Value = string | number | boolean | undefined;
type QueryParams = Record<string, Value | Value[]>;

type Separator = '?' | '&' | '';

/**
 * @deprecated Try using a `URLSearchParams` object instead
 */
export function queryParams(obj: QueryParams, separator: Separator = '?') {
  const search = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        return value
          .flatMap((v) =>
            v != null ? [`${key}=${encodeURIComponent(v)}`] : [],
          )
          .join('&');
      }

      if (value != null) {
        return `${key}=${encodeURIComponent(value)}`;
      }
      return null;
    })
    .filter((tmp) => tmp != null)
    .join('&');

  return search.length > 0 ? `${separator}${search}` : '';
}

export type UploadData = {
  type: SigData['type_code'];
  createType: CreateTypeCode;
  allowColorSig: boolean;
  file: File;

  guid?: string;
  height?: number;
  width?: number;
};
export type SignatureUploadResponse = {
  is_valid: true;
  height: number;
  width: number;
  signature_id: string;
  signature_guid: string;
};

export type SigData = {
  guid: string;

  // IDK why we have a type_code and a type
  type_code: SignatureTypeCode;
  type: SignatureTypeCode;

  signature: null | {
    image: string;
    is_vml?: boolean;
    text?: string;
    font_family?: string;
  };
  create_type_code: CreateTypeCode;
  is_cropped?: boolean;
  threshold?: number;
  degrees?: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

export default function signatureActions(preloadedTsmGroupKey: string) {
  /**
   * Fetches all signatures of type (signature or initial)
   */
  async function fetchSignatures(
    type: 'S' | 'I',
    page?: number | null,
    limit?: number | null,
  ) {
    let signatures = {
      list: [] as FromSignaturesData[],
      count: 0,
    };
    const url = `/signature/list${queryParams({
      type_code: type,
      ux_version: 2,
      preloaded_tsm_group_key: preloadedTsmGroupKey,
      page: page || undefined,
      limit: limit || undefined,
    })}`;
    const response = await hsFetch(url, {
      credentials: 'same-origin',
    });
    if (response.ok) {
      const data = await response.json();
      if (data.success) {
        signatures = data.data;
      }
    } else {
      return handleException(response);
    }
    return signatures;
  }

  async function fetchSignatureByGuid(guid: string): Promise<SigData> {
    let signature = {};
    const url = `/signature/get${queryParams({
      guid,
      ux_version: 2,
      preloaded_tsm_group_key: preloadedTsmGroupKey,
    })}`;
    const response = await hsFetch(url, {
      credentials: 'same-origin',
    });
    if (response.ok) {
      const data = await response.json();
      if (data.success) {
        signature = data.data;
      }
      return signature as SigData;
    } else {
      return handleException(response);
    }
  }

  /**
   * Removes a Saved Signature or Initial
   */
  async function removeSignature(guid: string) {
    const data = {
      guid,
      csrf_token: getCSRFToken(),
      ux_version: 2,
    };
    const response = await hsFetch('/signature/remove?json=1', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return true;
    } else {
      return handleException(response);
    }
  }

  /**
   * Rotates a signature
   */
  async function rotateSignature(guid: string, degrees: number) {
    const data = {
      guid,
      degrees,
      csrf_token: getCSRFToken(),
      ux_version: 2,
    };
    const response = await hsFetch('/signature/rotate?json=1', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return response.json();
    } else {
      return handleException(response);
    }
  }

  /**
   * Uploads a signature canvas
   */
  async function uploadCanvas(sigData: OnSignatureDataType) {
    invariant(
      sigData.create_type_code === 'U' || sigData.create_type_code === 'C',
      'uploadCanvas: Invalid create type code',
    );

    const data = {
      type_code: sigData.type_code,
      signature: sigData.signature.image,
      is_vml: sigData.signature.is_vml,
      csrf_token: getCSRFToken(),
    };
    const response = await hsFetch('/signature/uploadCanvas?json=1', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return response.json() as Promise<SignatureUploadResponse>;
    } else {
      return handleException(response);
    }
  }

  /**
   * Uploads a type-in, file or emailed signature
   */
  async function upload(
    sigData: OnSignatureDataType,
  ): Promise<SignatureUploadResponse> {
    const commonData = {
      create_type_code: sigData.create_type_code,
      type_code: sigData.type_code,
      csrf_token: getCSRFToken(),
    };
    let data;
    switch (sigData.create_type_code) {
      case SIGNATURE_TYPE_CANVAS:
        throw new Error('upload: Invalid upload type');
      case SIGNATURE_TYPE_TYPED:
        // Merge the basics for this type of data
        data = {
          ...commonData,
          // Add the specifics
          ...{
            data: {
              text: sigData.signature.text,
              font_family: sigData.signature.font_family,
            },
          },
        };
        break;
      case SIGNATURE_TYPE_UPLOAD:
        // Merge the basics for this type
        data = {
          ...commonData,
          // Add the specifics
          ...{
            is_edit: true,
            guid: sigData.guid,
            data: {
              editParams: {
                is_cropped: sigData.is_cropped,
                threshold: sigData.threshold,
                degrees: sigData.degrees,
                width: sigData.width,
                height: sigData.height,
                x: sigData.x,
                y: sigData.y,
              },
            },
          },
        };
        break;
      default:
        throw new UnreachableError(sigData);
    }
    const response = await hsFetch('/signature/upload?json=1', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return response.json();
    } else {
      return handleException(response);
    }
  }

  /**
   * Uploads the signature file triggered
   * by the Upload image tab
   */
  async function uploadSignatureFile(upload: UploadData) {
    let signature = {};
    const formData = new FormData();
    formData.append('type_code', upload.type);
    formData.append('create_type_code', upload.createType);
    formData.append('allow_color_signature', String(upload.allowColorSig));
    formData.append('csrf_token', getCSRFToken());
    formData.append('qq_file', upload.file);
    const response = await hsFetch('/signature/upload', {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      signature = response.json();
      return signature as {
        height: number;
        width: number;
        signature_id: string;
      };
    } else {
      return handleException(response);
    }
  }

  // We can compute the corect URL within the webapp, but not storybook.  The
  // mock implementation will look up a data: URL to use without having to
  // interact with the backend.
  function signatureURL(url: string, _guid: string) {
    return url;
  }

  return {
    fetchSignatures,
    fetchSignatureByGuid,
    removeSignature,
    rotateSignature,
    uploadCanvas,
    uploadSignatureFile,
    upload,
    signatureURL,
  };
}
