import React from 'react';
import { signerContext } from 'signer-app/signer-signature-document/context';
import Field from 'signer-app/signer-signature-document/signer-field';

type Props = {
  Field: typeof Field;
  onPageClick: (pageNumber: number) => void;
};

export default function wrapPage<P extends Props>(
  DisplayPage: React.ComponentType<P>,
) {
  return function Page(props: P) {
    const { onPageClick } = React.useContext(signerContext);

    return <DisplayPage {...props} Field={Field} onPageClick={onPageClick} />;
  };
}
