/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  prefer-rest-params
*/
import sift from 'sift';

let _timerInfos = [];

const timerTypes = {
  TIMEOUT: 1,
  INTERVAL: 2,
};

/**

 */

function _wrapTimerCallback(cb, args) {
  return function () {
    cb.apply(this, args);
  };
}

/**
 */

function _addTimer(timer, fn, type) {
  const info = {
    timer,
    type,
    call: fn,
    stop:
      type === timerTypes.TIMEOUT
        ? clearTimeout.bind(global, timer)
        : clearInterval.bind(global, timer),
  };
  _timerInfos.push(info);
  return info;
}

/**
 * triggers all timers immediately. Used for testing
 */

function _triggerTimers(filter) {
  let filteredTimerInfos = _timerInfos;
  if (filter) filteredTimerInfos = filteredTimerInfos.filter(filter);
  filteredTimerInfos.forEach((timerInfo) => {
    timerInfo.call();
  });
}

/**
 * This is a utility to be used with app code. It gives us strict control over timers that are defined in the application, making
 * it easier to test. (CC)
 */

module.exports = {
  /**
   */

  testUtils: {
    /**
     * clears all timer objects (intervals & timeouts). This is a TESTING utility. Use it wisely! (CC)
     */

    clearAllTimers() {
      _timerInfos.forEach((timerInfo) => {
        timerInfo.stop();
      });
      _timerInfos = [];
    },

    /**
     * triggers just intervals.
     */

    triggerAllIntervals() {
      _triggerTimers(sift({ type: timerTypes.INTERVAL }));
    },

    /**
     * triggers just timeouts
     */

    triggerAllTimeouts() {
      _triggerTimers(sift({ type: timerTypes.INTERVAL }));
    },

    /**
     * timeouts & intervals
     */

    triggerAllTimers() {
      _triggerTimers();
    },
  },

  /**
   */

  setTimeout(callback, ms) {
    const fn = _wrapTimerCallback(
      callback,
      Array.prototype.slice.call(arguments, 2),
    );
    let info;
    const timer = setTimeout(() => {
      const i = _timerInfos.indexOf(info);
      if (i > -1) _timerInfos.splice(i, 1);
      fn();
    }, ms);
    info = _addTimer(timer, fn, timerTypes.TIMEOUT);
    return timer;
  },

  /**
   */

  setInterval(callback, ms) {
    const fn = _wrapTimerCallback(
      callback,
      Array.prototype.slice.call(arguments, 2),
    );
    return _addTimer(setInterval(fn, ms), fn, timerTypes.INTERVAL).timer;
  },
};
