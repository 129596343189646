import React from 'react';
import { SyncingLine } from '@dropbox/dig-icons/assets';
import { DebugPanel, PanelComponent } from './debug-panel';
import { useForceUpdate } from 'signer-app/utils/use-force-update';
import { useEventListener } from 'signer-app/utils/use-event-listener';
import {
  WebAppClientNamespaces,
  AppLogSnapshot,
} from 'hello-react/web-app-client/build-web-app-client';
import { Button } from '@dropbox/dig-components/buttons';
import invariant from 'invariant';
import { TextInput } from '@dropbox/dig-components/text_fields';
import {
  globalAppActionsLog,
  loggingEnabled,
} from 'signer-app/utils/log-app-actions';
import { zenPage } from 'signer-app/signer-experience/story-data';

const copy = async (item: any) => {
  navigator.clipboard.writeText(item);
};

const replaceData = (
  data: AppLogSnapshot<WebAppClientNamespaces>,
): AppLogSnapshot<WebAppClientNamespaces> => {
  // Page URLs are not valid in snapshots, so they're replaced with a
  // placeholder. zenPage is a 1x1 pixel using Dropbox's Zen color.
  data.signer?.loadData?.forEach((d) => {
    (d.result as any)?.pages?.forEach((p: any) => {
      p.src = zenPage;
    });
  });

  return data;
};

const makeStory = (
  name: string,
  data: AppLogSnapshot<WebAppClientNamespaces>,
) => {
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
  const snapshot = replaceData(data);

  return `
// Adjust this to point to the correct story file
import * as stories from './application.stories';
import React from 'react';
import { WebClientNamespaces, AppLogSnapshot } from 'hello-react/web-app-client/build-web-app-client';
import { mockWebAppClient } from 'hello-react/web-app-client/mock-web-app-client';

const ${name}: AppLogSnapshot<WebClientNamespaces> = ${JSON.stringify(
    snapshot,
    null,
    2,
  )};

export const ${capitalizedName}Story = () => {
  const appActions = React.useMemo(() => mockWebAppClient('default', ${name}), []);

  return <stories.Main appActions={appActions} />;
}

export default {
  title: 'TestSnapshot/${name}',
  parameters: {
    layout: 'fullscreen',
    blockGlobalDecorator: true,
  },
}
`.trimStart();
};

const AppActionsPanel: PanelComponent = ({ isOpen }) => {
  /**
   * WARNING: This variable is mutated, which means it doesn't work correctly
   * with hooks.
   */
  const log = globalAppActionsLog;
  /**
   * Because the log is append only, this will change when the array changes, so
   * the useMemo will work as expected. It might look cleaner to clone
   * globalAppActionsLog, but then useMemo is going to recompute on EVERY
   * re-render.
   */
  const logLengthHookHack = log.length;
  const forceUpdate = useForceUpdate();
  useEventListener(document.body, 'app-action-log', () => {
    if (isOpen) {
      forceUpdate();
    }
  });

  const data = React.useMemo(
    () =>
      log.reduce((out, d) => {
        if (!isOpen || logLengthHookHack === 0) {
          return {};
        }
        const path = d.name.split('.');

        const leafNode = path.reduce((out: any, ns: string, i, arr) => {
          if (i === arr.length - 1) {
            out[ns] ??= [];
            out[ns].push({
              args: d.args,
              result: d.result,
            });
          } else {
            out[ns] ??= {};
          }
          return out[ns];
        }, out);
        invariant(Array.isArray(leafNode), 'leafNode should be an array');

        return out;
      }, {} as AppLogSnapshot<WebAppClientNamespaces>),
    [isOpen, log, logLengthHookHack],
  );

  const [name, setName] = React.useState('snapshot');

  if (!isOpen) {
    return null;
  }

  if (!loggingEnabled) {
    return (
      <React.Fragment>
        <DebugPanel.SectionHeader>WebAppClient</DebugPanel.SectionHeader>
        <div>
          If enabled, this will log everything that passes through WebAppClient
          in a big array.
        </div>
        <Button
          variant="primary"
          onClick={() => {
            window.localStorage.debug = 'hs:web-app-client';
            window.location.reload();
          }}
        >
          Enable and refresh
        </Button>
      </React.Fragment>
    );
  }

  const code = makeStory(name, data);
  return (
    <React.Fragment>
      <DebugPanel.SectionHeader>
        Actions
        <Button variant="outline" onClick={() => copy(code)}>
          {' '}
          Copy{' '}
        </Button>
      </DebugPanel.SectionHeader>
      <TextInput
        value={name}
        onChange={(e) => setName(e.target.value.replace(/\s+/g, ''))}
      />

      <pre style={{ whiteSpace: 'pre', overflow: 'auto' }}>{code}</pre>
      <Button
        variant="primary"
        onClick={() => {
          window.localStorage.debug = '';
          window.location.reload();
        }}
      >
        Disable and refresh
      </Button>
    </React.Fragment>
  );
};

export const appActionsPanel = NODE_ENV !== 'production' && (
  <DebugPanel
    Component={AppActionsPanel}
    updateWhileClosed={true}
    icon={SyncingLine}
    title="App Actions"
  />
);
