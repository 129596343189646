/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  import/no-named-as-default
*/
import React from 'react';
import createReactClass from 'create-react-class';
import ConfirmDecline from './confirm-decline';
import ConfirmDelegate from './confirm-delegate';
import ConfirmQualifiedSignature from './confirm-qualified-signature';
import CompleteQualifiedSignature from '../pages/complete-qualified-signature';

const FullScreenModals = createReactClass({
  render() {
    const locationState = this.props.location.state;
    const modal = locationState.fullScreenModal;

    switch (modal) {
      case 'confirmDecline':
        return <ConfirmDecline {...this.props} />;
      case 'confirmDelegate':
        return (
          <ConfirmDelegate
            {...this.props}
            signatureRequest={locationState.signatureRequest}
            router={this.props.app.router}
            notifier={this.props.app.notifier}
            isMobile={this.props.app.isMobile}
          />
        );
      case 'confirmQualifiedSignature':
        return (
          <ConfirmQualifiedSignature
            {...this.props}
            signatureRequest={locationState.signatureRequest}
          />
        );
      case 'completeQualifiedSignature':
        return (
          <CompleteQualifiedSignature
            {...this.props}
            signatureRequest={locationState.signatureRequest}
          />
        );
      default:
        return null;
    }
  },
});

module.exports = FullScreenModals;
