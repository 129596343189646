import React from 'react';

import useThunkReducer, {
  Dispatch,
} from 'signer-app/signature-modal/contexts/signature-modal/use-thunk-reducer';
import reducer from 'signer-app/signature-modal/contexts/canvas/reducer';
import { CanvasAction } from 'signer-app/signature-modal/contexts/canvas/actions';

export type CanvasState = {
  isDrawing: boolean;
  hasDrawn: boolean;
};

export const Context = React.createContext<{
  state: CanvasState;
  dispatch: Dispatch<CanvasState, CanvasAction>;
}>({
  state: {
    isDrawing: false,
    hasDrawn: false,
  },
  dispatch: () => {},
});

export const CanvasContextProvider = (
  props: React.PropsWithChildren<object>,
) => {
  const initialState = React.useContext(Context);
  const [state, dispatch] = useThunkReducer(reducer, initialState);
  const value = React.useMemo(() => ({ state, dispatch }), [dispatch, state]);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};
