/* eslint-disable import/no-commonjs, no-useless-escape, no-restricted-syntax */
import { cdnURL, hfHost } from 'js/sign-components/env';
import {
  siteCodes,
  getSupportEmailAddress,
} from 'js/sign-components/common/brand';

export default {
  hfCdn: cdnURL,
  hsCdn: cdnURL,
  HfSites: {
    SITE_CODE_HF: 'hellofax',
    SITE_CODE_HS: 'hellosign',
    SITE_CODE_HW: 'helloworks',
    SITE_CODES: Object.values(siteCodes),
  },
  HfConstants: {
    csrfFieldName: '_csrf_token',
    supportPhoneNumber: '(415) 766-0273',
    supportEmail: {
      api: 'apisupport@hellosign.com',
      hellosign: getSupportEmailAddress('S'),
      hellofax: getSupportEmailAddress('F'),
      helloworks: 'hwsupport@hellosign.com',
    },
    noReplyEmail: {
      hellosign: 'noreply@mail.hellosign.com',
      helloworks: 'noreply@mail.hellosign.com',
      hellofax: 'noreply@mail.hellofax.com',
    },
    team_fax_lines: {
      STATUS_CODES: {
        STATUS_CODE_ACTIVE: 'A',
        STATUS_CODE_PORTING_DENIED: 'D',
        STATUS_CODE_PORTING_LOA_COMPLETED: '4',
        STATUS_CODE_PORTING_ACCEPTED: '2',
        STATUS_CODE_PORTING_STARTED: '1',
      },
      URLS: {
        cancelPortingRequestUrl: `\/\/${hfHost}\/content\/cancelPortingRequestUrl`,
        removeFaxLineUrl: `\/\/${hfHost}\/content\/removeFaxLine`,
        updateFaxLinePermissionsUrl: `\/\/${hfHost}\/home\/updateFaxLinePermissions`,
        checkPortabilityStatusUrl: `\/\/${hfHost}\/content\/checkPortabilityStatus`,
        testUrl: `\/\/${hfHost}\/home\/manage`,
        spinnerUrl: `\/\/${cdnURL}\/images\/loading-spinner-16.gif`,
      },
    },
    URLS: {
      cancelPortingRequestUrl: `\/\/${hfHost}\/content\/cancelPortingRequestUrl`,
      removeFaxLineUrl: `\/\/${hfHost}\/content\/removeFaxLine`,
      updateFaxLinePermissionsUrl: `\/\/${hfHost}\/home\/updateFaxLinePermissions`,
      checkPortabilityStatusUrl: `\/\/${hfHost}\/content\/checkPortabilityStatus`,
      testUrl: `\/\/${hfHost}\/home\/manage`,
      spinnerUrl: `\/\/${cdnURL}\/\/images\/loading-spinner-16.gif`,
    },
    api_pricing: {
      URLS: {
        contactUsUrl: 'apisupport@hellosign.com',
        salesUrl: 'sales@hellosign.com',
      },
    },
    enterpriseFeatures: {
      HIPAA: 'HIPAA',
    },
  },
};
