import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { FeatureFlags } from 'js/sign-components/common/feature-flags';

import { cdnURL, sentryDSN, sentryEnvironment } from 'js/sign-components/env';

const pathRegex = {
  // Match paths with sign redirect - /s/{5 character guid}
  s: /\/s\/([a-zA-Z0-9]{8})/,
  // Match guids generically (40 char hexadecimal guids)
  generic: /\/([a-f0-9]{40})/,
};

/**
 * Configures Raven (Sentry client) with the appropriate user context.
 */
export default function configureRaven(
  userContext: Sentry.User | null = {},
  featureFlags: Partial<FeatureFlags> | null = {},
) {
  if (!sentryEnvironment || !sentryDSN || !cdnURL) {
    return;
  }

  // Configure client-side sampling for our transactions based on environment.
  // Note, we also further sample on Server-Side (Dynamic Sampling):
  // see: https://docs.sentry.io/product/data-management-settings/server-side-sampling/
  const defaultTracesSampleRate =
    sentryEnvironment === 'production' ? 0.015 : 0.001;
  const MAX_DUPLICATE_MESSAGES = 10;
  const messageCount: Record<string, number> = {};
  const isDropboxSentry = /dropbox/.test(sentryDSN);

  Sentry.init({
    dsn: sentryDSN,
    ignoreErrors: [
      'Non-Error promise rejection captured with value',
      '<unknown>',
      'Unauthorized',
      'Failed to fetch',
      'Request has been terminated',
      'An error occurred while saving P&S',
      'An error occured while saving P&S',
      'Oops! An error occurred when getting data. Please try again.',
      'Oops! An error occurred whilst getting data. Please try again.',
      "Can't execute code from a freed script",
      'NetworkError when attempting to fetch resource.',
      'Load failed',
    ],
    allowUrls: [cdnURL],
    sampleRate: 1,
    // Uncomment if you need local Sentry debugging data.
    // debug: true,
    attachStacktrace: true,
    environment: sentryEnvironment,
    release: SENTRY_RELEASE,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        // Replace guids with tokens so that common routescan be grouped together
        beforeNavigate: (context) => {
          let subGuid;
          /**
           * Replace guids {40 character hexadecimal strings} with :guid
           * examples:
           * /s/Sd12A08h => /s/:guid
           * /sign/b1358a4f976a43ec15bef7a5656b377726154421 => /sign/:guid
           * /prep-and-send/8f8f99a9a...43c1ce80e0f72/documents => /prep-and-send/:guid/documents
           */
          const name = Object.values(pathRegex).reduce(
            (acc: string, val: RegExp) => {
              const match = new RegExp(val, 'g').exec(context.name);
              if (match && match.length >= 1) {
                subGuid = match[1];
                return context.name.replace(match[1], ':guid');
              }
              return acc;
            },
            context.name,
          );
          return {
            ...context,
            name,
            pathGuid: subGuid,
          };
        },
      }),
    ],
    tracesSampleRate: isDropboxSentry ? 0.0001 : defaultTracesSampleRate,
    beforeSend(sentryEvent, hint) {
      let message;

      // If dev environment, check if Sentry reporting is enabled.
      if (sentryEnvironment === 'development') {
        try {
          const reportingEnabled =
            window.localStorage.getItem('local-sentry-reporting-enabled') ===
            'y';

          if (!reportingEnabled) {
            return null;
          }
        } catch (err) {
          return null;
        }
      }

      if (hint && hint.originalException) {
        if (typeof hint.originalException === 'string') {
          message = hint.originalException;
        } else {
          message = hint.originalException.message;
        }

        // JavaScript allows anything to be thrown, and there is a place where
        // we throw an object with a `.code` property.
        const error = hint.originalException as any;
        if (typeof error.code === 'string') {
          switch (error.code) {
            case 'csrf_protection':
            case 'nothing_to_sign':
            case 'sms_auth_required':
            case 'sms_auth_invalid':
            case 'sms_auth_blocked':
            case 'access_code_required':
            case 'access_code_invalid':
            case 'access_code_blocked':
              return null;
            default:
          }
        }
      }

      // Block this user from sending more than ten of the
      // same error message in a single session.
      if (message) {
        messageCount[message] = (messageCount[message] || 0) + 1;
        if (messageCount[message] > MAX_DUPLICATE_MESSAGES) {
          return null;
        }
      }

      // Ignore "Loading CSS chunk failed" errors.
      if (message && /Loading CSS chunk/.test(message)) {
        return null;
      }

      // Block all Sentry events that have `file:///` in
      // the URL. These are coming from users' personal
      // machines (presumably from the Microsoft
      // integration) and can very easily expose PII,
      // despite our safeguards on Sentry.
      if (sentryEvent.request?.url) {
        if (sentryEvent.request.url.indexOf('file:///') >= 0) {
          return null;
        }
      }

      // Scrub URL fragments from Sentry reports.
      // See https://romain-clement.net/articles/sentry-url-fragments/
      if (sentryEvent.request?.url) {
        const [url, hash] = sentryEvent.request.url.split('#');

        if (hash) {
          sentryEvent.request.url = url;

          // Sometimes security researchers will append `//xsstest` to URL
          // fragments when probing for vulnerabilities. We don't want to send
          // these to Sentry.
          if (hash.toLowerCase().includes('xss')) {
            return null;
          }
        }
      }

      return sentryEvent;
    },
  });

  Sentry.setUser(userContext);
  Sentry.setContext('Feature Flags', {
    data: JSON.stringify(featureFlags, undefined, 2),
  });

  return Sentry;
}
