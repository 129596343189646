import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { ThunkAction, Dispatch } from 'hellospa/redux/types';
import { Actions, BannerMessage, NotificationBannerActions } from './types';

type Options = {
  key?: string;
  timeout?: number;
};

export const createBannerMessage =
  (
    messageDescriptor: BannerMessage['messageDescriptor'],
    type: BannerMessage['type'],
    values?: BannerMessage['values'],
    { key, timeout }: Options = {},
  ): ThunkAction<BannerMessage['guid']> =>
  (dispatch) => {
    const guid = uuid.v4();

    dispatch({
      type: Actions.CreateBannerMessage,
      payload: { guid, type, messageDescriptor, values, key, timeout },
    });
    return guid;
  };

export const createTranslatedBannerMessage =
  (
    translatedMessage: string | React.ReactNode,
    type: BannerMessage['type'],
    { key, timeout }: Options = {},
  ): ThunkAction<BannerMessage['guid']> =>
  (dispatch) => {
    const guid = uuid.v4();

    dispatch({
      type: Actions.CreateBannerMessage,
      payload: { guid, type, translatedMessage, key, timeout },
    });
    return guid;
  };

export const removeBannerMessage = (id: string): NotificationBannerActions => ({
  type: Actions.RemoveBannerMessage,
  payload: id,
});

/**
 * This wraps the actions for creating banners and keeps track of the IDs of
 * any banner you create. When your component unmounts, useLocalBanner will
 * dispatch delete actions for everything you created.
 */
export function useLocalBanner() {
  const dispatch = useDispatch<Dispatch>();
  const idRef = React.useRef<BannerMessage['guid'][]>([]);

  React.useEffect(() => {
    function cleanup(ids: BannerMessage['guid'][]) {
      ids.forEach((id) => dispatch(removeBannerMessage(id)));
    }

    return () => {
      // ESLint assumes the ref may point to a React node, and if it did then
      // the ref would be empty here.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const ids = idRef.current;
      if (ids.length > 0) {
        // I ran into a timing issue where dispatching during cleanup DID change
        // the store, but React didn't re-render to pick up the change. Adding
        // this small timeout seems to fix it.
        setTimeout(cleanup, 1, ids);
      }
    };
  }, [dispatch]);

  const r = React.useMemo(() => {
    return {
      createTranslatedBannerMessage(
        ...args: Parameters<typeof createTranslatedBannerMessage>
      ): BannerMessage['guid'] {
        const id = dispatch(createTranslatedBannerMessage(...args));

        idRef.current.push(id);

        return id;
      },
      createBannerMessage(
        ...args: Parameters<typeof createBannerMessage>
      ): BannerMessage['guid'] {
        const id = dispatch(createBannerMessage(...args));

        idRef.current.push(id);

        return id;
      },
      removeBannerMessage(id: BannerMessage['guid']): void {
        dispatch(removeBannerMessage(id));
        idRef.current = idRef.current.filter((tmp) => tmp !== id);
      },
    };
  }, [dispatch]);

  return r;
}
