import { createSimpleDataContext } from 'js/sign-components/common/create-simple-data-context';

type SignerUser = {
  settings: {
    firstName: string;
    lastName: string;
  };
};

export const { Provider: SignerUserProvider, useContext: useSignerUser } =
  createSimpleDataContext<SignerUser>('SignerUser');
