import React, { useMemo } from 'react';
import UserError from 'signer-app/utils/error-handling/user-error';
import { errorCodes } from 'signer-app/utils/error-handling/response-codes';
import { useIntl, IntlShape } from 'react-intl';
import { Text } from '@dropbox/dig-components/typography';

import { Banner } from '@dropbox/dig-components/banner';

export type ErrorBannerProps = {
  error: Error;
  showChildrenOnError?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

export function ErrorBanner({
  error,
  children,
  showChildrenOnError,
  onClose,
}: ErrorBannerProps) {
  const [statefulError, setError] = React.useState<Error | null>(error);
  const intl = useIntl();
  const clearError = () => {
    setError(null);
    if (onClose) {
      onClose();
    }
  };

  // return pretranslated message if available, otherwise attempt to lookup translation
  // if that fails, just return the message
  const translateMessage = (err: Error | null, intl: IntlShape) => {
    const errorMessage = err?.message as string;
    if (err instanceof UserError && err.translated) {
      return errorMessage;
    }
    const translatedMessage = errorCodes[errorMessage];
    if (translatedMessage) {
      return intl.formatMessage(translatedMessage);
    }
    return errorMessage;
  };

  const translatedMessage = useMemo(
    () => translateMessage(statefulError, intl),
    [statefulError, intl],
  );

  return (
    <span>
      {statefulError && (
        <Banner
          type="alert"
          withCloseButton={'Close'}
          onRequestClose={clearError}
        >
          <Banner.Message>
            <Text>{translatedMessage}</Text>
          </Banner.Message>
        </Banner>
      )}
      {showChildrenOnError && children}
    </span>
  );
}
