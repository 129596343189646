import React, { FC, useCallback, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from 'signer-app/signature-modal/upload/croppable.module.css';

export type Bounds = {
  left: number;
  top: number;
  width: number;
  height: number;
};

type Point = { x: number; y: number };

interface CroppableProps {
  onSelect: (bounds: Bounds) => void;
}

const getPoint = (
  e: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent,
): Point => {
  if ('pageX' in e && e.pageX && e.pageY) {
    return {
      x: e.pageX,
      y: e.pageY,
    };
  }

  if ('touches' in e && e.touches[0]) {
    return {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
    };
  }

  throw new Error(`Unable to process event: ${e.type}`);
};

const Croppable: FC<CroppableProps> = ({ onSelect, children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [start, setStart] = useState<Point>({ x: 0, y: 0 });
  const [end, setEnd] = useState<Point>({ x: 0, y: 0 });
  const elementRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef(document.createElement('div'));

  const alignWithBounds = useCallback((point: Point): Point => {
    const element = elementRef.current;

    if (element) {
      const rect = element.getBoundingClientRect();
      return {
        x: Math.min(Math.max(rect.left, point.x), rect.right),
        y: Math.min(Math.max(rect.top, point.y), rect.bottom),
      };
    }

    return { x: 0, y: 0 };
  }, []);

  const handleDragStart = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault();

      const point = alignWithBounds(getPoint(e));

      setIsDragging(true);
      setIsBoxVisible(true);
      setStart({ x: point.x, y: point.y });
      setEnd({ x: point.x, y: point.y });
    },
    [alignWithBounds],
  );

  const handleDragMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      e.preventDefault();

      if (isDragging) {
        const point = alignWithBounds(getPoint(e));
        setEnd({ x: point.x, y: point.y });
      }
    },
    [alignWithBounds, isDragging],
  );

  const handleDragEnd = useCallback(
    (e: MouseEvent | TouchEvent) => {
      e.preventDefault();

      if (isDragging) {
        setIsDragging(false);

        onSelect({
          left: start.x,
          top: start.y,
          width: end.x - start.x,
          height: end.y - start.y,
        });
      }
    },
    [onSelect, setIsDragging, isDragging, start, end],
  );

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
      document.addEventListener('touchmove', handleDragMove);
      document.addEventListener('touchend', handleDragEnd);

      return () => {
        document.removeEventListener('mousemove', handleDragMove);
        document.removeEventListener('mouseup', handleDragEnd);
        document.removeEventListener('touchmove', handleDragMove);
        document.removeEventListener('touchend', handleDragEnd);
      };
    }

    return undefined;
  }, [isDragging, handleDragMove, handleDragEnd]);

  useEffect(() => {
    const portal = portalRef.current;
    document.body.appendChild(portal);
    return () => {
      document.body.removeChild(portal);
    };
  }, []);

  const style: React.CSSProperties = isBoxVisible
    ? {
        display: 'block',
        border: 'dashed 1px black',
        position: 'absolute',
        zIndex: 50000,
        width: Math.abs(end.x - start.x),
        height: Math.abs(end.y - start.y),
        left: Math.min(start.x, end.x),
        top: Math.min(start.y, end.y),
      }
    : {
        display: 'none',
      };

  return (
    <div
      className={styles.croppablePreview}
      role="button"
      tabIndex={0}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
      ref={elementRef}
    >
      {children}
      {ReactDOM.createPortal(
        <div className="cropbox" style={style} />,
        portalRef.current,
      )}
    </div>
  );
};

export default Croppable;
