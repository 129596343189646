import * as runtimeEnvironment from 'js/sign-components/env';
import { UnreachableError } from 'js/sign-components/common/ts-utils';

export const siteCodeFax: 'F' = 'F';
export const siteCodeSign: 'S' = 'S';
export const siteCodeForms: 'W' = 'W';
export const siteCodeDropbox: 'D' = 'D';

export const siteCodes = {
  fax: siteCodeFax,
  sign: siteCodeSign,
  forms: siteCodeForms,
  dropbox: siteCodeDropbox,
} as const;

export type SiteCodeKeys = keyof typeof siteCodes;
export type SiteCodeType = (typeof siteCodes)[SiteCodeKeys];

export type PropsWithSiteCode<P = {}> = P & {
  siteCode: SiteCodeType;
};

/**
 * Helper function which asserts that a site code is valid.
 * If the given site code isn't less generic than a string,
 * the developer can assert that it is a valid site code
 * using this function, rather than potentially dangerously
 * casting it as a SiteCodeType using "as SiteCodeType".
 *
 * @author Nathan Buchar <nbuchar@dropbox.com>
 * @throws {TypeError} siteCode must be valid.
 * @example
 *
 * ```
 * assertValidSiteCode(siteCode);
 * const brandName = getBrandName(siteCode);
 * ```
 */
export function assertValidSiteCode(
  siteCode: any,
): asserts siteCode is SiteCodeType {
  if (!Object.values(siteCodes).includes(siteCode)) {
    throw new TypeError(`Invalid site code ${siteCode}`);
  }
}

/**
 * Returns the host name of the site with the given
 * site code.
 *
 * @author Nathan Buchar <nbuchar@dropbox.com>
 * @example
 *
 * ```
 * <Link href={getHostName('W')}>
 *   Visit {getBrandName('W')}
 * </Link>
 * ```
 */
export function getHostName(siteCode: SiteCodeType) {
  switch (siteCode) {
    case siteCodeFax:
      return runtimeEnvironment.hfHost;
    case siteCodeSign:
      return runtimeEnvironment.hsHost;
    case siteCodeForms:
      return runtimeEnvironment.hfHost;
    case siteCodeDropbox:
      return runtimeEnvironment.dbxName;
    default:
      throw new UnreachableError(siteCode);
  }
}

/**
 * Returns the brand name for the given site code.
 *
 * @author Nathan Buchar <nbuchar@dropbox.com>
 * @example
 *
 * ```
 * <FormattedMessage
 *   id=""
 *   description="..."
 *   defaultMessage="Subscribe to {brandName} updates"
 *   values={{
 *     brandName: getBrandName(siteCode),
 *   }} />
 * ```
 */
export function getBrandName(siteCode: SiteCodeType) {
  switch (siteCode) {
    case siteCodeFax:
      return runtimeEnvironment.hfName;
    case siteCodeSign:
      return runtimeEnvironment.hsName;
    case siteCodeForms:
      return runtimeEnvironment.hwName;
    case siteCodeDropbox:
      return runtimeEnvironment.dbxName;
    default:
      throw new UnreachableError(siteCode);
  }
}

/**
 * Returns the brand short name for the given site code.
 * Sometimes when we use multiple brand names in succession,
 * we only want to use the long name for the first, and the
 * short names for the rest.
 *
 * For example, "Subscribe to Happy Sign, Forms, and Fax"
 * is preferable to "Subscribe to Happy Sign, Happy Forms,
 * and Happy Fax."
 *
 * @author Nathan Buchar <nbuchar@dropbox.com>
 * @example
 *
 * ```
 * <FormattedMessage
 *   id=""
 *   description="..."
 *   defaultMessage="Subscribe to {brandNameSign} and {brandNameForms} updates"
 *   values={{
 *     brandNameSign: getBrandName('S'),
 *     brandNameForms: getBrandShortName('W'),
 *   }} />
 * ```
 */
export function getBrandShortName(siteCode: SiteCodeType) {
  switch (siteCode) {
    case siteCodeFax:
      // Split at word boundary and return the last item in the array.
      // 1. "Foo Bar" -> ["Foo", " ", "Bar"] -> "Bar"
      // 2. "FooBar" -> ["FooBar"] -> "FooBar"
      return runtimeEnvironment.hfName.split(/\b/).pop();
    case siteCodeSign:
      return runtimeEnvironment.hsName.split(/\b/).pop();
    case siteCodeForms:
      return runtimeEnvironment.hwName.split(/\b/).pop();
    case siteCodeDropbox:
      return runtimeEnvironment.dbxName;
    default:
      throw new UnreachableError(siteCode);
  }
}

/**
 * Returns the support email address for the site with the
 * given site code.
 *
 * @author Nathan Buchar <nbuchar@dropbox.com>
 * @example
 *
 * ```
 * <FormattedMessage
 *   id=""
 *   description="..."
 *   defaultMessage="For assistance, please contact {brandName} support at {supportEmail}"
 *   values={{
 *     brandName: getBrandName('S')
 *     supportEmail: getSupportEmailAddress('S'),
 *   }} />
 * ```
 */
export function getSupportEmailAddress(siteCode: SiteCodeType) {
  switch (siteCode) {
    case siteCodeFax:
      return runtimeEnvironment.hfSupportEmail;
    case siteCodeSign:
      return runtimeEnvironment.hsSupportEmail;
    case siteCodeForms:
      return runtimeEnvironment.hwSupportEmail;
    case siteCodeDropbox:
      return '';
    default:
      throw new UnreachableError(siteCode);
  }
}

/**
 * Returns the _versioned_ CDN URL for the site. Not
 * suitable for things which are uploaded to S3 manually.
 *
 * @author Nathan Buchar <nbuchar@dropbox.com>
 */
export function getVersionedCdnUrl() {
  return runtimeEnvironment.cdnURL;
}

/**
 * Returns the developer site URL.
 *
 * @author Nathan Buchar <nbuchar@dropbox.com>
 * @example
 *
 * ```
 * <FormattedMessage
 *   id=""
 *   description="..."
 *   defaultMessage="Check out the developer docs at {developerSiteUrl}"
 *   values={{
 *     developerSiteUrl: getDeveloperSiteUrl(),
 *   }} />
 * ```
 */
export function getDevelopersSiteUrl() {
  return runtimeEnvironment.developersSite;
}
