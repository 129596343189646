import styles from 'signer-app/signature-modal/draw/canvas.module.css';

import React from 'react';
import {
  defineMessages,
  injectIntl,
  IntlShape,
  FormattedMessage,
} from 'react-intl';
import * as Sentry from '@sentry/browser';
import SignaturePad, { PointGroup } from 'signature_pad';
import { throttle } from 'lodash';
import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { DeleteLine, UndoLine, RedoLine } from '@dropbox/dig-icons/assets';
import * as signatureTypes from 'signer-app/signature-modal/constants/signature-types';
import { ButtonsDivider } from 'signer-app/signature-modal/common/buttons-divider';
import { SignatureModalLine } from 'signer-app/signature-modal/common/signature-modal-line';
import invariant from 'invariant';

const messages = defineMessages({
  canvasAriaLabel: {
    id: 'eebdd091e150afc37bf387c959da59ba77005cabf5db153c1731ab5accbd1001',
    description:
      'Screen reader label for a canvas where user can draw their signature.',
    defaultMessage: 'Canvas for drawing a signature',
  },
});

interface Coord {
  x: number;
  y: number;
}

interface CanvasRevision {
  xCoord: number;
  yCoord: number;
  sigWidth: number;
  sigHeight: number;
  pointGroups: PointGroup[];
}

interface CanvasProps {
  enableInsertButtonCallback: (enableInsert: boolean) => void;
  intl: IntlShape;
  velocityFilterWeight?: number;
  minWidth?: number;
  maxWidth?: number;
  throttle?: number;
  minDistance?: number;
  defaultCanvasHeight: number;
  defaultCanvasWidth: number;
}

interface CanvasState {
  hasDrawn: boolean;
  revisions: CanvasRevision[];
  currentRevisionIndex: number;
}

function isCustomEvent(event: Event): event is CustomEvent {
  return (event as CustomEvent).detail !== undefined;
}

// Nothing in this file is covered by tests because it
// interacts with a Canvas object that isn't available in
// our Jest environment.
/* istanbul ignore next */
class Canvas extends React.PureComponent<CanvasProps, CanvasState> {
  state: CanvasState = {
    hasDrawn: false,
    revisions: [],
    currentRevisionIndex: -1,
  };

  /**
   * The current minimum x-coordinate of the signature
   * drawn by the user. This value will be used to
   * calculate an invisible bounding box surrounding the
   * signature so that if the viewport is resized we can
   * properly reposition and scale the signature.
   */
  xMin: number | null = null;

  /**
   * The current maximum x-coordinate of the signature
   * drawn by the user. This value will be used to
   * calculate an invisible bounding box surrounding the
   * signature so that if the viewport is resized we can
   * properly reposition and scale the signature.
   */
  xMax: number | null = null;

  /**
   * The current minimum y-coordinate of the signature
   * drawn by the user. This value will be used to
   * calculate an invisible bounding box surrounding the
   * signature so that if the viewport is resized we can
   * properly reposition and scale the signature.
   */
  yMin: number | null = null;

  /**
   * The current maximum y-coordinate of the signature
   * drawn by the user. This value will be used to
   * calculate an invisible bounding box surrounding the
   * signature so that if the viewport is resized we can
   * properly reposition and scale the signature.
   */
  yMax: number | null = null;

  /**
   * Width of the canvas where user draws their signature.
   */
  canvasWidth: number | null = null;

  /**
   * Height of the canvas. Gets updated when view resizes (e.g. when user rotates their phone).
   */
  canvasHeight: number | null = null;

  /**
   * Resize observer monitoring canvas container size changes (in order to adjust the canvas size).
   */
  resizeObserver: ResizeObserver | null = null;

  /**
   * A React reference to the signature canvas element.
   */
  drawCanvasRef = React.createRef<HTMLCanvasElement>();

  /**
   * A React reference to the container wrapping the siganture canvas element.
   */
  drawCanvasContainerRef = React.createRef<HTMLDivElement>();

  /**
   * Signature pad is responsible for drawing with 'a pen effect'.
   * More can be read here: https://github.com/szimek/signature_pad
   */
  signaturePad: SignaturePad | null = null;

  /**
   * Is user currently drawing (in the middle of a stroke).
   */
  isDrawing: boolean = false;

  /**
   * Flag for checking if canvas was rescaled (e.g. when user
   * resizes browser's window on desktop or rotates phone)
   */
  rescaled: boolean = false;

  /**
   * Called when the clear button is pressed. Resets the
   * signature canvas.
   */
  onClearButtonPressed = () => {
    this.resetCanvas();
  };

  /**
   * Called when the undo button is pressed.
   */
  onUndoButtonPressed = () => {
    this.undo();
  };

  /**
   * Called when the redo button is pressed.
   */
  onRedoButtonPressed = () => {
    this.redo();
  };

  /**
   * Called when the mouse is moved within the document.
   * If the user is dragging while moving their mouse (i.e.
   * drawing their signature), we don't want the browser
   * to try to scroll the document so we prevent the
   * default action and stop the event propagation.
   */
  onMouseMove = (evt: Event) => {
    if (this.isDrawing) {
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  /**
   * Called when the window is resized. Redraws the canvas
   * and updates the canvas area dimensions.
   */
  onWindowResize = () => {
    if (!this.isDrawing) {
      this.updateCanvasDimensionsBasedOnContainerDimensions();
      this.redrawCanvas(true);
    }
  };

  /**
   * Caled when draw container is resized. Redraws the canvas
   * and updates the canvas area dimensions.
   */
  onDrawContainerResize = (entries: ResizeObserverEntry[]) => {
    if (!this.isDrawing && entries.length > 0) {
      const lastEntry = entries[entries.length - 1];
      this.updateCanvasDimensions(lastEntry.contentRect);
      this.redrawCanvas(true);
    }
  };

  startObservingDrawContainerResize() {
    this.resizeObserver = new ResizeObserver(this.onDrawContainerResize);
    invariant(
      this.drawCanvasContainerRef.current,
      'Missing reference to draw canvas container. ' +
        'Was this method called after component mounted (e.g. in componentDidMount)?',
    );
    this.resizeObserver.observe(this.drawCanvasContainerRef.current);
  }

  stopObservingDrawContainerResize() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  /**
   * Throttled window resize. The less often we call this
   * during window resize events, the better the JavaScript
   * performance will be and the higher the quality the
   * redrawn signature will have.
   */
  onWindowResizeThrottled = throttle(this.onWindowResize, 50);

  componentDidMount() {
    this.initCanvas();

    if (typeof ResizeObserver === 'function') {
      this.startObservingDrawContainerResize();
    } else {
      window.addEventListener('resize', this.onWindowResizeThrottled);
    }

    document.addEventListener('mousemove', this.onMouseMove);

    this.props.enableInsertButtonCallback(this.shouldEnableInsertButton());
  }

  componentWillUnmount() {
    this.stopObservingDrawContainerResize();
    window.removeEventListener('resize', this.onWindowResizeThrottled);
    document.removeEventListener('mousemove', this.onMouseMove);
    this.destroyCanvas();
  }

  componentDidUpdate() {
    if (this.signaturePad) {
      const {
        velocityFilterWeight,
        minWidth,
        maxWidth,
        throttle,
        minDistance,
      } = this.props;
      if (velocityFilterWeight) {
        this.signaturePad.velocityFilterWeight = velocityFilterWeight;
      }
      if (minWidth) {
        this.signaturePad.minWidth = minWidth;
      }
      if (maxWidth) {
        this.signaturePad.maxWidth = maxWidth;
      }
      if (throttle) {
        this.signaturePad.throttle = throttle;
      }
      if (minDistance) {
        this.signaturePad.minDistance = minDistance;
      }
    }
  }

  initCanvas() {
    if (!this.drawCanvasRef.current) {
      return;
    }
    if (typeof jest !== 'undefined') {
      // Our test environment doesn't actually have a canvas implementation, so
      // new SignaturePad crashes the test
      return;
    }

    const {
      velocityFilterWeight = 0.7,
      minWidth = 0.5,
      maxWidth = 2.5,
      throttle = 8,
      minDistance = 3,
    } = this.props;

    this.signaturePad = new SignaturePad(this.drawCanvasRef.current, {
      penColor: '#1E1919',
      velocityFilterWeight,
      minWidth,
      maxWidth,
      throttle,
      minDistance,
    });

    this.signaturePad.addEventListener('beginStroke', this.onBeginStroke);
    this.signaturePad.addEventListener(
      'afterUpdateStroke',
      this.onUpdateStroke,
    );
    this.signaturePad.addEventListener('endStroke', this.onEndStroke);

    this.updateCanvasDimensionsBasedOnContainerDimensions();
  }

  destroyCanvas() {
    this.signaturePad?.off();
  }

  onBeginStroke = (signaturePadEvent: Event) => {
    if (!isCustomEvent(signaturePadEvent)) {
      return;
    }

    const { detail } = signaturePadEvent;

    const coord = { x: detail.offsetX, y: detail.offsetY };
    this.calculateSignatureBoundingBox(coord);

    this.isDrawing = true;
    this.setState((state) => ({
      ...state,
      hasDrawn: true,
    }));
  };

  onUpdateStroke = (signaturePadEvent: Event) => {
    if (!isCustomEvent(signaturePadEvent)) {
      return;
    }

    const { detail } = signaturePadEvent;
    const coord = { x: detail.offsetX, y: detail.offsetY };
    this.calculateSignatureBoundingBox(coord);
  };

  onEndStroke = () => {
    this.props.enableInsertButtonCallback(true);
    this.isDrawing = false;

    const canvas = this.drawCanvasRef.current;
    if (canvas && this.signaturePad) {
      const pointGroups = [...this.signaturePad.toData()];
      this.setState((state) => {
        const currentRevisionIndex = state.currentRevisionIndex + 1;
        const revisions = state.revisions
          .slice(0, currentRevisionIndex)
          .concat([
            {
              xCoord: this.xMin !== null ? this.xMin : 0,
              yCoord: this.yMin !== null ? this.yMin : 0,
              sigWidth:
                this.xMax !== null && this.xMin !== null
                  ? this.xMax - this.xMin
                  : 0,
              sigHeight:
                this.yMax !== null && this.yMin !== null
                  ? this.yMax - this.yMin
                  : 0,
              pointGroups,
            },
          ]);
        return {
          ...state,
          revisions,
          currentRevisionIndex,
        };
      });
    }
  };

  /**
   * Resets the canvas by clearing the signature canvas
   * area snapshot and nulling any related fields, then
   * clears the canvas.
   */
  resetCanvas() {
    this.setState((state) => ({
      ...state,
      hasDrawn: false,
      revisions: [],
      currentRevisionIndex: -1,
    }));

    this.xMin = null;
    this.xMax = null;
    this.yMin = null;
    this.yMax = null;

    this.signaturePad?.clear();

    this.props.enableInsertButtonCallback(false);
  }

  undo() {
    this.setState(
      (state) => ({
        ...state,
        currentRevisionIndex: Math.max(state.currentRevisionIndex - 1, -1),
      }),
      () => {
        // Redraw or clear canvas after state is set.
        if (this.state.currentRevisionIndex >= 0) {
          this.redrawCanvas(this.rescaled);
        } else {
          this.signaturePad?.clear();
          this.props.enableInsertButtonCallback(false);
        }
      },
    );
  }

  redo() {
    if (this.state.currentRevisionIndex < this.state.revisions.length - 1) {
      this.setState(
        { currentRevisionIndex: this.state.currentRevisionIndex + 1 },
        () => {
          // Redraw canvas after state is set.
          this.redrawCanvas(this.rescaled);
          if (this.state.currentRevisionIndex === 0) {
            this.props.enableInsertButtonCallback(true);
          }
        },
      );
    }
  }

  redrawCanvas(rescale: boolean = false) {
    if (
      this.signaturePad &&
      this.canvasWidth &&
      this.canvasHeight &&
      this.state.currentRevisionIndex >= 0 &&
      this.state.revisions.length > 0
    ) {
      const currentRevision =
        this.state.revisions[this.state.currentRevisionIndex];
      const pointGroups = rescale
        ? this.rescalePoints(
            currentRevision,
            this.canvasWidth,
            this.canvasHeight,
          )
        : currentRevision.pointGroups;

      const containsInvalidPoint = pointGroups
        .flatMap(({ points }) => points)
        .some(({ x, y }) => isNaN(x) || isNaN(y));
      if (containsInvalidPoint) {
        Sentry.addBreadcrumb({
          category: 'draw',
          message: 'Invalid point(s) created during canvas redrawing',
          level: 'error',
          data: {
            rescale,
            currentRevision,
            revisionIndex: this.state.currentRevisionIndex,
            revisionsLength: this.state.revisions.length,
            rescaledPointGroups: pointGroups,
            canvasWidth: this.canvasWidth,
            canvasHeight: this.canvasHeight,
          },
        });
      }

      this.signaturePad.fromData(pointGroups);
    }
  }

  updateCanvasDimensionsBasedOnContainerDimensions() {
    const parentRect = this.getCanvasContainerDimensions();
    this.updateCanvasDimensions(parentRect);
  }

  /**
   * Updates the canvas dimensions to fit within the
   * container element.
   *
   * NOTE: Programmatically modifying the width and height
   * of an HTMLCanvasElement clears the canvas.
   */
  updateCanvasDimensions(parentRect?: DOMRectReadOnly | null) {
    let width = this.props.defaultCanvasWidth;
    let height = this.props.defaultCanvasHeight;

    if (parentRect && parentRect.width > 0 && parentRect.height > 0) {
      width = parentRect.width;
      height = parentRect.height;
    }

    const canvas = this.drawCanvasRef.current;
    if (canvas) {
      // this is the secret sauce for making signatures look slick on
      // screens with higher pixel density (e.g. retina displays)
      // for example, with devicePixelRatio equal to 2 we could get smoother
      // drawing UX by extending the canvas size:
      // <canvas width="1200" height="550" style="width: 600px; height: 275px">
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = Math.floor(width * ratio);
      canvas.height = Math.floor(height * ratio);
      canvas.getContext('2d')?.scale(ratio, ratio);

      this.canvasWidth = width;
      this.canvasHeight = height;
    }
  }

  rescalePoints(
    revision: CanvasRevision,
    canvasWidth: number,
    canvasHeight: number,
  ): PointGroup[] {
    if (isNaN(canvasHeight) || isNaN(canvasWidth)) {
      // this is very unlikely to happen so it's just a protective measure
      return revision.pointGroups;
    }

    const boundaries = {
      xMin: 50,
      xMax: Math.max(canvasWidth - 40, 100),
      yMin: 10,
      yMax: Math.max(canvasHeight - 40, 50),
    };

    const { xCoord, yCoord, sigWidth, sigHeight, pointGroups } = revision;

    if (sigWidth <= 0 || sigHeight <= 0) {
      // it looks possible to have sigWidth or sigHeight equal to zero
      // which can result later in division by zero and NaN errors
      return revision.pointGroups;
    }

    const xMin = boundaries.xMin;
    let xMax = xMin + sigWidth;
    let yMin = boundaries.yMin;
    let yMax = yMin + sigHeight;

    // rescale if signature too wide
    if (xMax > boundaries.xMax) {
      xMax = boundaries.xMax;
      const newSigWidth = xMax - xMin;
      const ratio = newSigWidth / sigWidth;
      yMax = yMin + sigHeight * ratio;
    }

    // rescale if signature too high
    if (yMax > boundaries.yMax) {
      yMax = boundaries.yMax;
      const newSigHeight = yMax - yMin;
      const ratio = newSigHeight / sigHeight;
      xMax = xMin + (xMax - xMin) * ratio;
    }

    // move signature to the bottom (slightly above the grey drawing line)
    const newSigHeight = yMax - yMin;
    yMax = boundaries.yMax;
    yMin = yMax - newSigHeight;

    // update bounding box
    this.xMin = xMin;
    this.xMax = xMax;
    this.yMin = yMin;
    this.yMax = yMax;
    this.rescaled = true;

    const newSigWidth = xMax - xMin;
    const ratio = newSigWidth / sigWidth;

    return pointGroups.map((pointGroup) => ({
      ...pointGroup,
      points: pointGroup.points.map((point) => {
        const x = xMin + ((xMax - xMin) * (point.x - xCoord)) / sigWidth;
        const y = yMin + ((yMax - yMin) * (point.y - yCoord)) / sigHeight;
        return {
          ...point,
          x,
          y,
        };
      }),
      maxWidth: Math.max(ratio * pointGroup.maxWidth, pointGroup.minWidth),
    }));
  }

  /**
   * Calculates the bounding box of the signature currently
   * drawn by the user.
   */
  calculateSignatureBoundingBox({ x, y }: Coord) {
    if (isNaN(x) || isNaN(y)) {
      // prevent potential corruption of bounding box coordinates
      // Math.max(NaN) => NaN
      // Math.floor(NaN) => NaN
      // Math.ceil(NaN) => NaN
      return;
    }

    if (this.xMin === null || x < this.xMin) {
      this.xMin = Math.max(Math.floor(x - 1), 0); // Avoid negatives.
    }

    if (this.xMax === null || x > this.xMax) {
      this.xMax = Math.max(Math.ceil(x + 1), 0); // Avoid negatives.
    }

    if (this.yMin === null || y < this.yMin) {
      this.yMin = Math.max(Math.floor(y - 1), 0); // Avoid negatives.
    }

    if (this.yMax === null || y > this.yMax) {
      this.yMax = Math.max(Math.ceil(y + 1), 0); // Avoid negatives.
    }
  }

  /**
   * Returns the canvas data as a high quality image/png data URI.
   */
  getCanvasData() {
    return this.signaturePad?.toDataURL('image/png');
  }

  /**
   * Gets the bounding client rect of the canvas container.
   */
  getCanvasContainerDimensions() {
    const parentElement = this.drawCanvasContainerRef.current;
    if (!parentElement) {
      return null;
    }

    const parentRect = parentElement.getBoundingClientRect();
    return parentRect;
  }

  /**
   * @returns a data representation of a drawn signature.
   */
  getSignatureData() {
    return {
      create_type_code: signatureTypes.CANVAS,
      signature: {
        image: this.getCanvasData(),
        is_vml: 0,
      },
    };
  }

  /**
   * @returns true if insert button should be enabled
   */
  shouldEnableInsertButton(): boolean {
    return this.state.hasDrawn;
  }

  render() {
    const { intl } = this.props;
    const { hasDrawn } = this.state;

    return (
      <div className={styles.draw}>
        <div
          ref={this.drawCanvasContainerRef}
          className={styles.canvasContainer}
        >
          <canvas
            id="signature-modal-draw__canvas"
            aria-label={intl.formatMessage(messages.canvasAriaLabel)}
            className={styles.canvas}
            ref={this.drawCanvasRef}
            onClick={() => {
              if (NODE_ENV === 'test') {
                this.setState({ hasDrawn: true });
                this.props.enableInsertButtonCallback(true);
              }
            }}
            data-qa-ref="signing-modal--draw-canvas"
            data-testid="signing-modal--draw-canvas"
          />
          <SignatureModalLine />
        </div>
        <div className={styles.buttons}>
          {hasDrawn && (
            <>
              <Button
                size="small"
                variant="borderless"
                className={styles.button}
                withIconLeft={<UIIcon src={DeleteLine} />}
                data-qa-ref="signing-modal--clear"
                data-testid="signing-modal--clear"
                disabled={this.state.currentRevisionIndex < 0}
                onClick={this.onClearButtonPressed}
              >
                <FormattedMessage
                  id="3eab3e95a355fc5c0d5a0cf8664248ce3517d8bf48e7b99ca27382ff5f3b5dc1"
                  description="Text for a button which clears all input when pressed"
                  defaultMessage="Clear"
                />
              </Button>
              <ButtonsDivider />
              <Button
                size="small"
                variant="borderless"
                className={styles.button}
                withIconLeft={<UIIcon src={UndoLine} />}
                data-testid="signing-modal--undo"
                disabled={this.state.currentRevisionIndex < 0}
                onClick={this.onUndoButtonPressed}
              >
                <FormattedMessage
                  id="b970ba5efdb3b72b14b268bad4e4fc38d57496a5aef81f45e1e584c93940738e"
                  description="Text for a button which undos the previous action when pressed."
                  defaultMessage="Undo"
                />
              </Button>
              <ButtonsDivider />
              <Button
                size="small"
                variant="borderless"
                className={styles.button}
                withIconLeft={<UIIcon src={RedoLine} />}
                data-testid="signing-modal--redo"
                disabled={
                  this.state.currentRevisionIndex >=
                  this.state.revisions.length - 1
                }
                onClick={this.onRedoButtonPressed}
              >
                <FormattedMessage
                  id="c9df2c14da3e54212dc139365d010821cf052521da85ace4c0b8aa5ca661e376"
                  description="Text for a button which redos the previous action when pressed."
                  defaultMessage="Redo"
                />
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(Canvas, { forwardRef: true });
