/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  func-names,
  import/no-extraneous-dependencies
*/
import '../../scss/style.scss';
import 'css/ionicon/css/ionicons.min.css';
import WebFont from 'webfontloader';

import '../common/utils/log-uncaught-promises';
import { buildWebAppClient } from 'hello-react/web-app-client/build-web-app-client';
import config from 'js/sign-components/common/config'; // eslint-disable-line no-restricted-syntax
import { signerHttpRequest } from 'signer/test/SignerHttpRequest';
import Application from './application';
import logger from '../common/logger';
import '../common/logger/track-logger';
import configureRaven from '../common/utils/sentry';
import 'hellospa/dig';

// Load typekit fonts
try {
  window.WebFont = WebFont;
} catch (_e) {
  // Fail silently
}

logger.track('entry.didLoad');

const appActions = buildWebAppClient(
  // This key isn't needed for the signer app
  'default',
);
const app = Application.create({
  ...config,
  appActions,
  httpRequest: (req) => {
    return signerHttpRequest(req, appActions);
  },
});

window.onload = function () {
  // Adding Sentry Raven for error logging
  configureRaven({}, window.config?.featureFlags);

  app.setProperties(
    Object.assign(
      {
        element: document.getElementById('signer-mobile-application'),
      },
      window.config,
    ),
  );

  app.initialize();
};

// make the app accessible from this console window
// IMPORTANT: do NOT access this global variable anywhere in the application. It's
// only intended to be used within the console window.
window.app = app;
