import React from 'react';

const Rectangle: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        background: '#ffffff',
        boxSizing: 'border-box',
      }}
    />
  );
};

export default React.memo(Rectangle);
