import {
  signatureRequestContext,
  fieldsContext,
  SignatureRequestContext,
  zoomContext,
  ZoomContextShape,
  DocumentAddress,
} from 'signer-app/signature-request/context';
import Document from 'signer-app/signature-request/document';
import Handle from 'signer-app/signature-request/handle';
import DisplayField from 'signer-app/signature-request/display-field';
import {
  ORIGIN_VIEWPORT,
  ORIGIN_PAGE,
  ORIGIN_PAGE_CONTAINER,
  PREPARER,
  SENDER,
  MIN_FONT_SIZE,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_FAMILY,
  DEFAULT_DATE_FORMAT,
  FIT_WIDTH,
  fontFamilies,
} from 'signer-app/signature-request/constants';

export {
  signatureRequestContext,
  fieldsContext,
  SignatureRequestContext,
  zoomContext,
  ZoomContextShape,
  Handle,
  DisplayField,
  ORIGIN_VIEWPORT,
  ORIGIN_PAGE,
  ORIGIN_PAGE_CONTAINER,
  PREPARER,
  SENDER,
  MIN_FONT_SIZE,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_FAMILY,
  DEFAULT_DATE_FORMAT,
  FIT_WIDTH,
  fontFamilies,
};

export default Document;

export const getBoundingBox = (fields: DocumentAddress[]) =>
  fields.reduce((bbox, field) => {
    const x = Math.min(bbox.x, field.x);
    const y = Math.min(bbox.y, field.y);
    const width = Math.max(bbox.x + bbox.width - x, field.x + field.width - x);
    const height = Math.max(
      bbox.y + bbox.height - y,
      field.y + field.height - y,
    );

    return {
      x,
      y,
      width,
      height,
    } as DocumentAddress;
  }, fields[0]);
