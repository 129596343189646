import { SiteCode } from 'js/sign-components/generated/types/WebApp';
import { UnreachableError } from 'js/sign-components/common/ts-utils';

let siteCode: SiteCode | null = null;
export function useSiteCode(): SiteCode {
  if (siteCode == null) {
    throw new Error('Missing siteCode');
  }

  return siteCode;
}
export function setSiteCode(code: SiteCode) {
  switch (code) {
    case 'F':
    case 'S':
    case 'W':
      siteCode = code;
      return;
    default:
      throw new UnreachableError(code);
  }
}
