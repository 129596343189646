import React from 'react';
import createDebug from 'debug';

const debugShallow = createDebug('hs:shallowEqual');
export function shallowEqual<T = object | object[]>(a: T, b: T) {
  if (typeof a !== typeof b || Array.isArray(a) !== Array.isArray(b)) {
    debugShallow('type mismatch', a, b);
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      debugShallow('key length');
      return false;
    }

    const mismatch = a.find((value, index) => value !== b[index]);
    if (mismatch != null) {
      debugShallow('Array mismatch', mismatch);
      return false;
    }
    return true;
  }

  if (!a || !b) {
    return false;
  }

  const keys = Object.keys(a);
  if (Object.keys(b).length !== keys.length) {
    debugShallow('key length');
    return false;
  } else {
    // Find any key that contains a different object
    // @ts-expect-error TS isn't sure the keys are valid DPC_REMOVE
    const key = keys.find((key) => b[key] !== a[key]);
    if (key != null) {
      debugShallow('key', key);
      return false;
    }
  }
  return true;
}

const debugContext = createDebug('hs:context');
/**
 * @template T
 * @typedef {(nextContext:T, nextKeys?: any[]) => T} ContextCacher
 */

/**
 * You can think of this as the caching mechanism you'd get out of a use memo if
 * you wrote it like:
 *
 * ```
 * const context = React.useMemo(() =>
 *   ({ a,b,c,d }),
 *   [a,b,c,d]);
 * ```
 *
 * */
export function makeContextCacher<T extends object>(): (t: T) => T {
  let context = {} as T;
  // If your context provides functions that act on a component's state, you may
  // need to force a context update when the state changes. One way would be to
  // place the state on the context, but then you've exposed your component
  // state to other components. With the keys, you can pass an array of objects
  // or values that will be compared to see if the context needs to update.
  let keys: Array<keyof T> = [];

  const whatChanged = (a: T, b: T): string[] =>
    Object.keys(a).filter(
      (key) =>
        // @ts-expect-error TS isn't sure the keys are valid DPC_REMOVE
        a[key] !== b[key],
    );

  return function contextCacher(nextContext: T, nextKeys = keys) {
    if (nextContext == null) {
      return context;
    }

    if (!shallowEqual(nextKeys, keys)) {
      if (debugContext.enabled) {
        debugContext(whatChanged(nextContext, context));
      }
      keys = nextKeys;
      context = nextContext;
    }

    if (!shallowEqual(nextContext, context)) {
      if (debugContext.enabled) {
        debugContext(whatChanged(nextContext, context));
      }
      context = nextContext;
    }

    return context;
  };
}

/**
 * @template T
 * @returns {ContextCacher<T>}
 */
export function useContextCacher<T extends object>() {
  const ref = React.useRef<(t: T) => T>();
  if (!ref.current) {
    ref.current = makeContextCacher<T>();
  }
  return ref.current;
}

export function makeShallowCache<T extends unknown[]>(fn: (...t: T) => T) {
  const cache = makeContextCacher();

  // memoize would compare the inputs and only recalculate if they change. It
  // works well as a performance improvement because you can skip the work of
  // the function. This shallowCache executes the function every time, but if
  // its result is shallowEqual to its previous object, it returns the old
  // object.
  return function shallowCache(...args: T) {
    return cache(fn(...args));
  };
}

/**
 * @deprecated This was created before hooks were available
 */
export const withContext = (
  { Consumer }: ReturnType<typeof React.createContext>,
  propName: string,
) => {
  // The default is to just pass the context down with the specified name
  function ctp(context: unknown) {
    return {
      [propName]: context,
    };
  }

  type C = React.ComponentType<any>;

  return (Component: C, contextToProps = ctp) => {
    // Using a named function will name the forwarded ref.
    // https://reactjs.org/docs/forwarding-refs.html#displaying-a-custom-name-in-devtools
    // eslint-disable-next-line react/display-name
    return React.forwardRef((props, ref) => {
      return (
        <Consumer>
          {(context) => (
            <Component {...props} {...contextToProps(context)} ref={ref} />
          )}
        </Consumer>
      );
    });
  };
};
