import * as uuid from 'uuid';
import createDebug from 'debug';
import { makeRecursiveProxy } from 'signer-app/utils/make-recursive-proxy';

type AppActionLogEntry = {
  id: string;
  timestamp: string;
  name: string;
  args: unknown[];
  result: unknown | Promise<unknown>;
};
type AnyFunction = (...args: any[]) => any;

type CapturedFunctionResult<T> = T extends AnyFunction
  ? Array<{
      args: Parameters<T>;
      result: Awaited<
        | ReturnType<T>
        | {
            type: 'captured-error';
            error: string;
          }
      >;
    }>
  : never;

export type AppLogSnapshot<Client extends object> = {
  [key in keyof Client]?: Client[key] extends AnyFunction
    ? CapturedFunctionResult<Client[key]>
    : Client[key] extends object
      ? AppLogSnapshot<Client[key]>
      : never;
};
export const globalAppActionsLog: AppActionLogEntry[] = [];

const logAppActions = createDebug('hs:web-app-client');

export const loggingEnabled = logAppActions.enabled;

export const logAppActionsInDevelopment = <T extends object>(
  original: T,
  overrides?: AppLogSnapshot<T>,
  originalObjectName?: string,
) => {
  // This can become `if (true)` at build time, and then all this code is
  // removed from builds going to production. When using the isProduction()
  // function, we make the user download all this code, and then we don't run
  // it.
  if (NODE_ENV === 'production') {
    return original;
  }
  // Having this run unnecesarily in tests seemed to slow down some of them
  // enough to break the tests.
  if (!overrides && NODE_ENV === 'test') {
    return original;
  }
  // The log effectively captures all network trafic, so I don't want that
  // memory leak unless it's explicitly enabled.
  if (!overrides && !loggingEnabled) {
    return original;
  }
  const unwrapResult = async (result: any) => {
    try {
      return await result;
    } catch (e) {
      if (e instanceof Error) {
        return `Error: ${e.message}`;
      }
    }
  };

  const logResult = async ({
    name,
    args,
    result,
  }: Pick<AppActionLogEntry, 'name' | 'args' | 'result'>) => {
    const detail: AppActionLogEntry = {
      id: uuid.v4(),
      timestamp: new Date().toISOString(),
      name,
      args,
      result: await unwrapResult(result),
    };

    if (args.length > 0) {
      logAppActions('%s(...%o) => %o', name, args, detail.result);
    } else {
      logAppActions('%s() => %o', name, detail.result);
    }

    globalAppActionsLog.push(detail);

    // Check if "dispatchEvent" is supported first.
    // Although this code should only run on non-prod
    // environments, for some automated tests this will
    // sometimes needlessly throw an error.
    if (
      'dispatchEvent' in document.body &&
      typeof document.body.dispatchEvent === 'function'
    ) {
      document.body.dispatchEvent(
        new CustomEvent('app-action-log', { detail: [...globalAppActionsLog] }),
      );
    }
  };
  return makeRecursiveProxy(original, {
    prefix: originalObjectName ?? 'appActions',
    interceptArgs(prefix, method, args) {
      if (overrides) {
        const [, ...path] = prefix.split('.');
        path.push(method);
        const leafNode = path.reduce(
          (acc: any, part) => acc?.[part],
          overrides,
        );
        if (Array.isArray(leafNode) && leafNode[0]) {
          return leafNode[0].args;
        }
      }

      return args;
    },
    interceptResult(prefix, method, args, result) {
      const [, ...path] = prefix.split('.');
      path.push(method);
      const name = path.join('.');
      if (overrides) {
        const leafNode = path.reduce(
          (acc: any, part) => acc?.[part],
          overrides,
        );
        if (Array.isArray(leafNode) && leafNode[0]) {
          const selectedOverride = leafNode[0]?.result;
          logAppActions(
            'OVERRIDE: %s(...%o) => %o',
            name,
            args,
            selectedOverride,
          );

          if (
            'type' in selectedOverride &&
            selectedOverride.type === 'captured-error'
          ) {
            throw new Error(selectedOverride.error);
          }

          return {
            ...result,
            ...selectedOverride,
          };
        }
      }

      logResult({ name, args, result });
      return result;
    },
  });
};
