/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  react/jsx-no-duplicate-props,
  react/prop-types
*/
import './input.scss';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import createReactClass from 'create-react-class';

const Input = createReactClass({
  propTypes: {
    ariaLabelledBy: PropTypes.string,
    autoFocus: PropTypes.bool,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    inputName: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    errorMessage: PropTypes.string,
    errorClass: PropTypes.string,
    handleChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
  },
  getDefaultProps() {
    return {
      disabled: false,
      errorClass: 'c-red',
      type: 'text',
    };
  },
  getInitialState() {
    return { error: false };
  },
  handleChange(event) {
    this.props.handleChange(event.target.value, event);
  },
  setDisabled(val) {
    this.props.disabled = val;
  },
  setErrorMessage(val) {
    if (typeof val === 'string') {
      this.props.errorMessage = val;
    }
  },
  render() {
    let errorMsg = '';

    let divClasses = {
      'm-input': true,
      'm-input-error': this.props.error,
    };
    divClasses[this.props.className] = this.props.className;
    divClasses = cx(divClasses);
    if (this.props.errorMessage) {
      let errorDivClasses = {
        'm-input-error-message': true,
      };
      if (this.props.errorClass) {
        errorDivClasses[this.props.errorClass] = true;
      }
      errorDivClasses = cx(errorDivClasses);

      errorMsg = (
        <div className="m-input-error">
          <img
            src={require('./error_x@2x.png')}
            className="m-input-error-message-img"
          />
          <p className={errorDivClasses}>{this.props.errorMessage}</p>
        </div>
      );
    }
    const { ariaLabelledBy } = this.props;

    return (
      <div className={divClasses}>
        <input
          {...(ariaLabelledBy ? { 'aria-labelledby': ariaLabelledBy } : null)}
          autoFocus={this.props.autoFocus}
          name={this.props.inputName}
          className="m-input-text"
          maxLength="99"
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onKeyDown={this.props.onKeyDown}
          onChange={this.handleChange}
          defaultValue={this.props.defaultValue}
          tabIndex={this.props.tabIndex || 0}
          type={this.props.type || 'text'}
          value={this.props.value}
        />
        {errorMsg}
      </div>
    );
  },
});
module.exports = Input;
