import { IntlFormatters } from 'react-intl';

export enum NotificationBannerType {
  Err = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

type FormatMessageParameters = Parameters<IntlFormatters['formatMessage']>;

type BaseBannerMessage = {
  readonly guid: string;
  readonly type: NotificationBannerType;

  /**
   * A key can be used to make sure you're updating a banner instead of
   * creating duplicates.
   */
  readonly key?: string;
  readonly timeout?: number;
};

export type BannerMessage = BaseBannerMessage & {
  readonly messageDescriptor: FormatMessageParameters[0];
  readonly values?: FormatMessageParameters[1];
};

export type TranslatedBannerMessage = BaseBannerMessage & {
  readonly translatedMessage: string | React.ReactNode;
};

export enum Actions {
  CreateBannerMessage = 'prep-and-send/CreateBannerMessage',
  RemoveBannerMessage = 'prep-and-send/RemoveBannerMessage',
}
export interface CreateBannerMessage {
  type: Actions.CreateBannerMessage;
  payload: BannerMessage | TranslatedBannerMessage;
}
export interface RemoveBannerMessage {
  type: Actions.RemoveBannerMessage;
  payload: string;
}
export type NotificationBannerActions =
  | CreateBannerMessage
  | RemoveBannerMessage;

export type NotificationBannerNamespaceState = Array<
  BannerMessage | TranslatedBannerMessage
>;
