import React from 'react';
import { Text } from '@dropbox/dig-components/typography';
import { ProgressBar } from '@dropbox/dig-components/progress_indicators';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styles from 'signer-app/signature-modal/upload/upload-progress-bar.module.css';

const messages = defineMessages({
  loadingAriaLabel: {
    id: '1c93b417f8f65eb70ec5928fa6f743579e65cea3c83e0ffa5bb03731c7b5dabc',
    description:
      'Screen reader label for a progress bar indicating that signature photo is being uploaded.',
    defaultMessage: 'Uploading signature photo',
  },
});

const UploadProgressBar = () => {
  const intl = useIntl();
  return (
    <>
      <div className={styles.uploadProgressBar}>
        <ProgressBar
          aria-valuetext={intl.formatMessage(messages.loadingAriaLabel)}
          in={true}
          isIndeterminate
        />
      </div>
      <Text>
        <FormattedMessage
          id="5f419524133d4d5c87964ab9f677b7a7c43ad9b59a49f7993b5960472b85a8a2"
          description="Loading text"
          defaultMessage="Loading…"
        />
      </Text>
    </>
  );
};

export default UploadProgressBar;
