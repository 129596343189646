import React from 'react';
import measureLineHeight from 'line-height';
import { parseDateFormat } from 'signer-app/signature-request/display-field/date-format';
import styles from 'signer-app/signature-request/display-field/date.module.css';
import { fontFamilies } from 'signer-app/signature-request/constants';
import { DateField } from 'js/sign-components/generated/types/HelloRequest';

type Props = {
  fieldData: DateField;
  tabIndex?: number;
};
export default class Date extends React.PureComponent<Props> {
  focusWrapper = React.createRef<HTMLDivElement>();

  textRef = React.createRef<HTMLParagraphElement>();

  measureLineHeight = () => {
    if (typeof jest !== 'undefined') {
      // JSDom can't measure the line height, so for tests I think fontSize is
      // good enough
      return this.props.fieldData.fontSize;
    }

    return measureLineHeight(this.textRef.current!);
  };

  getBoundingClientRect = () =>
    this.focusWrapper.current!.getBoundingClientRect();

  focus = (args: FocusOptions) => this.focusWrapper.current!.focus(args);

  render() {
    const { fieldData, tabIndex = 0 } = this.props;
    const readOnly = fieldData.readOnly || false;

    const style: React.CSSProperties = {
      fontSize: fieldData.fontSize && Number(fieldData.fontSize),
    };

    if (fieldData.fontFamily && fontFamilies[fieldData.fontFamily]) {
      style.fontFamily = fontFamilies[fieldData.fontFamily];
    }

    return (
      <div
        data-qa-ref={`${fieldData.type}-input`}
        data-testid={`${fieldData.type}-input`}
        className={styles.placeholder}
        ref={this.focusWrapper}
        tabIndex={readOnly ? -1 : tabIndex}
        style={{ outline: 'none' }}
      >
        <p className={styles.p} style={style} ref={this.textRef}>
          {parseDateFormat(fieldData.value)}
        </p>
      </div>
    );
  }
}
