/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  default-case,
  eqeqeq,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-param-reassign,
  no-restricted-globals,
  no-restricted-syntax,
  no-useless-escape,
  no-var,
  no-void,
  prefer-rest-params,
  vars-on-top
*/
import qs from 'qs';
import extend from 'lodash/extend';
import BaseObject from 'common/object/base';
import TypeNotifier from 'common/notifiers/type';
import { CHANGE, INITIALIZE, ErrorMessage } from 'common/messages';
import Location from './location';

/**
 * _setter for a deep path
 */

function _set(target, path, value) {
  let current = target;
  const pathParts = path.split('.');
  for (var i = 0, n = pathParts.length - 1; i < n; i++) {
    const key = pathParts[i];
    if (!current[key]) current[key] = {};
    current = current[key];
  }
  current[pathParts[i]] = value;
}

/**
 * getter for a deep path
 */

function _get(target, path) {
  let current = target;
  const pathParts = path.split('.');

  for (let i = 0, n = pathParts.length; i < n; i++) {
    const key = pathParts[i];
    if (current[key] == void 0) return void 0;
    current = current[key];
  }
  return current;
}

/**
 */

function _addRoot(pathname) {
  return `/${pathname}`.replace(/\/+/, '/');
}

/**
 */

function _parseUrl(url) {
  const path = url.replace('#', '');
  const pathParts = path.split('?');
  return {
    pathname: pathParts.shift(),
    query: pathParts.length ? qs.parse(pathParts.pop()) : {},
  };
}

/**
 */

function _bindWindowLocation(router) {
  let strLocation = String(router.location);

  // when the location changes in the hash, reflect that change
  // back down to the application state.
  const onChange = function () {
    const newLocation = new Location(
      _parseUrl(window.location.hash.replace('#', '')),
    );
    if (String(newLocation) !== strLocation) {
      strLocation = String(newLocation);
      router.redirect(_addRoot(strLocation));
    }
  };

  window.onpopstate = onChange;

  // watch the location for any change, stringify it, then reflect
  // that change in the location hash. This will ensure that the user
  // is able to reload the page and still maintain the application state
  router.notifier.push(
    TypeNotifier.create(CHANGE, (message) => {
      if (String(message.target) !== strLocation) {
        strLocation = String(message.target);
        window.location.hash = router.location.toString();
      }
    }),
  );
}

/**
 */

function Router() {
  BaseObject.apply(this, arguments);

  this.location = new Location({
    notifier: this.notifier,
  });

  this._routes = {};
}

BaseObject.extend(Router, {
  /**
   */

  notify(message) {
    switch (message.type) {
      case INITIALIZE:
        return this.initialize();
    }
  },

  /**
   */

  bootstrap() {
    this.initialize();
  },

  /**
   */

  initialize() {
    this.redirect(_addRoot(location.hash.replace('#', '')));
    if (!this.testMode && this.bindWindowLocation !== false) {
      _bindWindowLocation(this);
    }
  },

  /**
   */

  addRoute(alias, pathname, handler) {
    if (!handler) handler = function () {};

    // convert something like /home/:id/path to /home/(\w+)/
    const pathTester = new RegExp(
      `^${pathname.replace(/(:[^\/]+)/g, '([^/]+)')}$`,
    );
    const paramNames = pathname
      .split('/')
      .filter((path) => {
        return path.charAt(0) === ':';
      })
      .map((pathname) => {
        return pathname.substr(1);
      });

    this._routes[alias] = {
      pathname,
      pathTester,
      getPathname(aliasOrPathname, options) {
        if (!options) options = {};

        if (!options.params) options.params = {};

        // add params
        if (aliasOrPathname !== alias) {
          aliasOrPathname
            .match(pathTester)
            .slice(1)
            .forEach((param, i) => {
              _set(options.params, paramNames[i], param);
            });
        }

        let fullpath = pathname;

        pathname
          .match(pathTester)
          .slice(1)
          .forEach((param, i) => {
            fullpath = fullpath.replace(
              param,
              _get(options.params, paramNames[i]),
            );
          });

        return fullpath;
      },
      test(pathname) {
        return pathname === alias || pathTester.test(pathname);
      },
      handler,
    };
  },

  /**
   */

  redirect(aliasOrPathname, options) {
    if (!options) options = {};

    // URL redirect -- set window location
    if (/^(https?:)?\/\/\w+\.\w+(\.(com|org))?\/?/.test(aliasOrPathname)) {
      window.location = aliasOrPathname;
    }

    aliasOrPathname = aliasOrPathname.replace(/\/+/g, '/');

    const pathParts = _parseUrl(aliasOrPathname);

    const route = this.getRoute(pathParts.pathname);

    this.location.setProperties(
      extend(
        {
          pathname: route
            ? route.getPathname(pathParts.pathname, options)
            : pathParts.pathname,
          params: options.params,
          query: extend({}, options.query, pathParts.query),
        },
        options,
      ),
    );

    if (route) {
      route.handler(this.location);
    } else {
      const error = new Error('not found');
      error.code = 404;

      this.notifier.notify(ErrorMessage.create(error, this));
    }
  },

  /**
   * Sets a current modal on the global application state, as defined by the name.
   * An incorrect or empty name will simply do nothing.
   * @param string modalName
   */

  displayFullScreenModal(modalName) {
    const { state } = this.location;
    state.fullScreenModal = modalName;
    this.location.setProperties({ state });
  },

  /**
   * Removes any shown modal
   * Requires no parameters, since only one global modal can be shown at once.
   */
  hideFullScreenModal() {
    this.displayFullScreenModal(void 0);
  },

  /**
   * returns /just/the/path/name
   */

  getPathname(aliasOrPathname, options) {
    const route = this.getRoute(aliasOrPathname);
    return route
      ? route.getPathname(aliasOrPathname, options)
      : aliasOrPathname;
  },

  /**
   * returns something like /path?query=value
   */

  getPath(aliasOrPathname, options) {
    const pathname = this.getPathname(aliasOrPathname, options);

    return new Location({
      pathname,
      query: options.query,
    }).toString();
  },

  /**
   */

  getRoute(aliasOrPathname) {
    for (const alias in this._routes) {
      const route = this._routes[alias];
      if (route.test(aliasOrPathname)) return route;
    }
  },
});

module.exports = Router;
