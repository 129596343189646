const weakCache = new WeakMap();

const sortObjectProperties = (unknownValue: unknown) => {
  if (unknownValue == null || Array.isArray(unknownValue)) {
    return unknownValue;
  } else if (typeof unknownValue === 'function') {
    // Functions don't serialize
    return undefined;
  } else if (typeof unknownValue === 'object' && unknownValue != null) {
    // This allows for returning the same sorted object, which allows
    // JSON.stringify to detect circular structures.
    if (!weakCache.has(unknownValue)) {
      const newValue = Object.entries(unknownValue)
        .sort(([a], [b]) => (a < b ? -1 : 1))
        .reduce((memo: any, [key, v]) => {
          // I needed to use an any on memo because I couldn't find a way to
          // assure TypeScript that `key` is a valid key for the `memo` object
          // when memo is `typeof unknownValue`.
          memo[key] = v;
          return memo;
        }, {});

      weakCache.set(unknownValue, newValue);
    }
    return weakCache.get(unknownValue);
  }
  return unknownValue;
};

export const sortedStringify = (value: unknown, space?: number) => {
  return JSON.stringify(
    sortObjectProperties(value),
    (key, value) => sortObjectProperties(value),
    space,
  );
};

export function findDifferences<T = unknown>(
  a: T,
  b: T,
  path: Array<string> = [],
): Array<string> {
  const strA = sortedStringify(a);
  const strB = sortedStringify(b);

  if (strA !== strB) {
    if (typeof a === 'object' && a != null) {
      // Preemptively fail arrays that aren't the same length
      if (Array.isArray(a) && Array.isArray(b) && a.length !== b.length) {
        return [`${path.join('.')} ${strA} !== ${strB}`];
      }
      return Object.keys(a).reduce((differences, key) => {
        if (a && !b) {
          // eslint-disable-next-line no-console
          console.error(
            `${key} in ${JSON.stringify(a)} does not exist in ${JSON.stringify(b)}`,
          );
        } else if (!a && b) {
          // eslint-disable-next-line no-console
          console.error(
            `${key} in ${JSON.stringify(b)} does not exist in ${JSON.stringify(a)}`,
          );
        } else {
          // @ts-expect-error "Element implicitly has an 'any' type because
          // expression of type 'string' can't be used to index type 'unknown'.\n
          // No index signature with a parameter of type 'string' was found on
          // type 'unknown'."
          const valueA = a[key];
          // @ts-expect-error same as above
          const valueB = b[key];

          return differences.concat(
            findDifferences(valueA, valueB, path.concat(key)),
          );
        }
        return [];
      }, [] as Array<string>);
    } else {
      // Ignore these differences - null vs undefined vs empty string
      if (
        [strA, strB].every((value) =>
          [null, undefined, '', '""', 'null', 'undefined'].includes(value),
        )
      ) {
        return [];
      }
      return [`${path.join('.')} ${strA} !== ${strB}`];
    }
  }

  return [];
}
