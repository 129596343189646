import signatureActions from 'signer-app/context/signer-app-client/signature';
import * as signer from 'signer-app/context/signer-app-client/signer';
import { logAppActionsInDevelopment } from 'signer-app/utils/log-app-actions';
import * as tours from 'signer-app/context/signer-app-client/tours';
import * as authentication from 'signer-app/context/signer-app-client/authentication';
import { SignerApiMock } from 'signer-app/context/signer-app-client/signer-api-mock';
import { SignerAppClient } from '.';

// There is a mock version of this used for tests.
/** istanbul ignore next */
export const buildSignerAppClient = (
  preloadedTsmGroupKey: string,
): SignerAppClient => {
  const signerApi = new SignerApiMock('default');

  const actions = {
    signatures: signatureActions(preloadedTsmGroupKey),
    signer,
    tours,
    signerApi,
    authentication,
  };
  return logAppActionsInDevelopment(actions);
};
