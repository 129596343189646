import React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorPage, {
  ErrorPageProps,
} from 'signer-app/parts/error-boundary/error-page';

import {
  useLabeledRoutes,
  // LabledRoutes don't need to move
  // eslint-disable-next-line dropbox-sign/isolate-folder
} from 'hellospa/components/labeled-route';
import { trackHeapCustomEvent } from 'signer-app/utils/heap';
import { sentryEnvironment } from 'signer-app/utils/env';
import crypto from 'crypto';

export const context = React.createContext(false);

export type State = {
  errorTime: null | Date;
  error?: Error;
  errorCode: string;
};

export type Props = React.PropsWithChildren<{
  boundaryName?: string;
  activeRoutes?: string;
  component?: React.ComponentType<ErrorPageProps>;
}>;

class ErrorBoundaryClass extends React.Component<Props, State> {
  state: State = {
    errorTime: null,
    // https://reactjs.org/docs/react-component.html#componentdidcatch
    // Since componentDidCatch isn't going to be able to call setState, we need
    // an ID before that gets called.
    errorCode: `js${Math.random().toString(16).substring(2, 11)}`,
  };

  static getDerivedStateFromError(error: Error) {
    return {
      errorTime: new Date(),
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { boundaryName, activeRoutes } = this.props;
    if (sentryEnvironment !== 'production' && NODE_ENV !== 'test') {
      /* eslint-disable no-console */
      console.error(
        'ErrorBoundary',
        this.state.errorCode,
        error,
        error.message,
      );
      console.error('ErrorBoundary', this.state.errorCode, errorInfo);
      /* eslint-enable no-console */
    }

    // This has been a real pain to test. If React is in development mode it
    // will re-throw the error before calling componentDidCatch and Sentry will
    // pick it up and send the error. AFTER that, React will call this code and
    // I assume because it's the same error Sentry will NOT send this version
    // that has tags and extras.
    //
    // To verify this locally:
    // 1. uncomment the Sentry config in config/env/development
    // 2. Edit config/webpack.config.js
    //    'NODE_ENV': JSON.stringify('production'),
    // 3. Restart the build (npm start)
    // 4. Trigger an exception using /info/throwAFrontendErrorPlease and look for a request to https://sentry.io/api/
    Sentry.withScope((scope) => {
      scope.setExtras({ ...errorInfo });
      if (boundaryName) {
        scope.setTag('boundaryName', boundaryName);
      }
      if (activeRoutes) {
        scope.setTag('activeRoutes', activeRoutes);
      }
      // You can search for `errorCode:"js000000000"` in Sentry
      scope.setTag('errorCode', this.state.errorCode);

      Sentry.captureException(error);
    });

    const hash = crypto.createHash('sha256');
    // Messages could contain PII, so I think the safest solution is to send a
    // hash. This way grouping by the hash at least provides a count of
    // duplicates.
    hash.update(error.message);
    trackHeapCustomEvent('Error', {
      Type: error.name,
      Screen: activeRoutes,
      MessageHash: hash.digest('hex'),
      SentryErrorCode: this.state.errorCode,
    });
  }

  render() {
    const { errorTime, error } = this.state;
    if (errorTime != null) {
      const Component = this.props.component || ErrorPage;
      return (
        <Component
          errorTime={errorTime}
          errorCode={this.state.errorCode}
          error={error}
        />
      );
    }

    return (
      <context.Provider value={true}>{this.props.children}</context.Provider>
    );
  }
}

export default function ErrorBoundary(props: Props) {
  const activeRoutes = useLabeledRoutes();

  return <ErrorBoundaryClass {...props} activeRoutes={activeRoutes} />;
}
