/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/named
*/
import { setTimeout } from 'common/utils/timers';

module.exports = {
  componentDidMount() {
    this.props.app.notifier.push(this);
    this.isMounted = true;
  },
  notify() {
    clearTimeout(this._updateTimer);
    this._updateTimer = setTimeout(() => {
      if (this.isMounted) {
        this.forceUpdate();
      }
    }, 1);
  },
  componentWillUnmount() {
    this.props.app.notifier.remove(this);
    this.isMounted = false;
  },
};
