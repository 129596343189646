/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  no-bitwise,
  prefer-rest-params,
  prefer-spread
*/
import { EventEmitter } from 'events';
import LogLevels from './levels';

/**
 */

function Logger(properties = {}) {
  EventEmitter.call(this);
  Object.assign(this, properties);

  this._silent = properties.silent !== undefined ? properties.silent : false;
}

/**
 */

Object.assign(Logger.prototype, EventEmitter.prototype, {
  /**
   */

  level: LogLevels.ALL,

  /**
   */

  track() {
    this.emit.apply(
      this,
      ['track'].concat(Array.prototype.slice.call(arguments)),
    );
  },

  setSilent(silence) {
    this._silent = silence;
  },

  getSilent() {
    return this._silent;
  },
});

/**
 * attach the notice, error, warn, info, verbose methods
 */

Object.keys(LogLevels).forEach((key) => {
  const code = LogLevels[key];
  if (!(code & LogLevels.NONE)) {
    const type = key.toLowerCase();
    Logger.prototype[type] = function () {
      if (!(this.level & code)) return;
      this.emit('log', {
        // add english term to log
        type,

        // maintain consistent property
        level: code,

        // don't do anything to the arguments
        args: Array.prototype.slice.call(arguments, 0),

        // Allow for logs to be silent and now output anything to the
        // console.  This allows for testing the logger without making a
        // bunch of noise in the test output.
        silent: this.getSilent(),
      });
    };
  }
});

Logger.create = function (properties) {
  return new this(properties);
};

module.exports = new Logger();
module.exports.Logger = Logger;
