import React from 'react';
import HelloSign from 'common/utils/hellosign';
import { FormattedMessage } from 'react-intl';

const AccessCode = React.lazy(() => import('./access-code'));
const SignatureRequest = React.lazy(() => import('./signature-request'));
const SignatureRequestPreview = React.lazy(
  () => import('./signature-request-preview'),
);
const SignatureRequestConfirm = React.lazy(
  () => import('./signature-request-confirm'),
);
const SignatureRequestComplete = React.lazy(() => import('./complete'));
const SignatureRequestDeclineComplete = React.lazy(
  () => import('./decline-complete'),
);
const IntlSignatureRequestDelegateComplete = React.lazy(
  () => import('./delegate-complete'),
);
const SignatureRequestAttachment = React.lazy(
  () => import('./signature-request-attachment'),
);
const IntlCompleteQualifiedSignature = React.lazy(
  () => import('./complete-qualified-signature'),
);

const Pages = (props) => {
  const { signatureRequest } = props.location.state;

  switch (props.location.state.contentPage) {
    case 'enterCode':
      return <AccessCode {...props} />;

    case 'signatureRequestPreview':
      return (
        <SignatureRequestPreview
          {...props}
          signatureRequest={signatureRequest}
        />
      );

    case 'signatureRequestConfirm':
      return (
        <SignatureRequestConfirm
          {...props}
          signatureRequest={signatureRequest}
        />
      );

    case 'signatureRequest':
      return (
        <SignatureRequest {...props} signatureRequest={signatureRequest} />
      );

    case 'signatureRequestComplete':
      return (
        <SignatureRequestComplete
          {...props}
          signatureRequest={signatureRequest}
        />
      );

    case 'completeQualifiedSignature':
      return (
        <IntlCompleteQualifiedSignature
          {...props}
          signatureRequest={signatureRequest}
        />
      );

    case 'signatureRequestAttachments':
      return (
        <SignatureRequestAttachment
          {...props}
          signatureRequest={signatureRequest}
        />
      );
    case 'signatureRequestDeclineComplete':
      return <SignatureRequestDeclineComplete {...props} />;

    case 'signatureRequestDelegateComplete':
      return (
        <IntlSignatureRequestDelegateComplete
          {...props}
          headerProps={props.app.login}
          isEmbedded={props.app.isEmbedded()}
          signatureRequest={signatureRequest}
          onXwmSend={props.app.isEmbedded() ? HelloSign.XWM.send : undefined}
        />
      );

    default:
      return (
        <div>
          <FormattedMessage
            id="error.notFound.message"
            description="generic error message when the page that is requested is not found"
            defaultMessage="Page not found"
          />
        </div>
      );
  }
};

export default Pages;
