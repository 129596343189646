/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies,
  max-len,
*/
import React from 'react';
import PropTypes from 'prop-types';
import FixedModal from 'common/components/fixed-modal';
import Loader from 'signer-app/parts/loader';
import Button from 'common/components/button';
import CloseImageSrc from 'common/assets/modal-close@2x.png';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { ErrorMessage } from 'common/messages';
import errorCodes from 'signer/api/errors';
import Input from 'common/components/input';
import './index.scss';
import SignatureRequest from 'signer/models/signature-request';
import Router from 'common/router';
import logger from 'common/logger';
import { postMessage, messages } from 'js/sign-components/common/messages';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

const hsMessages = defineMessages({
  reassign: {
    id: 'signer.delegate.modal.confirmButton',
    description:
      'button text of modal in signer flow, when clicked it reassigns the signer for the signature request',
    defaultMessage: 'Reassign',
  },
});
/**
 * Displays the delegation confirmation modal.
 */
class ConfirmDelegate extends React.Component {
  static propTypes = {
    /**
     * Indicates if in the mobile context or not.
     */
    isMobile: PropTypes.bool,
    intl: PropTypes.object,

    /**
     * The notifier for the app
     */
    notifier(props) {
      const notifier = props.notifier;

      if (!notifier || typeof notifier.notify !== 'function') {
        return new Error('You must supply a notifier with a "notify" method');
      }
    },

    /**
     * The router for the app
     */
    router: PropTypes.instanceOf(Router).isRequired,

    /**
     * The signature request being delegated
     */
    signatureRequest: PropTypes.instanceOf(SignatureRequest).isRequired,
  };

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.delegate = this.delegate.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);

    this.state = {
      values: {
        name: '',
        email: '',
        reason: '',
      },
      errors: {
        name: '',
        email: '',
        reason: '',
      },
      pending: false,
    };
  }

  handleNameChange(name) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        name,
      },
    });
  }

  handleEmailChange(email) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        email,
      },
    });
  }

  handleReasonChange(e) {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        reason: e.target.value,
      },
    });
  }

  close() {
    this.props.router.hideFullScreenModal();
  }

  setValidity(cb) {
    const { values } = this.state;

    this.setState(
      {
        ...this.state,
        errors: {
          name: !values.name,
          email:
            !values.email || !HfReactHelper.isValidEmailAddress(values.email),
          reason: !values.reason,
        },
      },
      cb,
    );
  }

  isValid() {
    const { values } = this.state;

    return (
      values.email &&
      HfReactHelper.isValidEmailAddress(values.email) &&
      values.name &&
      values.reason
    );
  }

  submit() {
    if (!this.isValid()) {
      return;
    }

    const { notifier, router, signatureRequest } = this.props;
    const { values } = this.state;

    this.setState({ pending: true });
    logger.track('Reassign.Modal.Submit.onClick');

    return signatureRequest
      .delegate(values)
      .then(() => {
        if (
          this.props.signatureRequest.embeddedData &&
          this.props.signatureRequest.embeddedData.parentUrl
        ) {
          HelloSign.XWM.send(
            'reassign',
            signatureRequest.embeddedData.parentUrl,
          );

          // Embedded V2
          postMessage(
            {
              type: messages.USER_REASSIGN_REQUEST,
              payload: {
                ...values, // name, email, and reason
                signatureId: signatureRequest.embeddedData.signatureId,
              },
            },
            signatureRequest.embeddedData.parentUrl,
          );
        }

        router.redirect('delegateComplete');
      })
      .catch(() => {
        const error = new Error('Delegation failed.');
        error.code = errorCodes.SERVER;
        notifier.notify(ErrorMessage.create(error, this));

        if (
          this.props.signatureRequest.embeddedData &&
          this.props.signatureRequest.embeddedData.parentUrl
        ) {
          // Embedded V2
          postMessage(
            {
              type: messages.APP_ERROR,
              payload: {
                code: error.code,
                signatureId: signatureRequest.embeddedData.signatureId,
              },
            },
            signatureRequest.embeddedData.parentUrl,
          );
        }

        this.close();
      });
  }

  delegate(e) {
    e.preventDefault();

    return this.setValidity(this.submit);
  }

  render() {
    const { isMobile } = this.props;
    const { pending, errors } = this.state;
    const modalStyle = isMobile ? { borderRadius: 0 } : { borderRadius: 5 };

    const fixedModalAttrs = {
      width: 600,
      modalStyle,
      hideOnOverlayClick: true,
    };

    return (
      <FixedModal {...this.props} {...fixedModalAttrs}>
        <form className="m-modal-confirm-delegate">
          {!pending && (
            <div className="main-content">
              <h1>
                <FormattedMessage
                  id="signer.delegate.modal.title"
                  description="modal header text in signer flow, where signer can reassign the signature request to someone else"
                  defaultMessage="Reassign this document"
                />
              </h1>
              <p>
                <FormattedMessage
                  id="signer.delegate.modal.description"
                  description="modal text in signer flow asking user to reassign incase singer doesn't have authrization"
                  defaultMessage="If you’re not the authorized signer for this document, you can assign it to someone else. By reassigning, the document will be cleared of anything you have entered."
                />
              </p>
              <div className="m-modal-confirm-delegate__name">
                <h2 id="signer.delegate.modal.name">
                  <FormattedMessage
                    id="signer.delegate.modal.name"
                    description="modal input label in signer flow, asking singer to input the name"
                    defaultMessage="Name"
                  />
                  <span className="asterisk">*</span>
                </h2>
                <Input
                  ariaLabelledBy="signer.delegate.modal.name"
                  handleChange={this.handleNameChange}
                  data-test-ref="name-input"
                  type="text"
                />
              </div>
              <div className="m-modal-confirm-delegate__email">
                <h2 id="signer.delegate.modal.email">
                  <FormattedMessage
                    id="signer.delegate.modal.email"
                    description="modal input label in signer flow, asking signer to input email"
                    defaultMessage="Email"
                  />
                  <span className="asterisk">*</span>
                </h2>
                <Input
                  ariaLabelledBy="signer.delegate.modal.email"
                  data-testid="email-input"
                  handleChange={this.handleEmailChange}
                  type="email"
                />
              </div>
              {(errors.name || errors.email) && (
                <div>
                  <div className="m-modal-confirm-delegate__name error">
                    {errors.name && (
                      <FormattedMessage
                        id="signer.delegate.modal.noNameError"
                        description="error message in modal of signer flow, asking singer to provide the name"
                        defaultMessage="Please provide a name."
                      />
                    )}
                  </div>
                  <div className="m-modal-confirm-delegate__email error">
                    {errors.email && (
                      <FormattedMessage
                        id="signer.delegate.modal.noEmailError"
                        description="error message in modal of signer flow, asking signer to input a valid email"
                        defaultMessage="Please provide a valid email."
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="l-margin-t-20">
                <h2 id="signer.delegate.modal.reasonTitle">
                  <FormattedMessage
                    id="signer.delegate.modal.reasonTitle"
                    description="label of input of modal in signer flow, asking signer to give reason for reassigning signature request to different user"
                    defaultMessage="Reason for reassigning"
                  />
                  <span className="asterisk">*</span>
                </h2>
                <small>
                  <FormattedMessage
                    id="signer.delegate.modal.sharedOnlyWithSender"
                    description="text in modal of singer flow, informing that all the information will only be between requester and recipient while reassigning the signature request"
                    defaultMessage="This information will only be shared with the requester and the recipient."
                  />
                </small>
                <textarea
                  aria-labelledby="signer.delegate.modal.reasonTitle"
                  onChange={this.handleReasonChange}
                  data-test-ref="reason-input"
                  data-testid="reason-input"
                  maxLength={255}
                />

                {errors.reason && (
                  <div className="error">
                    <FormattedMessage
                      id="signer.delegate.modal.noReasonError"
                      description="error message in modal of signer flow, asking signer to give reason for reassigning the signature request"
                      defaultMessage="Please provide a reason."
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {!pending && (
            <div className="cta-bar whitelabel-header">
              <div className="cta-bar__message">
                <FormattedMessage
                  id="signer.delegate.modal.ctaMessage"
                  description="text in modal of signer flow, informing singer that once reassigned of signature request the document will no longer be accessible and will be notified by email once request is complete"
                  defaultMessage="In completing this step, the requester and the recipient will receive a notification of the changes.  You will no longer have access to sign this document and you will receive a copy of the completed document once all parties have signed."
                />
              </div>
              <div className="cta-bar__actions">
                <div className="cancel-link" onClick={this.close}>
                  <FormattedMessage
                    id="signer.delegate.modal.cancelButton"
                    description="button text of modal in signer flow, when clicked closes the modal"
                    defaultMessage="Cancel"
                  />
                </div>

                <Button
                  data-testid="reassign-btn"
                  buttonText={this.props.intl.formatMessage(
                    hsMessages.reassign,
                  )}
                  onClick={this.delegate}
                  className="whitelabel-primary-button"
                  buttonTextColor="white"
                  buttonTextUppercase={true}
                  type="submit"
                />
              </div>
            </div>
          )}

          {pending && <Loader className="loader" />}

          <div className="close-button" onClick={this.close}>
            <img src={CloseImageSrc} />
          </div>
        </form>
      </FixedModal>
    );
  }
}

export default injectIntl(ConfirmDelegate);
