/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  no-void
*/
import Signature from 'hellosign/models/signature';
import Field from './field';

module.exports = Field.extend({
  /**
   */

  validationOptions: {
    min: -1,
    max: -1,
  },

  /**
   */

  toData() {
    const data = Field.prototype.toData.call(this);
    data.value = this.value ? this.value.guid : void 0;
    return data;
  },

  /**
   */

  fromData(data) {
    const props = Field.prototype.fromData.call(this, data);

    if (data.value) {
      props.value = Signature.create({
        notifier: this.notifier,
        guid: data.value,
      });
    }

    return props;
  },
});
