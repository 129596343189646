import hsFetch from 'js/sign-components/common/hs-fetch';
import type {
  PrepAndSendSuccess,
  HsErrorResponse,
  TemplateList,
  Template,
  EditTemplateResponse,
  SignatureRequestSuccess,
  SignatureRequest,
  UseGDriveNewOAuthResponse,
} from 'hellospa/components/integration/data/types';
import * as Yup from 'yup';

export enum WorkflowStatusType {
  EnableThirdPartyCookies = 'enableThirdPartyCookies',
  UpgradeRequired = 'upgradeRequired',
  NotFound404 = 'notFound404',
  SignatureRequestSent = 'signatureRequestSent',
  ReminderSent = 'reminderSent',
  CancelSignatureRequestSent = 'cancelSignatureRequestSent',
  TemplateEducation = 'templateEducation',
  TemplateCreated = 'templateCreated',
  TemplateSaved = 'templateSaved',
  TemplateListEmpty = 'templateListEmpty',
  ErrorCannotCreateEditTemplate = 'cannot_create_edit_template',
  ErrorFromTokenRequest = 'error_from_token_request',
  ErrorParamFound = 'error_param_found',
  ErrorIntegrationInvalid = 'integration_invalid',
  ErrorInvalidCsrf = 'invalid_csrf',
  ErrorInvalidEventHash = 'invalid_event_hash',
  ErrorInvalidRequestMethod = 'invalid_request_method',
  ErrorMissingParameter = 'missing_parameter',
  ErrorMaximumRemidersSent = 'maximum_reminders_sent',
  ErrorNoAccountApiApp = 'no_account_api_app',
  ErrorNoCacheFromState = 'no_cache_from_state',
  ErrorNoCodeFromCallback = 'no_code_from_callback',
  // Use of literal "hellosign" here is acceptable. Questions? #ask-hs-frontend.
  // eslint-disable-next-line no-restricted-syntax
  ErrorNoHSAccount = 'no_hellosign_account',
  ErrorNoIntegrationInstantiated = 'no_integration_instantiated',
  ErrorNoStateValue = 'no_state_value',
  ErrorNonPaidPlan = 'non_paid_plan',
  ErrorTeamFeatureRequired = 'team_feature_required',
  ErrorTeamInviteRequired = 'team_invite_required',
  ErrorUnknown = 'unknown_error',
  ErrorUserEmailMismatch = 'user_email_mismatch',
  ErrorSignatureRequestDeclined = 'error_signature_request_declined',
  ErrorSignatureRequestSigned = 'error_signature_request_signed',
  ErrorSignatureRequestCanceled = 'error_signature_request_canceled',
  // Error we get back when requesting signature request that has been deleted/canceled
  Deleted = 'deleted',
}

const templateSchema: Yup.ObjectSchema<Template> = Yup.object<Template>({
  templateId: Yup.string(),
  title: Yup.string(),
  isLocked: Yup.boolean(),
  canEdit: Yup.boolean(),
  // Add template response properties here when needed
}).camelCase();

const templateArraySchema = Yup.array().of(templateSchema);

export async function unclaimedDraftCreateEmbedded(
  associatedObjectId: number,
  associatedObjectType: string,
  identifier: string,
  context: string,
): Promise<PrepAndSendSuccess | HsErrorResponse> {
  const body = {
    associatedObjectId,
    associatedObjectType,
    identifier,
    context,
  };

  const response = await hsFetch('/integration/unclaimedDraftCreateEmbedded', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.claim_url) {
    return {
      signatureRequestId: resp.signature_request_id,
      claimUrl: resp.claim_url,
      clientId: resp.client_id,
      success: true,
    } as PrepAndSendSuccess;
  } else {
    return {
      error: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function unclaimedDraftCreateEmbeddedWithTemplate(
  associatedObjectId: number,
  associatedObjectType: string,
  identifier: string,
  context: string,
  templateId: string,
): Promise<PrepAndSendSuccess | HsErrorResponse> {
  const body = {
    associatedObjectId,
    associatedObjectType,
    identifier,
    context,
    templateId,
  };

  const response = await hsFetch(
    '/integration/unclaimedDraftCreateEmbeddedWithTemplate',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  );

  const resp = await response.json();

  if (resp.claim_url) {
    return {
      signatureRequestId: resp.signature_request_id,
      claimUrl: resp.claim_url,
      clientId: resp.client_id,
      success: true,
    } as PrepAndSendSuccess;
  } else {
    return {
      error: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function signatureRequest(
  identifier: string,
  context: string,
  signatureRequestId: string,
  getRemainingSigners: boolean,
): Promise<SignatureRequestSuccess | HsErrorResponse> {
  const body = {
    identifier,
    context,
    signatureRequestId,
    getRemainingSigners,
  };

  const response = await hsFetch('/integration/signatureRequest', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if ('signatures' in resp) {
    const signaturesArray = resp.signatures.map((value: any) => {
      return {
        signerName: value.signer_name,
        signerEmailAddress: value.signer_email_address,
      } as SignatureRequest;
    });

    return {
      signatures: signaturesArray,
      success: true,
      isComplete: resp.is_complete,
      isDeclined: resp.is_declined,
      title: resp.title,
    } as SignatureRequestSuccess;
  } else {
    return {
      error: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function signatureRequestRemind(
  identifier: string,
  context: string,
  signatureRequestId: string,
  emails?: string,
): Promise<SignatureRequestSuccess | HsErrorResponse> {
  const body = {
    identifier,
    context,
    signatureRequestId,
    emails,
  };

  const response = await hsFetch('/integration/signatureRequestRemind', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if ('signatures' in resp) {
    return {
      signatures: resp.signatures,
      success: true,
    } as SignatureRequestSuccess;
  } else {
    return {
      error: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function templateList(
  associatedObjectId: number,
  associatedObjectType: string,
  identifier: string,
  context: string,
  pageSize: number,
  pageNum: number,
  query: string,
): Promise<TemplateList | HsErrorResponse> {
  const body = {
    associatedObjectId,
    associatedObjectType,
    identifier,
    context,
    pageSize,
    pageNum,
    query,
  };

  const response = await hsFetch('/integration/templateList', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if ('templates' in resp) {
    const templates = await templateArraySchema.validate(resp.templates);
    return {
      templates,
      listInfo: {
        numPages: resp.list_info.page_size,
        numResults: resp.list_info.num_results,
        page: resp.list_info.page,
        pageSize: resp.list_info.page_size,
        maxNumTemplates: resp.list_info.max_num_templates,
      },
    } as TemplateList;
  } else if (resp.length === 0) {
    const templates: Template[] = [];
    return {
      templates,
      listInfo: {
        numPages: 0,
        numResults: 0,
        page: 0,
        pageSize: 0,
      },
    } as TemplateList;
  } else {
    return {
      error: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function templateCreateEmbeddedDraft(
  associatedObjectId: number,
  associatedObjectType: string,
  identifier: string,
  context: string,
): Promise<PrepAndSendSuccess | HsErrorResponse> {
  const body = {
    associatedObjectId,
    associatedObjectType,
    identifier,
    context,
  };

  const response = await hsFetch('/integration/templateCreateEmbeddedDraft', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if (resp.edit_url) {
    return {
      signatureRequestId: resp.signature_request_id,
      claimUrl: resp.edit_url,
      clientId: resp.client_id,
      success: true,
    } as PrepAndSendSuccess;
  } else {
    return {
      error: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function embeddedEditTemplateUrl(
  templateId: string,
  context: string,
  identifier: string,
): Promise<EditTemplateResponse | HsErrorResponse> {
  const response = await hsFetch('/integration/embeddedEditUrl', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      context,
      identifier,
      templateId,
    }),
  });

  const responseJson = await response.json();

  if (responseJson?.edit_url && responseJson?.client_id) {
    return {
      editUrl: responseJson.edit_url,
      clientId: responseJson.client_id,
      success: true,
    };
  } else {
    const error: HsErrorResponse = {
      error: responseJson?.error,
      statusCode: response.status,
      success: false,
    };

    return error;
  }
}

export async function signatureRequestCancel(
  identifier: string,
  context: string,
  signatureRequestId: string,
  emails?: string,
): Promise<SignatureRequestSuccess | HsErrorResponse> {
  const body = {
    identifier,
    context,
    signatureRequestId,
    emails,
  };

  const response = await hsFetch('/integration/signatureRequestCancel', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();

  if ('signatures' in resp) {
    return {
      signatures: resp.signatures,
      success: true,
    } as SignatureRequestSuccess;
  } else {
    return {
      error: resp.error,
      statusCode: response.status,
      success: false,
    } as HsErrorResponse;
  }
}

export async function useGDriveNewOAuth(): Promise<UseGDriveNewOAuthResponse> {
  const url = '/endpoint/org/useGDriveNewOAuth';
  const response = await hsFetch(url);
  if (!response.ok) {
    throw Error(`${response.status}: Failed to fetch ${url}`);
  }
  const body = await response.json();
  return body;
}
