export const ORIGIN_VIEWPORT = Symbol('ORIGIN_VIEWPORT');
export const ORIGIN_PAGE = Symbol('ORIGIN_PAGE');
export const ORIGIN_PAGE_CONTAINER = Symbol('ORIGIN_PAGE_CONTAINER');

export const PREPARER = 'preparer';
export const SENDER = 'sender';

export const MIN_FONT_SIZE = 7;
export const DEFAULT_FONT_SIZE = 12;
export const DEFAULT_FONT_FAMILY = 'notoSans';

export const DEFAULT_DATE_FORMAT = 'MM / DD / YYYY';

export const FIT_WIDTH = 'FIT';

// This is used for keyboard shortcuts
export const IS_MAC = Boolean(
  navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i),
);

export const fontFamilies = {
  arial: 'Arial',
  calibri: 'Calibri',
  cambria: 'Cambria',
  courier: 'Courier',
  georgia: 'Georgia',
  times: 'Times',
  trebuchet: 'Trebuchet',
  verdana: 'Verdana',
  notoSans: 'Noto Sans',
  notoSerif: 'Noto Serif',
  roboto: 'Roboto',
  robotoMono: 'Roboto Mono',
  helvetica: 'Helvetica',
} as const;

export type FontFamily = keyof typeof fontFamilies;
