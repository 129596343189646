import React, { useState, useCallback } from 'react';

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  reloadThreshold?: number;
  fallbackUrl?: string;
};

export default function ResilientImage({
  src,
  reloadThreshold = 5,
  fallbackUrl = '',
  ...props
}: Props) {
  const [imgSrc, setImgSrc] = useState(src);
  const [imgReloadConunt, setImageReload] = useState(0);
  const handleImgError = useCallback(() => {
    if (imgReloadConunt < reloadThreshold) {
      setImageReload(imgReloadConunt + 1);
      // Switching the img src forces the image file to refetch from the server, resets next tick
      if (imgSrc === src) {
        setImgSrc(fallbackUrl);
      } else {
        setImgSrc(src);
      }
    }
  }, [fallbackUrl, imgReloadConunt, imgSrc, reloadThreshold, src]);

  return <img {...props} src={imgSrc} onError={handleImgError} />;
}
