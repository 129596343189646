import React from 'react';

export function usePreventNativeZoom() {
  React.useLayoutEffect(() => {
    const viewport = document.querySelector('meta[name=viewport]');
    if (viewport) {
      const content = viewport.getAttribute('content') || '';
      const newContent = content.split(/,\s?/);

      // Prevent browsers from automatically zooming into text fields
      if (!content.includes('maximum-scale=1')) {
        newContent.push('maximum-scale=1');
      }

      if (newContent.join(',') !== viewport.getAttribute('content')) {
        viewport.setAttribute('content', newContent.join(','));
      }

      return () => {
        viewport.setAttribute('content', content);
      };
    }
    return () => {};
  }, []);
}
