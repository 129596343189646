import * as ActionTypes from 'signer-app/signature-modal/contexts/canvas/action-types';
import { Reducer } from 'signer-app/signature-modal/contexts/signature-modal/use-thunk-reducer';
import { CanvasAction } from 'signer-app/signature-modal/contexts/canvas/actions';
import { CanvasState } from 'signer-app/signature-modal/contexts/canvas/context';

function canvasReset(state: CanvasState) {
  return {
    ...state,
    hasDrawn: false,
  };
}

function canvasStart(state: CanvasState) {
  return {
    ...state,
    hasDrawn: true,
    isDrawing: true,
  };
}

function canvasStop(state: CanvasState) {
  return {
    ...state,
    isDrawing: false,
    isDragging: false,
  };
}

const reducer: Reducer<CanvasState, CanvasAction> = (state, action) => {
  switch (action.type) {
    case ActionTypes.CANVAS_RESET:
      return canvasReset(state);
    case ActionTypes.CANVAS_START:
      return canvasStart(state);
    case ActionTypes.CANVAS_STOP:
      return canvasStop(state);
    default:
      return state;
  }
};

export default reducer;
