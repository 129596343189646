/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
const HeaderAlertTypes = {
  SUCCESS: 'success', // green
  ERROR: 'error', // red
  INFO: 'info', // yellow
  INSTRUCTIONS: 'instructions', // cerulean
};

module.exports = HeaderAlertTypes;
