import hsFetch from 'js/sign-components/common/hs-fetch';

/**
 * These actions are used on the landing page
 */

export type DocumentSummary = {
  // eslint-disable-next-line camelcase
  out_for_signature: number;
  // eslint-disable-next-line camelcase
  awaiting_my_signature: number;
  completed: number;
  draft: number;
};

export async function loadDocumentSummaryData(): Promise<DocumentSummary> {
  const url = '/endpoint/account/documentSummary';
  const response = await hsFetch(url);
  const body = await response.json();
  if (!response.ok) {
    throw Error(body.error || `${response.status}: Failed to fetch ${url}`);
  }
  return body.data;
}
