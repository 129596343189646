/* eslint-disable @typescript-eslint/no-use-before-define */
import { queryParams } from 'js/sign-components/common/fetch';
import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import {
  validateWebAppAccount,
  validateWebAppAccountSettings,
} from 'js/sign-components/generated/validators/validateWebApp';
import {
  EditableIntegration,
  WebAppAccount,
  WebAppAccountSettings,
} from 'js/sign-components/generated/types/WebApp';
import {
  assertBasicSuccess,
  assertDataSchemaResponse,
} from 'signer-app/utils/response-helpers';

export async function fetchAccountSettingsData(): Promise<WebAppAccountSettings> {
  const url = '/api/account/settings';
  const response = await hsFetch(url);
  return assertDataSchemaResponse(response, validateWebAppAccountSettings);
}

export async function fetchWebAppAccount(): Promise<WebAppAccount> {
  const url = '/api/account/user';
  const response = await hsFetch(url);
  return assertDataSchemaResponse(response, validateWebAppAccount);
}

export async function updateEditableIntegration(
  integration: EditableIntegration,
): Promise<string | null> {
  const url = '/account/updateExternalService';

  const body = new FormData();
  body.append('service_type', integration.serviceTypeCode);
  body.append('is_deactivate', integration.isActive ? '1' : '0');
  body.append('csrf_token', getCSRFToken());
  body.append('async', '1');

  const response = await hsFetch(url, {
    method: 'POST',
    body: JSON.stringify(integration),
  });

  // eslint-disable-next-line camelcase
  type MicroSchema = { auth_url?: string };
  const validator = (data: any): data is MicroSchema => {
    if (!data.success) {
      throw new Error('Missing success indicator');
    }
    return true;
  };
  const { auth_url: authUrl } = await assertDataSchemaResponse(
    response,
    validator,
  );

  return authUrl ?? null;
}

export async function uploadCustomLogo(f: File): Promise<string> {
  const formData = new FormData();
  formData.append('customLogoFile', f);
  const url = '/api/account/customLogo';
  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });
  type MicroSchema = { customLogoUrl: string };
  const validator = (data: any): data is MicroSchema => {
    if (!data.customLogoUrl) {
      throw new Error('Missing customLogoUrl');
    }
    return true;
  };
  const { customLogoUrl } = await assertDataSchemaResponse(response, validator);
  return customLogoUrl;
}

export async function saveWebAppAccountSettings(
  settings: WebAppAccountSettings,
): Promise<void> {
  const url = '/api/account/settings';
  const response = await hsFetch(url, {
    method: 'POST',
    body: JSON.stringify(settings),
  });
  assertBasicSuccess(response);
}

export async function saveWebAppAccount(account: WebAppAccount): Promise<void> {
  const url = '/api/account/user';
  const response = await hsFetch(url, {
    method: 'POST',
    body: JSON.stringify(account),
  });
  assertBasicSuccess(response);
}

export async function removeCustomLogo(forTeam: boolean): Promise<void> {
  // This URL doesn't return any data
  const url = `/content/removeCustomLogo${queryParams({
    for_team: forTeam ? '1' : '0',
  })}`;
  await hsFetch(url, {
    method: 'POST',
    body: JSON.stringify({
      csrf_token: getCSRFToken(),
    }),
  });
}

export async function clearAutocomplete() {
  const body = new FormData();
  body.append('clear_autocomplete[_csrf_token]', getCSRFToken());

  await hsFetch('editor/asyncClearAutocomplete', {
    method: 'POST',
    body,
  });

  return true;
}

export async function clearContacts() {
  const body = new FormData();
  body.append('clear_contacts[_csrf_token]', getCSRFToken());

  await hsFetch('editor/asyncClearAutocomplete', {
    method: 'POST',
    body,
  });

  return true;
}

export async function deleteAccount(password: string) {
  const body = new FormData();
  body.append('password', password);

  await hsFetch('/accountSettings/deleteAccount', {
    method: 'POST',
    body,
  });

  return true;
}
