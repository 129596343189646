/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  no-void,
  react/no-unescaped-entities
*/
import React from 'react';
import PropTypes from 'prop-types';
import SuperAgent from 'superagent';
import createReactClass from 'create-react-class';

const VerifyEmail = createReactClass({
  propTypes: {
    shortGuid: PropTypes.string,
    emailAddress: PropTypes.string,
    csrf_token: PropTypes.string,
  },

  getInitialState() {
    return {
      processing: false,
    };
  },

  onSuccess() {
    this.setState({ processing: false });
  },

  onResendClick(e) {
    e.preventDefault();
    const self = this;

    this.setState({ processing: true });

    const resendAddress = '/signer/sendTemplateLinkEmail';

    SuperAgent.post(resendAddress)
      .type('form')
      .send({ email_address: this.props.emailAddress })
      .send({ guid: this.props.shortGuid })
      .send({ csrf_token: this.props.csrf_token })
      .end((err, res) => {
        if (!err) {
          const data = JSON.parse(res.text);
          self.onSuccess(data);
        }
      });
  },

  render() {
    const spinner = this.state.processing ? (
      <img
        className="spinner"
        style={{ position: 'absolute', left: '10%' }}
        src={require('./loading-spinner-16.gif')}
        width="16"
        height="16"
      />
    ) : (
      void 0
    );

    return (
      <div className="m-verify-email row">
        <div className="m-verify-email--container">
          <div className="m-verify-email--text">
            <h4>One last thing</h4>
            <img src={require('./email_icon@2x.png')} />
            <div>We just sent you an email.</div>
            <div>Please open it and click 'Review Document'.</div>
          </div>
          <div className="m-verify-email--resend">
            {spinner}
            Don't see it in your inbox?{' '}
            <a href="#" onClick={this.onResendClick}>
              Click here to resend.
            </a>
          </div>
        </div>
        <span className="m-verify-email--legal">
          Note: This step is required to comply with eSignature laws.
        </span>
      </div>
    );
  },
});

module.exports = VerifyEmail;
