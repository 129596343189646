/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  max-len,
  no-bitwise,
  no-void
*/
import { intl } from 'hellospa/common/hs-intl-provider';
import hsMessages from 'signer/components/common/translations/hs-messages';
import recycle from 'common/models/utils/recycle';
import { isHidden } from 'signer/components/main/pages/sig-doc';
import Field from './field';
import Fields from './fields';

const GROUP_BORDER_PADDING = 5;

module.exports = Field.extend({
  /**
   */

  validationOptions: {
    min: -1,
    max: -1,
  },

  /**
   */

  hasValue() {
    for (let i = this.children.length; i--; ) {
      if (this.children[i].hasValue()) {
        return true;
      }
    }
    return false;
  },

  /**
   */

  isEdited() {
    return this.editable === true && this.edited === true;
  },

  /**
   */

  getValidationError() {
    if (!this.validationOptions) {
      return;
    }

    if (this.children.some((field) => field[isHidden])) {
      return;
    }

    // simple validation. Possibly move this somewhere else later on
    const numFieldsWithValues = this.children.filter((field) => {
      // TODO - this should be changed to field.value != void 0 && field.value !== false
      return field.value === true;
    }).length;
    if (
      ~this.validationOptions.min &&
      this.validationOptions.min > numFieldsWithValues
    ) {
      return new Error(
        intl.formatMessage(hsMessages.minNotMetMessage, {
          min: this.validationOptions.min,
        }),
      );
    }

    if (
      ~this.validationOptions.max &&
      this.validationOptions.max < numFieldsWithValues
    ) {
      return new Error(intl.formatMessage(hsMessages.maxExceededMessage));
    }
  },

  /**
   */

  getSignerIndex() {
    if (this.children && this.children.length) {
      return this.children[0].data.signer;
    }
  },

  /**
   */

  toData() {
    const data = Field.prototype.toData.call(this);
    data.children = this.children.toData();
    data.validate = this.validationOptions || {};
    return data;
  },

  /**
   */

  fromData(data) {
    let order;
    const props = Field.prototype.fromData.call(this, data);
    let hasValue = false;

    props.children = recycle(this.children, Fields, {
      notifier: this.notifier,
      data: data.children,
      snapshots: this.snapshots,
      snapshot: this.snapshot,
      fieldClasses: this.fieldClasses,
      signatureRequest: this.signatureRequest,
    });

    const fieldGuids = [];

    // Figure out if the group is editable
    props.editable = data.editable === true;
    if (!props.editable && data.children) {
      props.children.forEach((child) => {
        fieldGuids.push(child.guid);
        if (child.editable === true) {
          props.editable = true;
          return false;
        }
      });
    }

    // Figure out the coordinate of this group
    if (props.editable) {
      let minX;
      let minY;
      let maxX;
      let maxY;
      const self = this;

      props.children.forEach((child) => {
        child.group = self; // Back reference to group
        minX = minX !== void 0 ? Math.min(minX, child.x) : child.x;
        minY = minY !== void 0 ? Math.min(minY, child.y) : child.y;
        maxX =
          maxX !== void 0
            ? Math.max(maxX, child.x + child.width)
            : child.x + child.width;
        maxY =
          maxY !== void 0
            ? Math.max(maxY, child.y + child.height)
            : child.y + child.height;
        order = order !== void 0 ? Math.min(order, child.order) : child.order;

        if (!hasValue && child.hasValue()) {
          hasValue = true;
        }
      });

      props.x = minX !== void 0 ? minX - GROUP_BORDER_PADDING : void 0;
      props.y = minY !== void 0 ? minY - GROUP_BORDER_PADDING : void 0;
      props.width =
        minX !== void 0 && maxX !== void 0
          ? maxX - minX + 2 * GROUP_BORDER_PADDING + 2
          : void 0;
      props.height =
        minY !== void 0 && maxY !== void 0
          ? maxY - minY + 2 * GROUP_BORDER_PADDING + 6
          : void 0;
    }

    // Figure out if it's required
    props.required = data.required === true && props.editable === true;
    props.edited = props.editable && hasValue;
    props.root = data.root === true;
    props.order = order;

    // need a unique identifier on the group to ensure that equals() method works properly (CC)
    props.guid = fieldGuids.join(':');

    return props;
  },
});
