import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sentryEnvironment } from 'signer-app/utils/env';

import { Title, Text } from '@dropbox/dig-components/typography';
import { TrafficRoadBlockedSpot } from '@dropbox/dig-illustrations/dist/cjs/spot/traffic-road-blocked';
import { Banner } from '@dropbox/dig-components/banner';
import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { EmailLine, BugLine } from '@dropbox/dig-icons/assets';

import styles from 'signer-app/parts/error-boundary/error-page.module.css';
import { getFaqUrl } from 'signer-app/utils/url-helpers';

export function isLocalEnvironment(): boolean {
  return sentryEnvironment === 'development';
}

export function isProduction(): boolean {
  return sentryEnvironment === 'production';
}

export type ErrorPageProps = {
  errorCode: string;
  errorTime: Date;
  error?: Error;
};

type ErrorBannerProps = {
  error: Error;
  errorCode: string;
};

function ErrorBanner({ error, errorCode }: ErrorBannerProps) {
  const [showStack, toggle] = React.useReducer((v) => !v, false);

  if (isProduction()) {
    return null;
  }

  return (
    <Banner
      className={styles.errorStackBanner}
      type="alert"
      variant="basic"
      withLeftIcon={false}
    >
      <Banner.Message>
        <Title size="small">
          {errorCode} &ndash; {error.name}: {error.message}
        </Title>
        {showStack && (
          <Text
            className={styles.errorStack}
            tagName="p"
            size="small"
            style={{ whiteSpace: 'pre' }}
            monospace
          >
            {error.stack}
          </Text>
        )}
        <Button variant="transparent" onClick={toggle}>
          Toggle stack
        </Button>
      </Banner.Message>
    </Banner>
  );
}

function ErrorPage({ errorCode, errorTime, error }: ErrorPageProps) {
  const [didEnableLocalSentryReporting, setDidEnableLocalSentryReporting] =
    React.useState(false);
  const [isEnablingLocalSentryReporting, setIsEnablingLocalSentryReporting] =
    React.useState(false);

  const errorDetails = isProduction()
    ? undefined
    : error && <ErrorBanner errorCode={errorCode} error={error} />;

  const isSentryReportingEnabled = React.useMemo(() => {
    try {
      if (sentryEnvironment === 'development') {
        return (
          window.localStorage.getItem('local-sentry-reporting-enabled') === 'y'
        );
      }
    } catch (err) {
      // Do nothing.
    }
    return false;
  }, []);

  return (
    <div className={styles.container}>
      <Title size="large">
        <FormattedMessage
          id="8d15d24487de7423a4998032794282821a4a6d0d36cb2b3a5ea657ede9f4b1cf"
          description="unknown error, message in error page"
          defaultMessage="An unexpected error has occurred."
        />
      </Title>
      <div className={styles.illustration}>
        <TrafficRoadBlockedSpot />
      </div>
      <div className={styles.errorHelpText}>
        <Text variant="paragraph">
          <FormattedMessage
            id="b1cf54d4ec2b7f88047591841c9a4c80f314178e643801967bf7d8329dee7e20"
            description="error message"
            defaultMessage="Please try once more. If the error persists, please contact us with the error code and time below."
          />
        </Text>
      </div>
      <div className={styles.errorInfo}>
        <Text>
          <FormattedMessage
            id="8b8db4366e1871a6f4214bd9ce7f73c8947e5f27e700ce4cbce2072d659dca36"
            description="unknown error, message in error page"
            defaultMessage="Error code: {errorCode}"
            values={{
              errorCode: (
                <Text tagName="span" isBold monospace>
                  {errorCode}
                </Text>
              ),
            }}
          />
        </Text>
        <br />
        <Text>
          <FormattedMessage
            id="142f806e1926c0b7b49d984be7195df11576a332b8d24c69ef81a93b373a7a22"
            description="unknown error, message in error page, errorTime is simple time"
            defaultMessage="Error time: {errorTime}"
            values={{
              errorTime: (
                <Text
                  tagName="span"
                  isBold
                  monospace
                >{`${errorTime.toUTCString()}`}</Text>
              ),
            }}
          />
        </Text>
        {!isProduction() && (
          <>
            <br />
            <Text>
              <FormattedMessage
                id="ee03f9bbf2498df20b0375550277f045fdb7485c4352d1c128cd1e67a50b3574"
                description="unknown error, message in error page, errorTime is simple time"
                defaultMessage="Error environment: {errorTime}"
                values={{
                  errorTime: (
                    <Text tagName="span" isBold monospace>
                      {sentryEnvironment}
                    </Text>
                  ),
                }}
              />
            </Text>
          </>
        )}
      </div>
      <div className={styles.contactUsButton}>
        <Button
          withIconLeft={<UIIcon src={EmailLine} role="presentation" />}
          variant="opacity"
          href={getFaqUrl('requests/new')}
        >
          <FormattedMessage
            id="96209a7f862c512fb41ea6e0f57f6f2fe1d261d5e93f2ed1da29bbe739c160c0"
            description="button text in error page, when clicked reroutes user to conact page"
            defaultMessage="Contact us"
          />
        </Button>
      </div>
      {isLocalEnvironment() && !isSentryReportingEnabled && (
        <Banner
          variant="basic"
          type="warning"
          withLeftIcon={false}
          className={styles.sentryReportingBanner}
        >
          <Banner.Message>
            {didEnableLocalSentryReporting ? (
              <>
                <Text>
                  <FormattedMessage
                    id="d1e95bfe45724e491a9ae01ef01d410734a7425803af9617f721606a289c6417"
                    description="Internal debug info text."
                    defaultMessage="Local Sentry reporting has been enabled."
                  />
                </Text>
                <br />
                <Text>
                  <FormattedMessage
                    id="2a9a8684357d7b7a674d10121cfd5b28d714ed72114261f45054a5aad2a43921"
                    description="Internal debug info text."
                    defaultMessage="To disable local Sentry reporting in the future, click the {bugIcon} in the debug toolbar and click <em>Disable sentry reporting on dev machine</em>."
                    values={{
                      bugIcon: (
                        <UIIcon
                          className={styles.bugIcon}
                          src={BugLine}
                          role="presentation"
                        />
                      ),
                      em(...chunks: any[]) {
                        return <Text isBold>{chunks}</Text>;
                      },
                    }}
                  />
                </Text>
              </>
            ) : (
              <>
                <Text isBold>
                  <FormattedMessage
                    id="99013023ee46e65af3261d45390a6ad0a028c7c34210420e6e74636b98fe3cb8"
                    description="Internal debug message; notice to QA engineers"
                    defaultMessage="Attention QA engineers!"
                  />
                </Text>
                <br />
                <Text>
                  <FormattedMessage
                    id="b5b0310bae400f93939da2824356d7b779ac6012d90420ac0babc943cb6ca76a"
                    description="Internal debug message; notice to QA engineers"
                    defaultMessage="This error was <em>not</em> sent to Sentry. If you are using your dev vm to QA and verify tickets, please enable local Sentry reporting."
                    values={{
                      em(...chunks: any[]) {
                        return <Text isBold>{chunks}</Text>;
                      },
                    }}
                  />
                </Text>
                <br />
                <br />
                <Button
                  variant="transparent"
                  isLoading={isEnablingLocalSentryReporting}
                  onClick={() => {
                    setIsEnablingLocalSentryReporting(true);

                    // eslint-disable-next-line no-alert
                    if (
                      confirm(
                        'Are you sure? You should ONLY enable local Sentry reporting if you are actively using your dev vm to QA and verify tickets. If you are unsure, please reach out to #ask-hs-frontend.',
                      )
                    ) {
                      window.localStorage.setItem(
                        'local-sentry-reporting-enabled',
                        'y',
                      );

                      // Update debug toolbar text if enabled.
                      if (document.getElementById('hs-debug-tools')) {
                        document.getElementById(
                          'hs-debug-tools__enable-sentry-reporting',
                        )!.textContent =
                          'Disable Sentry reporting on dev machine';
                      }

                      setTimeout(() => {
                        setDidEnableLocalSentryReporting(true);
                      }, 500);
                    } else {
                      setIsEnablingLocalSentryReporting(false);
                    }
                  }}
                >
                  <FormattedMessage
                    id="362adedccfb6a0d188aba1b59aa81d2cc6d20972686061af347b6bf244a9e1eb"
                    description="Text for a button which enables local Sentry reporting when pressed."
                    defaultMessage="Enable local Sentry reporting"
                  />
                </Button>
              </>
            )}
          </Banner.Message>
        </Banner>
      )}
      {errorDetails}
    </div>
  );
}

export default ErrorPage;
