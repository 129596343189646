import * as Sentry from '@sentry/browser';
import {
  findDifferences,
  sortedStringify,
} from 'hellospa/page/editor/sorted-stringify';
import { isLocalEnvironment } from 'js/sign-components/common/ts-utils';
import { identity } from 'lodash';

async function verifyResponses<T>(
  newEndpointPromise: Promise<T>,
  oldEndpointPromise: Promise<T>,
  normalize: (data: [T, T]) => [T, T] = identity,
): Promise<boolean> {
  if (IS_STORYBOOK) {
    // The data definitely won't match in Storybook so this process needs to be skipped
    return true;
  }

  const [newEndpoint, oldEndpoint] = normalize(
    await Promise.all([newEndpointPromise, oldEndpointPromise]),
  );

  const newEndpointString = sortedStringify(newEndpoint);
  const oldEndpointString = sortedStringify(oldEndpoint);

  if (newEndpointString !== oldEndpointString) {
    const differences = findDifferences(newEndpoint, oldEndpoint);
    if (
      isLocalEnvironment() &&
      NODE_ENV !== 'test' &&
      differences.length !== 0
    ) {
      /* eslint-disable no-console */
      console.error('newEndpoint (after transform)', newEndpoint);
      console.error('was expected to match oldEndpoint', oldEndpoint);
      console.error(
        `(newEndpoint) !== (oldEndpoint)\n${differences.join('\n')}`,
      );
      /* eslint-enable no-console */
    }
    return differences.length === 0;
  }
  return true;
}

export async function loadDataWithFallback<T, Args extends unknown[]>(
  fallbackName: string,
  newEndpoint: (...args: Args) => Promise<T>,
  oldEndpoint: (...args: Args) => Promise<T>,
  args: Args,
  normalizeForComparison: (data: [T, T]) => [T, T] = identity,
) {
  let loadDataPromise;
  try {
    // I'm saving the promise here instead of await(ing) on it so the
    // verification code can fetch the other endpoint in parallel
    const dataPromise = newEndpoint(...args);

    if (isLocalEnvironment() && NODE_ENV !== 'test') {
      loadDataPromise = oldEndpoint(...args);

      Sentry.captureEvent({
        message: `fallback ${fallbackName} was triggered`,
      });

      if (
        !(await verifyResponses(
          dataPromise,
          loadDataPromise,
          normalizeForComparison,
        ))
      ) {
        // eslint-disable-next-line no-console
        console.error('Endpoint mismatch');
      }
    }
    // This needs to await so that errors are caught in this block.
    const data = await dataPromise;
    return data;
  } catch (error) {
    // Also log this in development
    // eslint-disable-next-line no-console
    console.error(error);
    Sentry.captureException(error);
    throw error;
  }
}

export class BadResponseError extends Error {
  public readonly response: Response;

  constructor(response: Response) {
    super(
      `${response.url} responded with non-200 status code: ${response.status}`,
    );
    this.response = response;
  }
}
