/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  no-multi-assign
*/
import Field from './field';

module.exports = Field.extend({
  /**
   */

  toData() {
    const data = Field.prototype.toData.call(this);
    data.fontSize = this.fontSize;
    data.fontFamily = this.fontFamily;
    // This prevents a hidden date field from sending its date to the server.
    if (data.value) {
      data.value = Date.now();
    }
    return data;
  },

  /**
   */

  fromData(data) {
    const props = Field.prototype.fromData.call(this, data);
    props.required = props.editable = false; // take out of the input section
    props.value = new Date();
    props.fontSize = data.fontSize;
    props.fontFamily = data.fontFamily;
    return props;
  },
});
