import hsFetch from 'js/sign-components/common/hs-fetch';
import { queryParams } from 'js/sign-components/common/fetch';

/**
 * Please don't add to fetchActions. It doesn't have a clear definition of what
 * should be here, so you're better off creating your own new namespace.
 */

const normalizeTemplate = (template) => ({
  id: template.template_id,
  title: template.title,
  isLocked: template.is_locked,
  isOrdered: template.is_ordered,
  dateFormat: template.date_format,
  allowReassign: template.allow_reassign,
  roles: template.roles,
});

let filteredTemplates = [];

export async function fetchTemplate(id) {
  const url = `/template${queryParams({ id })}`;
  const result = await hsFetch(url, { credentials: 'same-origin' });
  const { template } = await result.json();
  return normalizeTemplate(template);
}

/**
 * @param {{ pageNum: number, filter?: string}} param0
 */
export async function fetchTemplateList({ pageNum, filter = null }) {
  const url = `/template/list${queryParams({ page_num: pageNum, filter })}`;

  const response = await hsFetch(url, { credentials: 'same-origin' });

  if (response.status === 200) {
    const data = await response.json();

    return {
      total: data.list_info.num_results,
      pageSize: data.list_info.page_size,
      templates: data.templates.map(normalizeTemplate),
    };
  }

  throw new Error('Unhandled response from template/list');
}

export async function fetchAndCacheSingleRoleTemplates({
  pageNum,
  forceRefresh,
}) {
  const MAX_TEMPLATES = 500;
  const PAGE_SIZE = 100;

  let totalPagesFromServer = 1;
  let currentPage = 0;

  if (filteredTemplates.length === 0 || forceRefresh) {
    filteredTemplates = [];

    while (
      currentPage < totalPagesFromServer &&
      filteredTemplates.length <= MAX_TEMPLATES
    ) {
      ++currentPage;

      const url = `/template/list${queryParams({ page_num: currentPage })}`;
      /* eslint-disable no-await-in-loop */
      const response = await hsFetch(url, { credentials: 'same-origin' });

      if (response.status !== 200) {
        throw new Error('Could not fetch all templates');
      }

      /* eslint-disable no-await-in-loop */
      const data = await response.json();

      // This might change if teammates add templates while we are populating our cache.
      totalPagesFromServer = data.list_info.num_pages;

      const temps = data.templates
        .filter((t) => t.roles.length === 1)
        .map(normalizeTemplate);
      filteredTemplates = filteredTemplates.concat(temps);
    }
  }

  const offset = (pageNum - 1) * PAGE_SIZE;
  const templatePage = filteredTemplates.slice(offset, offset + PAGE_SIZE);

  return {
    total: filteredTemplates.length,
    pageSize: PAGE_SIZE,
    templates: templatePage,
  };
}

export async function fetchHubspotTemplateList({ pageNum, pageSize }) {
  const url = `/template/list${queryParams({ page_num: pageNum, page_size: pageSize })}`;

  const response = await hsFetch(url, { credentials: 'same-origin' });

  if (response.status === 200) {
    const data = await response.json();

    return {
      total: data.list_info.num_results,
      pageSize: data.list_info.page_size,
      templates: data.templates.map(normalizeTemplate),
    };
  }

  throw new Error('Unhandled response fetching hubspot templates');
}

export async function fetchManageDocumentsPage(filter, page, pageSize) {
  let documents = [];
  const url = `/home/manageDocs${queryParams({
    filter,
    page_num: page,
    page_size: pageSize,
  })}`;
  const response = await hsFetch(url, { credentials: 'same-origin' });
  const data = await response.json();

  documents = data.items.map((doc) => ({
    guid: doc.guid,
    type: doc.type,
    title: doc.title,
    email: doc.email,
    date: new Date(doc.date * 1000),
    totalSigners: doc.total_signers,
    totalSigned: doc.total_signed,
    isCsvDownloadable: doc.is_csv_downloadable,
  }));

  return {
    documents,
    totalItems: data.total_items,
  };
}

export async function fetchBulkSendSigners(id) {
  let signers = [];
  const url = `/bulksend/SignerList${queryParams({
    bulk_send_id: id,
  })}`;
  const response = await hsFetch(url, { credentials: 'same-origin' });
  const data = await response.json();

  signers = data.items.map((doc) => ({
    id: doc.guid,
    name: doc.signer_name,
    status: doc.signed ? 'signed' : 'not-signed',
  }));

  return {
    signers,
  };
}

export async function keepSessionAlive() {
  // keep session alive
  await hsFetch('/account/keepSessionAlive', {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
}
