/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  camelcase,
  eqeqeq,
  func-names,
  guard-for-in,
  import/no-extraneous-dependencies,
  max-len,
  no-bitwise,
  no-multi-assign,
  no-param-reassign,
  no-prototype-builtins,
  no-restricted-globals,
  no-restricted-syntax,
  no-useless-escape,
  no-var,
  one-var,
  prefer-const,
  prefer-rest-params,
  vars-on-top
*/
import * as runtimeEnvironment from 'js/sign-components/env';
import deepExtend from 'lodash/merge';
import config from 'js/sign-components/common/config';
import {
  isHelloFax,
  isHelloSign,
  getCurrentSubdomain,
  urlHelper,
  getMarketingLocaleFormatForFaq,
  getLocalizedWebflowUrl,
  getWwwHost,
  attachSessionInfoToUrl,
  getFaqDomain,
  getFaqUrl,
  getWebflowUrl,
} from 'signer-app/utils/url-helpers';
import { FONT_CHAR_MAP } from 'signer-app/utils/font-char-map';

const symbol = '$';

const HfReactHelper = deepExtend({}, config, {
  imgHelper(input_string) {
    let domain = HfReactHelper.hfCdn;
    if (typeof location !== 'undefined') {
      domain =
        String(location.hostname).indexOf(runtimeEnvironment.hfHost) !== -1
          ? HfReactHelper.hfCdn
          : HfReactHelper.hsCdn;
    }

    return `//${domain}/images/${input_string}`;
  },

  urlHelper,

  matchesHsDomain(toCheck, againstCheck, subdomain) {
    const getUrl = (url) => {
      const regex =
        /(>[a-z0-9]*\.)?(>[a-z0-9-]*)?hellosign(>(\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))?/g;
      const trimUrl = (str) => str.replace(/\/{2,}/g, '/').replace(/\/$/g, '');
      try {
        const parsed = new URL(url);
        if (parsed.hostname.search(regex) === -1) {
          return false;
        }
        return trimUrl(
          HfReactHelper.urlHelper(
            parsed.pathname,
            HfReactHelper.HfSites.SITE_CODE_HS,
            subdomain,
          ),
        );
      } catch {
        return trimUrl(
          HfReactHelper.urlHelper(
            url,
            HfReactHelper.HfSites.SITE_CODE_HS,
            subdomain,
          ),
        );
      }
    };
    return getUrl(toCheck) === getUrl(againstCheck);
  },

  getCurrentSubdomain,

  extend() {
    for (let i = 1; i < arguments.length; i++) {
      for (const key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) {
          arguments[0][key] = arguments[i][key];
        }
      }
    }
    return arguments[0];
  },

  trim(s) {
    if (s && s.length > 0) {
      for (var a = 0; a < s.length; a++) {
        if (s[a] !== ' ') {
          break;
        }
      }
      for (var b = s.length - 1; b >= 0; b--) {
        if (s[b] !== ' ') {
          break;
        }
      }
      return s.slice(a, b + 1);
    } else {
      return '';
    }
  },

  getMarketingLocaleFormatForFaq,

  getLocalizedWebflowUrl,

  getWebflowUrl,

  getWwwHost,

  getFaqDomain,

  getFaqUrl,

  getDocumentationUrl(pathname = '') {
    return `https://developers.hellosign.com/${pathname}`;
  },

  getDropboxHelpCenterUrl(pathname = '') {
    return `https://help.dropbox.com/${pathname}`;
  },

  getDropboxUrl(pathname = '') {
    return `https://www.dropbox.com/${pathname}`;
  },

  inArray(elem, array) {
    if (array.indexOf) {
      return array.indexOf(elem);
    }

    for (let i = 0, length = array.length; i < length; i++) {
      if (array[i] === elem) {
        return i;
      }
    }

    return -1;
  },

  isValidEmailAddress(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@\']+(\.[^<>()\[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  isValidPhoneNumber(number) {
    const re =
      /^(1[\s-]?)?[\(\[\s-]{0,2}?\d{3}[\)\]\s-]{0,2}?\d{3}[\s-]?\d{4}$/;
    return re.test(number);
  },

  /*
   * Helper function for Foundation modals - cf: http://foundation.zurb.com/sites/docs/v/5.5.3/components/reveal.html
   */
  getRevealAttachPoint(
    element_id,
    size,
    showCloseButton,
    className,
    closeButtonClassName,
  ) {
    let attachPoint;
    let modalContainer = document.getElementById(element_id);
    if (modalContainer === null) {
      modalContainer = document.createElement('div');
      modalContainer.id = element_id;
      let sizeClass = 'm-modal-restricted-size';
      if (typeof size !== 'undefined') {
        sizeClass = `m-modal ${size}`;
      }
      modalContainer.className = `reveal-modal ${sizeClass}`;
      if (typeof className !== 'undefined') {
        modalContainer.className += ` ${className}`;
      }
      modalContainer.setAttribute('data-reveal', '');

      if (typeof showCloseButton === 'undefined' || showCloseButton !== false) {
        const closeButton = document.createElement('a');
        closeButton.className = `close-reveal-modal m-modal--close ${closeButtonClassName}`;
        closeButton.innerHTML = '&#215;';
        modalContainer.appendChild(closeButton);
      }

      attachPoint = document.createElement('div');
      attachPoint.className = 'm-modal--container';
      modalContainer.appendChild(attachPoint);
    } else {
      attachPoint = modalContainer.getElementsByTagName('div')[0];
    }

    // Append the modal container when we're done so
    // that the browser is not painting and doing reflow
    // calculations unnecessarily.
    document.body.appendChild(modalContainer);

    return attachPoint;
  },

  formatCurrency(intl, amount, currencySymbol = symbol, currencyDecimals = 2) {
    const formattedAmount = intl.formatNumber(amount / 10 ** currencyDecimals, {
      minimumFractionDigits: currencyDecimals,
      maximumFractionDigits: currencyDecimals,
    });
    return `${currencySymbol}${formattedAmount.replace(/\D00(?=\D*$)/, '')}`; // regex removes '.00' or ',00' from formatted number
  },

  formatMoney(amount, currency, isInCents) {
    if (isInCents === true) {
      amount /= 100.0;
    }

    const rounded = parseInt(amount, 10);
    const cents = parseInt((amount - rounded) * 100, 10);

    return (
      (currency !== undefined ? currency : '') +
      rounded +
      (cents > 0 ? `.${cents}` : '')
    );
  },

  isHelloSign,
  isHelloFax,

  // deprecate this (CC)
  HfSites: {
    isHelloFax(siteOrSiteCode) {
      return (
        siteOrSiteCode === HfReactHelper.HfSites.SITE_CODES[0] ||
        siteOrSiteCode === HfReactHelper.HfSites.SITE_CODE_HF
      );
    },

    isHelloSign(siteOrSiteCode) {
      return (
        siteOrSiteCode === HfReactHelper.HfSites.SITE_CODES[1] ||
        siteOrSiteCode === HfReactHelper.HfSites.SITE_CODE_HS
      );
    },

    isHelloWorks(siteOrSiteCode) {
      return (
        siteOrSiteCode === HfReactHelper.HfSites.SITE_CODES[2] ||
        siteOrSiteCode === HfReactHelper.HfSites.SITE_CODE_HW
      );
    },

    getSite(siteCode) {
      return this[`SITE_CODE_H${siteCode}`];
    },
  },

  hasLocalStorage() {
    // in a try/catch block since this method of checking is more compatible with
    // other browsers. See http://stackoverflow.com/questions/16427636/check-if-localstorage-is-available.
    try {
      const k = '__local_storage_check__';
      localStorage.setItem(k, true);
      localStorage.removeItem(k);
      return true;
    } catch (e) {
      return false;
    }
  },

  FONT_CHAR_MAP,

  // Getter to safely retrieve localStorage and avoid throwing tons of exceptions
  // in private browsing mode or when stricter security rules are in application
  getLocalStorage() {
    try {
      if (typeof localStorage !== 'undefined') {
        return localStorage;
      }
    } catch (e) {
      if (e.name !== 'SecurityError') {
        // Only skip if it's a security error
        throw e;
      }
    }

    // Return stub
    return {
      setItem() {},
      getItem() {},
      removeItem() {},
    };
  },

  attachSessionInfoToUrl,

  Cookie: {
    get(key) {
      let parts,
        pairs = document.cookie.split(';');
      for (const k in pairs) {
        parts = $.trim(pairs[k]).split('=');
        if (parts.shift() == key) {
          return parts.join('=');
        }
      }
    },

    set(key, value, ttl_ms, path) {
      let c = `${key}=${value}`;
      if (ttl_ms !== undefined) {
        const d = new Date();
        d.setTime(d.getTime() + ttl_ms);
        c += `; expires=${d.toUTCString()}`;
      }
      if (path === undefined) {
        // Strip any characters that don't conform to RFC 3986 (http://www.ietf.org/rfc/rfc3986.txt)
        path = document.location.pathname.replace(
          /^[A-Za-z0-9\-\._~\:\/\?#\[\]\@\!\$\&\'\(\)\*\+,;\=]/g,
          '',
        );
      }
      if (path) {
        // This way we can skip setting path by using path=null
        c += `; path=${path}`;
      }
      c += `; domain=${document.domain};`;
      document.cookie = c;
    },
  },

  commitHash: COMMIT_HASH,
});

export default HfReactHelper;
