import * as teamsAPI from './admin/teams';
import * as usersAPI from './admin/users';

/**
 * @async
 * @template T - The type of entities.
 * @param {function(number): Promise<[T[], number]>} actionIteratee
 * @param {number} page
 * @param {T[]} seed
 * @returns {Promise<T[]>}
 */
async function getAllEntities(actionIteratee, page = 1, seed = []) {
  const [entities, numPages] = await actionIteratee(page);

  if (page + 1 <= numPages) {
    return getAllEntities(actionIteratee, page + 1, seed.concat(entities));
  } else {
    return seed.concat(entities);
  }
}

async function makeEndpointRequest(endpoint, args = []) {
  const responseData = await endpoint(...args);

  if (!responseData.success) {
    throw new Error(responseData.error);
  }

  return responseData;
}

/**
 * Invites users in bulk via a CSV file.
 *
 * @async
 * @param {File} file
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {boolean} [opts.confirmPaidSeatCountIncrease]
 * @returns {void}
 */
export async function adminBulkInvite(file, csrfToken, opts) {
  await makeEndpointRequest(usersAPI.bulkInvite, [file, csrfToken, opts]);
}

/**
 * Cancels a user invite.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} emailAddress
 * @param {string} teamGuid
 * @param {string} csrfToken
 * @returns {void}
 */
export async function adminCancelInvite(emailAddress, teamGuid, csrfToken) {
  await makeEndpointRequest(usersAPI.cancelInvite, [
    emailAddress,
    teamGuid,
    csrfToken,
  ]);
}

/**
 * Creates a new team.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} teamName
 * @param {string} parentGuid
 * @param {string} csrfToken
 * @returns {void}
 */
export async function adminCreateTeam(teamName, parentGuid, csrfToken) {
  await makeEndpointRequest(teamsAPI.createTeam, [
    teamName,
    parentGuid,
    csrfToken,
  ]);
}

/**
 * Deletes a subteam.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {void}
 */
export async function adminDeleteTeam(guid, csrfToken) {
  await makeEndpointRequest(teamsAPI.deleteTeam, [guid, csrfToken]);
}

/**
 * Edits the information for the team with the given
 * GUID.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.name]
 * @param {string} [opts.parentGuid]
 * @returns {void}
 */
export async function adminEditTeam(guid, csrfToken, opts = {}) {
  await makeEndpointRequest(teamsAPI.editTeam, [guid, csrfToken, opts]);
}

/**
 * Edits the information for the user account with the
 * given GUID.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {Object} [opts]
 * @param {string} [opts.recipientGuid]
 * @param {string} [opts.role]
 * @param {string} [opts.teamGuid]
 * @returns {void}
 */
export async function adminEditUser(guid, csrfToken, opts = {}) {
  await makeEndpointRequest(usersAPI.editUser, [guid, csrfToken, opts]);
}

/**
 * Gets all eligible parent teams.
 *
 * @async
 * @throws if request is not successful.
 * @returns {TeamsAPI~Entity[]}
 */
export async function adminGetParentTeams() {
  const responseData = await makeEndpointRequest(teamsAPI.getParentTeams);

  // return responseData.data.teams;
  return responseData.data;
}

/**
 * Gets the user's subscription's root team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {TeamsAPI~Team}
 */
export async function adminGetRootTeam() {
  const responseData = await makeEndpointRequest(teamsAPI.getRootTeam);

  return responseData.data;
}

/**
 * Gets a team within the user's subscription.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @returns {TeamsAPI~Team}
 */
export async function adminGetTeam(guid) {
  const responseData = await makeEndpointRequest(teamsAPI.getTeam, [guid]);

  return responseData.data;
}

/**
 * Gets all users who can receive documents, templates,
 * API apps, etc. from a user with the given guid.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @returns {UsersAPI~BasicUser[]}
 */
export async function adminGetTransferableUsers(guid) {
  const responseData = await makeEndpointRequest(
    usersAPI.getTransferableUsers,
    [guid],
  );

  return responseData.data;
}

/**
 * Gets all members within the current user's
 * subscription.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @returns {UsersAPI~User}
 */
export async function adminGetUser(guid) {
  const { data } = await makeEndpointRequest(usersAPI.getUser, [guid]);

  return data;
}

/**
 * @typedef {Object} AdminActions~TeamGeneralSettingsObj
 * @property {string} company_name
 * @property {string} custom_tagline
 * @property {number} industry_id
 * @property {string} custom_logo_file_path
 */

/**
 * Gets the general settings for the user's team.
 *
 * @async
 * @throws if request is not successful
 * @returns {Promise<AdminActions~TeamGeneralSettingsObj>}
 */
export async function adminGetTeamGeneralSettings() {
  const { data } = await makeEndpointRequest(teamsAPI.getTeamGeneralSettings);

  return data;
}

/**
 * @typedef {Object} AdminActions~TeamSecuritySettingsObj
 * @property {string} ssoEndpoint
 * @property {string} idpCert
 * @property {string} idpEntity
 * @property {boolean} optionAdminLoginPw
 * @property {boolean} optionJit
 * @property {boolean} optionAllowOverprovision
 * @property {boolean} optionForceSsoAuthentication
 * @property {boolean} multifactorAuthSms
 * @property {boolean} multifactorAuthApp
 */

/**
 * Gets the security settings for the user's team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AdminActions~TeamSecuritySettingsObj>}
 */
export async function adminGetTeamSecuritySettings() {
  const { data } = await makeEndpointRequest(teamsAPI.getTeamSecuritySettings);

  return data;
}

/**
 * @typedef {Object} AdminActions~TeamSignatureRequestSettingsObj
 * @property {boolean} customSigningRedirectEnabled
 * @property {string} customSigningRedirectUrl
 * @property {string} dateFormat
 * @property {string} everyoneHasSignedEmail
 * @property {boolean} isSignatureRemindersEnabled
 * @property {string} lockedSelfSignMessage
 * @property {string} lockedSelfSignTitle
 * @property {string} lockedSignatureRequestMessage
 * @property {string} lockedSignatureRequestTitle
 * @property {string} requestEmailFrom
 * @property {string} requestEmailSignature
 * @property {boolean} shouldEnableInPersonSigning
 * @property {boolean} shouldEnableTamperProof
 * @property {boolean} shouldOfferSignerAccessCode
 * @property {boolean} shouldOfferSignerSMSAuthentication
 * @property {boolean} qualifiedEsignatureEnabled
 * @property {boolean} smsSignatureDelivery
 * @property {string} tzCode
 */

/**
 * @typedef {Object} AdminActions~TeamDocumentsTemplatesSettingsObj
 * @property {boolean} lockTeamTemplateCreation
 * @property {boolean} enableTemplateMigrationTool
 */

/**
 * Gets the signature request settings for the user's team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AdminActions~TeamSignatureRequestSettingsObj>}
 */
export async function adminGetTeamSignatureRequestSettings() {
  const { data } = await makeEndpointRequest(
    teamsAPI.getTeamSignatureRequestSettings,
  );

  return data;
}

/**
 * @typedef {Object} AdminActions~TeamSyncingSharingSettingsObj
 * TODO
 */

/**
 * Gets the syncing and sharing settings for the user's
 * team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AdminActions~TeamSyncingSharingSettingsObj>}
 */
export async function adminGetTeamSyncingSharingSettings() {
  const { data } = await makeEndpointRequest(
    teamsAPI.getTeamSyncingSharingSettings,
  );

  return data;
}

/**
 * @typedef {Object} AdminActions~TeamDocumentsTemplatesSettingsObj
 * @property {boolean} lockTeamTemplateCreation
 * @property {boolean} enableTemplateMigrationTool
 */

/**
 * Gets the documents and template settings for the user's team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AdminActions~TeamDocumentsTemplatesSettingsObj>}
 */
export async function adminGetTeamDocumentsTemplatesSettings() {
  const { data } = await makeEndpointRequest(
    teamsAPI.getTeamDocumentsTemplatesSettings,
  );

  return data;
}

/**
 * @typedef {Object} AdminActions~TeamCloudSyncSettingsObj
 */

/**
 * Gets the cloud sync settings for the user's team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AdminActions~TeamCloudSyncSettingsObj>}
 */
export async function adminGetTeamCloudSyncSettings() {
  const { data } = await makeEndpointRequest(teamsAPI.getTeamCloudSyncSettings);

  return data;
}

/**
 * @typedef {Object} AdminActions~TeamDeleteDocumentsPolicySettingsObj
 */

/**
 * Gets the delete documents policy settings for the user's team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AdminActions~TeamDeleteDocumentsPolicySettingsObj>}
 */
export async function adminGetTeamDeleteDocumentsPolicySettings() {
  const { data } = await makeEndpointRequest(
    teamsAPI.getTeamDeleteDocumentsPolicySettings,
  );

  return data;
}

/**
 * @typedef {Object} AdminActions~SuperGroupCount
 * @property {number} total_num_super_groups
 */

/**
 * Gets the cloud sync settings for the user's team.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AdminActions~SuperGroupCount>}
 */
export async function adminOneTimeDeleteCompletedDocuments(csrfToken) {
  const { data } = await makeEndpointRequest(
    teamsAPI.oneTimeDeleteCompletedDocuments,
    [csrfToken],
  );

  return data;
}

/**
 * @typedef {Object} AdminActions~ListInvitesObj
 * @param {usersAPI~Invite[]} invites
 * @param {number} numInvites
 * @param {number} numPerPage
 * @param {number} pageSize
 */

/**
 * Gets a paginated list of invited users within the
 * user's subscription.
 *
 * @async
 * @throws if request is not successful.
 * @param {number} [page=1]
 * @param {number} [pageSize=50]
 * @param {string} [team]
 * @param {boolean} [recursive]
 * @returns {Promise<AdminActions~ListInvitesObj>}
 */
export async function adminListInvites(
  page = 1,
  pageSize = 50,
  team,
  recursive,
) {
  const responseData = await makeEndpointRequest(usersAPI.listInvites, [
    page,
    pageSize,
    team,
    recursive,
  ]);

  return {
    invites: responseData.data.invites,
    pageSize: responseData.pageSize,
    numPerPage: responseData.numPerPage,
    numInvites: responseData.data.numInvites,
    numInvitesRemaining: responseData.data.numInvitesRemaining,
    billingSchema: responseData.billingSchema,
  };
}

/**
 * @typedef {Object} AdminActions~ListTeamsObj
 * @param {TeamsAPI~Team[]} teams
 * @param {number} numTeams
 * @param {number} numPerPage
 * @param {number} pageSize
 */

/**
 * Gets all subteams within the current user's
 * subscription.
 *
 * @async
 * @throws if request is not successful.
 * @param {number} [page=1]
 * @param {number} [pageSize=50]
 * @param {string} [team]
 * @returns {Promise<AdminActions~ListTeamsObj>}
 */
export async function adminListSubteams(page = 1, pageSize = 50, team) {
  const responseData = await makeEndpointRequest(teamsAPI.listSubteams, [
    page,
    pageSize,
    team,
  ]);

  return {
    teams: responseData.data.teams,
    pageSize: responseData.pageSize,
    numPerPage: responseData.numPerPage,
    numTeams: responseData.data.numTeams,
    billingSchema: responseData.billingSchema,
  };
}

/**
 * Gets all teams, including the root team, within the
 * current user's subscription.
 *
 * @async
 * @throws if request is not successfull.
 * @param {number} [page=1]
 * @param {number} [pageSize=50]
 * @param {string} [team]
 * @returns {Promise<AdminActions~ListTeamsObj>}
 */
export async function adminListTeams(page = 1, pageSize = 50, team) {
  const responseData = await makeEndpointRequest(teamsAPI.listTeams, [
    page,
    pageSize,
    team,
  ]);

  return {
    teams: responseData.data.teams,
    pageSize: responseData.pageSize,
    numPages: responseData.numPages,
    numPerPage: responseData.numPerPage,
    numTeams: responseData.data.numTeams,
    billingSchema: responseData.billingSchema,
  };
}

/**
 * Gets a list of all teams within a user's subscription.
 *
 * @async
 * @throws if request is not successful.
 * @returns {TeamsAPI~Team[]}
 */
export async function adminGetAllTeams() {
  return getAllEntities(async (page) => {
    const { teams, numPages } = await adminListTeams(page, 200);

    return [teams, numPages];
  });
}

/**
 * @typedef {Object} AdminActions~PaginatedObj
 * @property {number} pageSize
 * @property {number} numPerPage
 */

/**
 * @typedef {AdminActions~PaginatedObj} AdminActions~ListUsers
 * @param {number} numUsers
 * @param {UsersAPI~User[]} users
 */

/**
 * Gets all members within the current user's
 * subscription, or within a team with the given GUID if
 * specified.
 *
 * @async
 * @throws if request is not successful.
 * @param {number} [page=1]
 * @param {number} [pageSize=50]
 * @param {?string} [team=null]
 * @param {?boolean} [recursive=null]
 * @param {?string} [q='']
 * @param billingSchema
 * @returns {Promise<AdminActions~ListUsers>}
 */
export async function adminListUsers(
  page = 1,
  pageSize = 50,
  team = null,
  recursive = null,
  q = '',
  billingSchema = null,
) {
  const responseData = await makeEndpointRequest(usersAPI.listUsers, [
    page,
    pageSize,
    team,
    recursive,
    // Only send q if its length > 0
    ...(q.length ? [q] : []),
    billingSchema,
  ]);

  return {
    users: responseData.data.users,
    pageSize: responseData.pageSize,
    numPages: responseData.numPages,
    numPerPage: responseData.numPerPage,
    numUsers: responseData.data.numUsers,
    billingSchema: responseData.billingSchema,
  };
}

/**
 * Gets values to populate AccountInformation component
 * for logged in user.
 *
 * @async
 * @throws if request is not successful.
 * @returns {Promise<AccountInfo>}
 */
export async function adminGetAccountInformation() {
  const responseData = await makeEndpointRequest(
    usersAPI.getAccountInformation,
  );
  return responseData.data;
}

/**
 * Gets all users within the given team.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} team
 * @returns {UsersAPI~User[]}
 */
export async function adminGetAllUsersWithinTeam(team) {
  return getAllEntities(async (page) => {
    const { users, numPages } = await adminListUsers(page, 20, team);

    return [users, numPages];
  });
}

/**
 * Gets reports for pretty charts
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.metric_type]
 * @returns {void}
 */
export async function adminGetGraphReport(csrfToken, opts) {
  const responseData = await makeEndpointRequest(teamsAPI.getGraphReport, [
    csrfToken,
    opts,
  ]);
  return responseData.data;
}

/**
 * @typedef {AdminActions~PaginatedObj} AdminActions~ListUserTemplates
 * @param {number} numTemplates
 * @param {UsersAPI~Template[]} templates
 */

/**
 * Gets all templates available to the user (owned or
 * shared).
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @param {number} [page=1]
 * @param {number} [pageSize=50]
 * @returns {Promise<AdminActions~ListUserTemplates>}
 */
export async function adminListUserTemplates(guid, page = 1, pageSize = 50) {
  const responseData = await makeEndpointRequest(usersAPI.listUserTemplates, [
    guid,
    page,
    pageSize,
  ]);

  return {
    templates: responseData.data.templates,
    pageSize: responseData.pageSize,
    numPerPage: responseData.numPerPage,
    numTemplates: responseData.data.numTemplates,
  };
}

/**
 * Locks the user account with the given GUID.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {void}
 */
export async function adminLockUser(guid, csrfToken) {
  await makeEndpointRequest(usersAPI.lockUser, [guid, csrfToken]);
}

/**
 * Removes a user from the org and either converts them
 * to a free user or deletes the account entirely.
 *
 * @param {string} guid
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {number} [opts.delete]
 * @param {string} [opts.recipientGuid]
 */
export async function adminRemoveUser(guid, csrfToken, opts) {
  await makeEndpointRequest(usersAPI.removeUser, [guid, csrfToken, opts]);
}

/**
 * Resets the password for the user account with the
 * given GUID.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {void}
 */
export async function adminResetPassword(guid, csrfToken) {
  await makeEndpointRequest(usersAPI.resetPassword, [guid, csrfToken]);
}

/**
 * Initiates a compliance and/or usage report for a subscription.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {number} [opts.compliance]
 * @param {number} [opts.usage]
 * @returns {void}
 */
export async function adminRunReport(csrfToken, opts) {
  await makeEndpointRequest(teamsAPI.runReport, [csrfToken, opts]);
}

/**
 * Invite multiple users to the subscription.
 *
 * @async
 * @throws if request is not successful.
 * @param {string[]} emailAddresses
 * @param {string} csrfToken
 * @param {Object} [opts]
 * @param {string} [opts.role]
 * @param {string} [opts.teamGuid]
 * @param {number} [opts.confirmPaidSeatIncrease]
 * @returns {void}
 */
export async function adminSendMultipleInvites(
  emailAddresses,
  csrfToken,
  opts = {},
) {
  return makeEndpointRequest(usersAPI.sendMultiUserInvite, [
    emailAddresses.join(','),
    csrfToken,
    opts,
  ]);
}

/**
 * Unlocks the user account with the given GUID.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} guid
 * @param {string} csrfToken
 * @returns {void}
 */
export async function adminUnlockUser(guid, csrfToken) {
  await makeEndpointRequest(usersAPI.unlockUser, [guid, csrfToken]);
}

/**
 * Updates team general settings.
 *
 * NOTE: Delete custom logo by passing '' as `custom_logo_file_path`.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {AdminActions~TeamGeneralSettingsObj} opts
 * @returns {void}
 */
export async function adminUpdateDocumentsTemplatesRequestSettings(
  csrfToken,
  opts,
) {
  await makeEndpointRequest(teamsAPI.updateTeamSettings, [csrfToken, opts]);
}

/**
 * Updates team cloud sync settings.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {AdminActions~TeamCloudSyncSettingsObj} opts
 * @returns {Object}
 */
export async function adminUpdateTeamCloudSyncSettings(csrfToken, opts) {
  return makeEndpointRequest(teamsAPI.updateAccountSettings, [csrfToken, opts]);
}

/**
 * Updates team delete documents policy settings.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {AdminActions~TeamDeleteDocumentsPolicySettingsObj} opts
 * @returns {Object}
 */
export async function adminUpdateTeamDeleteDocumentsPolicySettings(
  csrfToken,
  opts,
) {
  return makeEndpointRequest(teamsAPI.updateOngoingDeleteTeamSetting, [
    csrfToken,
    opts,
  ]);
}

/**
 * Updates team general settings.
 *
 * NOTE: Delete custom logo by passing '' as `custom_logo_file_path`.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {AdminActions~TeamGeneralSettingsObj} opts
 * @returns {Object}
 */
export async function adminUpdateTeamGeneralSettings(csrfToken, opts) {
  return makeEndpointRequest(teamsAPI.updateTeamGeneralSettings, [
    csrfToken,
    opts,
  ]);
}

/**
 * Updates team security general settings.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {AdminActions~TeamSecuritySettingsObj} opts
 * @returns {void}
 */
export async function adminUpdateTeamSecuritySettings(csrfToken, opts) {
  await makeEndpointRequest(teamsAPI.updateTeamSettings, [csrfToken, opts]);
}

/**
 * Updates team general settings.
 *
 * NOTE: Delete custom logo by passing '' as `custom_logo_file_path`.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {AdminActions~TeamSignatureRequestSettingsObj} opts
 * @returns {Object}
 */
export async function adminUpdateTeamSignatureRequestSettings(csrfToken, opts) {
  return makeEndpointRequest(teamsAPI.updateTeamSettings, [csrfToken, opts]);
}

/**
 * Updates team syncing and sharing settings.
 *
 * @async
 * @throws if request is not successful.
 * @param {string} csrfToken
 * @param {AdminActions~TeamSyncingSharingSettingsObj} opts
 * @returns {void}
 */
export async function adminUpdateTeamSyncingSharingSettings(csrfToken, opts) {
  await makeEndpointRequest(teamsAPI.updateTeamSettings, [csrfToken, opts]);
}
