import React from 'react';

const hasIntersectionObserver = typeof IntersectionObserver === 'function';

export function useViewportObserver(enabled: boolean) {
  const [intersecting, setIntersecting] = React.useState<Element[]>([]);

  const [observer] = React.useState(() => {
    if (!hasIntersectionObserver || !enabled) {
      return null;
    }

    return new IntersectionObserver(
      ([entry]) => {
        setIntersecting((items) => {
          if (entry.isIntersecting) {
            return items.concat(entry.target);
          } else {
            return items.filter((item) => item !== entry.target);
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // The callback will run when at least 10% of the target is visible
      },
    );
  });
  const observe = React.useMemo(
    () => observer?.observe.bind(observer),
    [observer],
  );
  const unobserve = React.useMemo(
    () => observer?.unobserve.bind(observer),
    [observer],
  );

  // isIntersecting will change identities when the intersecting array changes
  const isIntersecting = React.useCallback(
    (element: HTMLElement) => {
      if (typeof observe === 'undefined') {
        return true;
      }
      return intersecting.includes(element);
    },
    [intersecting, observe],
  );

  // Observer will never change identity, so this will only change when
  // isIntersecting changes
  return React.useMemo(() => {
    return {
      isIntersecting,
      observe,
      unobserve,
    };
  }, [isIntersecting, observe, unobserve]);
}

export type SignViewportObserver = ReturnType<typeof useViewportObserver>;

export function useElementObserver(
  observer: SignViewportObserver | undefined,
  ref: React.RefObject<HTMLElement>,
) {
  // observer's identity changes regularly, but observe and unobserve should be stable, so we
  // don't want to re-trigger the effect hook when `observer` or `isIntersecting` change.
  const { isIntersecting, observe, unobserve } = observer ?? {};
  const { current } = ref;
  const [renderCount, setRenderCount] = React.useState(0);

  React.useEffect(() => {
    if (current) {
      observe?.(current);
    }
    if (renderCount === 0) {
      // If we don't have an element yet, force a single update to try to get
      // one+
      setRenderCount(1);
    }
    // Clean up the observer when the component unmounts
    return () => {
      if (current) {
        unobserve?.(current);
      }
    };
  }, [current, observe, renderCount, unobserve]);

  const val = React.useMemo(() => {
    // Assume it's on screen if we didn't get an observer
    if (
      typeof observe === 'undefined' ||
      typeof isIntersecting === 'undefined'
    ) {
      return !!current;
    }

    return current && isIntersecting(current);
  }, [current, isIntersecting, observe]);

  return val;
}
