/* eslint-disable camelcase */
import hsFetch from 'js/sign-components/common/hs-fetch';

export type Level = {
  id: string;
  name: string;
  size: number;
  rate_monthly: number;
  rate_yearly: number;
  rate_monthly_discounted: number | null;
  rate_yearly_discounted: number | null;
};

export type Plan = {
  id: string;
  name: string;
  is_api: boolean;
  is_enterprise: boolean;
  is_team_plan: boolean;
  is_seat_plan: boolean;
  rate_monthly: number;
  rate_yearly: number;
  rate_monthly_discounted: number | null;
  rate_yearly_discounted: number | null;
  num_free_days: number;
  num_seats_min: number | null;
  num_seats_max: number | null;
  tier_num_seats_min: number | null;
  tier_num_seats_max: number | null;
  num_templates: number | null;
  num_sig_requests: number | null;
  billing_scheme: 'S' | 'T' | null;
  plans?: Level[];
  tier: string | null;
  features: {
    audit_trail?: boolean;
    data_validation?: boolean;
    template_links?: boolean;
    branding?: boolean;
    advanced_signer_fields?: boolean;
    reporting?: boolean;
    signer_attachments?: boolean;
    bulk_send?: boolean;
    embedded_signing?: boolean;
    forms?: boolean;
  };
};

type PaymentBody = {
  _csrf_token: string;
  target_plan: Plan['id'];
  stripe_token?: string;
  cc_zip?: string;
  cc_country?: string;
  tax_id?: string;
  period: string;
  num_seats?: number; // UI plans only
  plan_level?: Level['id']; // API plans only
  currency?: string;
  register_fax_line?: {
    country: string | null;
    area_code: string | null;
    state?: string | null | undefined;
    ca_province?: string | null | undefined;
  };
};

export type SubmitPaymentData = {
  quoteId: string | null;
  currentPlan: string;
  cardHolderName: string;
  payment: PaymentBody;
  integration: string;
  is_business_purposes: boolean;
  business_name?: string;
  receipt_address?: string;
  dbx_payment_method_id?: string;
  ack_auto_renewal?: boolean;
  direct_purchase?: boolean;
  checkoutFormFillDurationMs?: number;
};

export type SubmitPaymentResponse = {
  success: boolean;
  should_route_to_api_setting: boolean;
  integration_redirect_url: string;
  should_route_to_prep_and_send: boolean;
  errors?: string[];
};

export type GetTaxRateResponse = {
  success: boolean;
  tax_rate: number;
  tax_amount: number;
  tax_next_billing_amount: number;
  stripe_error_code?: string;
};

export type GetPlanDataResponse = {
  plan: Plan;
  currencyCode: string;
  currencySymbol: string;
  currencyDecimals: number;
};

export const submitPayment = async (
  data: SubmitPaymentData,
): Promise<SubmitPaymentResponse> => {
  const response = await hsFetch('/info/submitPayment', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

export const getTaxRate = async (
  data: FormData,
): Promise<GetTaxRateResponse> => {
  const response = await hsFetch('/endpoint/account/getTaxRate', {
    method: 'POST',
    body: data,
  });

  return response.json();
};

export const getPlanData = async (
  data: FormData,
): Promise<GetPlanDataResponse> => {
  const response = await hsFetch('/endpoint/account/getUpdatedPlanData', {
    method: 'POST',
    body: data,
  });

  return response.json();
};
