import {
  FeatureFlags,
  FeatureFlagWithExperiment,
} from 'js/sign-components/common/feature-flags';

export function checkFeatureFlag(
  name: keyof FeatureFlags,
  featureFlags: FeatureFlags,
): boolean {
  return Boolean(featureFlags[name]);
}

export function hasFeatureFlagFactory(featureFlags: FeatureFlags) {
  return function hasFeatureFlag(name: keyof FeatureFlags): boolean {
    return checkFeatureFlag(name, featureFlags);
  };
}

function isFeatureFlagWithExperiment(obj: any): boolean {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.status === 'boolean' &&
    typeof obj.inExperiment === 'boolean' &&
    'values' in obj &&
    (obj.variationId === undefined || typeof obj.variationId === 'number') &&
    (obj.key === undefined || typeof obj.key === 'string')
  );
}

export function getFeatureFlagWithExperiment(
  name: keyof FeatureFlags,
  featureFlags: FeatureFlags,
): FeatureFlagWithExperiment | null {
  const t: boolean | FeatureFlagWithExperiment | undefined = featureFlags[name];
  if (!isFeatureFlagWithExperiment(t)) {
    return null;
  }
  return t as FeatureFlagWithExperiment;
}
