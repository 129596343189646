/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import './index.scss';

import React from 'react';
import createReactClass from 'create-react-class';

const Header = createReactClass({
  /**
   */

  getInitialState() {
    return {
      className: this.props.className || '',
    };
  },

  /**
   */

  render() {
    return (
      <div className={`m-signer-mobile-header ${this.state.className}`}>
        {this.props.children}
      </div>
    );
  },
});

module.exports = Header;
