import * as React from 'react';

/**
 * This might not be an exhaustive list, but it matches everything we
 * currently have
 */
type BasicMediaQuery =
  | `(max-width: ${number}px)`
  | `(min-width: ${number}px)`
  | `(orientation: ${'landscape' | 'portrait'})`
  | `(min-aspect-ratio: ${number}/${number})`;

type CompoundQueries<T extends string> = `${T}, ${T}` | `${T} and ${T}`;

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 */
type MediaQuery =
  // This can be just a basic query
  | BasicMediaQuery
  // or a compound query
  /**
   * TypeScript didn't like making this recursive, so my workaround is to just
   * go 2 levels deep here.
   */
  | CompoundQueries<
      // Each side can either be a BasicMediaQuery
      // or a compound containing only basic queries.
      BasicMediaQuery | CompoundQueries<BasicMediaQuery>
    >;

export function useMatchMedia(query: MediaQuery) {
  const [matches, setMatches] = React.useState(false);
  React.useLayoutEffect(() => {
    // https://caniuse.com/matchmedia
    const mq = window.matchMedia?.(query);

    const onChange = () => {
      setMatches(mq.matches);
    };

    if (!mq || !mq.addEventListener || !mq.removeEventListener) {
      return;
    }

    // Safari <=13 doesn't have addEventListener.
    // istanbul ignore next
    if (mq.addEventListener == null) {
      mq.addListener(onChange);
      onChange();
      return () => {
        mq.removeListener(onChange);
      };
    }

    mq.addEventListener('change', onChange);
    onChange();
    return () => {
      mq.removeEventListener('change', onChange);
    };
  }, [query]);

  return matches;
}
export const useIsMobile = () => useMatchMedia('(max-width: 480px)');
