/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  react/prop-types
*/
/**
 *   FixedModal
 *   Intented to be a drop in replacement for Foundation/Fancybox. Use this instead.
 * */

import './index.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modal';
import CheckPlatform from 'common/utils/check-platform';

class FixedModal extends Component {
  static propTypes = {
    width: PropTypes.number,
    hideOnOverlayClick: PropTypes.bool,
    onOverlayClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = { hide: false };
    this.onOverlayClick = this.onOverlayClick.bind(this);
  }

  onOverlayClick(e) {
    const overlayClass = 'm-common-modal'; // From the inner modal
    const overlayClicked = e.target.classList.contains(overlayClass);
    if (this.props.hideOnOverlayClick && overlayClicked) {
      // TODO this should trigger on ESC keypress too
      this.setState({
        hide: true,
      });
      this.props.onOverlayClick?.();
    }
  }

  render() {
    let style;
    if (this.state.hide === true) {
      style = { display: 'none' };
    }

    const modalStyle = this.props.modalStyle || {};

    let width = this.props.width;
    let fixedModalClass = 'm-fixed-modal';
    if (CheckPlatform() === 'mobile') {
      width = '100%';
      fixedModalClass += ' mobile';
    }

    return (
      <div
        className={fixedModalClass}
        style={style}
        onClick={this.onOverlayClick}
      >
        {/* This is not a DIG modal. */}
        {/* eslint-disable-next-line dropbox-sign/dig-modal-aria-labelledby */}
        <Modal
          {...this.props}
          width={width}
          style={modalStyle}
          className={this.props.modalClassName}
        >
          {this.props.children}
        </Modal>
      </div>
    );
  }
}

module.exports = FixedModal;
