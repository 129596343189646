/* eslint-disable no-console */
/* eslint-disable camelcase */

type Notification = {
  data: {
    class_name: string;
    message: string;
  };
};

const Base64 = {
  encode(str: string): string {
    return Buffer.from(str).toString('base64');
  },
  decode(str: string): string {
    return Buffer.from(str, 'base64').toString('utf8');
  },
};

export default class NotificationHandler {
  notification_element: HTMLElement | null;

  encodedNotification: string[] = [];

  notifications: Notification[];

  constructor(element?: HTMLElement) {
    this.notification_element =
      element || document.getElementById('notification_content');
    this.notifications = this._parseNotifications() || [];
  }

  removeNotifications() {
    if (!this.notification_element) {
      return false;
    }

    this.notifications.forEach((notification) => {
      // fancybox notification are handled in JQuery
      if (notification.data.class_name !== 'fancybox') {
        this.removeNotification(notification);
      }
    });

    this.notification_element.innerHTML = JSON.stringify(
      this.encodedNotification,
    );
    return true;
  }

  removeNotification(notification: Notification) {
    const index = this.encodedNotification.indexOf(
      Base64.encode(JSON.stringify(notification.data)),
    );
    if (index >= 0) {
      this.encodedNotification.splice(index, 1);
    }
  }

  /**
      "Private" Methods
   */

  _parseNotifications() {
    try {
      // eslint-disable-next-line no-nested-ternary
      this.encodedNotification = this.notification_element
        ? this.notification_element.innerHTML.trim()
          ? JSON.parse(this.notification_element.innerHTML)
          : []
        : [];

      return this.encodedNotification
        .map(this._parseNotification.bind(this))
        .filter((n): n is Notification => typeof n !== 'undefined')
        .filter((notification) => notification.data.class_name !== 'fancybox'); // Filter out fancybox which is handled by jquery
    } catch (e) {
      console.error('Could not parse notification', e);
      return false;
    }
  }

  _parseNotification(notification: string): Notification | undefined {
    try {
      return { data: JSON.parse(Base64.decode(notification)) };
    } catch (e) {
      console.error('Could not parse notification data', e);
      return undefined;
    }
  }
}
