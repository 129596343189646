import 'libphonenumber-wrapper';
import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';
import * as yup from 'yup';
import { BANNER } from 'hellospa/components/prep-and-send/formik-utils';
import { getIn } from 'formik';
import * as uuid from 'uuid';
import { getFormattedPhoneOptions } from 'libphonenumber-wrapper/utils';

declare module 'yup' {
  interface ArraySchema<T> {
    unique(paths: Array<keyof T>): ArraySchema<T>;
    removeEmpty(
      fields: Array<keyof T>,
      options?: { alwaysFilter?: boolean },
    ): ArraySchema<T>;
  }

  interface StringSchema {
    sequence(fields: Array<Function>): StringSchema<string>;
  }
}

const messages = defineMessages({
  errorPhoneNumberInvalid: {
    id: '',
    description: 'Error message for invalid phone number',
    defaultMessage: 'Number is invalid',
  },
  errorCountryNotSupported: {
    id: '',
    description: 'Error message for unsupported country code',
    defaultMessage: 'Numbers from {country} are not supported',
  },
  errorEmailInvalid: {
    id: '',
    description: 'Error message for invalid email',
    defaultMessage: 'Email format is invalid',
  },
  errorNotUnique: {
    id: '',
    description: 'Error message for non-uniqueness',
    defaultMessage: '{label} must be unique',
  },
});

// We use this regex in BackEnd
const EMAIL_REGX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

// Yup does not validate email correctly for e.g testing@dropbox.co0m
// override email validation method
yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return yup.string().matches(EMAIL_REGX, {
    message: message || intl.formatMessage(messages.errorEmailInvalid),
    excludeEmptyString: true,
  });
});

const getPhoneOptions = (value: string) => {
  return getFormattedPhoneOptions(value);
};

export function phoneNumber(): yup.StringSchema<string> {
  return yup
    .string()
    .test(
      'phone-number',
      intl.formatMessage(messages.errorPhoneNumberInvalid),
      function test(value: string) {
        if (value) {
          const opts = getPhoneOptions(value);
          return opts.length > 0;
        }
        // I'm considering an empty value as valid so that .required() complains
        // instead.
        return true;
      },
    )
    .transform((value) => {
      if (value) {
        const opts = getPhoneOptions(value);
        if (opts.length) {
          return opts[0].number;
        }
      }
      return value;
    });
}

type Values<T> = T[keyof T];

export type InferUnion<
  T extends Record<string | number | symbol, yup.Schema<unknown>>,
> = Values<{ [P in keyof T]: yup.InferType<T[P]> }>;

export function lazyUnion<T extends Object, K extends keyof T>(
  key: K,
  schemas: T[K] extends string | number | symbol
    ? Record<T[K], yup.Schema<T>>
    : never,
): (typeof schemas)[T[K]] {
  // @ts-ignore lazyUnion is lying about the return type. At runtime we're using
  // Lazy, but it will resolve to one of the schemas provided. So for the type
  // system I'm just claiming we return one of those schemas.
  return yup.lazy<T>((object: T) => {
    const kind = object[key];
    if (schemas[kind]) {
      return schemas[kind];
    }

    // @ts-ignore
    // Type 'ObjectSchema<{ [x: string]: ...; }>' is not assignable to type 'Schema<T>'
    //
    // I think TS can't verify that type T will have a `[key]`. I think that
    // should be fine, because this is the schema to use if we couldn't find a
    // proper schema.
    return yup.object({
      [key]: yup.string().oneOf(Object.keys(schemas)).required(),
    });
  });
}

/* eslint-disable func-names */

/**
 * Cycles through an array of objects to find duplicate values returned from mapper()
 *
 * paths: List properties to join to make a unique identifier for each item.
 *        Errors will be attached to ALL paths and a BANNER (constant) key for use
 *        with <BannerErrorMessage. Whatever value is found at each path is cast to a
 *        string, trimmed, and lowercased.
 *
 * Must use function() vs () => {} to get proper `this` context
 */
yup.addMethod(yup.array, 'unique', function (paths: string[]) {
  return this.test(
    'unique',
    ({ label }) => intl.formatMessage(messages.errorNotUnique, { label }),
    function (list: string[]) {
      // v is usually a string, but it can be anything.
      const normalize = (v: unknown) => String(v).trim().toLowerCase();
      const mapper = (attachment: unknown) =>
        paths
          .map((path) => normalize(getIn(attachment, path, uuid.v4())))
          .join('|');

      const values: string[] = list.map(mapper);

      const duplicates = values.reduce(
        (acc: string[], v, i, arr): string[] =>
          // If the first instance of this value is not at i, and the item isn't
          // already listed as a duplicate add it to the list
          arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc,
        [],
      );

      if (duplicates.length > 0) {
        // The outer error seems to be discarded if you push to `.inner`
        const validationError: yup.ValidationError = this.createError();

        const nestedErrors: yup.ValidationError[] = values.flatMap(
          (value, index) => {
            if (!duplicates.includes(value)) {
              return [];
            }
            return paths.map((path) =>
              this.createError({
                path: `[${this.path}.${index}.${path}]`,
              }),
            );
          },
        );

        validationError.inner.push(
          this.createError({
            path: `[${this.path}.${BANNER}]`,
          }),
        );
        validationError.inner.push(...nestedErrors);
        return validationError;
      }
      return true;
    },
  );
});

/**
 * Filters a list of items to only include
 * items where at least one of the given fields
 * is not empty. If all the given fields in an item
 * are empty, the item is filtered out.
 * If the post-filter result is empty and less than
 * the length of the original value, the filter is not applied.
 */
yup.addMethod<yup.ArraySchema<object>>(yup.array, 'removeEmpty', function <
  T extends object,
>(this: yup.ArraySchema<T>, fields: Array<keyof T>, options: { alwaysFilter?: boolean } = {}) {
  const filterEmpty = (item: T, fields: Array<keyof T>): boolean => {
    const remove: { [key in keyof T]?: boolean } = {};
    fields.forEach((field) => {
      if (
        field in item &&
        (item[field] === null ||
          item[field] === undefined ||
          // @ts-ignore TS doesn't believe item[field] has a .length property
          item[field].length === 0)
      ) {
        remove[field] = true;
      }
    });
    if (Object.entries(remove).length === fields.length) {
      return false;
    }
    return true;
  };
  return this.transform((value: Array<any>) => {
    const newValue = value.filter((item) => filterEmpty(item, fields));
    if (options.alwaysFilter) {
      return newValue;
    }

    if (newValue.length === 0 && newValue.length < value.length) {
      return value;
    }
    return newValue;
  });
});

/* eslint-enable func-names, no-template-curly-in-string */

yup.addMethod(yup.string, 'sequence', function makeSequence(functionList) {
  return this.test(
    'sequence',
    'Failed Sequence',
    async function sequence(value: string) {
      try {
        for (const func of functionList) {
          // eslint-disable-next-line no-await-in-loop
          await func(this).validate(value);
        }
      } catch (error: any) {
        return this.createError({
          message: error.message,
        });
      }
      return true;
    },
  );
});
