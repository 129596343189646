/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
import BaseCollection from 'common/models/base/collection';

module.exports = BaseCollection.extend({
  idProperty: 'id',
  createModel(props) {
    props.notifier = this.notifier;
    props.fieldClasses = this.fieldClasses;
    props.page = this.page;
    props.signatureRequest = this.signatureRequest;
    props.snapshot = this.snapshot;
    props.snapshots = this.snapshots;
    const clazz = this.fieldClasses[props.data.type] || this.fieldClasses.def;
    return clazz.create(props);
  },
});
