/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import './modal.scss';

import React from 'react';
import { injectIntl } from 'react-intl';
import createReactClass from 'create-react-class';

const Modal = createReactClass({
  render() {
    let className = 'm-common-modal--center-content';
    let style = {};
    style.backgroundColor = 'white'; // Default
    // Merge with supplied style props
    style = Object.assign(style, this.props.style);

    if (this.props.className) {
      className += ` ${this.props.className}`;
    }

    if (this.props.width) {
      style.width = this.props.width;
    }

    return (
      <div className="m-common-modal">
        <div role="dialog" style={style} className={className}>
          {this.props.children}
        </div>
      </div>
    );
  },
});

module.exports = injectIntl(Modal);
