import React from 'react';
import { useContextCacher } from 'signer-app/utils/legacy-context-utilities';
import { Field, FieldTypes } from 'signer-app/types/editor-types';

import { PREPARER } from 'signer-app/signature-request/constants';
import Signature from 'signer-app/signature-request/display-field/signature';
import Checkbox from 'signer-app/signature-request/display-field/checkbox';
import Dropdown, {
  Props as DropdownProps,
} from 'signer-app/signature-request/display-field/dropdown';
import Radio, {
  Props as RadioProps,
} from 'signer-app/signature-request/display-field/radio';
import DateField from 'signer-app/signature-request/display-field/date';
import Text from 'signer-app/signature-request/display-field/text';
import Hyperlink from 'signer-app/signature-request/display-field/hyperlink';
import Rectangle from 'signer-app/signature-request/display-field/rectangle';
import Image from 'signer-app/signature-request/display-field/image';
import { useSignatureRequestContext } from 'signer-app/signature-request/context';

const NOOP = () => {};

const unreachable = (_t: never) => {};

export type WrapperStyles = React.CSSProperties & {
  height: string;
  width: string;
};

type FieldContext = {
  fieldData: Field;
  wrapperStyles: WrapperStyles;
  height: string;
  width: string;
};

type ProviderProps = React.PropsWithChildren<{
  fieldData: Field;
  wrapperStyles: WrapperStyles;
}>;

const fieldContext = React.createContext<FieldContext>({} as FieldContext);

export function FieldContextProvider({
  fieldData,
  wrapperStyles,
  children,
}: ProviderProps) {
  const contextCache = useContextCacher<FieldContext>();
  return (
    <fieldContext.Provider
      value={contextCache({
        fieldData,
        height: wrapperStyles.height,
        width: wrapperStyles.width,
        wrapperStyles,
      })}
    >
      {children}
    </fieldContext.Provider>
  );
}

// This is a SUPER awkward API and I wish I hadn't written it this way.
// Depending on which type of Field you have, props needs to be the matching
// props for  its implementatoin from the `componentMap`.
export type Props =
  | {
      fieldData: Field;
      style?: React.CSSProperties;
      placeholder?: string;
      onKeDown?: undefined | ((event: React.KeyboardEvent) => void);
      onChange: (e: React.ChangeEvent) => void;
    }
  | Partial<RadioProps>
  | Partial<DropdownProps>;

type ImperitiveAPI = {
  focus(): void;
  // This API is forwarded from an implementation in `componentMap`. Not every
  // implementation provides this measure function.
  measure?: () => { height: number; width: number };
};

// This whole component's concept was a mistake. It got created while attempting
// to separate code that was formerly just Editor into code to be shared across
// Editor, Signer, and Overlay. Before TypeScript this used a `componentMap`
// object where the keys were field type and the values were the specific
// component implemenation. I fought with trying to get TypeScript to understand
// this and eventually had to give up. Now that object is gone and we just have
// a huge switch statement where every component gets the same props.
function DisplayField(
  props: Props & { isFormView?: boolean },
  ref: React.Ref<ImperitiveAPI>,
) {
  const { fieldData, height, width, wrapperStyles } =
    React.useContext(fieldContext);
  const { isOverlay } = useSignatureRequestContext();
  const readOnly = fieldData.readOnly || false;

  // The caller can override the field data. The Editor may use this to show a
  // draft version of the field, or to replace the `.value` with the `.name`
  // for display purposes.
  const fd = props.fieldData || fieldData;

  let propOptions: any = {
    'data-qa-ref': fd.type === 'checkbox' ? fd.type : `${fd.type}-input`,
    ...props,
  };

  // For readOnly Fields we disabled all actions
  if (readOnly) {
    // setting fieldData.require to false
    fd.required = false;

    propOptions = {
      ...propOptions,
      disabled: true,
      onKeyPress: NOOP,
      onTouchEnd: NOOP,
      onClick: NOOP,
      onChange: NOOP,
      tabIndex: -1,
    };
  }

  let fieldImpl;
  switch (fd.type) {
    case 'signature':
    case FieldTypes.Signature:
      fieldImpl = (
        <Signature
          fieldData={fd}
          {...propOptions}
          width={width}
          height={height}
          ref={ref}
        />
      );
      break;
    case 'initials':
    case FieldTypes.Initials:
      fieldImpl = (
        <Signature
          fieldData={fd}
          {...propOptions}
          width={width}
          height={height}
          ref={ref}
        />
      );
      break;
    case 'text':
    case FieldTypes.Text:
      fieldImpl = (
        <Text
          fieldData={fd}
          {...propOptions}
          isOverlay={isOverlay}
          width={width}
          height={height}
          ref={ref}
        />
      );
      break;
    case 'date':
    case FieldTypes.Date:
      if (fd.signer === PREPARER) {
        fieldImpl = (
          <Text
            fieldData={fd}
            isOverlay={isOverlay}
            {...propOptions}
            width={width}
            height={height}
            ref={ref}
          />
        );
      } else {
        fieldImpl = (
          <DateField
            fieldData={fd}
            {...propOptions}
            width={width}
            height={height}
            ref={ref}
          />
        );
      }
      break;
    case 'checkbox':
    case FieldTypes.Checkbox:
      fieldImpl = (
        <Checkbox
          fieldData={fd}
          {...propOptions}
          width={width}
          height={height}
          ref={ref}
        />
      );
      break;
    case 'dropdown':
    case FieldTypes.Dropdown:
      fieldImpl = (
        <Dropdown
          fieldData={fd}
          {...propOptions}
          width={width}
          height={height}
          ref={ref}
        />
      );
      break;
    case 'radiobutton':
    case FieldTypes.Radio:
      fieldImpl = (
        <Radio
          fieldData={fd}
          {...propOptions}
          width={width}
          height={height}
          ref={ref}
        />
      );
      break;
    case 'hyperlink':
    case FieldTypes.Hyperlink:
      fieldImpl = (
        <Hyperlink
          fieldData={fd}
          {...propOptions}
          width={width}
          height={height}
          ref={ref}
        />
      );
      break;
    case 'rectangle':
    case FieldTypes.Rectangle:
      fieldImpl = <Rectangle />;
      break;
    case 'image':
    case FieldTypes.Image:
      fieldImpl = <Image id={fd.src} ref={ref} />;
      break;
    default:
      unreachable(fd);
  }

  // Note: on the Mobile form view, fieldContext always returns height as 20px and width as 200px
  // for wrapperStyles, which messes up the positioning of the signature and initials
  // placeholders. in order to resolve this, we need to remove wrapperStyles for mobile form view
  return (
    <div style={!props.isFormView ? wrapperStyles : undefined}>{fieldImpl}</div>
  );
}

export default React.memo(React.forwardRef(DisplayField));
