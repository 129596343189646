import * as signerApi from 'signer-app/context/signer-app-client/signer-api';
import { MockUserName } from 'signer-app/signer-app-client/mock-signer-app-client';
import { simulateNetworkDelay } from 'signer-app/context/signer-app-client/simulate-network-delay';
import { SavedData } from 'signer-app/signer-signature-document/types';
import {
  checkboxes0to1,
  everyTextValidation,
  exampleSignatureRequest,
  singleZenPage,
  varietyOfFields,
  veryTallPage,
} from 'signer-app/signer-experience/story-data';

export type MockSignantureRequestId =
  | 'default'
  | 'very-tall-page'
  | 'field-types'
  | 'checkboxes-0-1'
  | 'text-field-validation';

type SignerApi = typeof signerApi;

export class SignerApiMock implements SignerApi {
  mockUsername: MockUserName;

  constructor(mockUsername: MockUserName = 'default') {
    this.mockUsername = mockUsername;
  }

  async getSignatureRequest(
    signatureRequestId: string,
  ): Promise<signerApi.SignatureRequestResponse> {
    await simulateNetworkDelay();
    let signatureRequest = { ...exampleSignatureRequest };

    const user = {
      settings: {
        firstName: 'Default',
        lastName: 'User',
      },
      primarySignatureGuid: undefined,
    };

    const isEmbedded = false;
    const canInsertEverywhere = true;
    const allowColorSignature = false;
    const defaultDateFormat = 'MM/DD/YYYY';

    switch (signatureRequestId as MockSignantureRequestId) {
      case 'very-tall-page':
        signatureRequest = { ...veryTallPage };
        break;
      case 'text-field-validation':
        signatureRequest.pages = singleZenPage;
        signatureRequest.fields = everyTextValidation.map((field) => ({
          ...field,
          documentId: signatureRequest.pages[0].documentId,
        }));
        break;

      case 'field-types':
        signatureRequest.pages = singleZenPage;
        signatureRequest.fields = varietyOfFields.map((field) => ({
          ...field,
          documentId: signatureRequest.pages[0].documentId,
        }));
        break;
      case 'checkboxes-0-1':
        signatureRequest.pages = singleZenPage;
        signatureRequest.fields = checkboxes0to1.map((field) => ({
          ...field,
          documentId: signatureRequest.pages[0].documentId,
        }));
        break;
      case 'default':
      default:
    }

    return {
      signatureRequest,
      user: {
        primarySignatureGuid: user.primarySignatureGuid,
        settings: {
          firstName: user.settings.firstName,
          lastName: user.settings.lastName,
        },
      },
      isEmbedded,
      canInsertEverywhere,
      allowColorSignature,
      defaultDateFormat,
    };
  }

  savedData: Record<string, SavedData> = {};

  async saveSignatureRequest(
    signatureRequestId: string,
    data: SavedData,
  ): Promise<void> {
    this.savedData[signatureRequestId] = data;
  }

  async decline({
    signatureRequestId,
  }: {
    signatureRequestId: string;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (IS_STORYBOOK) {
        // Timeout for visual effect in Storybook
        setTimeout(() => {
          if (signatureRequestId === 'reject') {
            reject();
          } else {
            resolve({});
          }
        }, 1000);
      } else if (signatureRequestId === 'reject') {
        reject();
      } else {
        resolve({});
      }
    });
  }
}
