/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies,
  max-len,
  no-void
*/
import extend from 'lodash/extend';
import recycle from 'common/models/utils/recycle';
import BaseModel from 'common/models/base/model';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import FaxLines from './fax-lines';
import Signatures from './signatures';
import Team from './team';

module.exports = BaseModel.extend({
  signatures: void 0,
  settings: {},

  apiEndpointAlias: 'user',

  mixins: [require('common/models/mixins/model')],

  /**
   */

  getSignatures(type) {
    if (!this.signatures) this.signatures = {};

    if (!this.signatures[type]) {
      this.signatures[type] = Signatures.create({
        type,
        notifier: this.notifier,
      });
    }

    return this.signatures[type];
  },

  /**
   *
   */

  setSignatures(type, signatures) {
    this.signatures = this.signatures || {};
    this.signatures[type] = signatures;
  },

  /**
   */

  getFirstName() {
    return this.settings.firstName || '';
  },

  /**
   */

  getLastName() {
    return this.settings.lastName || '';
  },

  /**
   */

  getFullName() {
    return HfReactHelper.trim(`${this.getFirstName()} ${this.getLastName()}`);
  },

  /**
   */

  setFullName(fullName) {
    if (fullName) {
      const parts = fullName.split(' ');
      this.settings.firstName = parts[0];
      this.settings.lastName = parts.slice(1).join(' ');
    } else {
      this.settings.firstName = null;
      this.settings.lastName = null;
    }
  },

  /**
   */

  getInitials() {
    let v = '';
    const fn = this.getFirstName();
    const ln = this.getLastName();
    if (fn) {
      v += fn[0];
    }
    if (ln) {
      v += ln[0];
    }
    return v.toUpperCase();
  },

  /**
   */

  getFaxLines() {
    if (!this._faxLines) {
      this._faxLines = FaxLines.create({
        user: this,
        notifier: this.notifier,
      });
      this._faxLines.load();
    }

    return this._faxLines;
  },

  /**
   */

  getTeam() {
    if (!this._team) {
      this._team = Team.create({
        user: this,
        notifier: this.notifier,
      });

      this._team.load();
    }

    return this._team;
  },

  /**
   */

  fromData(data) {
    return {
      emailAddress: data.emailAddress,
      features: recycle(
        this.features,
        BaseModel,
        extend({ user: this, notifier: this.notifier }, data.features),
      ),
    };
  },
});
