/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-bitwise,
  no-console,
  no-continue,
  no-restricted-syntax,
  no-useless-escape,
  no-void,
  react/prop-types
*/
import './link.scss';

import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

function _pluck(params, keys) {
  const copy = {};
  for (const key in params) {
    if (~keys.indexOf(key)) continue;
    copy[key] = params[key];
  }
  return copy;
}

// <link alias='home'>content</link>
const Link = createReactClass({
  /**
   */

  propTypes: {
    alias: PropTypes.string,
    href: PropTypes.string,
    query: PropTypes.object,
    ignoreQueryProps: PropTypes.array,
    target: PropTypes.string,
  },

  getDefaultProps() {
    return {
      target: '_self',
    };
  },

  /**
   */
  route() {
    if (this.props.app && this.props.app.router && this.props.alias) {
      this.props.app.router.redirect(this.props.alias, {
        params: this.props,
      });
    }
  },

  render() {
    let href;
    let className = this.props.className ? `${this.props.className} ` : '';
    const target = this.props.target;

    className += 'rx-link ';

    if (this.props.href) {
      href = this.props.href;
    } else {
      if (!this.props.app || !this.props.app.router) {
        console.error('must specify href or provide app/router properties.');
      }

      const router = this.props.app.router;
      const location = router.location;
      const path = router.getPath(
        this.props.alias || location.pathname || '/',
        {
          params: Object.assign({}, this.props),
          query: _pluck(
            Object.assign({}, location.query, this.props.query),
            this.props.ignoreQueryProps || [],
          ),
        },
      );

      let selected;

      if (this.props.selected != void 0) {
        selected = this.props.selected;
      } else {
        // TODO - maybe not ideal. Maybe pluck a property off the location state
        selected = !!~String(`#${location.pathname}`).search(
          new RegExp(`#${path.replace(/\?.*/, '')}(\/|$)`),
        );
      }

      // should also be selected for stuff like /app-callbacks & /app-callbacks/:guid
      className += cx({
        selected,
      });

      href = `#${path}`;
    }

    return (
      <a href={href} className={className} target={target} onClick={this.route}>
        {this.props.children}
      </a>
    );
  },
});

module.exports = Link;
