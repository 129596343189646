/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  no-void
*/
import recycle from 'common/models/utils/recycle';
import BaseModel from 'common/models/base/model';
import Field from './field';
import GroupField from './group-field';
import DateField from './date-field';
import TextField from './text-field';
import CheckboxField from './checkbox-field';
import SignatureField from './signature-field';
import Page from './page';
import Dropdown from './dropdown';

const fieldClasses = {
  group: GroupField,
  def: Field,
  dropdown: Dropdown,
  signature: SignatureField,
  initials: SignatureField,
  date: DateField,
  text: TextField,
  checkbox: CheckboxField,
  radiobutton: CheckboxField,
};

module.exports = BaseModel.extend({
  /**
   */

  save() {
    this.signatureRequest.save();
  },

  /**
   */

  fields: [],
  pages: [],

  /**
   */

  getValidationError() {
    // TODO
    this._rootField.validate();
  },

  /**
   */

  getRequiredFieldsLeftCount() {
    return this.fields.filter((field) => {
      return field.required && !field.hasValue();
    }).length;
  },

  /**
   */

  setFieldValues(type, value) {
    this.fields.forEach((field) => {
      if (field.type === type) {
        field.value = value;
      }
    });
  },

  /**
   */

  getNextInvalidField(start) {
    for (let i = (start || 0) + 1, n = this.fields.length; i < n; i++) {
      const field = this.fields[i];
      if (field.getValidationError()) {
        return field;
      }
    }
  },

  /**
   */

  getNextEditableFieldIndex(start) {
    for (let i = (start || 0) + 1, n = this.fields.length; i < n; i++) {
      const field = this.fields[i];
      if (field.editable) {
        return i;
      }
    }
    return -1;
  },

  /**
   */

  getPrevEditableFieldIndex(start) {
    for (let i = (start || this.fields.length) - 1; i >= 0; i--) {
      const field = this.fields[i];
      if (field.editable) {
        return i;
      }
    }
    return -1;
  },

  /**
   */

  getNextInvalidFieldIndex(start) {
    const reqField = this.getNextInvalidField(start);
    if (!reqField) {
      return -1;
    }
    return this.fields.indexOf(reqField);
  },

  /**
   */

  isValid() {
    return this.getNextInvalidField() == void 0;
  },

  /**
   */

  getHeight() {
    let h = 0;
    for (let i = 0; i < this.pages.length; i++) {
      h += this.pages[i].height;
    }
    return h;
  },

  /**
   */

  toData() {
    return {
      guid: this.guid,
      fields: this.fields.toData(),
    };
  },

  /**
   */

  fromData(data) {
    const pages = (data.pages || []).map((data) => {
      return Page.create({ data });
    });

    const rootField = recycle(this._rootField, GroupField, {
      snapshot: this,
      notifier: this.notifier,
      snapshots: this.snapshots,
      fieldClasses,
      signatureRequest: this,
      data: {
        root: true,
        children: data.fields,
      },
    });

    return {
      guid: data.guid,
      pages,
      fields: rootField.children,
      _rootField: rootField,
      numRequiredFields: rootField.children.map((field) => {
        return !!field.required;
      }).length,
      height: this.getHeight(),
    };
  },
});
