/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-restricted-syntax,
  no-void,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import Button from 'common/components/button';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import SuperAgent from 'superagent';
import createReactClass from 'create-react-class';
import { getBrandName } from 'js/sign-components/common/brand';

require('./template-link-login-or-signup.scss');

const messages = defineMessages({
  onSubmitErrorEmail: {
    id: '',
    description:
      'template link page, error message, error message displayed when user enters wrong email address',
    defaultMessage: 'Please enter a valid email address',
  },
  verifyEmailAddress: {
    id: '',
    description:
      'template link page, button text, asks user to verify their email address',
    defaultMessage: 'Verify your email address',
  },
  sendingButtonText: {
    id: '',
    description:
      'template link page, button text, informs user that sending is in progress',
    defaultMessage: 'Sending...',
  },
});

const TemplateLinkLoginOrSignup = createReactClass({
  propTypes: {
    shortGuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    csrf_token: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
  },

  getInitialState() {
    return {
      email: '',
      errors: {},
      processing: false,
    };
  },

  onEmailChange(e) {
    this.setState({
      email: e.target.value,
      errors: {
        email: void 0,
      },
    });
  },

  onSubmit(e) {
    const { intl } = this.props;
    e.preventDefault();
    const self = this;

    if (!HfReactHelper.isValidEmailAddress(this.state.email)) {
      this.setState({
        errors: {
          ...this.state.errors,
          email: intl.formatMessage(messages.onSubmitErrorEmail),
        },
      });

      return; // Don't submit bad emails
    }

    this.setState({ processing: true });

    const url = '/signer/getOrCreateAccountForTemplateLink';

    SuperAgent.post(url)
      .type('form')
      .send({ email: this.state.email })
      .send({ guid: this.props.shortGuid })
      .send({ csrf_token: this.props.csrf_token })
      .end((err, res) => {
        if (!err) {
          const data = JSON.parse(res.text);
          self.onSuccess(data);
        }
      });
  },

  onSuccess(data) {
    this.setState({ processing: false });

    if (this.props.onSuccess) {
      this.props.onSuccess(data);
    }
  },

  renderErrors() {
    const errors = [];
    for (const error in this.state.errors) {
      errors.push(this.state.errors[error]);
    }

    return errors;
  },

  render() {
    const { intl } = this.props;
    return (
      <div className="m-signer-mobile-template-link--login-or-signup row text-center bg-plume m-create-account--inline-container-vertical l-padding-v-20">
        <h4>{this.props.title}</h4>
        <div>
          <FormattedMessage
            id=""
            description="template link page, warning message, title, asks user to input their email address to progress further"
            defaultMessage="To review and sign, enter your email:"
          />
        </div>
        <form id="login-or-signup-form" onSubmit={this.onSubmit}>
          <input
            id="email"
            type="email"
            name="email"
            className="m-signer-mobile-template-link--login-or-signup--email"
            onChange={this.onEmailChange}
          />
          <Button
            data-testid="template-link-login-or-signup-submit"
            type="submit"
            buttonClass="center"
            buttonText={
              !this.state.processing
                ? intl.formatMessage(messages.verifyEmailAddress)
                : intl.formatMessage(messages.sendingButtonText)
            }
            buttonColor="cerulean"
            buttonHoverColor="castle-rock"
            buttonTabIndex="1"
          />
          <input type="hidden" name="guid" value={this.props.shortGuid} />
        </form>
        <div className="m-signer-mobile-template-link--login-or-signup--error">
          {this.renderErrors()}
        </div>
        <p className="m-signer-mobile-template-link--login-or-signup--tos">
          <FormattedMessage
            id=""
            description="template link page, link text, providing user with link to terms of service and privacy policy."
            defaultMessage="By verifying my email address, I accept the {brandName} <termslink>Terms</termslink> & <pplink>Privacy Policy</pplink>."
            values={{
              brandName: getBrandName('S'),
              termslink: (...chunks) => (
                <a
                  href={this.props.tosUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              pplink: (...chunks) => (
                <a
                  href={this.props.privacyPolicyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </p>
      </div>
    );
  },
});

module.exports = injectIntl(TemplateLinkLoginOrSignup);
